import React, { useState, useEffect } from "react";
import {
  Container,
  Grow,
  Grid,
  //  AppBar,
  //  TextField,
  //  Button,
  Paper,
  Typography,
  //  FormControlLabel,
  //rgbToHex,
  //  Tooltip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
//import ChipInput from "material-ui-chip-input";
//import { Multiselect } from "multiselect-react-dropdown";
//import JATF from "./JATF";
import {
  getMintedWisdoms,
  getProposedWisdoms,
  getStatusesToLoad,
  getWisdomsBySearch,
} from "../../actions/wisdoms";
import Wisdoms from "../Wisdoms/Wisdoms";
import WisdomForm from "../WisdomForm/WisdomForm";
import Pagination from "../Paginations/PaginateWisdoms";
import CookieConsent from "react-cookie-consent";
//import Radio from "@material-ui/core/Radio";
//import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
//import { YouTubeIcon } from "@material-ui/icons/YouTube";

import useStyles from "./styles";
import {
  //  PROPOSED_STRING,
  MINTED_STRING,
  DISPLAY_WISDOM_TYPE,
  DO_LOG,
  SHOW_BETA_TWEET,
  // APPROVED_STRING,
  // DECLINED_STRING,
  //  PROFILE,
} from "../../constants/constants";

import {
  getDisplayProfileValue,
  loadWebUser,
  setDisplayProfileValue,
} from "../myLocalStorage";

import * as perm from "../../constants/permissions.js";
import { isOnList } from "../../constants/permissions.js";
//import { CollectionsOutlined } from "@material-ui/icons";
import WisdomSearchToy from "../Wisdoms/Wisdom/WisdomSearchToy";
import TweetBeta from "../Tweet/TweetBeta";

//import { coinInCurrency } from "../../api";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = ({ reload }) => {
  const classes = useStyles();
  const query = useQuery();
  const page = query.get("page") || 1;
  const pageReload = query.get("pageReload") || false;
  //  const searchQuery = query.get("searchQuery");

  const [currentId, setCurrentId] = useState(0);
  const [editWisdom, setEditWisdom] = useState(false);
  const [isLoading] = useState(false);
  // const [coinInCurrency, setCoinInCurrency] = useState(
  //   useSelector((state) => state.wisdoms.coinInCurrency)
  // );

  const dispatch = useDispatch();

  //  const [searchText, setSearchText] = useState("");

  //  const [hashtagTexts, setHashtagTexts] = useState([]);
  // const [hashtagFilterArray, setHashtagFilterArray] = useState([]);

  const [forceReload, setForceReload] = useState(false);
  const [displayWisdomType, setDisplayWisdomType] = useState(
    getDisplayProfileValue(DISPLAY_WISDOM_TYPE, MINTED_STRING)
  );
  const history = useHistory();

  //console.log("Home>history=", history);
  //  console.log("Home>query=", query.get("search"));
  //  console.log("Home>searchQuery=", searchQuery);
  //console.log("Home>page=", page, "reload=", pageReload);

  //  var displayWisdomType = MINTED_STRING; // TODO pull from storage

  // const location = useLocation();
  // const currentUrl = location?.pathname + location?.search;
  // alert("home>currentUrl=" + currentUrl);

  const webUser = loadWebUser();
  //  const userId = webUser?._id;

  const serverHashtags = useSelector((state) => state?.hashtags?.hashtags);
  var doShuffle = false;

  // function handleHashtagChange(event) {
  //   console.log("handleHashtagChange>", event);

  //   const hashtagsFilter = [];
  //   let i = 0;
  //   for (i = 0; i < event.length; i++) {
  //     hashtagsFilter.push(event[i].hashtagText);
  //     console.log("handleHashtagChange>adding", event[i].hashtagText);
  //   }
  //   //if (reRender) reRender();
  //   setHashtagFilterArray(hashtagsFilter);
  //   setForceReload(true);
  // }

  const changeWisdomType = (value) => {
    //copy to storage
    //console.log("changeWisdomType>", value);
    setDisplayProfileValue(DISPLAY_WISDOM_TYPE, value);
    const webUser = loadWebUser();
    //console.log("changeWisdomType>", value, webUser?.name);

    // console.log(
    //   "changeWisdomType.after>",
    //   getDisplayProfileValue(DISPLAY_WISDOM_TYPE)
    // );
    setDisplayWisdomType(value);
    setForceReload(true);
    // commented 12/28/22
    //setIsLoading(true);
    const page = 1;
    // console.log(
    //   "changeWisdomType>dispatching now>value=",
    //   value,
    //   "page=",
    //   page,
    //   "webUser.name",
    //   webUser?.name
    // );
    if (value === MINTED_STRING)
      dispatch(getMintedWisdoms(page, webUser?.name));
    else dispatch(getProposedWisdoms(page, webUser?.name));
    history.push("/wisdoms?pageReload=true");
  };

  // const { coinInCurrency } = useSelector((state) => {
  //   console.log("Home>state.wisdoms=", state.wisdoms);
  //   return state.wisdoms;
  // });

  const searchWisdomShuffle = (_searchText, _hashtagFilterArray) => {
    doShuffle = true;
    searchWisdom(_searchText, _hashtagFilterArray);
  };
  const searchWisdom = (_searchText, _hashtagFilterArray) => {
    // build hashtagArray
    // var hashtagTextArray = [];
    // hashtagFilterArray.map((hashtag) => {
    //   hashtagTextArray.push(hashtag.hashtagText);
    // });

    const { innerWidth: screenWidth } = window;
    var theSearchText = _searchText || "";
    if (doShuffle) {
      //console.log("searchWisdom>shuffule", doShuffle);
      // inject search command word "shuffle" if no already present
      if (_searchText.toLowerCase().indexOf("shuffle") === -1) {
        theSearchText = "shuffle " + _searchText;
        document.getElementById("searchTextField").value = theSearchText;
      }
      doShuffle = false;
    }
    // console.log(
    //   "searchWisdom>search=",
    //   theSearchText,
    //   ",",
    //   hashtagFilterArray,
    //   ",",
    //   displayWisdomType,
    //   ",",
    //   screenWidth,
    //   ",",
    //   webUser?._id
    // );
    const statuses = getStatusesToLoad(displayWisdomType);
    if (theSearchText.trim().length > 0 || _hashtagFilterArray.length > 0) {
      //console.log("searchWisdom>**NO RELOAD");
      setForceReload(false);
      dispatch(
        getWisdomsBySearch({
          searchText: theSearchText || "",
          hashtagTexts: _hashtagFilterArray.join(","),
          status: statuses,
          authorId: "*",
          authorName: "*",
          ownerId: "*",
          ownerName: "*",
          page: "1",
          width: screenWidth.toString(),
          userName: webUser?.name || "unk",
          userId: webUser?._id || "unk",
        })
      );
      //      history.push("/");
      //history.push("/wisdoms");

      history.push(
        `/wisdoms/search?search=${
          theSearchText || ""
        }&hashtagTexts=${_hashtagFilterArray.join(
          ","
        )}&status=${statuses}&authorId=${"*"}&authorName=${"*"}&ownerId=${"*"}&ownerName=${"*"}&width=${screenWidth}&page=1`
      );
    } else {
      // no search by text or hashtag
      //console.log("searchWisdom>RELOAD");
      setForceReload(true);
      if (displayWisdomType === MINTED_STRING) dispatch(getMintedWisdoms());
      else dispatch(getProposedWisdoms());

      history.push("/");
    }
  }; // search wisdom

  // const handleSearchTextChange = (e) => {
  //   //console.log("handleSearchTextChange>", "____", e.target.value);
  //   //e.preventDefault();
  //   setSearchText(e.target.value);
  //   //    searchText += e.target.value;
  //   // force value into text field
  //   //    document.getElementById("searchTextField").value = searchText;

  //   //console.log("handleSearchTextChange>searchText=", searchText);

  //   //return false;
  // };

  // const handleKeyPress = (e) => {
  //   //    console.log("handleKeyPress>", "____", e.target);
  //   //e.preventDefault();
  //   setSearchText(e.target.value);
  //   //searchText = e.target.value;
  //   // const tf = document.getElementById("searchTextField");
  //   // if (tf) {
  //   //   console.log("YES tf");

  //   //   tf.value = e.target.value;
  //   // } else {
  //   //   console.log("NO tf");
  //   // }
  //   //console.log("handleKeyPress>", tf.value, "__", e.target.value);
  //   if (e.keyCode === 13) {
  //     searchWisdom(searchText || "");
  //   }
  //   // else {
  //   //   searchText += e.target.value;
  //   //   // force value into text field
  //   //   document.getElementById("searchTextField").value = searchText;
  //   // }
  //   //e.preventDefault();
  //   //return false;
  // };

  // const handleAddChip = (tag) => setHashtagTexts([...hashtagTexts, tag]);

  // const handleDeleteChip = (chipToDelete) =>
  //   setHashtagTexts(hashtagTexts.filter((tag) => tag !== chipToDelete));

  // const styleMulti = {
  //   chips: {
  //     background: "#3949ab", // "#303f9f",
  //   },
  //   searchBox: {
  //     border: "none",
  //     borderBottom: "1px solid blue",
  //     borderRadius: "0px",
  //     margin: ".5rem",
  //   },
  //   multiselectContainer: {
  //     color: "black",
  //   },
  // };

  useEffect(() => {
    //console.log("Home>useEffect>isLoading=", isLoading);
    //console.log("Home>useEffect>reload=", reload || forceReload || pageReload);
    if (reload || forceReload || pageReload) {
      setForceReload(false);

      //history.push("/");
    }

    // if (isLoading) {
    //   console.log("Home.useEffect>isLoading.");
    //   history.push("/");
    //   //      setIsLoading(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, displayWisdomType, forceReload, pageReload]);

  const { coinInCurrency } = useSelector((state) => {
    if (DO_LOG) console.log("Home>state.wisdoms=", state.wisdoms);
    return state.wisdoms;
  });

  //console.log("Home.Home>refresh");
  if (isLoading) {
    return <Typography variant="h4">Loading..</Typography>;
  } else {
    return (
      <div style={{ minWidth: "380px", paddingLeft: "10px" }}>
        <Grow in>
          <Container maxWidth="xl">
            {SHOW_BETA_TWEET && <br></br>}
            <Grid
              container
              justifyContent="space-between"
              alignItems="stretch"
              spacing={3}
              className={classes.gridContainer}
            >
              <Grid item xs={12} sm={6} md={3}>
                {SHOW_BETA_TWEET && <TweetBeta />}
                <WisdomSearchToy
                  searchWisdom={searchWisdom}
                  searchWisdomShuffle={searchWisdomShuffle}
                  changeWisdomType={changeWisdomType}
                  serverHashtags={serverHashtags}
                  showBetaTweet={SHOW_BETA_TWEET}
                />
                {!isOnList(webUser, perm.NOMINT_WISDOM.name) &&
                  !SHOW_BETA_TWEET && (
                    <WisdomForm
                      currentId={currentId}
                      setCurrentId={setCurrentId}
                      displayWisdomType={displayWisdomType}
                      editWisdom={editWisdom}
                      coinInCurrencyParent={coinInCurrency}
                    />
                  )}
                <Paper className={classes.pagination} elevation={6}>
                  <div style={{ minWidth: "360px", paddingLeft: "0px" }}>
                    <Pagination
                      page={page}
                      displayWisdomType={displayWisdomType}
                      forceReload={forceReload || pageReload}
                      userName={webUser?.name}
                    />
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6} md={9}>
                <Wisdoms
                  setCurrentId={setCurrentId}
                  displayWisdomType={displayWisdomType}
                  setEditWisdom={setEditWisdom}
                  //setCoinInCurrency={setCoinInCurrency}
                />
              </Grid>
            </Grid>
          </Container>
        </Grow>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="miwConsentCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "18px" }}
          expires={150}
        >
          This website uses only 1 cookie{" "}
          <span style={{ fontSize: "16px" }}>(to remember this consent!)</span>
        </CookieConsent>
      </div>
    );
  }
};

export default Home;
