import { AUTH } from "../constants/actionTypes";
import * as api from "../api/index.js";

export const signin = (formData, router) => async (dispatch) => {
  //var data = {};
  try {
    const { data } = await api.signIn(formData);
    //console.log("auth.signin>data= ", data);
    // NOTE: data.token is set
    //    console.log("auth.signin>data= ", data?.message);
    //    console.log("auth.signin>data= ", data?.reset);

    // TODO: handle error messages
    if (formData?.resetPassword) {
      alert(data?.message);
      // if (data?.reset) {
      //   alert("Reset password email sent.");
      // } else {
      //   alert("Reset password email NOT sent.");
      // }
    } else {
      // Should token be included?
      //const reply =
      await dispatch({ type: AUTH, data });
      //console.log("auth.signin>reply = ", reply);
      // TODO increment loginCount in local storage
      router.push("/");
    }
  } catch (error) {
    console.log("auth.signin>error = ", error);
    //console.log(data);
    alert("Invalid login/password");
  }
};
//
export const signup = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);

    dispatch({ type: AUTH, data });

    router.push("/");
  } catch (error) {
    console.log(error);
  }
};
//
export const changePassword = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.changePassword(formData);
    //console.log("auth.changePassword>data= ", data);

    // TODO: handle error messages
    //const reply =
    await dispatch({ type: AUTH, data });
    //console.log("auth.changePassword>reply = ", reply);
    router.push("/");
  } catch (error) {
    console.log("auth.changePassword>error = ", error);
    console.log(error);
    alert("Invalid change password login/password");
  }
};
