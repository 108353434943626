//
//
import { combineReducers } from "redux";

import wisdoms from "./wisdoms";
import auth from "./auth";
import users from "./users";
import hashtags from "./hashtags";

export const reducers = combineReducers({ wisdoms, auth, users, hashtags });
