//
//
//
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pagination: {
    borderRadius: 4,
    marginTop: "1rem",
    padding: "16px",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
  //
  hashtagMulti: {
    chips: {
      background: "teal",
    },
    searchBox: {
      border: "none",
      borderBottom: "1px solid blue",
      borderRadius: "3px",
    },
    multiselectContainer: {
      color: "green",
      marginBottom: "1rem",
    },
  },
  appBarSearch: {
    borderRadius: 4,
    marginBottom: "1rem",
    display: "flex",
    padding: "16px",
  },

  searchButtonSearch: {
    width: "60%",
  },
  searchButtonShuffle: {
    width: "30%",
  },
}));
