//
//
//
//import { SettingsApplicationsOutlined } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
//import { logEvent } from "../../api";
//import { Container, Grow, Typography } from "@material-ui/core";

import useStyles from "./styles";

function AlertBanner({
  children,
  type,
  message,
  showNow,
  theKey,
  pages = false,
  startPageNumber,
  handleClose,
}) {
  //
  const doLog = false;
  var showItNow = showNow;
  const classes = useStyles();
  const [isShow, setIsShow] = useState(showNow);
  const [pageNum, setPageNum] = useState(startPageNumber);

  //
  const renderElAlert = function () {
    return React.cloneElement(children);
  };
  //
  const renderPage = () => {
    if (pages && pages?.pages.length > 0) {
      //console.log("renderPage>has", pages?.pages.length, "pages");
      if (pageNum === -1 && message !== "") {
        //console.log("renderPage>initial page");
        return message;
      }
      //console.log("renderPage>show page number", pageNum);

      return pages?.pages[pageNum]?.text || " ";
    } else {
      //console.log("renderPage>no pages, show initial message");
      return message;
    }
  }; // render page
  //
  const handleTheClose = (e) => {
    e.preventDefault();
    if (doLog) console.log("Alert.handleClose>");
    showItNow = false;
    setIsShow(false);
    handleClose();
  };
  //
  //
  const handleNextPage = (e) => {
    e.preventDefault();
    if (doLog) console.log("Alert.handleNextPage>");
    // loop around if needed
    if (pageNum >= pages?.pages.length - 1) setPageNum(startPageNumber);
    else setPageNum(pageNum + 1);
  };
  //
  const handlePrevPage = (e) => {
    e.preventDefault();
    if (doLog) console.log("Alert.handlePrevPage>");
    // loop around if needed
    if (pageNum <= startPageNumber) setPageNum(pages?.pages.length - 1);
    else setPageNum(pageNum - 1);
  };
  //
  //
  const hasPages = () => {
    if (pages) {
      return pages?.pages.length > 0;
    }
    return false;
  }; // has pages
  //
  //
  if (doLog)
    console.log(
      "Alert>type=",
      type,
      "message=",
      message,
      "showNow",
      showNow,
      "isShow",
      isShow
    );

  useEffect(() => {
    if (showItNow) {
      if (doLog) console.log("******* show it now! ********");
    } else {
      if (doLog) console.log("******* NO show now! ********");
    }
  });

  return (
    // <div className={css(style.alert, style[type], !isShow && style.hide)}>
    // <div className=" {classes.success}  {classes.alert} {!isShow && classes.hide)}">

    <>
      {showItNow && (
        <div key={theKey} className={classes[type]}>
          <>
            <span className={classes.closebtn} onClick={handleTheClose}>
              Close &times;
            </span>
            {hasPages() && (
              <div>
                <span className={classes.prevbtn} onClick={handlePrevPage}>
                  &lt; Prev
                </span>{" "}
                <span className={classes.nextbtn} onClick={handleNextPage}>
                  Next &gt;
                </span>
              </div>
            )}
            <br />

            {children ? renderElAlert() : renderPage()}
          </>
        </div>
      )}
      {!showItNow && <> </>}
    </>
  );
} // Alert

export default AlertBanner;
