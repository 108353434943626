import React from "react";
import { Container, Grow, Typography } from "@material-ui/core";
//import { useDispatch } from "react-redux";
//import { useLocation } from "react-router-dom";

//import useStyles from "./styles";
// import {
//   PROPOSED_STRING,
//   MINTED_STRING,
//   DISPLAY_WISDOM_TYPE,
// } from "../../constants/constants";

// import {
//   getDisplayProfileValue,
//   setDisplayProfileValue,
// } from "../localStorage";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const Stats = () => {
  // const classes = useStyles();
  // const query = useQuery();
  //   const dispatch = useDispatch();

  //   const history = useHistory();

  //  var displayWisdomType = MINTED_STRING; // TODO pull from storage

  return (
    <Grow in>
      <Container maxWidth="xl">
        <Typography variant="h6">
          Stats..
          <br></br>
          Stats..
          <p />
        </Typography>
      </Container>
    </Grow>
  );
};

export default Stats;
