import React, { useState, useEffect } from "react";
import { Container, Grow, Button, Paper, Typography } from "@material-ui/core";
//import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

// from hardhat react demo
// hardhat
//import MakeItWisdomContract from "../../artifacts/contracts/MakeItWisdomContract.sol/MakeItWisdomContract.json";
import PioneerContract from "../../artifacts/contracts/PioneerContract.sol/PioneerContract.json";
import { pioneeraddress } from "../../contractManifest.js";

//import { getPioneersServer } from "../../actions/pioneers";
import * as api from "../../api";

import useStyles from "./styles";
import { loadWebUser } from "../myLocalStorage";
import { COIN_LABEL, DO_LOG } from "../../constants/constants";
import AlertBanner from "../AlertBanner/AlertBanner.js";
import { getMyBCConfig } from "../../configs/multiConfig.mjs";

//console.log("Pioneer>pioneeraddress=", pioneeraddress);
//  hardocded for testing
var pioneerMax = 0;
var pioneerCount = 0;
//const pioneerMintPrice = 100;
const pioneerResidual = 1;

const Pioneers = () => {
  const [pioneerLoadingState, setPioneerLoadingState] =
    useState("pioneerLoading");

  const [pioneerAddressArray, setPioneerAddressArray] = useState([0, 0, 0]);
  const [pioneerMintPrices, setPioneerMintPrices] = useState([0, 0, 0]);

  const { library, account, chainId } = useWeb3React();
  //
  const handleCloseAlertLoaded = () => {
    // changing state should cause rerender
    setAlertBannerLoaded({ ...alertBannerLoaded, showNow: false });
  };
  const alertKeyLoaded = "PioneerAlertKeyLoaded";
  const [alertBannerLoaded, setAlertBannerLoaded] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKeyLoaded,
    handleClose: handleCloseAlertLoaded,
  });
  //
  //
  const handleCloseAlertUnloaded = () => {
    // changing state should cause rerender
    setAlertBannerUnloaded({ ...alertBannerUnloaded, showNow: false });
  };
  const alertKeyUnloaded = "PioneerAlertKeyUnloaded";
  const [alertBannerUnloaded, setAlertBannerUnloaded] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKeyLoaded,
    handleClose: handleCloseAlertUnloaded,
  });
  //
  const { networkName } = getMyBCConfig();

  // hardhat
  //  const accounts = await web3.eth.getAccounts();
  //  const web3React = useWeb3React();
  //
  //web3React.activate(injected, undefined, true);
  //
  //setTimeout(() => web3React.activate(injected, undefined, true), 500);
  //

  // web3React.activate(
  //   new InjectedConnector(
  //     {
  //       supportedChainIds: [1, 3, 4, 5, 42, 137, 31337, 80001]
  ////[1, 3, 4, 5, 42, 31337],
  //     },
  //     undefined,
  //     true
  //   )
  // );

  const classes = useStyles();
  const history = useHistory();

  const webUser = loadWebUser();

  //
  async function mintPioneerContract(_pos, _pioneerMintPrice) {
    if (library) {
      if (DO_LOG)
        console.log(
          "mintPioneerContract>pos=",
          _pos,
          "price=",
          _pioneerMintPrice
        );

      const signer = library.getSigner();
      const pioneerContract = new ethers.Contract(
        pioneeraddress,
        PioneerContract.abi,
        signer
      );
      if (DO_LOG) console.log("mintPioneerContract>signer=", signer);

      // console.log("mintPioneerContract>sending value=", priceInEth.toString());
      const nowMS = Math.round(Date.now() / 1000);
      if (DO_LOG)
        console.log(
          "mintPioneerContract>sending value=",
          _pioneerMintPrice.toString()
        );

      try {
        const tx = await pioneerContract.safeMintPioneer(_pos, nowMS, {
          value: _pioneerMintPrice.toString(),
        });
        var receipt = await tx.wait();
        //
        if (DO_LOG)
          console.log("mintPioneerContract>DONE. transaction=", receipt);

        // TODO check mint pioneer receipt for event. see buyWisdomContract
        api.logEvent({
          eventName: "mintPioCon",
          data1: _pos.toString(),
          data2: _pioneerMintPrice.toString(),
        });

        // determine from receipt that transaction completed
        //    and process returned values in the event
        if (receipt?.events.length > 0) {
          // check each event?
          for (let i = 0; i < receipt.events.length; ++i) {
            // pull data from event
            // uint256 come back as BigNumber
            // wisdomId, forSalePrice, saleStartDateMS, saleEndDateMS
            const event = receipt.events[i];
            if (DO_LOG) console.log("mintPioneerContract>   event=", event);
            if (event?.event === "SafeMintPioneerEvent") {
              if (_pos.toString() === event?.args?.pos.toString()) {
                if (webUser?.wallet === event?.args?.owner) {
                  //
                  // event is for us!
                  return receipt;
                } else {
                  // console.log(
                  //   "mintPioneerContract>wrongOwnerWallet. eventWallet=",
                  //   event?.args?.owner?.toString(),
                  //   "webUserWallet=",
                  //   webUser?.wallet
                  // );
                  //receipt = null;
                }
              } else {
                // console.log(
                //   "mintPioneerContract>wrongPioneerPosition=",
                //   event?.args?.pos?.toString()
                // );
                //receipt = null;
              }
            } else {
              console.log("mintPioneerContract>wrongEvent=", event.event);
              //receipt = null;
            }
          } // each event in receipt
        } else {
          console.log("mintPioneerContract>No Events In Receipt");
          receipt = null;
        }

        return receipt;
      } catch (err) {
        console.log("mintPioneerContract>ERROR", err);
        if (err?.data?.message) {
          const i = err?.data?.message.indexOf("custom error");
          if (i !== -1) {
            console.log(
              "mintPioneerContract>ERROR=",
              err?.data?.message.substring(i + 13)
            );
            //alert(err?.data?.message.substring(i + 13));
            //
            setAlertBannerLoaded({
              children: null,
              type: "error",
              message: err?.data?.message.substring(i + 13),
              showNow: true,
              theKey: alertKeyLoaded,
              handleClose: handleCloseAlertLoaded,
            });
          }
        }
        console.log("mintPioneerContract>ERROR=", err?.data?.message);
      }
    } else {
      if (DO_LOG) console.log("mintPioneerContract>NO LIBRARY");
    }
    return null;
  } // mint pioneer contract
  //
  //
  const mintPioneerServer = async (_pos, _pioneerMintPrice) => {
    var result = false;
    if (DO_LOG)
      console.log(
        "mintPioneerServer>_pos=",
        _pos,
        "_pioneerMintPrice=",
        _pioneerMintPrice
      );
    // const priceInEth = ethers.utils.parseUnits(
    //   _pioneerMintPrice.toString(),
    //   "ether"
    // );
    const webUser = loadWebUser();

    if (webUser) {
      const pioneer = {
        email: webUser?.email,
        ownerId: webUser?._id,
        twitterHandle: webUser.result?.twitterHandle,
        position: _pos,
        price: _pioneerMintPrice.toString(), // wei
        ownerName: webUser?.name,
      };

      if (DO_LOG) console.log("mintPioneerServer>pioneer=", pioneer);
      //const { data } =
      await api.mintPioneer(pioneer);
      // TODO check data for success/fail
      result = true;
      api.logEvent({
        eventName: "mintPioServ",
        data1: _pos.toString(),
        data2: _pioneerMintPrice.toString(),
      });

      //console.log("mintPioneerServer>pioneer=", data);
    } else {
      console.log("mintPioneerServer>NO webUser");
      //
      setAlertBannerLoaded({
        children: null,
        type: "error",
        message: "Sign in to your MakeItWisdom account.",
        showNow: true,
        theKey: alertKeyLoaded,
        handleClose: handleCloseAlertLoaded,
      });
      result = false;
    }
    return result;
  }; // mint pioneer server

  async function handleMintPioneer(_pos) {
    // TODO confirm!
    //    alert(_pos.toString() + ", " + pioneerMintPrices[_pos].toString());
    //
    const webUser = loadWebUser();

    if (webUser) {
      const mintPriceEth = ethers.utils.formatUnits(
        pioneerMintPrices[_pos].toString(),
        "ether"
      );
      const receipt = await mintPioneerContract(_pos, pioneerMintPrices[_pos]);
      // TODO check mintPioneerContract success
      if (receipt) {
        if (DO_LOG) console.log("handleMintPioneer>receipt=", receipt);
        const result = await mintPioneerServer(_pos, mintPriceEth);
        // check server response success
        if (result) {
          // success!
          setAlertBannerLoaded({
            children: null,
            type: "success",
            message:
              "Congratulations! You are now Pioneer " +
              (_pos + 1).toString() +
              ". Your residuals will accumulate in the MakeItWisdom smart contract. Visit the Transfer page any time ( see menu ) and transfer them to your wallet.",
            showNow: true,
            theKey: alertKeyLoaded,
            handleClose: handleCloseAlertLoaded,
          });
        } else {
          //
        }
        // alert(
        //   "Congratulations! You are now Pioneer " +
        //     (_pos + 1).toString() +
        //     ". Your residuals will accumulate in the MakeItWisdom smart contract until you visit the Transfer page ( see menu ) and transfer them to your wallet."
        // );
        setPioneerLoadingState("pioneerLoading");
      } else {
        console.log("handleMintPioneer>tx is NULL");
      }
      history.push("/pioneers");
    } else {
      //alert("Sign in to your MakeItWisdom.com account. Then connect to your wallet. Then try again.");
      //
      setAlertBannerLoaded({
        children: null,
        type: "error",
        message:
          "Sign in to your MakeItWisdom.com account. Then connect to your wallet. Then try again.",
        showNow: true,
        theKey: alertKeyLoaded,
        handleClose: handleCloseAlertLoaded,
      });
    }
  } // handle mint pioneer

  function noSciNotation(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    if (DO_LOG) console.log("toFixed>", x.toString());
    return x;
  }

  useEffect(() => {
    const loadPioneerDataContract = async () => {
      if (library) {
        //console.log("Pioneer.loadPioneerDataContract>YES library");
        const provider = await library;
        // const makeItWisdomContract = new ethers.Contract(
        //   makeitwisdomaddress,
        //   MakeItWisdomContract.abi,
        //   provider
        // );

        // if (!makeItWisdomContract) {
        //   if (DO_LOG)
        //     console.log("loadPioneerDataContract>NO MakeItWisdomContract2");
        //   return;
        // }

        const pioneerContract = new ethers.Contract(
          pioneeraddress,
          PioneerContract.abi,
          provider
        );
        if (DO_LOG) console.log("loadPioneerDataContract>provider=", provider);
        const signer = library.getSigner();
        if (DO_LOG) console.log("loadPioneerDataContract>signer=", signer);

        try {
          //console.log("loadPioneerDataContract>", pioneeraddress);
          const pioneerMaxString = await pioneerContract.pioneerMax();
          pioneerMax = parseInt(pioneerMaxString);
          //console.log("getPioneerMintPrices>pioneerMax=", pioneerMax);

          const mintPriceArray =
            await pioneerContract.getPioneerMintPriceArray();
          if (DO_LOG) console.log("getPioneerMintPrices>", mintPriceArray);
          if (DO_LOG)
            console.log(
              "getPioneerMintPrices>[0]",
              mintPriceArray[0].toString()
            );
          var mintPriceIntArray = [0, 0, 0];

          mintPriceIntArray[0] = noSciNotation(mintPriceArray[0]).toString(); //parseInt(mintPriceArray[0].toString());
          mintPriceIntArray[1] = noSciNotation(mintPriceArray[1]).toString(); //parseInt(mintPriceArray[1].toString());
          mintPriceIntArray[2] = noSciNotation(mintPriceArray[2]).toString(); //parseInt(mintPriceArray[2].toString());

          const addressArray = await pioneerContract.getPioneers();

          pioneerCount = 0;
          for (let i = 0; i < pioneerAddressArray.length; i++) {
            if (
              addressArray[i] !== "0x0000000000000000000000000000000000000000"
            )
              ++pioneerCount;
          }
          setPioneerMintPrices(mintPriceIntArray);
          setPioneerAddressArray(addressArray);

          if (DO_LOG) {
            console.log("getPioneerMintPrices>", mintPriceIntArray);
            console.log("getPioneerMintPrices>addresses=", addressArray);
          }
          setPioneerLoadingState("pioneerLoaded");
          if (DO_LOG) console.log("Pioneer.loadPioneerData>LOADED");
        } catch (error) {
          //
          //console.log("loadPioneerDataContract>Contract not visible");
          // alertBannerLoaded
          setAlertBannerUnloaded({
            children: null,
            type: "error",
            message:
              "ERROR: Be certain your wallet points to the " +
              networkName +
              " network. Wallet Disconnect, reconnect. Maybe reload this webpage.",
            showNow: true,
            theKey: alertKeyUnloaded,
            handleClose: handleCloseAlertUnloaded,
          });
        }
      } else {
        if (DO_LOG) console.log("Pioneer.loadPioneerData>NO library");
      }
    }; // load pioneer data contract
    // djf commented 3/14/22
    //web3React.activate(injected, undefined, true);
    loadPioneerDataContract().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [library, account, chainId, pioneerLoadingState]);

  // useEffect(() => {
  //   //loadPioneerDataContract();
  // }, [library, account, chainId]);

  if (DO_LOG) console.log("Pioneer>pioneerLoadingState=", pioneerLoadingState);

  if (pioneerLoadingState === "pioneerLoading") {
    // console.log("Pioneer.loadPioneerData>FAKE LOADED");
    // setPioneerLoadingState("pioneerLoaded");
    return (
      <div>
        <AlertBanner {...alertBannerUnloaded}></AlertBanner>

        <Typography variant="h6">
          A pioneer pays a one time price to earn a 1% residual every time any
          Wisdom is bought/sold, forever.
          <p>
            Sign in and connect your crypto wallet to the website to unlock the
            mint pioneer feature on this page.
          </p>
          <p>
            See also <a href="/getstarted">Get Started</a> on the menu
          </p>
        </Typography>
      </div>
    );
  }
  //
  // TODO build array of pioneers so .map can lay down mint pioneer buttons properly
  //
  const walletStr = webUser?.wallet ? webUser?.wallet : "Wallet unknown";
  return (
    <Grow in>
      <Container maxWidth="xl">
        <Paper
          elevation={8}
          style={{
            padding: 12,
            // backgroundColor: "blue",
            border: "1px solid black",
          }}
        >
          <AlertBanner {...alertBannerLoaded}></AlertBanner>
          <Typography variant="h6">
            A pioneer pays a one time price to earn a {pioneerResidual}%
            residual every time any Wisdom is bought/sold (not minted), forever.
            <br></br>
            MakeItWisdom allows only {pioneerMax} pioneers.{" "}
            {pioneerMax - pioneerCount} of {pioneerMax} pioneer positions are
            available.
            <br />
            Each of the 3 pioneers earns the same revenue share.
            <p /> <strong>No refunds. No resales. No transfers.</strong>
            <p />
            Your share accumulates automatically on a MakeItWisdom smart
            contract.
            <br />
            Visit the Transfer page (on menu and link below) with the Pioneer
            wallet to move your coins to that wallet.
            <p />
            You are connected right now with this wallet:
            <br />
            {walletStr}
            <p />
            Important: The crypto wallet used to mint a Pioneer position becomes
            the wallet used for residuals. The smart contract makes this
            immutable on the blockchain. It can't be changed. You must keep this
            wallet secure. It is the only way to access Pioneer residuals on the
            Transfer page.
          </Typography>
          <p />
          {pioneerAddressArray.map((pioneer, pos) => (
            <Button
              key={"mintButton" + pos.toString()}
              onClick={() => handleMintPioneer(pos)}
              className={classes.mintButton}
              variant="contained"
              color="primary"
              disabled={
                pioneerAddressArray[pos] !==
                "0x0000000000000000000000000000000000000000"
              }
            >
              Mint Pioneer #{pos + 1} (
              {ethers.utils
                .formatUnits(pioneerMintPrices[pos].toString(), "ether")
                .replace(".0", "")}{" "}
              {COIN_LABEL})
            </Button>
          ))}
          <p />
          <h3>
            See also:
            <br />
            <div className={classes.seeAlsoDiv}>
              <a href="/transfer">Transfer.</a>
              <br />
              <a href="/faqs">FAQ.</a>
            </div>
          </h3>
        </Paper>
      </Container>
    </Grow>
  );
};

export default Pioneers;
