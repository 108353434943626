import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";
import { lightTheme, darkTheme } from "../Themes.js";

const theme = window.localStorage.getItem("theme");
const themeMode = theme === "dark" ? darkTheme : lightTheme;

export default makeStyles((theme) => ({
  linkbutton: {
    height: "40px",
  },
  card: {
    display: "flex",
    width: "100%",
    margin: "5px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      flexDirection: "column",
    },
  },
  commentsOuterContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  commentsInnerContainer: {
    height: "200px",
    overflowY: "auto",
    marginRight: "30px",
  },

  imageSection: {
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  // hashtagButton: {
  //   textDecoration: "none",
  //   transition: "all 0.3s ease-in-out",
  //   "&:hover": {
  //     color: "rgba(10,10,99,1.0)",
  //     size: "large",
  //   },
  // },
  loadingPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
    height: "39vh",
  },
  media: {
    borderRadius: "20px",
    objectFit: "cover",
    width: "100%",
    maxHeight: "600px",
  },
  recommendedWisdoms: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  section: {
    borderRadius: "20px",
    margin: "1px",
    padding: "10px",
    color: themeMode.text,
    background: themeMode.background,

    flex: 1,
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  btnhelp: {
    background: "none",
    border: "none",
    padding: "0px 15px 0 0",

    flex: 1,
  },
  boundaryRow: {
    display: "flex",
  },
  boundaryHelp: { flex: 1 },
  boundaryCheck: { flex: 1 },
  boundaryText: { flex: 20 },
  voteBox: {
    display: "flex",
  },
  voteButton: {
    flex: 1,
  },

  brandContainer: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: "1rem",
    width: "100%",
    padding: "0px 0px 0px 150px",
  },
  image: {
    marginLeft: "10px",
    marginTop: "5px",
  },
}));
