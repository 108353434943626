import {
  DISPLAY_FREE_HASHTAGS_ONLY,
  DISPLAY_PROFILE,
  DISPLAY_WISDOM_TYPE,
  MINTED_STRING,
  PROFILE,
} from "../constants/constants.js";

var profile = false;
var displayProfile;
//var contractProfile;
const doLog = false;

//
// PROFILE is user account profile
//
export function loadWebUser() {
  // console.log(
  //   "loadWebUser>PROFILE=",
  //   JSON.parse(localStorage.getItem(PROFILE))
  // );
  //  return JSON.parse(localStorage.getItem(PROFILE))?.result;
  profile = getProfile();
  if (profile?.result) {
    return profile.result;
  } else {
    return profile;
  }
  //  return getProfile()?.result;
}

export function saveProfile(_profile) {
  if (doLog) console.log("saveProfile>", JSON.stringify(_profile, null, 3));
  profile = _profile;
  localStorage.setItem(PROFILE, JSON.stringify({ ..._profile }));
}

export function saveProfileString(_profileString) {
  if (doLog) console.log("saveProfileString>", _profileString);
  profile = JSON.parse(_profileString);
  localStorage.setItem(PROFILE, _profileString);
}

export function getProfile() {
  // profile = JSON.parse(localStorage.getItem(PROFILE));
  // return profile;
  if (profile) return profile;
  else return JSON.parse(localStorage.getItem(PROFILE));
}

export function setProfileValue(field, value) {
  if (doLog) console.log("setProfileValue>field=", field, "value=", value);
  var profile = getProfile();
  if (profile) {
    profile[field] = value;
    // if (field !== "preventAnimation")
    //   console.log("setProfileValue>setting field=", field, "value=", value);
    localStorage.setItem(PROFILE, JSON.stringify({ ...profile }));
    if (doLog)
      console.log("setProfileValue>set field", field, "value=", profile[field]);
  } else {
    if (doLog) console.log("setProfileValue>NO PROFILE", field, value);
  }
}

export function getProfileValue(field, defaultValue) {
  var profile = getProfile();
  var ans = defaultValue;
  if (profile) {
    ans = profile[field] || defaultValue;
    if (doLog)
      console.log("getProfileValue>setting field=", field, "value=", ans);
  }
  return ans;
}

//
//
//

// display profile are the variables user can set to
//  adjust what they see.
//    1.  minted or proposed wisdom
//    2.  free hashtags or all hashtags
//
export function getDisplayProfile() {
  displayProfile = JSON.parse(localStorage.getItem(DISPLAY_PROFILE));
  if (doLog) console.log("getDisplayProfile>", displayProfile);
  if (!displayProfile) {
    // make one!
    if (doLog) console.log("getDisplayProfile>make one!");
    displayProfile = {};
    displayProfile[DISPLAY_WISDOM_TYPE] = MINTED_STRING;
    displayProfile[DISPLAY_FREE_HASHTAGS_ONLY] = false;
    localStorage.setItem(DISPLAY_PROFILE, JSON.stringify(displayProfile));
  }
  return displayProfile;
}

export function getDisplayProfileValue(field, defaultValue) {
  displayProfile = getDisplayProfile();
  try {
    if (doLog)
      console.log(
        "getDisplayProfileValue>field,value=",
        field,
        displayProfile[field]
      );
    if (!displayProfile.hasOwnProperty(field)) {
      // field missing so add default
      displayProfile[field] = defaultValue;
      //localStorage.setItem(PROFILE, JSON.stringify(displayProfile));
    } else {
      return displayProfile[field];
    }
  } catch (err) {
    if (doLog) console.log("getDisplayProfileValue>no profile:err=", err);
  }
  if (doLog)
    console.log(
      "getDisplayProfileValue>field=",
      field,
      ",displayProfile",
      displayProfile
    );
  return defaultValue;
}

export function setDisplayProfileValue(field, value) {
  if (doLog)
    console.log("setDisplayProfileValue>displayProfile1", displayProfile);
  if (!displayProfile) getDisplayProfile();

  displayProfile[field] = value;
  if (doLog)
    console.log("setDisplayProfileValue>displayProfile2", displayProfile);

  localStorage.setItem(DISPLAY_PROFILE, JSON.stringify(displayProfile));
}

export function clearMyStorage() {
  profile = false;
  localStorage.clear();
}
//
//TODO: duplicate and rewrite displayProfile to create contractProfile
//
//  take contract name as parm
//    retrieve from contract as use requires
//    store in local storage nowMS of last refresh to throttle overhead
//  change contractSetting components to use contractProfile
//  change everywhere that uses constants that really live in contracts
//
