import { Container, Grow, Paper } from "@material-ui/core";
import React from "react";
//import ReactDOM from "react-dom";
import FilterToyHelp from "./filterToyHelp";
import useStyles from "./styles";

// try using QA json and .map
export default function FilterToyHelps() {
  const classes = useStyles();

  function buildOne(afaq) {
    // var link = afaq.link
    //   ? "<a href='" + afaq.link + "'>" + afaq.linkText + "</a>"
    //   : "";
    return (
      <FilterToyHelp.QAItem key={afaq.id}>
        <FilterToyHelp.Question answerId={afaq.id}>
          {(isOpen, onToggle) => {
            return (
              <>
                {/* {isOpen ? 'Open ' : 'Close '} */}
                <span>{afaq.ques}</span>
              </>
            );
          }}
        </FilterToyHelp.Question>
        <FilterToyHelp.Answer id={afaq.id} className={classes.FAQ__question}>
          {afaq.ans}
          <a href={afaq.link} target={afaq?.newTab}>
            {afaq.linkText}{" "}
          </a>
        </FilterToyHelp.Answer>
      </FilterToyHelp.QAItem>
    );
  }

  return (
    <Grow in>
      <Container maxWidth="xl" id="FAQs" className={classes.FAQs}>
        <Paper
          elevation={8}
          style={{
            padding: 12,
            // backgroundColor: "blue",
            border: "1px solid black",
          }}
        >
          <FilterToyHelp>
            {/* {buildFAQ()} */}
            {faqArray.map(buildOne)}
          </FilterToyHelp>
        </Paper>
      </Container>
    </Grow>
  );
} // FAQs

const faqArray = [
  {
    id: "q1",
    ques: "What is the CallIn app?",
    ans: "CallIn is the Social podcast app for iPhone, iPad and Android created by @DavidSacks, COO & co-founder of PayPal.",
    link: "https://www.callin.com/",
    newTab: "_blank",
    linkText: "Join at CallIn.com and install the app.",
  },
  {
    id: "q1a",
    ques: "What is in the pilot episode of the Making It Wisdom show?",
    ans: "Intro to MakeItWisdom.com. Big Tech & Free Speech. Why #Fallacy?",
    link: "https://www.callin.com/episode/making-it-wisdom-pilot-sneak-peak-yOdVRyWVZP",
    newTab: "_blank",
    linkText: "Making It Wisdom - The Pilot.",
  },
  // {
  //   id: "q",
  //   ques: "",
  //   ans: "",
  //   newTab: "",
  // },
];
