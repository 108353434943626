import React from "react";

import { useHistory } from "react-router-dom";

import {
  Drawer,
  //  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import Box from "@material-ui/core/Box";
//import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import {
  InfoOutlined,
  Settings,
  QuestionAnswerOutlined,
  CropSquareOutlined,
  MoveToInboxOutlined,
  EmailOutlined,
  PlayCircleOutlineOutlined,
  HomeOutlined,
  AttachMoneyOutlined,
  PriorityHighOutlined,
  //CallInIcon
} from "@material-ui/icons";

//  SendOutlined,
//  SubjectOutlined,
//  PlayCircleFilledOutlined,

//import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

//import useStyles from "./styles";
import { LIST_ITEM, DIVIDER } from "../../constants/constants";
import { logEvent } from "../../api";

const menuItems = [
  {
    type: LIST_ITEM,
    text: "Home",
    icon: <HomeOutlined color="secondary" />,
    path: "/",
  },
  {
    type: LIST_ITEM,
    text: "Get Started",
    icon: <PlayCircleOutlineOutlined color="secondary" />,
    path: "/getstarted",
  },
  {
    type: LIST_ITEM,
    text: "Invite",
    icon: <EmailOutlined color="secondary" />,
    path: "/invites",
  },
  // {
  //   type: LIST_ITEM,
  //   text: "Search Help",
  //   icon: <EmailOutlined color="secondary" />,
  //   path: "/filtertoyhelps",
  // },
  {
    type: DIVIDER,
    text: "divider1",
    icon: "",
    path: "",
  },
  {
    type: LIST_ITEM,
    text: "Residual NFT",
    icon: <PriorityHighOutlined color="secondary" />,
    path: "/residualnft",
  },
  {
    type: LIST_ITEM,
    text: "Pioneers",
    icon: <AttachMoneyOutlined color="secondary" />,
    path: "/pioneers",
  },
  {
    type: LIST_ITEM,
    text: "Transfer",
    icon: <MoveToInboxOutlined color="secondary" />,
    path: "/transfer",
  },
  {
    type: DIVIDER,
    text: "divider2",
    icon: "",
    path: "",
  },
  // {
  //   type: LIST_ITEM,
  //   text: "Swipe",
  //   icon: <SubjectOutlined color="secondary" />,
  //   path: "/swipe",
  // },
  // V2
  // {
  //   type: LIST_ITEM,
  //   text: "Stats",
  //   icon: <SubjectOutlined color="secondary" />,
  //   path: "/stats",
  // },
  // {
  //   type: DIVIDER,
  //   text: "divider3",
  //   icon: "",
  //   path: "",
  // },
  // {
  //   type: LIST_ITEM,
  //   text: "Authors",
  //   icon: <SubjectOutlined color="secondary" />,
  //   path: "/authors?pageReload=true",
  // },
  // {
  //   type: LIST_ITEM,
  //   text: "Hashtags",
  //   icon: <SubjectOutlined color="secondary" />,
  //   path: "/hashtags",
  // },
  // {
  //   type: DIVIDER,
  //   text: "divider4",
  //   icon: "",
  //   path: "",
  // },
  {
    type: LIST_ITEM,
    text: "Boundaries",
    icon: <CropSquareOutlined color="secondary" />,
    path: "/boundaries",
  },
  {
    type: LIST_ITEM,
    text: "FAQ",
    icon: <QuestionAnswerOutlined color="secondary" />,
    path: "/faqs",
  },
  {
    type: LIST_ITEM,
    text: "CallIn",
    icon: <Settings color="secondary" />,
    path: "/callins",
  },
  {
    type: LIST_ITEM,
    text: "Settings",
    icon: <Settings color="secondary" />,
    path: "/settings",
  },
  {
    type: LIST_ITEM,
    text: "About",
    icon: <InfoOutlined color="secondary" />,
    path: "/about",
  },
];

// const TheDrawerTemp = ({ user }) => {
//   const classes = useStyles();
//   const history = useHistory();

//   return (
//     <Drawer
//       className={classes.drawer}
//       variant="persistent"
//       anchor="left"
//       classes={{ paper: classes.drawerPaper }}
//     >
//       <div>
//         <Typography variant="h5">Menu</Typography>
//       </div>
//       <List>
//         {menuItems.map((item) => (
//           <ListItem
//             button
//             key={item.text}
//             onClick={async () => {
//               console.log("TheDrawerTemp.click>", item.text);
//               await logEvent({
//                 eventName: "openDR",
//                 //data1: index.toString(),
//                 data1: item.text,
//               });

//               //history.push(item.path);
//             }}
//           >
//             <ListItemIcon>{item.icon}</ListItemIcon>
//             <ListItemText primary={item.text} />
//           </ListItem>
//         ))}
//       </List>
//     </Drawer>
//   );
// };

export function TemporaryDrawer({ user, toggleTheDrawerCB }) {
  //  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    //console.log("toggleDrawer>");

    //console.log("toggleDrawer>before setState");
    setState({ ...state, [anchor]: open });
    //console.log("toggleDrawer>after setState");
  };

  toggleTheDrawerCB("registerCB", toggleDrawer);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={() => toggleDrawer(anchor, false)}
      onKeyDown={() => toggleDrawer(anchor, false)}
    >
      <List>
        {menuItems.map((item, index) =>
          item.type === LIST_ITEM ? (
            <ListItem
              button
              key={item.text}
              onClick={async () => {
                logEvent({
                  eventName: "openDR",
                  data1: index.toString(),
                  data2: item.text,
                });

                history.push(item.path);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ) : (
            <Divider key={item.text} />
          )
        )}
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={() => toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={() => toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
} // Temporary Drawer

export default TemporaryDrawer;
