//
//
//
import {
  //  APPROVE_HASHTAG,
  //  DECLINE_HASHTAG,
  //  MINT_HASHTAG,
  //  CANCEL_HASHTAG,
  START_LOADING_HASHTAGS,
  STOP_LOADING_HASHTAGS,
  //  FETCH_ALL_HASHTAGS,
  //  FETCH_HASHTAG,
  FETCH_HASHTAGS,
  UPDATE_HASHTAG,
  //  FETCH_BY_SEARCH,
  //  FETCH_BY_AUTHORS,
  //  FETCH_OWNER_HASHTAG,
  //  PROPOSE_HASHTAG,
  //  UPDATE_HASHTAG,
  //  DELETE_HASHTAG,
} from "../constants/actionTypes";
import * as api from "../api/index.js";
// import {
//   PROPOSED_STRING,
//   APPROVED_STRING,
//   CANCELLED_STRING,
//   DECLINED_STRING,
//   MINTED_STRING,
//   HASHTAG_ABSOLUTE_MAX_LENGTH,
//   SECONDS_BETWEEN_HASHTAG_FETCH,
// } from "../constants/constants";

// import * as perm from "../constants/permissions.js";
// import { isOnEitherList } from "../components/Settings/Permissions";
// import { getWisdomsOwnedCount, isUserOwner } from "../actions/users";
// import { loadWebUser } from "../components/localStorage";

// var coinInUsd = 0;
// var lastEthPriceGetMS = 0;
//

export const getHashtags = (query) => async (dispatch) => {
  //console.log("client.getAllHashags>");
  try {
    dispatch({ type: START_LOADING_HASHTAGS });
    const {
      data: { data, currentPage, numberOfPages },
    } = await api.fetchHashtags(query);

    dispatch({
      type: FETCH_HASHTAGS,
      payload: { data, currentPage, numberOfPages },
    });
    dispatch({ type: STOP_LOADING_HASHTAGS });
  } catch (error) {
    console.log("getHashtags>ERROR", error);
  }
}; // get hashtags
//
export const updateHashtag = (id, post) => async (dispatch) => {
  try {
    console.log("updateHashtag>", post);
    // mintPrice can change when hashtags change
    const { wisdomMintPriceBaseEth } = post;

    var fields = {
      wisdomMintPriceBaseEth,
    };
    const { data } = await api.updateHashtag(id, fields);

    //    dispatch({ type: UPDATE, payload: data });
    dispatch({ type: UPDATE_HASHTAG, payload: data });
  } catch (error) {
    console.log("updateHashtag", error);
  }
}; // update hashtag

//
// helper functions
//
export const findHashtagByText = (hashtags, hashtagText) => {
  var hashtag = hashtags.find(function (ht) {
    if (ht.hashtagText === hashtagText) return true;
    else return false;
  });
  return hashtag;
};
