
    // deployed at "Sat Dec 31 2022 21:38:41 GMT-0700 (Mountain Standard Time)"
    // depolyed by "0xa864425D00a81E47F7000c5d800b390f1dbf174b"
    // deployed with "polygon"
    // deployed for "prod"
    export const stringutilsaddress = "0x481a9c9A8e1d4A97b5325D738b7b56C2AA258648"
    export const wisdomcommonlibaddress = "0x6903c3D8a34CaD6C9a428E22de98a2762abB997b"
    export const makeitwisdomaddress = "0x05a99225634D60D265Ee08508b5EeaFD5D4f5F85"
    export const wisdomaddress = "0x12F2115898Ba498fDa95AA54323ca2b2f81A4C94"
    export const hashtagaddress = "0x9eBbc8dc14773054a2A4907c40dbb936Abcb02B8"
    export const wisdomlistaddress = "0x32F8C8289C3Ac42Cb54294D1dF5Ae285cC761b47"
    export const permissionaddress = "0xf67AD74F5911EA7b9A076630D18AD1585600B549"
    export const makeitwisdomlockboxaddress = "0x5E36396B54Be5A3f3186e4a8dBE6607F9dfAC58d"
    export const pioneeraddress = "0x8C0C48E36fcB24409b70E70f0F2A2d9568F62eC7"
    export const approvedaddress = "0xf826680c18d4871538706ee7e702b875bC30a87a"
    