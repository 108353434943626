//
//
//
import React from "react";
import { Typography } from "@material-ui/core";
//import { useDispatch } from "react-redux";
//import { useHistory, useLocation } from "react-router-dom";

//import { lightTheme, darkTheme } from "../Themes.js";
import { useDarkMode } from "../useDarkMode.js";

//import useStyles from "./styles";
//import PermissionSettings from "./PermissionSettings";
import Toggle from "../Toggle";
// import {
//   PROPOSED_STRING,
//   MINTED_STRING,
//   DISPLAY_WISDOM_TYPE,
// } from "../../constants/constants";

// import {
//   getDisplayProfileValue,
//   setDisplayProfileValue,
// } from "../localStorage";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const UserSettings = () => {
  //  const classes = useStyles();
  //  const query = useQuery();
  //  const history = useHistory();
  //const location = useLocation();
  //   const dispatch = useDispatch();

  //   const history = useHistory();

  //  var displayWisdomType = MINTED_STRING; // TODO pull from storage
  const [theme, themeToggler] = useDarkMode();
  //  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const themeToggle = () => {
    themeToggler();
    //    history.push("/");
    window.location.reload();
    // history.push({
    //   // no need
    //   pathname: "/",
    //   state: {},
    // });
  };

  return (
    <>
      <Typography variant="h6">User Settings</Typography>;
      <Toggle theme={theme} toggleTheme={themeToggle} />
    </>
  );
};

export default UserSettings;
