import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams, useHistory, Link } from "react-router-dom";
import * as perm from "../../constants/permissions.js";
import { isOnList } from "../../constants/permissions.js";
import {
  loadWebUser,
  setProfileValue,
  getDisplayProfileValue,
} from "../myLocalStorage";

import thinksNoMeIcon from "../../images/outline_psychology_black_24dp.png";
import thinksMeIcon from "../../images/baseline_psychology_black_24dp.png";
import cheersNoMeIcon from "../../images/outline_celebration_black_24dp.png";
import cheersMeIcon from "../../images/baseline_celebration_black_24dp.png";
import feelsNoMeIcon from "../../images/outline_favorite_border_black_24dp.png";
import feelsMeIcon from "../../images/baseline_favorite_black_24dp.png";
import likesNoMeIcon from "../../images/outline_thumb_up_black_24dp.png";
import likesMeIcon from "../../images/baseline_thumb_up_black_24dp.png";

import {
  //approveWisdom,
  //cancelWisdom,
  declineWisdom,
  getWisdom,
  getWisdomsBySearch,
  //getWisdomsByHashtag,
  //mintWisdom,
  //isUserWisdomOwner,
  //isWisdomProposed,
  //isWisdomApproved,
  isWisdomDeclined,
  isWisdomMinted,
  areCommentsOn,
  getStatusesToLoad,
  voteWisdom,
} from "../../actions/wisdoms";
import * as api from "../../api/index.js";

// for web3 / contract
import { useWeb3React } from "@web3-react/core";
//import { injected } from "../../../hooks/connectors";
import { ethers } from "ethers";
import WisdomListContract from "../../artifacts/contracts/WisdomListContract.sol/WisdomListContract.json";
import { wisdomlistaddress, approvedaddress } from "../../contractManifest.js";
import ApprovedContract from "../../artifacts/contracts/ApprovedContract.sol/ApprovedContract.json";

//import { useDarkMode } from "../useDarkMode.js";
import makeItWisdomLogo from "../../images/MakeItWisdom_MW1_64x64.png";

import {
  //  isUserOwner,
  canUserApproveWisdoms,
  canUserDeclineWisdoms,
} from "../../actions/users";
import CommentSection from "./CommentSection";
import useStyles from "./styles";
import WisdomBox from "../Wisdoms/Wisdom/WisdomBox";
//import userEvent from "@testing-library/user-event";
//import DeleteIcon from "@material-ui/icons/Delete";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
//import CancelIcon from "@material-ui/icons/Cancel";
//import AddCircleIcon from "@material-ui/icons/AddCircle";
import TagIcon from "@material-ui/icons/TagFaces";
import {
  DISPLAY_WISDOM_TYPE,
  DO_LOG,
  SHOW_BETA_TWEET,
  SIGN_IN_TO_ACCOUNT,
} from "../../constants/constants";
import HelpOutline from "@material-ui/icons/HelpOutline";

import {
  MINTED_STRING,
  SECONDS_PER_HOUR,
  //PERMISSION_HOURS_DEFAULT,
  //  PERMISSION_COUNT_DEFAULT,
} from "../../constants/constants";
import BoundarySection from "./BoundarySection";
import AlertBanner from "../AlertBanner/AlertBanner.js";

// import { createTheme } from "@material-ui/core/styles";
// import { ThemeProvider } from "@material-ui/core/styles";
// import {
// //  lightGreen,
// //  blue,
//   green,
// //  purple,
// //  pink,
//   red,
// } from "@material-ui/core/colors";
const doLog = false;

const ImageOrNot = ({ imageFile }) => {
  const classes = useStyles();
  //console.log("ImageOrNot>", imageFile);
  if (!imageFile || imageFile === "") {
    return <div></div>;
  } else {
    return <img className={classes.media} src={imageFile} alt={" "} />;
  }
}; // image or not

const Wisdom = () => {
  // console.log(
  //   "Wisdom>state.wisdom=",
  //   useSelector((state) => state.wisdoms)
  // );

  // useSelector((state) => {
  //   console.log("Wisdom>state=", state);
  // });

  const {
    post: serverWisdom,
    wisdoms,
    isLoading,
  } = useSelector((state) => state.wisdoms);
  //  const [isLoading, setIsLoading] = useState(false);
  //  var poUsers = [];
  const dispatch = useDispatch();
  // useWeb3React can only be used in a React component.
  //    so functions that use Library must be in a react component
  //    THUS, permission functions here were copied from PermissionSettings.js
  // TODO: try to create common permission functions that take library as parm
  //    OR.. a non-React web3 library??
  //
  const { library } = useWeb3React();

  const history = useHistory();
  const classes = useStyles();
  const { id, wisdomId } = useParams();
  const webUser = loadWebUser();
  //  const location = useLocation();
  //  const currentUrl = location?.pathname + location?.search;
  //  const [declinedBoundaryList, setDeclinedBoundaryList] = useState([]);
  const [boundaryCount, setBoundaryCount] = useState(0);
  const [poUsers, setPoUsers] = useState([]);
  const [wisdomBusy, setWisdomBusy] = useState(false);
  const [serverAuthor, setServerAuthor] = useState();

  const [theVoteCount, setTheVoteCount] = useState(0);

  // Alert setup
  const handleCloseAlertResult = () => {
    // changing state should cause rerender
    setAlertBannerResult({ ...alertBannerResult, showNow: false });
  };
  const alertKeyResult = serverWisdom?.wisdomId.toString() + "AlertKeyResult";
  const [alertBannerResult, setAlertBannerResult] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKeyResult,
    handleClose: handleCloseAlertResult,
  });

  const handleCloseAlertBoundary = () => {
    // changing state should cause rerender
    setAlertBannerBoundary({ ...alertBannerBoundary, showNow: false });
  };
  const alertKeyBoundary =
    serverWisdom?.wisdomId.toString() + "AlertKeyBoundary";
  const [alertBannerBoundary, setAlertBannerBoundary] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKeyBoundary,
    handleClose: handleCloseAlertBoundary,
  });

  // Alert Vote setup
  const handleCloseAlertVote = () => {
    // changing state should cause rerender
    setAlertBannerVote({ ...alertBannerVote, showNow: false });
  };

  const alertKeyVote = serverWisdom?.wisdomId.toString() + "AlertKeyVote";
  const [alertBannerVote, setAlertBannerVote] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKeyVote,
    handleClose: handleCloseAlertVote,
  });

  //  alert("mint>currentUrl=" + currentUrl);
  //  console.log("WisdomDetails>id=", id);
  // const approveWisdom = (e) => {
  //   console.log("approveWisdom", serverWisdom);
  // };

  // const declineWisdom = (e) => {
  //   console.log("declineWisdom", serverWisdom);
  // };
  const handleVote = async (event, voteType, voteTypeCount, wisdom) => {
    event.preventDefault();
    const webUser = loadWebUser();
    if (!webUser) {
      //alert("Sign in to vote: " + voteType);
      setAlertBannerVote({
        children: null,
        type: "error",
        message: SIGN_IN_TO_ACCOUNT,
        showNow: true,
        theKey: alertKeyVote,
        handleClose: handleCloseAlertVote,
      });
      setProfileValue("preventAnimation", true);
      //history.go(0);
      //return;
    } else {
      const hasVotedWisdom =
        webUser[voteType].find((vote) => vote === wisdom?._id) || false;

      if (hasVotedWisdom) {
        //console.log("handleVote>", voteType, "removing ", serverWisdom?._id);
        webUser[voteType] = webUser[voteType].filter(
          (id) => id !== wisdom?._id
        );
        // save to local storage
        setProfileValue(voteType, webUser[voteType]);
        setProfileValue(voteTypeCount, --webUser[voteTypeCount]);
        //change wisdom
        wisdom[voteTypeCount]--;
        wisdom[voteType] = [];
      } else {
        //console.log("handleVote>", voteType, "adding ", serverWisdom?._id);
        //setLikes([...webUser[voteType], serverWisdom?._id]);
        // save to local storage
        setProfileValue(voteTypeCount, ++webUser[voteTypeCount]);
        webUser[voteType].push(wisdom?._id);
        setProfileValue(voteType, webUser[voteType]);
        // change wisdom
        wisdom[voteTypeCount]++;
        wisdom[voteType] = [webUser._id];
      }
      // prevent animation on Wisdoms page refresh
      setProfileValue("preventAnimation", true);
      // TODO: add server confirmation & error handling
      //  (might not actually be logged in)
      //
      //    const webUserAfter = loadWebUser();
      //console.log("handleVote>webUserAfter", webUserAfter);
      dispatch(voteWisdom(wisdom?._id, voteType, voteTypeCount));

      setTheVoteCount(theVoteCount + 1);
      //history.go(0);
    }
  }; // handle vote

  useEffect(() => {
    async function loadPreviousOwners(ids) {
      // load users so avatars can be displayed
      const poUserResp = await api.getImgs(ids);
      // console.log(
      //   "WisdomDetails>previous owners=",
      //   JSON.stringify(poUserResp.data)
      // );
      setPoUsers(poUserResp.data);
    }

    async function loadAuthor(wisdom) {
      //TODO: getAuthor
      const { data } = await api.fetchAuthor(wisdom?.authorId);
      if (doLog) console.log("loadAuthor>data=", data);
      setServerAuthor(data);
    } // load author

    //
    if (!serverWisdom && boundaryCount > -1) {
      //      setIsLoading(true);
      dispatch(getWisdom(id, wisdomId, webUser?.name || "unk"));
      //      setIsLoading(false);
      setPoUsers([]);
    }

    if (poUsers?.length === 0) {
      loadPreviousOwners(serverWisdom?.previousOwnerIds);
    }
    if (!serverAuthor && serverWisdom) {
      loadAuthor(serverWisdom);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, serverWisdom, dispatch]);

  //  useEffect(() => {
  // if (!isLoading) {
  //   if (!serverWisdom) {
  //   dispatch(
  //     getWisdomsBySearch({
  //       search: "none",
  //       hashtagTexts: serverWisdom?.hashtagTexts.join(","),
  //       status: serverWisdom?.status || MINTED_STRING,
  //     })
  //   );
  //   }
  // }
  //  }, [serverWisdom, dispatch]);

  if (!serverWisdom) {
    //    console.log("WisdomDetails>serverWisdom is NULL");
    //dispatch(getWisdom(id));
    return null;
  }
  //
  //
  const setAlertBannerBoundaryHelper = (msg) => {
    // if this AlertBanner already showing msg, close it.
    //
    if (alertBannerBoundary.message === msg && alertBannerBoundary.showNow) {
      handleCloseAlertBoundary();
    } else {
      setAlertBannerBoundary({
        children: null,
        type: "info",
        message: msg,
        showNow: true,
        theKey: alertKeyBoundary,
        handleClose: handleCloseAlertBoundary,
      });
    }
  };
  //
  // approver can put user on NOMINT list and DENY_PROPOSE_IMAGE
  //
  // add permission must be inside React Component to have access to contract via React
  // (note: function copied from PermissionSettings. Must be in a React component)
  const manageListServer = async (
    serverUser,
    listName,
    addNow,
    nowMS,
    expireDateMS,
    expireCount
  ) => {
    //
    // update local object
    // TODO: this could be after successful server update. build new permissions array to update server.
    if (addNow) {
      serverUser.permissions.push({
        listName: listName,
        fromId: webUser._id,
        sinceDateMS: nowMS,
        expireDateMS: expireDateMS,
        expireCount: expireCount,
      });
      // update server!
      await api.updateUser(serverUser?._id, {
        permissions: serverUser.permissions,
      });

      // update local storage
      if (webUser?._id === serverUser?._id) {
        setProfileValue("permissions", serverUser?.permissions);
      }
    } else {
      // remove

      removeFromPermissionList(serverUser, [listName]);
    }
  }; // manage list server

  //
  // (note: function copied from PermissionSettings. Must be in a React component)
  const manageListContract = async (
    serverUser,
    listName,
    listEnum,
    expireDateMS,
    expireCount,
    addNow,
    nowMS
  ) => {
    if (listEnum < perm.MINT_WISDOM.enum) {
      if (library) {
        //const webUser = loadWebUser();
        //console.log("manageListContract>webUser=", webUser);
        const signer = library.getSigner();
        //console.log("manageListContract>signer=", signer);
        //var callbackCount = 0;

        const wisdomListContract = new ethers.Contract(
          wisdomlistaddress,
          WisdomListContract.abi,
          signer
        );

        const numberHours =
          expireDateMS > 0
            ? parseInt(Math.round((expireDateMS - nowMS) / SECONDS_PER_HOUR))
            : -1;
        // console.log(
        //   "manageListContract>calling contract.manageList with wallet",
        //   serverUser?.wallet,
        //   "enum=",
        //   listEnum,
        //   "addNow",
        //   addNow,
        //   "durationHours=",
        //   numberHours,
        //   "count=",
        //   expireCount,
        //   "nowMS=",
        //   nowMS
        // );

        // call the contract
        const tx = await wisdomListContract.manageList(
          serverUser?.wallet,
          listEnum,
          addNow,
          numberHours,
          expireCount,
          nowMS
        );
        const receipt = await tx.wait(1);
        // console.log("manageListContract>OK DONE. transaction=", tx);
        // console.log("manageListContract>OK DONE. receipt=", receipt);
        // pull from the smart contract receipt: _person, _nowMS
        // determine from receipt that transaction completed
        //    and process returned values in the event
        if (receipt?.events.length > 0) {
          // check each event?
          for (let i = 0; i < receipt.events.length; ++i) {
            // pull data from event
            // uint256 come back as BigNumber
            // wisdomId, forSalePrice, saleStartDateMS, saleEndDateMS
            const event = receipt.events[i];
            //          console.log("manageListContract>   event=", event);
            // event is for this wisdom
            if (event?.event === "ManageListEvent") {
              if (serverUser?.wallet === event?.args?._person) {
                if (nowMS.toString() === event?.args?._nowMS.toString()) {
                  // event is for us!

                  // update Server
                  await manageListServer(
                    serverUser,
                    listName,
                    addNow,
                    nowMS,
                    expireDateMS,
                    expireCount
                  );
                  // TODO: reload webpage
                  history.push("/settings");
                } else {
                  console.log(
                    "manageListContract>wrongNowMS=",
                    event?.args._nowMS
                  );
                }
              } else {
                console.log(
                  "manageListContract>wrongPerson=",
                  event?.args?._person
                );
              }
            } else {
              console.log("manageListContract>wrongEvent=", event.event);
            }
          } // each event in receipt
        } else {
          console.log("manageListContract>No Events In Receipt");
        }

        return tx;
      } // if library
      else {
        if (DO_LOG) console.log(">NO Library");
      }
    } else {
      if (doLog) console.log("No contract for list", listEnum, listName);
      // update Server
      await manageListServer(
        serverUser,
        listName,
        addNow,
        nowMS,
        expireDateMS,
        expireCount
      );
      // TODO: reload webpage
      history.push("/settings");
    } // tell contract, or not
  }; // maange list contract
  //
  //
  // (note: function copied from PermissionSettings. Must be in a React component)
  // async function addToPermissionList(
  //   serverUser,
  //   permissionList, // array of string. listNames only, not objects
  //   durationHours = 0,
  //   expireCount = PERMISSION_COUNT_DEFAULT,
  //   tellingContract,
  //   setTellingContract
  // ) {
  //   // console.log(
  //   //   "addToPermissionList>permissionList=",
  //   //   permissionList,
  //   //   "durationHours=",
  //   //   durationHours,
  //   //   "expireCount=",
  //   //   expireCount
  //   // );
  //   if (serverUser && !tellingContract) {
  //     setTellingContract(true);
  //     const nowMS = Math.round(Date.now() / 1000);
  //     const expireDateMS =
  //       Number(durationHours) === -1
  //         ? -1
  //         : Math.round(nowMS + durationHours * SECONDS_PER_HOUR);
  //     for (let i = 0; i < permissionList.length; ++i) {
  //       const listName = permissionList[i];
  //       //        console.log("addToPermissionList>checking List=", listName);
  //       if (!perm.isOnList(serverUser, listName)) {
  //         // console.log(
  //         //   "addToPermissionList>adding permission=",
  //         //   listName,
  //         //   "expireDateMS",
  //         //   expireDateMS
  //         // );

  //         // special handling. tell contract!
  //         // if (
  //         //   ![
  //         //     "MINT_WISDOM",
  //         //     "MANAGE_MINT_WISDOM",
  //         //     "MINT_HASHTAG",
  //         //     "MANAGE_MINT_HASHTAG",
  //         //   ].includes(listName)
  //         // ) {
  //         const listEnum = perm.getListEnumFromListName(listName);
  //         // console.log(
  //         //   "addToPermissionsList>tell WisdomListContract for listName=",
  //         //   listName,
  //         //   "listEnum=",
  //         //   listEnum
  //         // );
  //         await manageListContract(
  //           serverUser,
  //           listName,
  //           listEnum,
  //           expireDateMS,
  //           Number(expireCount),
  //           true,
  //           nowMS
  //         );
  //         // } else {
  //         //   // update Server
  //         //   // unfortunately, to support conditional contract update, server added each listname 1 at a time.
  //         //   manageListServer(
  //         //     serverUser,
  //         //     listName,
  //         //     true,
  //         //     nowMS,
  //         //     expireDateMS,
  //         //     expireCount
  //         //   );
  //         // } // tell contract
  //       }
  //     }
  //   } else {
  //     console.log(
  //       "addToPermissionList>serverUser",
  //       serverUser == null,
  //       "tellingContract already",
  //       tellingContract
  //     );
  //   }
  // } // add to permission list
  //
  // (note: function copied from PermissionSettings. Must be in a React component)
  async function removeFromPermissionList(serverUser, permissionList) {
    // permissionList is array of  list name strings (not permission objects)
    //var changeCount = 0;
    if (serverUser?.permissions && permissionList.length > 0) {
      const nowMS = Math.round(Date.now() / 1000);
      // const expireDateMS = durationMS == 0 ? 0 : nowMS + durationMS;
      for (let i = 0; i < permissionList.length; i++) {
        const listName = permissionList[i];
        if (doLog)
          console.log("removeFromPermissionList> checking listName=", listName);
        //++changeCount;
        // serverUser.permissions.removeByValue(permissionName);
        // traverse form the end and delete when found.
        for (var j = serverUser?.permissions.length - 1; j >= 0; --j) {
          if (serverUser.permissions[j].listName === listName) {
            if (doLog)
              console.log(
                "removeFromPermissionList> removing listName=",
                listName
              );
            serverUser.permissions.splice(j, 1);
            // update contract, then database
            const listEnum = perm.getListEnumFromListName(listName);
            // console.log(
            //   "removeFromPermissionList>tell WisdomListContract for listName=",
            //   listName,
            //   "listEnum=",
            //   listEnum
            // );
            //
            if (listEnum < perm.NOMINT_WISDOM.enum) {
              await manageListContract(
                serverUser,
                listName,
                listEnum,
                -1,
                -1,
                false,
                nowMS
              );
            } else {
              // update server!
              await api.updateUser(serverUser?._id, {
                permissions: serverUser.permissions,
              });
            }
          }
        }
        if (webUser?._id === serverUser._id) {
          // update local storage
          setProfileValue("permissions", serverUser.permissions);
        }
      }
      // update database
      // if (changeCount > 0) {
      //   await api.updateUser(serverUser?._id, {
      //     permissions: serverUser.permissions,
      //   });
      // }
    }
  } // remove from permisison list

  //
  //
  const canAuthorProposeImage = (serverAuthor) => {
    return !isOnList(serverAuthor, perm.DENY_PROPOSE_IMAGE.name);
  }; // can author propose image

  const canAuthorMintWisdom = (serverAuthor) => {
    return !isOnList(serverAuthor, perm.NOMINT_WISDOM.name);
  }; // can author mint wisdom

  const canAuthorBuyWisdom = (serverAuthor) => {
    return !isOnList(serverAuthor, perm.NOBUY_WISDOM.name);
  }; // can author mint wisdom

  const handleDenyProposeImage = async (serverAuthor, addNow) => {
    const nowMS = Math.round(Date.now() / 1000);
    if (doLog) console.log("handleDenyProposeImage", addNow);
    await manageListServer(
      serverAuthor,
      perm.DENY_PROPOSE_IMAGE.name,
      addNow,
      nowMS,
      -1,
      -1
    );

    history.push(`/wisdoms/${serverWisdom?._id}`);
    setAlertBannerResult({
      children: null,
      type: "success",
      message: addNow
        ? "User can no longer propose with images"
        : "User can now propose with images.",
      showNow: true,
      theKey: alertKeyResult,
      handleClose: handleCloseAlertResult,
    });
  }; // handle deny propose image
  //
  const handleNoMintUser = async (serverAuthor, addNow) => {
    const nowMS = Math.round(Date.now() / 1000);
    await manageListServer(
      serverAuthor,
      perm.NOMINT_WISDOM.name,
      addNow,
      nowMS,
      -1,
      -1
    );

    history.push(`/wisdoms/${serverWisdom?._id}`);
    setAlertBannerResult({
      children: null,
      type: "success",
      message: addNow
        ? "User can no longer propose Wisdoms"
        : "User can now propose Wisdoms.",
      showNow: true,
      theKey: alertKeyResult,
      handleClose: handleCloseAlertResult,
    });
  }; // handle no mint user
  //
  const handleDenyBuyWisdom = async (serverAuthor, addNow) => {
    const nowMS = Math.round(Date.now() / 1000);
    await manageListServer(
      serverAuthor,
      perm.NOBUY_WISDOM.name,
      addNow,
      nowMS,
      -1,
      -1
    );

    history.push(`/wisdoms/${serverWisdom?._id}`);
    setAlertBannerResult({
      children: null,
      type: "success",
      message: addNow
        ? "User can no longer buy Wisdoms."
        : "User can now buy Wisdoms.",
      showNow: true,
      theKey: alertKeyResult,
      handleClose: handleCloseAlertResult,
    });
  }; // handle no buy user
  //
  const handleApproveWisdom = async (event) => {
    event.preventDefault();
    // tell wisdom contract the wisdom has been approved
    //    so safeMintWisdom() can confirm
    //
    const webUser = loadWebUser();
    if (!webUser?.wallet) {
      //
      setWisdomBusy(false);
      setAlertBannerResult({
        children: null,
        type: "error",
        message: "Sign out and sign back in, please",
        showNow: true,
        theKey: alertKeyResult,
        handleClose: handleCloseAlertResult,
      });
    } else {
      setWisdomBusy(true);
      try {
        const signer = library?.getSigner();
        if (doLog) console.log("handleApproveWisdom>");
        //      console.log("handleApproveWisdom>signer=", signer);

        const approvedContract = new ethers.Contract(
          approvedaddress,
          ApprovedContract.abi,
          signer
        );
        if (library && approvedContract) {
          const nowMS = Math.round(Date.now() / 1000);
          const tx = await approvedContract.approveWisdom(
            nowMS,
            serverWisdom.wisdomText
          );
          if (doLog) console.log("handleApproveWisdom>after .approveWisdom");

          const receipt = await tx.wait(1);
          // console.log("handleApproveWisdom>OK DONE. transaction=", tx);
          // console.log("handleApproveWisdom>OK DONE. receipt=", receipt);

          // pull from the smart contract receipt: wisdomId, startSale, forSalePrice, saleStartDateMS, saleEndDateMS
          // determine from receipt that transaction completed
          //    and process returned values in the event
          if (receipt?.events.length > 0) {
            // check each event?
            for (let i = 0; i < receipt.events.length; ++i) {
              // pull data from event
              // uint256 come back as BigNumber
              // wisdomId, forSalePrice, saleStartDateMS, saleEndDateMS
              const event = receipt.events[i];
              console.log("handleContractSetApprover>   event=", event);
              if (event?.event === "approveWisdomEvent") {
                // event is for us!
                if (nowMS.toString() === event?.args?.nowMS.toString()) {
                  // event is for this wisdom
                  var result;
                  try {
                    result = event?.args?.result;
                    //event?.args?.result === true ? 0 : event?.args?.result;
                    console.log("handleApproveWisdom>   resultA=", result);
                  } catch (e) {
                    //
                    result = 3; //event?.args?.result;
                    console.log("handleApproveWisdom>   resultB=", result);
                  }
                  if (result === 0) {
                    // approved on contract, so update the server
                    await api.approveWisdom(serverWisdom?._id, {
                      id: serverWisdom?._id,
                      wallet: webUser?.wallet,
                    });
                    setWisdomBusy(false);
                    setAlertBannerResult({
                      children: null,
                      type: "success",
                      message: "Wisdom approved! Thanks for being an Approver!",
                      showNow: true,
                      theKey: alertKeyResult,
                      handleClose: handleCloseAlertResult,
                    });
                    setTimeout(() => {
                      history.push(`/`);
                    }, 5000);
                  } else {
                    // approval failed on contract
                    setWisdomBusy(false);
                    var msgStr = "";
                    if (result === 1) {
                      msgStr =
                        "This wallet is not an approver (" +
                        webUser?.wallet +
                        ")";
                    } else if (result === 2) {
                      msgStr = "This Wisdom is already approved. Sorry.";
                    } else if (result === 3) {
                      msgStr =
                        "Wisdom approval failed (code=" +
                        result.toString() +
                        "). Make sure you have the correct network and wallet/account selected. Please sign out, sign back in!";
                    }
                    console.log("WisdomDetails.handleApproveWisdom>", msgStr);
                    setAlertBannerResult({
                      children: null,
                      type: "error",
                      message: msgStr,

                      showNow: true,
                      theKey: alertKeyResult,
                      handleClose: handleCloseAlertResult,
                    });
                    await api.logEvent({
                      eventName: "approveWisdom-FailResult",
                      data1: webUser?._id,
                      data2: webUser?.wallet,
                      data3: webUser?.name,
                      data4: result.toString(),
                    });

                    return null;
                  }
                  //
                } else {
                  console.log(
                    "handleApproveWisdom>wrong nowMS=",
                    event?.args?.nowMS.toString(),
                    nowMS
                  );
                }
              } else {
                if (event?.event) {
                  console.log("handleApproveWisdom>wrongEvent=", event.event);
                }
              }
            } // each event in receipt
          } else {
            console.log("handleApproveWisdom>No Events in Receipt");
            await api.logEvent({
              eventName: "approveWisdom-NoEvents",
              data1: webUser?._id,
              data2: webUser?.wallet,
              data3: webUser?.name,
            });
          }
          return receipt;
        } else {
          setWisdomBusy(false);
          console.log(
            "WisdomDetails.handleApproveWisdom>Error contacting the blockchain. Please try again later"
          );

          setAlertBannerResult({
            children: null,
            type: "error",
            message: "Error contacting the blockchain. Please try again later.",
            showNow: true,
            theKey: alertKeyResult,
            handleClose: handleCloseAlertResult,
          });
          await api.logEvent({
            eventName: "approveWisdom-NoLibrary",
            data1: webUser?._id,
            data2: webUser?.wallet,
            data3: webUser?.name,
          });
        } // contract blockchain
      } catch (err) {
        //
        setWisdomBusy(false);
        console.log(
          "WisdomDetails.handleApproveWisdom>Error approving Wisdom on the smart contract. Please try again.",
          JSON.stringify(err, null, 3)
        );
        setAlertBannerResult({
          children: null,
          type: "error",
          message:
            "Error approving Wisdom on the smart contract. Please try again.",
          showNow: true,
          theKey: alertKeyResult,
          handleClose: handleCloseAlertResult,
        });
        await api.logEvent({
          eventName: "approveWisdom-Error",
          data1: webUser?._id,
          data2: webUser?.wallet,
          data3: webUser?.name,
          data4: err.toString(),
        });
      }
    } // webuser set
    return null;
  }; // handle approve wisdom
  //
  //
  //
  const VoteIcons = {
    cheersMe: () => {
      return (
        <img src={cheersMeIcon} alt="cheers" width="35px" height="35px"></img>
      );
    },
    feelsMe: () => {
      return (
        <img src={feelsMeIcon} alt="feels" width="35px" height="35px"></img>
      );
    },
    likesMe: () => {
      return (
        <img src={likesMeIcon} alt="likes" width="35px" height="35px"></img>
      );
    },
    thinksMe: () => {
      return (
        <img src={thinksMeIcon} alt="thinks" width="35px" height="35px"></img>
      );
    },
    cheersNoMe: () => {
      return (
        <img src={cheersNoMeIcon} alt="cheers" width="35px" height="35px"></img>
      );
    },
    feelsNoMe: () => {
      return (
        <img src={feelsNoMeIcon} alt="feels" width="35px" height="35px"></img>
      );
    },
    likesNoMe: () => {
      return (
        <img src={likesNoMeIcon} alt="likes" width="35px" height="35px"></img>
      );
    },
    thinksNoMe: () => {
      return (
        <img src={thinksNoMeIcon} alt="thinks" width="35px" height="35px"></img>
      );
    },
  };

  //
  const Votes = ({ voteType, voteTypeCount, wisdom }) => {
    const webUser = loadWebUser();
    //    const voteTypeSingle = voteType.substring(0, voteType.length - 1);
    // if (serverWisdom?.wisdomText == "test1") {
    //   console.log("Votes>=", voteType, voteTypeCount, "webUser=", webUser);
    // }
    const ThisVoteIconNoMe = VoteIcons[voteType + "NoMe"];
    const ThisVoteIconMe = VoteIcons[voteType + "Me"];
    if (webUser) {
      if (wisdom[voteTypeCount] > 0) {
        return webUser[voteType]?.find((vote) => vote === wisdom?._id) ? (
          <>
            <ThisVoteIconMe />
            <br />
            {wisdom[voteTypeCount]}{" "}
          </>
        ) : (
          <>
            <ThisVoteIconNoMe />
            <br />
            {wisdom[voteTypeCount]}{" "}
          </>
        );
      }
    }
    return (
      <>
        <ThisVoteIconNoMe />
      </>
    );
  }; // Votes

  //console.log("WisdomDetails>serverWisdom is NOT NULL");
  // called when user clicks on a "you might also like" item
  // const openWisdom = (_id) => history.push(`/wisdoms/${_id}`);
  const openWisdom = (event, _id) => {
    event.preventDefault();
    dispatch(getWisdom(_id, webUser?.name || "unk", history));
    //console.log("openWisdom>", _id);

    history.push(`/wisdoms/${_id}`);
  };

  if (isLoading && (!serverWisdom || serverWisdom._id !== id)) {
    return (
      <Paper elevation={6} className={classes.loadingPaper}>
        <CircularProgress size="7em" />
      </Paper>
    );
  }

  const recommendedWisdoms = wisdoms.filter(
    ({ _id }) => _id !== serverWisdom._id
  );

  // const openHashtag = (e, query) => {
  //   e.preventDefault();
  //   //    console.log("openHashtag", query);
  //   //    dispatch(getWisdomsByHashtag(query));
  //   //history.push("/wisdoms/hashtagText?hashtagText=$" + query.hashtagText);
  //   dispatch(getWisdomsBySearch(query), history);
  //   history.push(
  //     "/wisdoms/search?search=none&hashtagTexts=" +
  //       query.hashtagTexts +
  //       "&status=" +
  //       serverWisdom?.status
  //   );
  // };

  // const searchHashtag = (_hashtagText, _displayWisdomType) => {
  //   console.log(
  //     "searchHashtag>searchQuery=",
  //     _hashtagText,
  //     "=",
  //     _displayWisdomType
  //   );
  //   const status = _displayWisdomType || MINTED_STRING;
  //   if (_hashtagText !== "") {
  //     dispatch(
  //       getWisdomsBySearch({
  //         searchText: "",
  //         hashtagTexts: [_hashtagText],
  //         status: status,
  //       })
  //     );
  //     history.push(
  //       `/wisdoms/search?search=${"none"}&hashtagTexts=${[
  //         _hashtagText,
  //       ]}&status=${status}`
  //     );
  //   } else {
  //     history.push("/");
  //   }
  // };
  const doShowBoundaries = (webUser, serverWisdom) => {
    return (
      canUserApproveWisdoms(webUser, serverWisdom) ||
      canUserDeclineWisdoms(webUser, serverWisdom)
    );
  };

  // const handleTagLink = () => {};
  // console.log("WisdomDetails>serverWisdom=", serverWisdom);
  // console.log("WisdomDetails>user=", webUser);

  // var poWebUsers = {};

  var poNames = [];
  var previousName = "";
  for (let i = 0; i < serverWisdom.previousOwnerNames.length; i++) {
    if (previousName !== serverWisdom.previousOwnerNames[i]) {
      previousName = serverWisdom.previousOwnerNames[i];
      poNames.push(serverWisdom.previousOwnerNames[i]);
    }
  }

  // <Avatar
  //   className={classes.purple}
  //   alt={webUser.name}
  //   src={webUser.imageUrl}
  // >
  //   {webUser?.name?.charAt(0)}
  // </Avatar>;
  //console.log("WisdomDetails", serverWisdom.createdAt);
  // format epoc seconds into  2022-05-22T23:09:52.079Z  for use in moment()
  //
  // const momentStr = moment(new Date(serverWisdom?.mintedDateMS * 1000)).format(
  //   "YYYY-MM-DD[T00:00:00.000Z]"
  // );
  const displayWisdomType = getDisplayProfileValue(
    DISPLAY_WISDOM_TYPE,
    MINTED_STRING
  );

  const mintedMomentStr =
    displayWisdomType === MINTED_STRING
      ? moment(new Date(serverWisdom?.mintedDateMS * 1000)).format(
          "YYYY-MM-DD[T00:00:00.000Z]"
        )
      : serverWisdom?.createdAt;

  const ownedSinceMomentStr =
    displayWisdomType === MINTED_STRING
      ? moment(new Date(serverWisdom?.ownedSinceDateMS * 1000)).format(
          "YYYY-MM-DD[T00:00:00.000Z]"
        )
      : serverWisdom?.createdAt;

  //console.log("WisdomDetails", mintedMomentStr);

  const startSaleDateStr =
    serverWisdom?.saleStartDateMS === 0
      ? "N/A"
      : new Date(serverWisdom?.saleStartDateMS * 1000)
          .toString()
          .replace(/\s*\(.*?\)\s*/g, "");

  const endSaleDateStr =
    serverWisdom?.saleEndDateMS === 0
      ? "N/A"
      : new Date(serverWisdom?.saleEndDateMS * 1000)
          .toString()
          .replace(/\s*\(.*?\)\s*/g, "");

  const statuses = getStatusesToLoad(serverWisdom?.status);
  //  const loadStatus = serverWisdom?.status == MINTED_STRING ? MINTED_STRING : PROPOSED_STRING+","+APPROVED_STRING+","+DECLINED_STRING
  const poUsersRev = poUsers.reverse();

  // const btnStyles = StyleSheet.create({
  //   btnstylehere: {
  //     width: 75,
  //     height: 75,
  //     borderRadius: 0,
  //     backgroundColor: "#000",
  //     color: "#fff",
  //   },
  // });
  // const customTheme = createTheme({
  //   palette: {
  //     primary: { main: green[500] },
  //     secondary: { main: red[500] },
  //   },
  // });
  var cheerTooltip = webUser ? "Cheer or not" : "Sign in to cheer";
  var feelTooltip = webUser ? "Feel or not" : "Sign in to feel";
  var likeTooltip = webUser ? "Like or not" : "Sign in to like";
  var thinkTooltip = webUser ? "Think or not" : "Sign in to think";

  if (wisdomBusy) {
    return (
      <center>
        <CircularProgress size="7em" />
        <Typography variant="h4">Please wait</Typography>
        <Typography variant="h4">Do not change pages</Typography>
      </center>
    );
    //return <DotProgress />;
  }
  if (doLog)
    if (serverAuthor) {
      console.log(
        "WisdomDetails>Author.name=",
        serverAuthor?.name,
        "proposeImage=",
        canAuthorProposeImage(serverAuthor),
        "mintWisdom=",
        canAuthorMintWisdom(serverAuthor)
      );
    }
  return (
    <Paper
      style={{ padding: "20px", borderRadius: "15px" }}
      elevation={6}
      key={"WisdomDetailsPaper" + serverWisdom.createdAt.toString()}
    >
      <AlertBanner {...alertBannerResult}></AlertBanner>

      <div className={classes.card}>
        <div className={classes.section}>
          <WisdomBox wisdom={serverWisdom} cursor={"default"} />
          <Typography variant="subtitle1">
            Created by
            <Button
              size="large"
              color="primary"
              key={
                "authorLinkTop" +
                serverWisdom.authorName +
                serverWisdom?.createdAt?.toString()
              }
              //
              onClick={(event) => {
                event.preventDefault();
                const { innerWidth: screenWidth } = window;
                api.logEvent({
                  eventName: "detailsCreated",
                  data1: serverWisdom?.authorId,
                  data2: serverWisdom.authorName,
                });

                dispatch(
                  getWisdomsBySearch({
                    searchText: "",
                    hashtagTexts: "",
                    status: statuses,
                    authorId: serverWisdom?.authorId,
                    authorName: "*",
                    ownerId: "*",
                    ownerName: "*",
                    width: screenWidth.toString(),
                    page: 1,
                    userName: webUser?.name || "unk",
                    userId: webUser?._id || "unk",
                  })
                );
                // history.push("/wisdoms/search");
                history.push(
                  `/wisdoms/search?search=${"none"}&hashtagTexts=${""}&status=${
                    serverWisdom?.status
                  }&authorId=${
                    serverWisdom?.authorId
                  }&authorName=${"*"}&ownerId=${"*"}&ownerName=${"*"}&width=${screenWidth}&page=1`
                );
              }}
            >
              {serverWisdom.authorName}
            </Button>
            {moment(mintedMomentStr).fromNow()}
          </Typography>

          <Typography variant="subtitle1">
            Owned by
            <Button
              size="large"
              color="primary"
              key={
                "ownerLinkTop" +
                serverWisdom.ownerName +
                serverWisdom?.createdAt?.toString()
              }
              //
              onClick={(event) => {
                event.preventDefault();
                const { innerWidth: screenWidth } = window;
                api.logEvent({
                  eventName: "detailsCreated",
                  data1: serverWisdom?.ownerId,
                  data2: serverWisdom.ownerName,
                });

                dispatch(
                  getWisdomsBySearch({
                    searchText: "",
                    hashtagTexts: "",
                    status: statuses,
                    ownerId: serverWisdom?.ownerId,
                    ownerName: "*",
                    authorId: "*",
                    authorName: "*",
                    width: screenWidth.toString(),
                    page: 1,
                    userName: webUser?.name || "unk",
                    userId: webUser?._id || "unk",
                  })
                );
                // history.push("/wisdoms/search");
                history.push(
                  `/wisdoms/search?search=${"none"}&hashtagTexts=${""}&status=${
                    serverWisdom?.status
                  }&ownerId=${
                    serverWisdom?.ownerId
                  }&authorName=${"*"}&authorId=${"*"}&ownerName=${"*"}&width=${screenWidth}&page=1`
                );
              }}
            >
              {serverWisdom.ownerName}
            </Button>
            {moment(ownedSinceMomentStr).fromNow()}
          </Typography>
          <Divider style={{ margin: "20px 0" }} />
          <Typography variant="h6">
            Previous Owners
            <Grid
              container
              justifyContent="flex-start"
              display="flex"
              alignItems="flex-start"
              spacing={1}
              className={classes.gridContainer}
            >
              {poUsersRev?.map((poUser, index) => (
                <Grid
                  item
                  key={
                    "ownerLinkTopAvatarItem" + poUser.name + index.toString()
                  }
                >
                  <Tooltip title={poUser.name} placement="top">
                    <Avatar
                      className={classes.purple}
                      key={
                        "ownerLinkTopAvatar" + poUser.name + index.toString()
                      }
                      alt={poUser.name}
                      src={poUser.imageUrl}
                      style={{ cursor: "pointer" }}
                      onClick={(event) => {
                        event.preventDefault();
                        const { innerWidth: screenWidth } = window;
                        api.logEvent({
                          eventName: "detailsPrev",
                          data1: poUser.name,
                        });

                        dispatch(
                          getWisdomsBySearch({
                            searchText: "",
                            hashtagTexts: "",
                            status: serverWisdom?.status,
                            authorId: "*",
                            authorName: "*",
                            ownerId: poUser.userId,
                            ownerName: "*",
                            width: screenWidth.toString(),
                            page: 1,
                            userName: webUser?.name || "unk",
                            userId: webUser?._id || "unk",
                          })
                        );

                        history.push(
                          `/wisdoms/search?search=${""}&hashtagTexts=${""}&status=${
                            serverWisdom?.status
                          }&authorId=${"*"}&authorName=${"*"}&ownerId=${
                            poUser.userId
                          }&ownerName=${"*"}&width=${screenWidth}&page=1`
                        );
                      }}
                    >
                      {poUser?.name?.charAt(0)}
                    </Avatar>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </Typography>
          <Divider style={{ margin: "20px 0" }} />
          {!SHOW_BETA_TWEET && (
            <div>
              <Typography variant="body1">
                <strong>Hashtags</strong>
              </Typography>
              <Typography
                gutterBottom
                variant="h6"
                color="textSecondary"
                component="h2"
              >
                {serverWisdom.hashtagTexts.map((tag) => (
                  <Button
                    //                className={classes.hashtagButton}
                    size="small"
                    color="primary"
                    key={"tagLink" + tag + serverWisdom?.createdAt?.toString()}
                    // TODO: remove page hardcode
                    onClick={(event) => {
                      event.preventDefault();
                      const { innerWidth: screenWidth } = window;

                      dispatch(
                        getWisdomsBySearch({
                          searchText: "",
                          hashtagTexts: tag,
                          authorId: "*",
                          authorName: "*",
                          ownerId: "*",
                          ownerName: "*",
                          status: serverWisdom?.status,
                          width: screenWidth.toString(),
                          page: 1,
                          userName: webUser?.name || "unk",
                          userId: webUser?._id || "unk",
                        })
                      );
                      // history.push("/wisdoms/search");
                      history.push(
                        `/wisdoms/search?search=${"none"}&hashtagTexts=${tag}&status=${
                          serverWisdom?.status
                        }&authorId=${"*"}&authorName=${"*"}&ownerId=${"*"}&ownerName=${"*"}&width=${screenWidth}&page=1`
                      );
                    }}
                  >
                    <TagIcon fontSize="small" /> &nbsp; {tag}
                  </Button>
                ))}
              </Typography>

              <Typography variant="body1">
                <strong>Status: {serverWisdom?.status}</strong>
              </Typography>
              <Typography variant="body1">
                <strong>Mint Price: {serverWisdom?.mintPrice}</strong>
              </Typography>
              <Typography variant="body1">
                <strong>Current Value: {serverWisdom?.currentValue}</strong>
              </Typography>
              <Typography variant="body1">
                <strong>Start Sale: {startSaleDateStr}</strong>
              </Typography>
              <Typography variant="body1">
                <strong>End Sale: {endSaleDateStr}</strong>
              </Typography>
              {canUserApproveWisdoms(webUser, serverWisdom) && (
                <Button
                  size="small"
                  color="primary"
                  key={"approveButton" + serverWisdom?.createdAt?.toString()}
                  disabled={serverWisdom?.declinedBoundaryList.length > 0}
                  onClick={async (event) => {
                    handleApproveWisdom(event);
                  }}
                >
                  <ThumbUpIcon fontSize="small" /> &nbsp; Approve
                </Button>
              )}

              {canUserDeclineWisdoms(webUser, serverWisdom) && (
                <Button
                  size="small"
                  color="primary"
                  key={"declineButton" + serverWisdom?.createdAt?.toString()}
                  disabled={serverWisdom?.declinedBoundaryList.length === 0}
                  onClick={(event) => {
                    event.preventDefault();
                    dispatch(declineWisdom(serverWisdom, webUser));
                    setAlertBannerResult({
                      children: null,
                      type: "success",
                      message: "Wisodm declined! Thanks for being an Approver!",
                      showNow: true,
                      theKey: alertKeyResult,
                      handleClose: handleCloseAlertResult,
                    });

                    //history.push(`/`);
                  }}
                >
                  <ThumbDownIcon fontSize="small" /> &nbsp; Decline
                </Button>
              )}
              {/* {isWisdomApproved(serverWisdom) &&
              isUserWisdomOwner(webUser, serverWisdom) && (
                <Button
                  size="small"
                  color="secondary"
                  key={"mintButton" + serverWisdom?.createdAt?.toString()}
                  onClick={() => {
                    dispatch(mintWisdom(serverWisdom, webUser));
                    //history.push(`/`);
                    //window.location.reload();
                    //alert("mint>currentUrl=" + currentUrl);
                    history.push(currentUrl);
                  }}
                >
                  <AddCircleIcon fontSize="small" /> &nbsp; Mint
                </Button>
              )} */}
              {/* {isWisdomApproved(serverWisdom) ||
              (isWisdomDeclined(serverWisdom) &&
                isUserWisdomOwner(webUser, serverWisdom) && (
                  <Button
                    size="small"
                    color="secondary"
                    key={"cancelButton" + serverWisdom?.createdAt?.toString()}
                    onClick={(event) => {
                      event.preventDefault();
                      dispatch(cancelWisdom(serverWisdom, webUser));
                      history.push(`/`);
                    }}
                  >
                    <CancelIcon fontSize="small" /> &nbsp; Cancel
                  </Button>
                ))} */}
            </div>
          )}
          {!SHOW_BETA_TWEET && (
            <div>
              {isOnList(webUser, perm.APPROVE_WISDOM.name) &&
                canAuthorProposeImage(serverAuthor) && (
                  <Button
                    size="small"
                    color="primary"
                    key={
                      "denyProposeImageButton" +
                      serverWisdom?.createdAt?.toString()
                    }
                    disabled={false}
                    onClick={async (event) => {
                      handleDenyProposeImage(serverAuthor, true);
                    }}
                  >
                    <ThumbDownIcon fontSize="small" /> &nbsp; Deny Images
                  </Button>
                )}
              {isOnList(webUser, perm.APPROVE_WISDOM.name) &&
                !canAuthorProposeImage(serverAuthor) && (
                  <Button
                    size="small"
                    color="primary"
                    key={
                      "allowProposeImageButton" +
                      serverWisdom?.createdAt?.toString()
                    }
                    disabled={false}
                    onClick={async (event) => {
                      handleDenyProposeImage(serverAuthor, false);
                    }}
                  >
                    <ThumbUpIcon fontSize="small" /> &nbsp; Allow Images
                  </Button>
                )}

              {isOnList(webUser, perm.APPROVE_WISDOM.name) &&
                canAuthorMintWisdom(serverAuthor) && (
                  <Button
                    size="small"
                    color="primary"
                    key={"noMintButton" + serverWisdom?.createdAt?.toString()}
                    disabled={false}
                    onClick={async (event) => {
                      handleNoMintUser(serverAuthor, true);
                    }}
                  >
                    <ThumbDownIcon fontSize="small" /> &nbsp; Deny Minting
                  </Button>
                )}

              {isOnList(webUser, perm.APPROVE_WISDOM.name) &&
                !canAuthorMintWisdom(serverAuthor) && (
                  <Button
                    size="small"
                    color="primary"
                    key={
                      "allowMintButton" + serverWisdom?.createdAt?.toString()
                    }
                    disabled={false}
                    onClick={async (event) => {
                      handleNoMintUser(serverAuthor, false);
                    }}
                  >
                    <ThumbUpIcon fontSize="small" /> &nbsp; Allow Minting
                  </Button>
                )}
              {isOnList(webUser, perm.APPROVE_WISDOM.name) &&
                canAuthorBuyWisdom(serverAuthor) && (
                  <Button
                    size="small"
                    color="primary"
                    key={
                      "denyBuyWisdomButton" +
                      serverWisdom?.createdAt?.toString()
                    }
                    disabled={false}
                    onClick={async (event) => {
                      handleDenyBuyWisdom(serverAuthor, true);
                    }}
                  >
                    <ThumbDownIcon fontSize="small" /> &nbsp; Deny Buying
                  </Button>
                )}
              {isOnList(webUser, perm.APPROVE_WISDOM.name) &&
                !canAuthorBuyWisdom(serverAuthor) && (
                  <Button
                    size="small"
                    color="primary"
                    key={
                      "allowBuyWisdomButton" +
                      serverWisdom?.createdAt?.toString()
                    }
                    disabled={false}
                    onClick={async (event) => {
                      handleDenyBuyWisdom(serverAuthor, false);
                    }}
                  >
                    <ThumbUpIcon fontSize="small" /> &nbsp; Allow Buying
                  </Button>
                )}
            </div>
          )}
          <Divider style={{ margin: "20px 0" }} />

          {areCommentsOn() && <CommentSection serverWisdom={serverWisdom} />}
          <AlertBanner {...alertBannerBoundary}></AlertBanner>

          {doShowBoundaries(webUser, serverWisdom) && !SHOW_BETA_TWEET && (
            <div>
              <Typography variant="h6">
                Select each boundary this wisdom violates.
                <br></br>
                Also confirm each hashtag selected makes sense for this
                proposal.
                <Tooltip
                  placement="top"
                  title={<h2>Not everything is wisdom</h2>}
                >
                  <button
                    className={classes.btnhelp}
                    onClick={() =>
                      setAlertBannerBoundaryHelper("Not everything is Wisdom.")
                    }
                  >
                    <HelpOutline />
                  </button>
                </Tooltip>
              </Typography>
              <BoundarySection
                key="BoundarySectionKey"
                serverWisdom={serverWisdom}
                setAlertBannerHelper={setAlertBannerBoundaryHelper}
                setBoundaryCount={setBoundaryCount}
              />
            </div>
          )}
          {isWisdomDeclined(serverWisdom) && !SHOW_BETA_TWEET && (
            <div>
              <Divider style={{ margin: "20px 0" }} />

              <Typography variant="h6">Wisdom boundary violations</Typography>
              <BoundarySection serverWisdom={serverWisdom} />
            </div>
          )}
          <Divider style={{ margin: "20px 0" }} />
        </div>
        <div className={classes.imageSection}>
          <ImageOrNot imageFile={serverWisdom?.imageFile} />
          {/* <img
            className={classes.media}
            src={
              post.imageFile ||
              "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
            }
            alt={post.title}
          /> */}
        </div>
      </div>
      {!!recommendedWisdoms.length && !SHOW_BETA_TWEET && (
        <div className={classes.section}>
          <Typography gutterBottom variant="h5">
            &nbsp; You might also like:
          </Typography>
          <Divider />
          <div className={classes.recommendedWisdoms}>
            {recommendedWisdoms.map((wisdom) => (
              // ({ title, name, wisdomText, likes, imageFile, _id }) => (
              <div
                style={{ margin: "20px", cursor: "pointer" }}
                // onClick={() => openWisdom(wisdom._id)}
                key={"recommmend" + wisdom._id}
              >
                {/* <Typography gutterBottom variant="h6">
                  {serverWisdom.wisdomText}
                </Typography> */}
                {/* <Typography gutterBottom variant="subtitle2">
                    {name}
                  </Typography> */}
                {/* <Typography gutterBottom variant="subtitle2">
                    {message}
                  </Typography> */}
                <div
                  //style={{ margin: "20px", cursor: "pointer" }}
                  onClick={(event) => openWisdom(event, wisdom._id)}
                  key={"recommmenBox" + wisdom._id}
                >
                  <WisdomBox wisdom={wisdom} cursor={"pointer"} />
                </div>
                <Typography variant="h6">
                  Created by:
                  <Link
                    to={`/author/${wisdom?.authorId}/${wisdom?.status}`}
                    style={{ textDecoration: "none", color: "#3f51b5" }}
                  >
                    {` ${wisdom.authorName}`}
                  </Link>
                </Typography>

                {/* <Typography gutterBottom variant="subtitle1">
                  Likes: {wisdom.likes.length}
                </Typography> */}
                <div className={classes.voteBox}>
                  {isWisdomMinted(serverWisdom) && (
                    <Tooltip title={<h2>{likeTooltip}</h2>} placement="top">
                      <span>
                        <Button
                          size="small"
                          color="primary"
                          // disabled={!webUser}
                          onClick={(event) =>
                            handleVote(event, "likes", "likesCount", wisdom)
                          }
                        >
                          <Votes
                            className={classes.voteButton}
                            voteType="likes"
                            voteTypeCount="likesCount"
                            VoteIcon={VoteIcons["likes"]}
                            wisdom={wisdom}
                          />
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  {isWisdomMinted(serverWisdom) && (
                    <Tooltip title={<h2>{feelTooltip}</h2>} placement="top">
                      <span>
                        <Button
                          size="small"
                          color="primary"
                          // disabled={!webUser}
                          onClick={(event) =>
                            handleVote(event, "feels", "feelsCount", wisdom)
                          }
                        >
                          <Votes
                            className={classes.voteButton}
                            voteType="feels"
                            voteTypeCount="feelsCount"
                            wisdom={wisdom}
                          />
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  {isWisdomMinted(serverWisdom) && (
                    <Tooltip title={<h2>{thinkTooltip}</h2>} placement="top">
                      <span>
                        <Button
                          size="small"
                          color="primary"
                          // disabled={!webUser}
                          onClick={(event) =>
                            handleVote(event, "thinks", "thinksCount", wisdom)
                          }
                        >
                          <Votes
                            className={classes.voteButton}
                            voteType="thinks"
                            voteTypeCount="thinksCount"
                            wisdom={wisdom}
                          />
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  {isWisdomMinted(serverWisdom) && (
                    <Tooltip title={<h2>{cheerTooltip}</h2>} placement="top">
                      <span>
                        <Button
                          size="small"
                          color="primary"
                          // disabled={!webUser}
                          onClick={(event) =>
                            handleVote(event, "cheers", "cheersCount", wisdom)
                          }
                        >
                          <Votes
                            className={classes.voteButton}
                            voteType="cheers"
                            voteTypeCount="cheersCount"
                            wisdom={wisdom}
                          />
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </div>
                {/* <img src={imageFile} alt={" "} width="200px" /> */}
              </div>
            ))}
          </div>
        </div>
      )}
      {SHOW_BETA_TWEET && (
        <Tooltip placement="right" title={<h2>Home page</h2>}>
          <Link to="/" className={classes.brandContainer}>
            <img
              className={classes.image}
              src={makeItWisdomLogo}
              alt="icon"
              height="40px"
            />
          </Link>
        </Tooltip>
      )}
    </Paper>
  );
};

export default Wisdom;
