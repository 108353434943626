import React from "react";
import {
  Avatar,
  ButtonBase,
  Card,
  //  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core/";
//import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
// import LaunchIcon from "@material-ui/icons/Launch";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

//import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import { useDispatch } from "react-redux";
//import moment from "moment";
import { useHistory } from "react-router-dom";

import {
  // likeWisdom,
  // hideWisdom,
  isUserWisdomOwner,
  isWisdomHidden,
  //  unHideWisdom,
} from "../../actions/wisdoms";
//
import { getAuthor } from "../../actions/users";
//

import useStyles from "./styles";
// import WisdomBox from "./WisdomBox";
// import {
//   MINTED_STRING,
//   PROPOSED_STRING,
//   PROFILE,
// } from "../../constants/constants.js";
import { loadWebUser } from "../myLocalStorage.js";

const AuthorCard = ({ authorJSON, setCurrentId }) => {
  const webUser = loadWebUser();
  // const [likes, setLikes] = useState(authorJSON?.likes);
  // const [hidden, setHidden] = useState(authorJSON?.hidden);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  //  const userId = user?.googleId || user?._id;
  //  const userId = webUser?._id;
  // const hasLikedWisdom =
  //   authorJSON?.likes?.find((like) => like === userId) || false;

  // const hasLikedWisdom = serverWisdom?.likes?.forEach((i) => {
  //   console.log("AuthorCard>check ", i, userId);
  //   if (i === userId) {
  //     console.log("AuthorCard>like MATCH FOUND");
  //     return true;
  //   }
  // });

  // const hasLikedWisdom = () => {
  //   var count = 0;
  //   var c = serverWisdom?.likes?.forEach((i) => {
  //     console.log("AuthorCard>check ", i, userId);
  //     if (i === userId) {
  //       console.log("AuthorCard>like MATCH FOUND");
  //       ++count;
  //     }
  //   });
  //   return count > 0;
  // };

  // console.log(
  //   "AuthorCard>",
  //   userId,
  //   serverWisdom?.hidden,
  //   "=",
  //   serverWisdom?.wisdomText,
  //   "=",
  //   serverWisdom?.likes.length,
  //   "=",
  //   hasLikedWisdom
  //   // "=",
  //   // serverWisdom?.likes
  // );

  // const handleHideWisdom = async () => {
  //   dispatch(hideWisdom(authorJSON?._id));
  //   authorJSON.hidden = !authorJSON.hidden;
  //   setHidden(authorJSON?.hidden);
  // };
  // //
  // const handleLike = async (event) => {
  //   event.preventDefault();
  //   dispatch(likeWisdom(authorJSON?._id));

  //   if (hasLikedWisdom) {
  //     //      console.log("handleLike>removing ", userId);
  //     setLikes(authorJSON?.likes.filter((id) => id !== userId));
  //   } else {
  //     //      console.log("handleLike>adding ", userId);
  //     setLikes([...authorJSON?.likes, userId]);
  //   }
  // };

  // const Likes = () => {
  //   if (likes?.length > 0) {
  //     return likes?.find((like) => like === userId) ? (
  //       <>
  //         <ThumbUpAltIcon fontSize="small" />
  //         &nbsp;
  //         {likes?.length > 2
  //           ? `You and ${likes.length - 1} others`
  //           : `${likes.length} like${likes.length > 1 ? "s" : ""}`}
  //       </>
  //     ) : (
  //       <>
  //         <ThumbUpAltOutlined fontSize="small" />
  //         &nbsp;{likes.length} {likes.length === 1 ? "Like" : "Likes"}
  //       </>
  //     );
  //   }

  //   return (
  //     <>
  //       <ThumbUpAltOutlined fontSize="small" />
  //       &nbsp;Like
  //     </>
  //   );
  // };

  const openAuthor = (e) => {
    e.stopPropagation();
    dispatch(getAuthor(authorJSON?._id, history));
    console.log("openAuthor>", authorJSON?._id);

    history.push(`/author/${authorJSON?._id}`);
  };

  //console.log(">>=", serverWisdom?.createdAt);
  //console.log(">>=", serverWisdom?._id);

  // useEffect(() => {
  //   if (!serverWisdom) {
  //     dispatch(getWisdom(serverWisdom?._id));
  //   }
  // }, [serverWisdom, hidden]);
  if (isWisdomHidden(authorJSON) && !isUserWisdomOwner(webUser, authorJSON))
    return (
      <Card
        className={classes.card}
        raised
        elevation={6}
        key={"AuthorCard" + authorJSON?.createdAt?.toString()}
      ></Card>
    );
  else
    return (
      <Card
        className={classes.card}
        raised
        elevation={6}
        key={"AuthorCard" + authorJSON?.createdAt?.toString()}
      >
        <ButtonBase
          component="span"
          name="test"
          className={classes.cardAction}
          key={"openAuthor" + authorJSON?.createdAt?.toString()}
          onClick={openAuthor}
        >
          <CardMedia
            className={classes.mediaNoPic}
            key={"Card-CardMedia" + authorJSON?._id}
            image={require("../../images/no_pic.png")}
            //title={"the title"}
          />

          <Avatar
            className={classes.purple}
            alt={authorJSON?.name}
            src={authorJSON?.imageUrl}
          ></Avatar>
          <Typography variant="h6">{authorJSON?.name}</Typography>
          <Typography variant="h6">{authorJSON?.twitterHandle}</Typography>
          <Typography variant="h6">
            Minted: {authorJSON?.wisdomOwned.length}
          </Typography>
          <Typography variant="h6">
            Owned: {authorJSON?.wisdomMintedCount}
          </Typography>
          {/* <CardMedia
            className={
              author?.imageFile ? classes.media : classes.mediaNoPic
            }
            key={"Card-CardMedia" + author?.createdAt?.toString()}
            image={
              author?.imageFile || require("../../../images/no_pic.png")
            }
            //title={"the title"}
          />
          <div
            className={classes.overlay}
            key={"momentDiv" + author?.createdAt?.toString()}
          >
            <Typography variant="h6">{author?.authorName}</Typography>
            <Typography variant="body2">
              {moment(author?.createdAt).fromNow()}
            </Typography>
          </div>
          {isUserWisdomOwner && (
            <div className={classes.overlay2} name="edit">
              <Button
                key={"ButtonHoriz" + author?.createdAt?.toString()}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentId(author?._id);
                }}
                style={{ color: "white" }}
                size="small"
              >
                <MoreHorizIcon fontSize="medium" />
              </Button>
            </div>
          )}
          <div
            className={classes.details}
            key={"tagDiv" + author?.createdAt?.toString()}
          >
            <Typography variant="body2" color="textSecondary" component="h2">
              {author?.hashtagTexts?.map((tag) => `#${tag} `)}
            </Typography>
          </div>
          <WisdomBox wisdom={author} cursor={"pointer"} /> */}
        </ButtonBase>
        {/* <CardActions className={classes.cardActions}>
          {author?.status === MINTED_STRING && (
            <Button
              size="small"
              color="primary"
              disabled={!user}
              onClick={handleLike}
            >
              <Likes />
            </Button>
          )}
          {author?.status === MINTED_STRING &&
            isUserWisdomOwner &&
            !isWisdomHidden && (
              <Button
                size="small"
                color="secondary"
                key={"hideButton" + author?.createdAt?.toString()}
                onClick={handleHideWisdom}
              >
                <VisibilityOffIcon fontSize="small" /> &nbsp; Hide
              </Button>
            )}
          {author?.status === MINTED_STRING &&
            isUserWisdomOwner &&
            isWisdomHidden && (
              <Button
                size="small"
                color="secondary"
                key={"unHideButton" + author?.createdAt?.toString()}
                onClick={handleHideWisdom}
              >
                <VisibilityIcon fontSize="small" /> &nbsp; unHide
              </Button>
            )}
          <Button
            size="small"
            color="secondary"
            key={"openButton" + author?.createdAt?.toString()}
            onClick={openAuthor}
          >
            <LaunchIcon fontSize="small" /> &nbsp; Open
          </Button>
          <br />
        </CardActions> */}
        <br />
      </Card>
    );
};

export default AuthorCard;
