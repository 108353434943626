//
//
//
import axios from "axios";
import { PROFILE } from "../constants/constants.js";
//import { isLocalhost } from "../components/serviceWorker.js";
//import getNodeConfigFolderField from "../configs/getNodeConfigFolderField.mjs";
//import getNodeConfigField from "../configs/getNodeConfigField.mjs";
import { getMyNodeConfigField, getMyDBPort } from "../configs/multiConfig.mjs";
//import { getMyDBPort } from "../../../server/configs/multiConfig.mjs";

// build path to SERVER
// old way
//var baseURL = "http://gypcapp.com:(5000)";
//if (isLocalhost()) baseURL = "http://localhost:(5000)";

// get domainAddy from config
// get port from config (5000)
//var domainAddy, port; //, nodeIdJSON, thisNodeJSON, thisFolderJSON;

//console.log("index>dirname=", __dirname);

//[domainAddy, nodeIdJSON, thisNodeJSON] =
// var retJSON = getNodeConfigField(
//   "/Users/danfarfan/Documents/Crypto/makeitwisdom/server",
//   "miwServer",
//   "domainAddy"
// );
//const domainAddy = retJSON.fieldValue;
const doLog = false;

// multiConfig way:
if (doLog) console.log("index>get domainAddy");
const domainAddy = getMyNodeConfigField("domainAddy");
if (doLog) console.log("index>got domainAddy", domainAddy);

//[port, nodeIdJSON, thisNodeJSON, thisFolderJSON]
// retJSON = getNodeConfigFolderField(
//   "/Users/danfarfan/Documents/Crypto/makeitwisdom/server",
//   "miwServer",
//   "listenPort"
// );
// const port = retJSON.fieldValue;
// multiConfig
//const { listenPort, dbName } = getMyDBConfig();
const listenPort = getMyDBPort();

// used to connect from Client to Server (i.e. the API)
const baseURL = domainAddy + ":" + listenPort;
if (doLog) console.log("index>baseURL (with port)=", baseURL);

//const API = axios.create({ baseURL: "http://gypcapp.com:(5000)" });
//const API = axios.create({ baseURL: "http://localhost:(5000)" });
const API = axios.create({ baseURL: baseURL });

API.interceptors.request.use((req) => {
  if (localStorage.getItem(PROFILE)) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem(PROFILE)).token
    }`;
  }

  return req;
});

export const fetchWisdom = (id, wisdomId, userName) =>
  API.get(`/wisdoms/${id}/${wisdomId}/${userName}`);
export const fetchMintedWisdoms = (page, width, userName) => {
  //console.log("fetchMintedWisdom>");
  return API.get(`/wisdoms?width=${width}&userName=${userName}&page=${page}`);
};

export const fetchWisdomsByAuthor = (query) =>
  API.get(
    `/wisdoms/authors/${query?.name}/${query?.status}/${query.width}/${query.userName}`
  );

export const fetchWisdomsByHashtag = (query) =>
  API.get(
    `/wisdoms/hashtagText?hashtagText=${query?.hashtagText}&status=${query?.status}&userName=${query.userName}&page=${query?.page}`
  );
//
export const fetchWisdomsBySearch = (query) =>
  API.get(
    `/wisdoms/search?searchText=${query?.searchText || ""}&hashtagTexts=${
      query?.hashtagTexts
    }&status=${query?.status}&authorId=${query?.authorId}&authorName=${
      query?.authorName
    }&ownerId=${query?.ownerId}&ownerName=${query?.ownerName}&width=${
      query?.width
    }&userName=${query.userName}&userId=${query?.userId}&page=${query?.page}`
  );
// proposed wisdoms only works as post because AUTH is needed
export const fetchProposedWisdoms = (page, width, userName) =>
  API.post(
    `/wisdoms/proposed?width=${width}&userName=${userName}&page=${page}`
  );

//
//
//
export const fetchAuthor = async (id = "unk", userName = "unk") => {
  if (doLog) console.log("fetchAuthor>id=", id, userName);
  return await API.get(`/user/author/${id}/${userName}`);
};
export const fetchAuthors = (query) =>
  API.get(
    `/user/search?name=${query?.name}&status=${query?.status}&userName=${query.userName}&walletsOnly=${query.walletsOnly}&page=${query?.page}`
  );

export const updateUser = (id, userQuery) =>
  API.patch(`/user/${id}`, userQuery);

//
// experiment
// export const fetchProposedWisdoms = (page) =>
//   API.patch(`/wisdoms/proposed/${page}`);

//

//export const approveWisdom = (id) => API.patch(`/wisdoms/${id}/approveWisdom`);
export const approveWisdom = (id, data) =>
  API.post(`/wisdoms/${id}/approveWisdom`, data);
export const setWisdomApprover = (data) =>
  API.post(`/wisdoms/0/setWisdomApprover`, data);
export const declineWisdom = (id, declinedBoundaryList) =>
  API.post(`/wisdoms/${id}/declineWisdom`, declinedBoundaryList);
export const voteWisdom = (id, data) =>
  API.post(`/wisdoms/${id}/voteWisdom`, data);

export const mintWisdom = (id, data) =>
  API.post(`/wisdoms/${id}/mintWisdom`, data);
export const cancelWisdom = (id) => API.patch(`/wisdoms/${id}/cancelWisdom`);
export const buyWisdom = (id, data) =>
  API.post(`/wisdoms/${id}/buyWisdom`, data);
//
export const proposeWisdom = (newWisdom) => API.post("/wisdoms", newWisdom);
//export const likeWisdom = (id) => API.patch(`/wisdoms/${id}/likeWisdom`);

export const startBuyWisdom = (id) =>
  API.patch(`/wisdoms/${id}/startBuyWisdom`);
export const stopBuyWisdom = (id) => API.patch(`/wisdoms/${id}/stopBuyWisdom`);

export const hideWisdom = (id) => API.patch(`/wisdoms/${id}/hideWisdom`);
export const unHideWisdom = (id) => API.patch(`/wisdoms/${id}/unHideWisdom`);
export const comment = (id, value) =>
  API.post(`/wisdoms/${id}/commentWisdom`, { value });
export const updateWisdom = (id, updatedWisdom) =>
  API.patch(`/wisdoms/${id}`, updatedWisdom);
export const deleteWisdom = (id) => API.delete(`/wisdoms/${id}`);
export const sellWisdom = (id, data) =>
  API.patch(`/wisdoms/${id}/sellWisdom`, data);

export const registerWisdomSSE = (data) =>
  API.post(`/wisdoms/registerWisdomSSE`, data);

//
//
//
export const fetchHashtags = (query) => {
  //console.log("fetchMintedHashtags>");
  //  return API.get(`/hashtags/getminted?page=${page}`);
  return API.get(
    `/hashtags/get?searchText=${query?.searchText || ""}&status=${
      query?.status
    }&ownerId=${query?.ownerId}&ownerName=${query?.ownerName}&userName=${
      query?.userName
    }&page=${query?.page}`
  );
};
//
export const proposeHashtag = (newHashtag) => API.post("/hashtags", newHashtag);
export const approveHashtag = (id, data) =>
  API.post(`/hashtags/${id}/approveHashtag`, data);
export const setHashtagApprover = (data) =>
  API.post(`/wisdoms/0/setHashtagApprover`, data);

export const mintHashtag = (id, data) =>
  API.post(`/hashtags/${id}/mintHashtag`, data);

export const updateHashtag = (id, data) => API.patch(`/hashtags/${id}`, data);

export const fetchHashtag = (id, hashtagText, userName) =>
  API.get(`/hashtags/${id}/${hashtagText}/${userName}`);

//
//
//
export const vcha = (token) => API.post("/user/vcha", token);

export const checkAuth = (formData) => API.patch("/user/checkAuth", formData);

export const inviteByEmail = (formData) =>
  API.patch("/user/inviteByEmail", formData);

export const signIn = (formData) => API.post("/user/signin", formData);
export const signUp = (formData) => API.post("/user/signup", formData);
export const changePassword = (formData) =>
  API.post("/user/changePassword", formData);
export const signInGoogle = (formData) =>
  API.post("/user/signinGoogle", formData);
//
export const activateWallet = (account) =>
  API.post("/wallet/activateWallet", account);
//
//
export const fetchPioneers = () => API.get(`/pioneers`);
export const mintPioneer = (pioneer) =>
  API.patch(`/pioneers/mintPioneer`, pioneer);
export const getImgs = (ids) => API.post("/user/getImgs", ids);

//
//
//
export const logEvent = async (event) => {
  //console.log("logEvent>to server:eventName=", event.eventName);
  API.post("/event/logEvent", event);
};
// server config
export const getServerConfig = async () => {
  return await API.get("/servers/getConfig");
};
export const setServerConfig = async (data) => {
  API.post("/servers/setConfig", data);
};
//
//use post to require auth (instead of get which does not)
export const coinInCurrency = async (data) => {
  return await API.post("/servers/coinInCurrency", data);
};
