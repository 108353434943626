/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link, useLocation } from "react-router-dom";
// import {
//   //  PROPOSED_STRING,
//   MINTED_STRING,
//   //  DISPLAY_WISDOM_TYPE,
// } from "../../constants/constants";

// import {
//   getMintedWisdoms,
//   getProposedWisdoms,
//   getWisdomsBySearch,
// } from "../../actions/wisdoms";
import useStyles from "../styles";
import { getAuthors } from "../../actions/users";
//import { createImportSpecifier } from "typescript";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const doLog = false;

const Paginate = ({
  name,
  status,
  page,
  forceReload = false,
  userName = "unk",
}) => {
  const { numberOfPages, reload } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const query = useQuery();
  const searchQuery = query.get("searchQuery");
  const classes = useStyles();

  // console.log(
  //   "Paginate>useSelector.state.wisdoms",
  //   useSelector((state) => state.wisdoms)
  // );
  const numAuthors = useSelector((state) => state.users.users.length);
  // console.log("PaginationAuthors>numAuthors=", numAuthors);

  // const isLoading = useSelector((state) => state.users.isLoading);
  // console.log("Paginate>isLoading>", isLoading);
  // console.log("PaginationAuthors.useLocation", useLocation());
  // console.log("PaginationAuthors.useLocation.search", useLocation().search);
  // console.log("PaginationAuthors.searchQuery>", searchQuery);

  const pathRoot = useLocation().pathname;
  const pathSearch = useLocation().search;

  if (doLog)
    console.log(
      "PaginateAuthors>reload",
      reload,
      "forceReload=",
      forceReload,
      "page=",
      page
    );

  useEffect(() => {
    // if (page) {
    //   console.log("PaginationAuthors.useEffect>dispatch = ");
    // }
    if (page) {
      if (doLog)
        console.log(
          "PaginationAuthors.useEffect>reload=",
          reload,
          "forceReload=",
          forceReload,
          "page=",
          page
        );

      if (numAuthors === 0 || forceReload) {
        if (doLog) {
          console.log("PaginationAuthors.useEffect>reloading now");
          console.log("PaginationAuthors.searchQuery>", searchQuery);
        }
        const { innerWidth: screenWidth } = window;
        //
        //   if (pathRoot === "/wisdoms/search") {
        //     // building this query from the url is SOO STUPID
        //     const parms = pathSearch.split("&");
        //     console.log("PaginationAuthors.useEffect>parms=", parms);

        //     const query = {
        //       searchText: parms[0].split("=")[1],
        //       hashtagTexts: parms[1].split("=")[1],
        //       status: parms[2].split("=")[1],
        //       authorId: parms[3].split("=")[1],
        //       authorName: parms[4].split("=")[1],
        //       page: page,
        //     };
        //     dispatch(getWisdomsBySearch(query));
        //   } else {
        //     if (displayWisdomType === MINTED_STRING) {
        //       dispatch(getMintedWisdoms(page));
        //     } else {
        //       dispatch(getProposedWisdoms(page));
        //     }
        //   }
        // }
        dispatch(
          getAuthors({
            name: name,
            status: status,
            width: screenWidth.toString(),
            page: page,
          })
        );
      }
    } else {
      console.log("PaginationAuthors.useEffect>page=", page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  //console.log("Paginate>displayWisdomType", displayWisdomType, "page", page);
  // const pageRoot =
  //   displayWisdomType === MINTED_STRING
  //     ? "/wisdoms?page="
  //     : "/wisdoms/proposed?page=";
  // TODO: build rest of URL string to access different page of same search criteria
  // const toOtherPage = (otherPage) => {
  //   var n = pathSearch.indexOf("&page=");
  //   var s = pathSearch.substring(0, n !== -1 ? n : pathSearch.length);
  //   if (s === "") s = s + "?";
  //   else s = s + "&";

  //   var op = pathRoot + s + "page=" + otherPage.toString(); // + "&pageReload=true";
  //   if (op.indexOf("Reload") === -1) op += "&pageReload=true";
  //   return op;
  // };

  // const toOtherPage = (otherPage) => {
  //   var n1 = pathSearch.indexOf("?page=");
  //   var n2 = pathSearch.indexOf("&page=");
  //   var n = n1 === -1 ? n2 : n2 === -1 ? n1 : Math.min(n1, n2);
  //   var s = pathSearch.substring(0, n !== -1 ? n : pathSearch.length);
  //   if (s === "") s = s + "?";
  //   else s = s + "&";

  //   var op = pathRoot + s + "page=" + otherPage.toString() + "&pageReload=true";
  //   return op;
  // };

  const toOtherPage = (otherPage) => {
    var n1 = pathSearch.indexOf("?page=");
    var n2 = pathSearch.indexOf("&page=");
    var n = n1 === -1 ? n2 : n2 === -1 ? n1 : Math.min(n1, n2);
    var s = pathSearch.substring(0, n !== -1 ? n : pathSearch.length);
    if (s === "") s = s + "?";
    else s = s + "&";

    var op = pathRoot + s + "page=" + otherPage.toString() + "&pageReload=true";
    if (doLog) console.log("toOtherPage=", op);
    return op;
  };
  //
  //
  //
  return (
    <Pagination
      classes={{ ul: classes.ul }}
      count={numberOfPages}
      page={Number(page) || 1}
      variant="outlined"
      color="primary"
      renderItem={(item) => (
        <PaginationItem
          {...item}
          component={Link}
          //to={`/wisdoms?page=${item.page}`}
          // TODO: make Paginate.to get other page of serverWisdoms, but same URL
          //to={`${pageRoot}${item.page}&pageReload=true`}
          to={() => toOtherPage(item.page)}
        />
      )}
    />
  );
};

export default Paginate;
