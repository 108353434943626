import React, { useState } from "react";
import {
  Container,
  Grow,
  Typography,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@material-ui/core";
//import { useDispatch } from "react-redux";
//import { useLocation } from "react-router-dom";
import AlertBanner from "../AlertBanner/AlertBanner.js";
import useStyles from "./styles";
import HelpOutline from "@material-ui/icons/HelpOutline";
// import {
//   PROPOSED_STRING,
//   MINTED_STRING,
//   DISPLAY_WISDOM_TYPE,
// } from "../../constants/constants";

// import {
//   getDisplayProfileValue,
//   setDisplayProfileValue,
// } from "../localStorage";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }
var currentAlertMessageMap = new Map();
// currentAlertMessageMap.set(alertKey, "test1");
// currentAlertMessageMap.set(alertKey, "test2");

const Boundaries = () => {
  const classes = useStyles();

  const alertKey = "BoundaryAlertKey";
  const showBoundaryAlert = (num) => {
    // toggle AlertBanner
    //
    // console.log(
    //   "showBoundaryAlert>already set:",
    //   currentAlertMessageMap.get(alertKey)
    // );
    if (currentAlertMessageMap.get(alertKey) !== boundaryArray[num].info) {
      setAlertBanner({
        children: null,
        type: "info",
        message: boundaryArray[num].info,
        showNow: true,
        theKey: alertKey,
        handleClose: () => handleCloseAlert(alertKey),
      });
      currentAlertMessageMap.set(alertKey, boundaryArray[num].info);
      // console.log(
      //   "showBoundaryAlert>set now",
      //   currentAlertMessageMap.get(alertKey)
      // );
    } else {
      // console.log("CLOSE ALERT");
      currentAlertMessageMap.set(alertKey, "");
      // remove banner already there
      handleCloseAlert(alertKey);
    }
  }; // show boundary alert
  //
  const handleCloseAlert = (key) => {
    // changing state cause rerender
    setAlertBanner({ ...alertBanner, theKey: key, showNow: false });
  };

  const [alertBanner, setAlertBanner] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKey,
    handleClose: () => handleCloseAlert(alertKey),
  });

  function displayRow(boundary) {
    return (
      <TableRow
        key={boundary.number}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          className={classes.tableCellBoundary}
          component="th"
          onClick={() => showBoundaryAlert(boundary.num1)}
          scope="row"
          padding="none"
          align="center"
          style={{
            width: 100,
            //borderTop: "1px solid black",
            borderBottom: "1px solid black",
            cursor: "pointer",
          }}
        >
          {boundaryArray[boundary.num1].text} <HelpOutline />
        </TableCell>
        <TableCell
          className={classes.tableCellBoundary}
          onClick={() => showBoundaryAlert(boundary.num2)}
          component="th"
          scope="row"
          padding="none"
          align="center"
          style={{
            width: 100,
            //borderTop: "1px solid black",
            borderBottom: "1px solid black",
            cursor: "pointer",
          }}
        >
          {boundaryArray[boundary.num2].text} <HelpOutline />
        </TableCell>
      </TableRow>
    );
  } // display row
  //

  function isOdd(boundary) {
    return !(boundary % 2 === 0);
  } // is odd

  return (
    <Grow in>
      <Container maxWidth="xl">
        <Paper
          elevation={8}
          style={{
            padding: 12,
            border: "1px solid black",
          }}
        >
          <Typography variant="h6">
            Since a definitive definition of Wisdom is elusive and
            <strong> "I'll know it when I see it" </strong>
            is impractical, instead, we use the following boundaries:
          </Typography>
          <br></br>
          <TableContainer component={Paper}>
            <Table
              aria-label="Boundaries"
              elevation="5"
              align="center"
              style={{ width: "90%", marginBottom: "1em", marginTop: "1em" }}
            >
              <TableBody>
                {boundaryDoubleNumArray.filter(isOdd).map(displayRow)}
              </TableBody>
            </Table>
          </TableContainer>
          <br></br>

          <h3>
            See also <a href="/faqs">FAQ.</a>
          </h3>
          <AlertBanner {...alertBanner}></AlertBanner>
        </Paper>
      </Container>
    </Grow>
  );
}; // Boundaries

export const boundaryArray = [
  {
    number: 0,
    //row: 11,
    text: "Truth alone is not Wisdom",
    info: "It may be true that you eat cookies before bed because it reminds you of your childhood, but it is not Wisdom.",
  },
  {
    number: 1,
    //row: 1,
    text: "Obvious is not Wisdom",
    info: "Some Wisdom connects the obvious and the non-obvious in a thoughtful way. But just the obvious falls.",
  },
  {
    number: 2,
    //row: 3,
    text: "Fact is not Wisdom",
    info: "Wisdom sets a higher bar than something that is factual.",
  },
  {
    number: 3,
    //row: 5,
    text: "Insult is not Wisdom",
    info: "Wisdom honors, not dishonors.",
  },
  {
    number: 4,
    //row: 9,
    text: "Duplicating Wisdom is not Wisdom",
    info: "A non-trivial variation, a significant improvement on Wisdom may be Wisdom. A close copy isn't.",
  },
  {
    number: 5,
    //row: 7,
    text: "Motivation is not Wisdom",
    info: "All good advice is not Wisdom.",
  },
  {
    number: 6,
    //row: 1,
    text: "Vulgar is not Wisdom",
    info: "Impolite adds nothing valuable to Wisdom. Anything vulgar can be reworded to not be.",
  },
  {
    number: 7,
    //row: 11,
    text: "Evil is not Wisdom",
    info: "On the long arc of history, good beats evil, but only wiser Wisdom beats Wisdom.",
  },
  {
    number: 8,
    //row: 7,
    text: "Fake is not Wisdom",
    info: "Users must use their real name.",
  },
  {
    number: 9,
    //row: 5,
    text: "Hyperbole is not Wisdom",
    info: "Exaggeration lessens what it touches.",
  },
  {
    number: 10,
    //row: 3,
    text: "Fallacy is not Wisdom",
    info: "Wisdom wins on its own merits, without trickery.",
  },
  {
    number: 11,
    //row: 9,
    text: "Illegal is not Wisdom",
    info: "Speech can cross the line into illegal. Wisdom can't.",
  },
  {
    number: 12,
    text: "Wisdom must be English, for now",
    info: "Language support will grow over time.",
  },
  { number: 13, text: "Images must be family friendly", info: "You know why." },
];
//
// export const boundaryInfo = [
//   {
//     number: 0,
//     info: "It may be true that you eat cookies before bed because it reminds you of your childhood, but it's not Wisdom.",
//   },
//   {
//     number: 1,
//     info: "Connect the obvious and the non-obvious in a thoughtful way to approach Wisdom, maybe.",
//   },
//   {
//     number: 2,
//     info: "Wisdom is a higher bar that something that isn't factual.",
//   },
//   { number: 3, info: "Wisdom honors, not dishonors." },
//   {
//     number: 4,
//     info: "A non-trivial variation, a significant improvement on Wisdom may be Wisdom.",
//   },
//   { number: 5, info: "All good advice is not Wisdom." },
//   { number: 6, info: "Wisdom seeks not impolite." },
//   {
//     number: 7,
//     info: "On the long arc of history, good beats evil and nothing beats Wisdom.",
//   },
//   { number: 8, info: "No pretending." },
//   { number: 9, info: "Exaggeration lessens what it touches." },
//   { number: 10, info: "Wisdom wins without trickery." },
//   { number: 11, info: "Speech can cross the line into illegal. Wisdom can't." },
// ];
//
export const boundaryDoubleNumArray = [
  {
    number: 1,
    num1: 6,
    num2: 1,
  },
  {
    number: 3,
    num1: 10,
    num2: 2,
  },
  {
    number: 5,
    num1: 3,
    num2: 9,
  },
  {
    number: 7,
    num1: 8,
    num2: 5,
  },
  {
    number: 9,
    num1: 11,
    num2: 4,
  },
  {
    number: 11,
    num1: 7,
    num2: 0,
  },
  {
    number: 13,
    num1: 12,
    num2: 13,
  },
];

// export const boundaryDoubleArray = [
//   {
//     number: 1,
//     text1: "Vulgar is not Wisdom",
//     text2: "Obvious is not Wisdom",
//   },
//   {
//     number: 3,
//     text1: "Fallacy is not Wisdom",
//     text2: "Fact is not Wisdom",
//   },
//   {
//     number: 5,
//     text1: "Insult is not Wisdom",
//     text2: "Hyperbole is not Wisdom",
//   },
//   {
//     number: 7,
//     text1: "Fake is not Wisdom",
//     text2: "Motivation is not Wisdom",
//   },
//   {
//     number: 9,
//     text1: "Illegal is not Wisdom",
//     text2: "Duplicating Wisdom is not Wisdom",
//   },
//   {
//     number: 11,
//     text1: "Evil is not Wisdom",
//     text2: "Truth alone is not Wisdom",
//   },
// ];

export default Boundaries;
