//
//
//
import React, { useState } from "react";

import {
  AppBar,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  DISPLAY_WISDOM_TYPE,
  MINTED_STRING,
  PROPOSED_STRING,
  //  SHOW_BETA_TWEET,
} from "../../../constants/constants";
import Multiselect from "multiselect-react-dropdown";
import HelpOutline from "@material-ui/icons/HelpOutline";
import useStyles from "./styles";

import {
  getDisplayProfileValue,
  loadWebUser,
  //  setDisplayProfileValue,
} from "../../../components/myLocalStorage.js";
import AlertBanner from "../../AlertBanner/AlertBanner";

//const webUser = loadWebUser();
var currentAlertBannerNum = -1;

// used by AlertBanner for a multi-page experience
var wisdomSearchHelpPages = {
  prevPageText: "Prev",
  nextPageText: "Next",
  pages: [
    { text: "Any word: brain", link: "link1" },
    { text: "Either word: brain free", link: "" },
    { text: "Wisdoms you own: mine", link: "" },
    { text: "ownedby Dan", link: "" },
    { text: "mintedby Plato", link: "" },
    { text: "No votes: nolikes", link: "" },
    { text: "Less than 10 votes: feels < 10", link: "" },
    { text: "More than 1 vote: thinks > 1", link: "" },
    { text: "1 vote: cheers = 1", link: "" },
    { text: "for sale", link: "" },
    { text: "not for sale", link: "" },
    { text: "By date: minted November", link: "" },
    { text: "Before date: before december", link: "" },
    { text: "After date: after July", link: "" },
    { text: "since last week", link: "" },
    { text: "Sale price: salePrice < 80", link: "" },
    { text: "Select a hashtag: Liberty", link: "" },
    { text: "Either hashtag: Liberty Fallacy", link: "" },
    // { text: "", link: "" },
    // { text: "", link: "" },
    // { text: "", link: "" },
  ],
};

const WisdomSearchToy = ({
  searchWisdom,
  searchWisdomShuffle,
  changeWisdomType,
  serverHashtags,
  showBetaTweet,
}) => {
  const classes = useStyles();
  //  const [justText, setJustText] = useState("");
  const [webUser] = useState(loadWebUser());
  const [searchText, setSearchText] = useState("");
  const [hashtagFilterArray, setHashtagFilterArray] = useState([]);
  //  const [forceReload, setForceReload] = useState(false);

  const [displayWisdomType, setDisplayWisdomType] = useState(
    getDisplayProfileValue(DISPLAY_WISDOM_TYPE, MINTED_STRING)
  );

  const proposedTooltip = showBetaTweet
    ? "Coming soon"
    : webUser
    ? "See Proposed Wisdom"
    : "Sign in First";

  //
  const alertKey = "WisdomSearchToyAlertKey";

  const handleCloseAlert = (key) => {
    // changing state causes rerender
    setAlertBanner({ ...alertBanner, theKey: key, showNow: false });
    currentAlertBannerNum = -1;
  };

  const [alertBanner, setAlertBanner] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKey,
    pages: null,
    startPageNumber: -1,
    handleClose: () => handleCloseAlert(alertKey),
  });

  const toggleSearchAlertBanner = (alertBannerNum, messageStr) => {
    // toggle AlertBanner
    //
    // );
    if (currentAlertBannerNum !== alertBannerNum) {
      setAlertBanner({
        children: null,
        type: "info",
        message: messageStr,
        showNow: true,
        theKey: alertKey,
        pages: wisdomSearchHelpPages,
        startPageNumber: -1,
        handleClose: () => handleCloseAlert(alertKey),
      });
      currentAlertBannerNum = alertBannerNum;
    } else {
      // console.log("CLOSE ALERT");

      // remove banner already there
      handleCloseAlert(alertKey);
    }
  }; // toggle banner alert
  //
  //
  //
  const handleSearchTextChange = (e) => {
    //console.log("WisdomSearchToy.handleSearchTextChange");
    setSearchText(e.target.value);
  };
  //
  const handleWisdomSearchKeyPress = (e) => {
    //console.log("WisdomSearchToy.handleKeyPress");
    if (e.keyCode === 13) {
      searchWisdom(searchText, hashtagFilterArray);
    }
  };
  //
  //
  function handleHashtagChange(event) {
    //console.log("handleHashtagChange>", event);

    const hashtagsFilter = [];
    let i = 0;
    for (i = 0; i < event.length; i++) {
      hashtagsFilter.push(event[i].hashtagText);
      //console.log("handleHashtagChange>adding", event[i].hashtagText);
    }
    //if (reRender) reRender();
    setHashtagFilterArray(hashtagsFilter);
    //setForceReload(true);
  }

  const styleMulti = {
    chips: {
      background: "#3949ab", // "#303f9f",
    },
    searchBox: {
      border: "none",
      borderBottom: "1px solid blue",
      borderRadius: "0px",
      margin: ".5rem",
    },
    multiselectContainer: {
      color: "black",
    },
  };

  return (
    <div style={{ minWidth: "360px", paddingLeft: "0px" }}>
      <AppBar
        className={classes.appBarSearch}
        position="static"
        color="inherit"
      >
        <AlertBanner {...alertBanner}></AlertBanner>

        <RadioGroup
          value={displayWisdomType}
          onChange={(e) => {
            setDisplayWisdomType(e.target.value);
            changeWisdomType(e.target.value);
          }}
        >
          <div
            style={{
              margin: "auto",
              width: "70%",
              minWidth: "200px",
              paddingLeft: "2px",
              text: "#000",
            }}
          >
            <Tooltip title={<h2>See Blockchain Wisdom</h2>} placement="top">
              <FormControlLabel
                value={MINTED_STRING}
                control={<Radio />}
                style={{ color: "#000" }}
                label={MINTED_STRING}
              />
            </Tooltip>
            <Tooltip title={<h2>{proposedTooltip}</h2>} placement="top">
              <FormControlLabel
                value={PROPOSED_STRING}
                control={<Radio disabled={!webUser || showBetaTweet} />}
                style={{ color: "#000" }}
                label={PROPOSED_STRING}
              />
            </Tooltip>
          </div>
        </RadioGroup>

        <TextField
          onKeyDown={(e) => handleWisdomSearchKeyPress(e)}
          id="searchTextField"
          name="search"
          variant="outlined"
          label="Search Wisdom"
          fullWidth
          value={searchText}
          onChange={(e) => handleSearchTextChange(e)}
        />
        <Multiselect
          options={serverHashtags}
          style={styleMulti}
          placeholder="Select Hashtags"
          onSelect={handleHashtagChange} // Function will trigger on select event
          onRemove={handleHashtagChange} // Function will trigger on remove event
          // onKeyPressFn={this.onKeyPress} //
          displayValue="hashtagText"
          showCheckbox={true}
        />
        <div>
          <center>
            <Tooltip placement="top" title={<h2>How to search</h2>}>
              <button
                className={classes.searchBtnHlp}
                type="button"
                onClick={
                  () =>
                    toggleSearchAlertBanner(
                      1,
                      "Click Next & Prev to see examples for the Search Wisdom text box"
                    )
                  // setAlertBanner({
                  //   children: null,
                  //   type: "success",
                  //   message: "Help here!",
                  //   showNow: true,
                  //   theKey: alertKey,
                  //   handleClose: handleCloseAlert,
                  // })
                }
              >
                <HelpOutline />
              </button>
            </Tooltip>
            <Button
              onClick={() => {
                searchWisdom(searchText, hashtagFilterArray);
              }}
              className={classes.searchButtonSearch}
              variant="contained"
              color="primary"
            >
              Search
            </Button>
            &nbsp; &nbsp;
            <Button
              onClick={() => {
                searchWisdomShuffle(searchText, hashtagFilterArray);
              }}
              className={classes.searchButtonShuffle}
              variant="contained"
              color="primary"
            >
              Shuffle
            </Button>
          </center>
        </div>
      </AppBar>
    </div>

    // <TextField
    //   onKeyDown={(e) => handleKeyPress(e)}
    //   id="justATextField"
    //   name="just"
    //   variant="outlined"
    //   label="Just Textfield"
    //   fullWidth
    //   value={justText}
    //   onChange={(e) => handleSearchTextChange(e)}
    // />
  );
};

export default WisdomSearchToy;
