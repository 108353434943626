//
//
//
// multiConfig.mjs
//    common module for js miwServer and miwClient
//    no main() so not for /bin scripts
//
// Example Usage:
//   getNodeConfigFolderField <hostName> <appName> <purposeName> <version> <fieldName>
//   getNodeConfigFolderFields <hostName> <appName> <purposeName> <version> [<fieldNames>]
//
import { nodeIdJSON } from "./miw_node_id.js";
import { miwAppsJSON } from "./miw_app_config.js";
import { miwNodesJSON } from "./miw_node_config.js";
import { miwUsersJSON } from "./miw_user_config.js";

// comment out for miwServer
//import { logEvent } from "../api";

//import { logEventToDatabase } from "./events.js";

const LISTEN_PORT = "listenPort";
const DB_CONFIG_FIELD_NAME = "DB";
const BC_CONFIG_FIELD_NAME = "BC";
const DB_CONNECT_URL = "dbConnectURL";
const DB_NAME = "dbName";
const CLIENT_APP_NAME = "miwClient";
const ACCOUNT_FIELD_NAME = "accounts";
//const WALLET_FIELD_NAME = "wallet";
//const PREVIOUS_OWNER_FIELD_NAME = "previousOwnerWallets";
//const AUTHOR_WALLET_FIELD_NAME = "authorWallet";
//const OWNER_WALLET_FIELD_NAME = "ownerWallet";
//const APPROVER_WALLET_FIELD_NAME = "approverWallet";
const ROLE_OWNER = "ROLE_OWNER";

//const SERVER_APP_NAME = "miwServer";

const doLog = false;
//
// send from miwClient or miwServer
//
//const sendEvent = (eventJSON, userId="", pass=false) => {
const sendEvent = (eventJSON) => {
  if (nodeIdJSON.myAppName === CLIENT_APP_NAME) {
    //logEvent(eventJSON);
    console.log("sendEvent>", JSON.stringify(eventJSON, null, 3));
  } else {
    // add to db
    //    logEventToDatabase(eventJSON, userId, pass);
  }
};
//
//   8/16/22
//MAYBE:
//  don't find anything using folderName
//  ONLY use appName, purposeName, version
//
//  IF multiple objects meet that criteria,
//    return LIST of values of field asked for
//    AND caller can decide which to pick
//
//  that means EVERY app Base folder must have full config
//    .js config files, AND .mjs code to traverse them
//
//
export const getNodeConfig = (hostName, nodeId) => {
  var err = { message: "NOT found" };
  try {
    if (doLog) console.log("getNodeConfig>", hostName, nodeId);

    for (let i = 0; i < miwNodesJSON.miwNodes.length; i++) {
      const aConfigNode = miwNodesJSON.miwNodes[i];
      if (aConfigNode.hostName === hostName) {
        if (aConfigNode.nodeId === nodeId) {
          // found!
          return aConfigNode;
        }
      }
    }
  } catch (e) {
    console.log("getNodeConfig>ERROR", e);
    err = e;
  }
  sendEvent({
    eventName: "configError",
    data1: "getNodeConfig:" + JSON.stringify(err),
    data2:
      nodeIdJSON.myAppName +
      ":" +
      nodeIdJSON.myPurposeName +
      ":" +
      nodeIdJSON.myVersion,
    data3:
      nodeIdJSON.myHostName +
      ":" +
      hostName +
      " " +
      nodeIdJSON.myNodeId +
      ":" +
      nodeId,
  });
  return false;
}; // get node config
//
export const getUserConfig = (userName) => {
  var err = { message: "NOT found" };
  try {
    if (doLog) console.log("getUserConfig>", userName);

    for (let i = 0; i < miwUsersJSON.miwUsers.length; i++) {
      if (miwUsersJSON.miwUsers[i].userName === userName) {
        // found!
        return miwUsersJSON.miwUsers[i];
      }
    }
  } catch (e) {
    console.log("getUserConfig>ERROR", e);
    err = e;
  }
  sendEvent({
    eventName: "configError",
    data1: userName + "  getUserConfig:" + JSON.stringify(err),
    data2:
      nodeIdJSON.myAppName +
      ":" +
      nodeIdJSON.myPurposeName +
      ":" +
      nodeIdJSON.myVersion,
    data3: nodeIdJSON.myHostName + ":" + userName + " " + nodeIdJSON.myNodeId,
  });
  return false;
}; // get user config

//
export const getAppConfig = (appName) => {
  var err = { message: "NOT found" };
  try {
    if (doLog) console.log("getAppConfig>", appName);

    for (let i = 0; i < miwAppsJSON.miwApps.length; i++) {
      if (miwAppsJSON.miwApps[i].appName === appName) {
        // found!
        return miwAppsJSON.miwApps[i];
      }
    }
  } catch (e) {
    console.log("getAppConfig>ERROR", e);
    err = e;
  }
  sendEvent({
    eventName: "configError",
    data1: "getAppConfig:" + JSON.stringify(err),
    data2:
      nodeIdJSON.myAppName +
      ":" +
      nodeIdJSON.myPurposeName +
      ":" +
      nodeIdJSON.myVersion,
    data3: nodeIdJSON.myHostName + ":" + appName + " " + nodeIdJSON.myNodeId,
  });
  return false;
}; // get app config
//
export const getMyNodeConfig = () => {
  var err = { message: "NOT found" };

  try {
    const nodeId = nodeIdJSON.myNodeId;
    const hostName = nodeIdJSON.myHostName;

    for (let i = 0; i < miwNodesJSON.miwNodes.length; i++) {
      if (miwNodesJSON.miwNodes[i].hostName === hostName) {
        if (miwNodesJSON.miwNodes[i].nodeId === nodeId) {
          // found!
          return miwNodesJSON.miwNodes[i];
        }
      }
    }
  } catch (e) {
    console.log("getMyNodeConfig>ERROR", e);
    err = e;
  }
  sendEvent({
    eventName: "configError",
    data1: "getMyNodeConfig:" + JSON.stringify(err),
    data2:
      nodeIdJSON.myAppName +
      ":" +
      nodeIdJSON.myPurposeName +
      ":" +
      nodeIdJSON.myVersion,
    data3: nodeIdJSON.myNodeId,
  });

  return false;
}; // get my node config

export const getNodeConfigField = (
  targetHostName,
  targetAppName,
  targetPurposeName,
  targetVersion,
  fieldName
) => {
  if (doLog)
    console.log(
      "getNodeConfigField>",
      targetHostName,
      targetAppName,
      targetPurposeName,
      targetVersion,
      fieldName
    );
  // which nodeID?
  //console.log(JSON.stringify(nodeIdJSON,null,3));
  //const myNodeName = nodeIdJSON.myNodeName;
  //  const myAppNodeId = nodeIdJSON.myAppNodeId;
  //  const parms = myAppNodeId.split("_");
  //  const nodeId = parms[1];

  // call sets targets = false to mean ME, via node_id<appName>.js config file
  const hostName = targetHostName || nodeIdJSON.myHostName;
  const appName = targetAppName || nodeIdJSON.myAppName;
  const purposeName = targetPurposeName || nodeIdJSON.myPurposeName;
  const version = targetVersion || nodeIdJSON.myVersion;
  // TODO: consider if this limitation to MY nodeId is okay
  // app, purpose, version could be used to determin nodeId
  // OR nodeId could be passed in
  //
  const nodeId = nodeIdJSON.myNodeId;
  if (doLog)
    console.log(
      "getNodeConfigField>",
      hostName,
      appName,
      purposeName,
      version,
      fieldName
    );

  var fieldValue; // the answer
  var err = { message: "NOT found" };

  //console.log("nodeId=",nodeId);
  //
  //console.log("\n\n",JSON.stringify(miwNodesJSON,null,3));

  try {
    const thisNodeJSON = getNodeConfig(hostName, nodeId);
    if (thisNodeJSON) {
      if (doLog)
        console.log(
          "getNodeConfigField>node found!",
          JSON.stringify(thisNodeJSON, null, 3)
        );

      if (fieldName) fieldValue = thisNodeJSON[fieldName];
      return { fieldValue, thisNodeJSON };
    }
    // // find nodeId in "miwNodes"
    // var thisNodeJSON;
    // for (let i = 0; i < miwNodesJSON.miwNodes.length; i++) {
    //   if (miwNodesJSON.miwNodes[i].hostName == hostName) {
    //     if (miwNodesJSON.miwNodes[i].nodeId == nodeId) {
    //       // node found!
    //       thisNodeJSON = miwNodesJSON.miwNodes[i];
    //       if (doLog)
    //         console.log(
    //           "getNodeConfigField>node found!",
    //           JSON.stringify(thisNodeJSON, null, 3)
    //         );

    //       if (fieldName) fieldValue = thisNodeJSON[fieldName];
    //       return { fieldValue, thisNodeJSON };

    //       //break;
    //     }
    //   }
    // }
  } catch (e) {
    console.log("getNodeConfigField>ERROR", e);
    err = e;
  }
  sendEvent({
    eventName: "configError",
    data1: "getNodeConfigField:" + JSON.stringify(err),
    data2: hostName + ":" + appName + ":" + purposeName + ":" + version,
    data3: nodeIdJSON.myNodeId,
  });

  return { fieldValue: false, thisNodeJSON: false };
}; // get Node Config Field

//
//
//
export const getNodeConfigFolderField = (
  targetHostName,
  targetAppName,
  targetPurposeName,
  targetVersion,
  fieldName
) => {
  const hostName = targetHostName || nodeIdJSON.myHostName;
  const appName = targetAppName || nodeIdJSON.myAppName;
  const purposeName = targetPurposeName || nodeIdJSON.myPurposeName;
  const version = targetVersion || nodeIdJSON.myVersion;
  const nodeId = nodeIdJSON.myNodeId;

  //var fieldValue; // the answer

  //console.log("nodeId=",nodeId);

  //const miwNodesJSON = JSON.parse(fs.readFileSync(MIW_HOME+"/miw_node_config.json","utf8"));
  //console.log("\n\n",JSON.stringify(nodeConfigJSON,null,3));
  var folderValue;
  var thisFolderJSON;
  // var { thisNodeJSON } = getNodeConfigField(
  //   hostName,
  //   appName,
  //   purposeName,
  //   version,
  //   false
  // );
  var thisNodeJSON = getNodeConfig(hostName, nodeId);

  var err = { message: "NOT found" };

  // // find nodeJSON in "miwNodes" using nodeID
  // var thisNodeJSON;
  // var thisFolderJSON;
  // for (let i = 0; i < miwNodesJSON.miwNodes.length; i++) {
  //   if (miwNodesJSON.miwNodes[i].nodeId == nodeId) {
  //     // node found!
  //     //console.log("node found!");
  //     thisNodeJSON = miwNodesJSON.miwNodes[i];
  //   }
  // }

  try {
    if (thisNodeJSON) {
      for (let i = 0; i < thisNodeJSON.Folders.length; i++) {
        if (thisNodeJSON.Folders[i].appName === appName) {
          if (thisNodeJSON.Folders[i].purposeName === purposeName) {
            if (thisNodeJSON.Folders[i].version === version) {
              // found!
              thisFolderJSON = thisNodeJSON.Folders[i];
              if (doLog)
                console.log(
                  "getNodeConfigFolderField>folder found!",
                  JSON.stringify(thisFolderJSON, null, 3)
                );
            }
          }
        }
      }
      if (thisFolderJSON) {
        //console.log(myArgs[2] + " = " + thisFolderJSON[myArgs[2]]);
        folderValue = thisFolderJSON[fieldName];
      }
    } else {
      if (doLog) {
        console.log("getNodeConfigFolderField>node NOT FOUND");
        console.log(
          "getNodeConfigFolderField>",
          hostName,
          appName,
          purposeName,
          version,
          fieldName
        );
      }
    }
    return { folderValue, nodeIdJSON, thisNodeJSON, thisFolderJSON };
  } catch (e) {
    console.log("getNodeConfigFolderField>ERROR", e);
    err = e;
  }
  sendEvent({
    eventName: "configError",
    data1: "getNodeConfigFolderField:" + JSON.stringify(err),
    data2: appName + ":" + purposeName + ":" + version,
    data3: nodeId,
  });
  return {
    folderValue: false,
    nodeIdJSON: false,
    thisNodeJSON: false,
    thisFolderJSON: false,
  };
}; // get Node Config Folder Field
//
//
//
export const getNodeConfigFolderFields = (
  targetHostName,
  targetAppName,
  targetPurposeName,
  targetVersion,
  fieldNamesArray
) => {
  const hostName = targetHostName || nodeIdJSON.myHostName;
  const appName = targetAppName || nodeIdJSON.myAppName;
  const purposeName = targetPurposeName || nodeIdJSON.myPurposeName;
  const version = targetVersion || nodeIdJSON.myVersion;
  const nodeId = nodeIdJSON.myNodeId;

  //var fieldValue; // the answer

  //console.log("nodeId=",nodeId);

  //const miwNodesJSON = JSON.parse(fs.readFileSync(MIW_HOME+"/miw_node_config.json","utf8"));
  //console.log("\n\n",JSON.stringify(nodeConfigJSON,null,3));
  //  var folderValue;
  var folderValuesJSON = {};
  var thisFolderJSON;
  // var { thisNodeJSON } = getNodeConfigField(
  //   hostName,
  //   appName,
  //   purposeName,
  //   version,
  //   false
  // );
  var thisNodeJSON = getNodeConfig(hostName, nodeId);

  var err = { message: "NOT found" };

  // // find nodeJSON in "miwNodes" using nodeID
  // var thisNodeJSON;
  // var thisFolderJSON;
  // for (let i = 0; i < miwNodesJSON.miwNodes.length; i++) {
  //   if (miwNodesJSON.miwNodes[i].nodeId == nodeId) {
  //     // node found!
  //     //console.log("node found!");
  //     thisNodeJSON = miwNodesJSON.miwNodes[i];
  //   }
  // }

  try {
    if (thisNodeJSON) {
      for (let i = 0; i < thisNodeJSON.Folders.length; i++) {
        if (thisNodeJSON.Folders[i].appName === appName) {
          if (thisNodeJSON.Folders[i].purposeName === purposeName) {
            if (thisNodeJSON.Folders[i].version === version) {
              // found!
              thisFolderJSON = thisNodeJSON.Folders[i];
              if (doLog)
                console.log(
                  "getNodeConfigFolderFields>folder found!",
                  JSON.stringify(thisFolderJSON, null, 3)
                );
            }
          }
        }
      }
      if (thisFolderJSON) {
        //console.log(myArgs[2] + " = " + thisFolderJSON[myArgs[2]]);
        // get each fieldName from folder JSON
        fieldNamesArray.forEach(
          (fieldName) =>
            (folderValuesJSON[fieldName] = thisFolderJSON[fieldName])
        );
      }
    } else {
      if (doLog) {
        console.log("getNodeConfigFolderFields>node NOT FOUND");
        console.log(
          "getNodeConfigFolderFields>",
          hostName,
          appName,
          purposeName,
          version,
          fieldNamesArray
        );
      }
    }
    return { folderValuesJSON, nodeIdJSON, thisNodeJSON, thisFolderJSON };
  } catch (e) {
    console.log("getNodeConfigFolderFields>ERROR", e);
    err = e;
  }
  sendEvent({
    eventName: "configError",
    data1: "getNodeConfigFolderFields:" + JSON.stringify(err),
    data2: appName + ":" + purposeName + ":" + version,
    data3: nodeId,
  });
  return {
    folderValuesJSON: false,
    nodeIdJSON: false,
    thisNodeJSON: false,
    thisFolderJSON: false,
  };
}; // get Node Config Folder Fields

//
//
//
export const getAppConfigPurposeFields = (
  targetHostName,
  targetAppName,
  targetPurposeName,
  targetVersion,
  fieldNamesArray
) => {
  const hostName = targetHostName || nodeIdJSON.myHostName;
  const appName = targetAppName || nodeIdJSON.myAppName;
  const purposeName = targetPurposeName || nodeIdJSON.myPurposeName;
  const version = targetVersion || nodeIdJSON.myVersion;
  const nodeId = nodeIdJSON.myNodeId;

  //var fieldValue; // the answer

  if (doLog)
    console.log(
      "getAppConfigPurposeFields>",
      hostName,
      appName,
      purposeName,
      version,
      nodeId
    );

  //const miwNodesJSON = JSON.parse(fs.readFileSync(MIW_HOME+"/miw_node_config.json","utf8"));
  //console.log("\n\n",JSON.stringify(nodeConfigJSON,null,3));
  //  var folderValue;
  var purposeValuesJSON = {};
  var thisPurposeJSON;
  var thisAppJSON = getAppConfig(appName);

  var err = { message: "NOT found" };

  // // find nodeJSON in "miwNodes" using nodeID
  // var thisNodeJSON;
  // var thisFolderJSON;
  // for (let i = 0; i < miwNodesJSON.miwNodes.length; i++) {
  //   if (miwNodesJSON.miwNodes[i].nodeId == nodeId) {
  //     // node found!
  //     //console.log("node found!");
  //     thisNodeJSON = miwNodesJSON.miwNodes[i];
  //   }
  // }

  // find the app
  try {
    if (thisAppJSON) {
      // for (let i = 0; i < thisAppJSON.Purposes.length; i++) {
      //   const aPurposeJSON = thisAppJSON.Purposes[i];
      thisAppJSON.Purposes.forEach((aPurposeJSON) => {
        if (doLog)
          console.log(
            "getNodeConfigFolderField> checking:",
            thisAppJSON.appName,
            aPurposeJSON.purposeName,
            aPurposeJSON.version
          );
        if (aPurposeJSON.purposeName === purposeName) {
          if (aPurposeJSON.version === version) {
            // found!
            thisPurposeJSON = aPurposeJSON;
            if (doLog)
              console.log(
                "getNodeConfigFolderField>app FOUND!",
                JSON.stringify(thisPurposeJSON, null, 3)
              );
          }
        }
      });
      if (thisPurposeJSON) {
        //console.log(myArgs[2] + " = " + thisFolderJSON[myArgs[2]]);
        // get each fieldName from folder JSON
        fieldNamesArray.forEach(
          (fieldName) =>
            (purposeValuesJSON[fieldName] = thisPurposeJSON[fieldName])
        );
      } else {
        console.log("getAppConfigPurposeFields>APP NOT FOUND");
      }
    } else {
      if (doLog) {
        console.log("getNodeConfigFolderField>app config NOT FOUND");
        console.log(
          "getAppConfigPurposeFields>",
          hostName,
          appName,
          purposeName,
          version,
          fieldNamesArray
        );
      }
    }
    return {
      purposeValuesJSON: purposeValuesJSON,
      nodeIdJSON,
      thisAppJSON,
      thisPurposeJSON: thisPurposeJSON,
    };
  } catch (e) {
    console.log("getAppConfigPurposeFields>ERROR", e);
    err = e;
  }
  sendEvent({
    eventName: "configError",
    data1: "getAppConfigPurposeFields:" + JSON.stringify(err),
    data2: appName + ":" + purposeName + ":" + version,
    data3: nodeId,
  });
  return {
    folderValuesJSON: false,
    nodeIdJSON: false,
    thisNodeJSON: false,
    thisFolderJSON: false,
  };
}; // get app Config purpose Fields
//
//
//
export const getAppJSON = (targetAppName) => {
  var appJSON;
  const appName = targetAppName || nodeIdJSON.myAppName;
  //console.log("getAppJSON>appName=", appName);
  // find app
  for (let i = 0; i < miwAppsJSON.miwApps.length; i++) {
    if (miwAppsJSON.miwApps[i].appName === appName) {
      // FOUND
      appJSON = miwAppsJSON.miwApps[i];
      // console.log(
      //   "getAppJSON>FOUND!! appJSON=",
      //   JSON.stringify(appJSON, null, 3)
      // );
    }
  }
  return { appJSON };
}; // get AppJSON
//
//
//
export const getAppPurposeJSON = (
  targetAppName,
  targetPurposeName,
  targetVersion
) => {
  var appPurposeJSON;
  const appName = targetAppName || nodeIdJSON.myAppName;
  const purposeName = targetPurposeName || nodeIdJSON.myPurposeName;
  const version = targetVersion || nodeIdJSON.myVersion;

  if (doLog) console.log("getAppPurposeJSON", appName, purposeName, version);
  // find app
  const { appJSON } = getAppJSON(appName);
  if (appJSON) {
    // find purpose
    for (let j = 0; j < appJSON.Purposes.length; j++) {
      if (appJSON.Purposes[j].purposeName === purposeName) {
        if (appJSON.Purposes[j].version === version) {
          // FOUND
          appPurposeJSON = appJSON.Purposes[j];
        }
      }
    }
  }

  return { appJSON, appPurposeJSON };
}; // get AppPurposeJSON
//
//
//
export const getAppPurposeField = (
  targetAppName,
  targetPurposeName,
  targetVersion,
  fieldName
) => {
  var purposeValue;
  const appName = targetAppName || nodeIdJSON.myAppName;
  const purposeName = targetPurposeName || nodeIdJSON.myPurposeName;
  const version = targetVersion || nodeIdJSON.myVersion;

  if (doLog)
    console.log(
      "getAppPurposeField>appName,purposeName,version,fieldName",
      appName,
      purposeName,
      version,
      fieldName
    );
  const { appJSON, appPurposeJSON } = getAppPurposeJSON(
    appName,
    purposeName,
    version
  );
  if (appPurposeJSON) {
    purposeValue = appPurposeJSON[fieldName];
    if (doLog) console.log("getAppPurposeField>purposeValue=", purposeValue);
  } else {
    if (doLog) console.log("getAppPurposeField>NO appPurposeJSON FOUND");
  }
  return { purposeValue, appJSON, appPurposeJSON };
}; //
//
export const getUserPurposeJSON = (
  targetUserName,
  targetPurposeName,
  targetVersion
) => {
  var userPurposeJSON;
  const userName = targetUserName || "";
  const purposeName = targetPurposeName || nodeIdJSON.myPurposeName;
  const version = targetVersion || nodeIdJSON.myVersion;

  if (doLog)
    console.log("   getUserPurposeJSON", userName, purposeName, version);
  // find app
  const userJSON = getUserConfig(userName);
  if (userJSON) {
    // find purpose
    for (let j = 0; j < userJSON.Purposes.length; j++) {
      if (userJSON.Purposes[j].purpose === purposeName) {
        if (userJSON.Purposes[j].version === version) {
          // FOUND
          if (doLog)
            console.log(
              "   getUserPurposeJSON>FOUND",
              userName,
              purposeName,
              version
            );
          userPurposeJSON = userJSON.Purposes[j];
        }
      }
    }
  }

  return { userJSON: userJSON, userPurposeJSON: userPurposeJSON };
}; // get user Purpose JSON

//
//specific helpers
//
export const getMyListenPort = () => {
  const { folderValue } = getNodeConfigFolderField(
    nodeIdJSON.myHostName,
    nodeIdJSON.myAppName,
    nodeIdJSON.myPurposeName,
    nodeIdJSON.myVersion,
    LISTEN_PORT
  );

  return folderValue;
}; // get my port

export const getMyNodeConfigField = (fieldName) => {
  const myNodeConfig = getMyNodeConfig();
  if (myNodeConfig) {
    return myNodeConfig[fieldName];
  } else {
    return false;
  }
}; // get my node config field
//
//
//

export const getAppPurposeAccount = (appName, purposeName, version) => {
  //var noAnswer;
  if (doLog)
    console.log(
      "getAppPurposeAccount>nodeIdJSON=",
      JSON.stringify(nodeIdJSON, null, 3)
    );
  try {
    var { purposeValue } = getAppPurposeField(
      appName,
      purposeName,
      version,
      ACCOUNT_FIELD_NAME
    );

    if (doLog) console.log("getAppPurposeAccount>purposeValue=", purposeValue);

    // if (purposeValue) {
    //   const account = purposeValue[0];
    //   // console.log(
    //   //   "getAppPurposeAccount>account[0].wallet=",
    //   //   account.wallet,
    //   //   ".privateKey=",
    //   //   account.privateKey
    //   // );
    // }
  } catch (e) {
    console.log("getAppPurposeAccount>ERROR", e);
  }
}; // get app purpose account
//
// miwAppsJSON has which miwServer miwClient connects to
//
//
//
export const getMyDBPort = () => {
  var noAnswer;
  if (doLog)
    console.log("getMyDBPort>nodeIdJSON=", JSON.stringify(nodeIdJSON, null, 3));
  try {
    var { purposeValue } = getAppPurposeField(
      nodeIdJSON.myAppName,
      nodeIdJSON.myPurposeName,
      nodeIdJSON.myVersion,
      DB_CONFIG_FIELD_NAME
    );

    if (doLog) console.log("getMyDBPort>purposeValue=", purposeValue);

    if (purposeValue) {
      const db = purposeValue[0];
      if (doLog)
        console.log(
          "getMyDBPort>do getNodeConfigFolderField. myHostName, purposeName=",
          nodeIdJSON.myHostName,
          db.purposeName
        );
      const { folderValue } = getNodeConfigFolderField(
        nodeIdJSON.myHostName,
        db.appName,
        db.purposeName,
        db.version,
        LISTEN_PORT
      );
      if (doLog) console.log("getMyDBPort>folderValue", folderValue);
      return folderValue;
    } else {
      console.log(
        "getMyDBPort>purposeValue",
        DB_CONFIG_FIELD_NAME,
        "NOT FOUND"
      );
    }
  } catch (e) {
    console.log("getMyDBPort>ERROR", e);
    sendEvent({
      eventName: "configError",
      data1: "getMyDBPort:" + JSON.stringify(e),
      data2:
        nodeIdJSON.myAppName +
        ":" +
        nodeIdJSON.myPurposeName +
        ":" +
        nodeIdJSON.myVersion,
    });
  }
  return noAnswer;
}; // get my DB port
//
//
// TODO: getMyOtherAppConfig()  .. i.e. not hardcoded "DB"
export const getMyDBConfig = () => {
  var noAnswer = { listenPort: 5000, dbName: "makeitwisdom_v0" };
  if (doLog)
    console.log(
      "getMyDBConfig>nodeIdJSON=",
      JSON.stringify(nodeIdJSON, null, 3)
    );
  try {
    var { purposeValue } = getAppPurposeField(
      nodeIdJSON.myAppName,
      nodeIdJSON.myPurposeName,
      nodeIdJSON.myVersion,
      DB_CONFIG_FIELD_NAME
    );

    if (doLog) console.log("getMyDBConfig>dbConfigJSON=", purposeValue);

    if (purposeValue) {
      const db = purposeValue[0];
      if (doLog)
        console.log(
          "getMyDBConfig>do getNodeConfigFolderField. myHostName, purposeName=",
          nodeIdJSON.myHostName,
          db.purposeName
        );
      // get whole folder JSON to access multiple values from it
      var { thisFolderJSON } = getNodeConfigFolderField(
        nodeIdJSON.myHostName,
        db.appName,
        db.purposeName,
        db.version,
        LISTEN_PORT
      );

      if (doLog)
        console.log(
          "\n\n\n ***** \ngetMyDBConfig>listenPort",
          thisFolderJSON.listenPort,
          "dbName",
          thisFolderJSON.dbName
        );
      return {
        listenPort: thisFolderJSON.listenPort,
        dbName: thisFolderJSON.dbName,
        dbConnectURL: thisFolderJSON.dbConnectURL,
      };
      //return noAnswer;
    } else {
      console.log(
        "getMyDBConfig>purposeValue",
        DB_CONFIG_FIELD_NAME,
        "NOT FOUND"
      );
    }
  } catch (e) {
    console.log("getMyDBConfig>ERROR", e);
    sendEvent({
      eventName: "configError",
      data1: "getMyDBConfig:" + JSON.stringify(e),
      data2:
        nodeIdJSON.myAppName +
        ":" +
        nodeIdJSON.myPurposeName +
        ":" +
        nodeIdJSON.myVersion,
    });
  }
  return noAnswer;
}; // get my DB config
//
//
//
export const getMyBCConfig = (purposeOverride = false) => {
  // default answer is Mumbai network (Polygon Testnet)
  var noAnswer = {
    netwokName: "Polygon Testnet",
    chainId: "80001",
    rpcUrl: "https://matic-mumbai.chainstacklabs.com",
    blockExplorerUrl: "https://mumbai.polygonscan.com/",
  };
  var responseValuesJSON = {};

  const fieldNamesArray = [
    "networkName",
    "chainId",
    "rpcUrl",
    "blockExplorerUrl",
    "accounts",
  ];
  if (doLog) console.log("getMyBCConfig>");

  if (doLog)
    console.log(
      "getMyBCConfig>nodeIdJSON=",
      JSON.stringify(nodeIdJSON, null, 3)
    );

  // if LOCAL and devTest,
  if (
    nodeIdJSON.myNodeName === "DanMacM1" &&
    nodeIdJSON.myPurposeName === "devTest"
  )
    purposeOverride = "dev";

  try {
    var { purposeValue } = getAppPurposeField(
      "miwClient", // nodeIdJSON.myAppName,
      purposeOverride || nodeIdJSON.myPurposeName,
      nodeIdJSON.myVersion,
      BC_CONFIG_FIELD_NAME
    );

    if (doLog) console.log("getMyBCConfig>purposeValue=", purposeValue);

    if (purposeValue) {
      const bc = purposeValue[0];
      if (doLog)
        console.log(
          "getMyBCConfig>do getAppConfigPurposeFields. myHostName, purposeName, bcAppName=",
          nodeIdJSON.myHostName,
          bc.purposeName,
          bc.appName
        );

      const { purposeValuesJSON, thisPurposeJSON } = getAppConfigPurposeFields(
        nodeIdJSON.myHostName,
        bc.appName,
        bc.purposeName,
        bc.version,
        fieldNamesArray
      );
      responseValuesJSON = {
        bcNetworkName: purposeValuesJSON.networkName,
        bcChainId: purposeValuesJSON.chainId,
        bcRpcUrl: purposeValuesJSON.rpcUrl,
        bcExplorerUrl: purposeValuesJSON.blockExplorerUrl,
        bcAccounts: purposeValuesJSON.accounts,
      };

      if (doLog)
        console.log("getMyBCConfig>responseValuesJSON", responseValuesJSON);
      // hardcode field names, for now
      //return responseValuesJSON;
      // return entire BC JSON
      return thisPurposeJSON;
    } else {
      console.log(
        "getMyBCConfig>purposeValue",
        DB_CONFIG_FIELD_NAME,
        "NOT FOUND"
      );
    }
  } catch (e) {
    console.log("getMyBCConfig>ERROR", e);
    sendEvent({
      eventName: "configError",
      data1: "getMyBCConfig:" + JSON.stringify(e),
      data2:
        nodeIdJSON.myAppName +
        ":" +
        nodeIdJSON.myPurposeName +
        ":" +
        nodeIdJSON.myVersion,
    });
  }
  return noAnswer;
}; // get my BC config
//
//
//
// miwServer connects to MongoDB
// (miwClient connects to miwServer)
//
//
// reMint needs to update wallet addresses in every purpose database
export const getDBConnectUrl = (purpose) => {
  // use my signature to
  const { folderValue } = getNodeConfigFolderField(
    nodeIdJSON.myHostName,
    nodeIdJSON.myAppName,
    purpose,
    nodeIdJSON.myVersion,
    DB_CONNECT_URL
  );

  return folderValue;
}; // get my db connection URL

export const getMyDBConnectUrl = () => {
  // use my signature to
  const { folderValue } = getNodeConfigFolderField(
    nodeIdJSON.myHostName,
    nodeIdJSON.myAppName,
    nodeIdJSON.myPurposeName,
    nodeIdJSON.myVersion,
    DB_CONNECT_URL
  );

  return folderValue;
}; // get my db connection URL

export const getMyDBName = () => {
  // use my signature to
  //console.log("\n\n\ngetMyDBName>");
  const { folderValue } = getNodeConfigFolderField(
    nodeIdJSON.myHostName,
    nodeIdJSON.myAppName,
    nodeIdJSON.myPurposeName,
    nodeIdJSON.myVersion,
    DB_NAME
  );

  return folderValue;
}; // get my db name
//
// user helpers
//
export const getPurposeOwnerJSON = (purpose, version = "v0") => {
  //var noUserJSON;
  for (let i = 0; i < miwUsersJSON.Users.length; i++) {
    const userJSON = miwUsersJSON.Users[i];
    if (userJSON.userName !== "") {
      for (let j = 0; j < userJSON.Purposes.length; j++) {
        //
        const userPurposeJSON = userJSON.Purposes[j];
        if (userPurposeJSON.purpose === purpose) {
          //
          if (userPurposeJSON.version === version) {
            // check permission for ROLE_OWNER
            for (let k = 0; k < userPurposeJSON.permissions.length; k++) {
              //
              if (userPurposeJSON.permissions[k].listName === ROLE_OWNER) {
                // found owner
                if (doLog)
                  console.log(
                    "getPurposeOwnerJSON>",
                    purpose,
                    version,
                    userJSON.userName,
                    userPurposeJSON.wallet
                  );
                return { userJSON: userJSON, wallet: userPurposeJSON.wallet };
              }
            }
          }
        }
      }
    }
  }
  if (doLog) console.log("getPurposeOwnerJSON>NO USER", purpose, version);
  return { userJSON: false, wallet: false };
}; // get purpose owner
//
export const getUserNameList = () => {
  var userNameListJSON = { userNameList: [] };
  if (miwUsersJSON) {
    // build list of all user names is miw_user_config.js
    for (let i = 0; i < miwUsersJSON.miwUsers.length; i++) {
      if (miwUsersJSON.miwUsers[i].userName !== "") {
        userNameListJSON.userNameList.push(miwUsersJSON.miwUsers[i].userName);
      }
    }
  }
  return userNameListJSON;
}; // get user name list
//
export const getUserFromToWallets = (
  userName,
  fromPurpose,
  toPurpose,
  version = "v0"
) => {
  const userJSON = getUserConfig(userName);
  var fromWallet = "";
  var toWallet = "";

  if (userJSON) {
    // console.log(
    //   "getUserFromToWallets>",
    //   userName,
    //   fromPurpose,
    //   toPurpose,
    //   version
    // );
    //
    const { userPurposeJSON: fromPurposeJSON } = getUserPurposeJSON(
      userName,
      fromPurpose,
      version
    );
    // console.log(
    //   "      getUserFromToWallets>from=",
    //   JSON.stringify(fromPurposeJSON, null, 3)
    // );
    const { userPurposeJSON: toPurposeJSON } = getUserPurposeJSON(
      userName,
      toPurpose,
      version
    );
    // console.log(
    //   "      getUserFromToWallets>to=",
    //   JSON.stringify(toPurposeJSON, null, 3)
    // );
    fromWallet = fromPurposeJSON.wallet;
    toWallet = toPurposeJSON.wallet;
  }
  return { fromWallet: fromWallet, toWallet: toWallet };
}; // get user from to wallets

//
export default getNodeConfigFolderField;
