import {
  FETCH_AUTHOR,
  FETCH_AUTHORS,
  START_LOADING_USERS,
  STOP_LOADING_USERS,
  DELETE_USER,
  UPDATE_USER,
  ACTIVATE_WALLET,
} from "../constants/actionTypes";

const doLog = false;

export default (
  state = { isLoading: false, users: [], reload: false },
  action
) => {
  switch (action.type) {
    case START_LOADING_USERS:
      return { ...state, isLoading: true, reload: false };
    case STOP_LOADING_USERS: {
      if (doLog) console.log("reducer:users.STOP_LOADING_USERS>");
      return { ...state, isLoading: false, reload: false };
    }
    case FETCH_AUTHORS: {
      if (doLog)
        console.log("reducer:users.FETCH_AUTHORS>payload=", action?.payload);
      return {
        ...state,
        users: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        reload: false,
      };
    }
    case FETCH_AUTHOR:
      return {
        ...state,
        user: action.payload.user,
        //users: action.payload.users,
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === action.payload._id ? action.payload : user
        ),
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };

    case ACTIVATE_WALLET:
      //  djf 3/14/22 experiment
      //      history.push(`/wallet/activateWallet?account=${account}`);
      //      history.push("/");

      return {
        ...state,
      };
    default:
      //console.log("reducer:users>default action.type-", action.type);
      return state;
  }
};
