import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "37ch",
      //width: "90%",
      [theme.breakpoints.down("sm")]: {
        width: "35ch", // <600
        margin: "0px 0px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "24ch", // >600
      },
      [theme.breakpoints.up("800")]: {
        width: "26ch", // >800
      },
      [theme.breakpoints.up("md")]: {
        width: "22ch", // >900
      },
      [theme.breakpoints.up("lg")]: {
        width: "30ch", // >1200
      },
      [theme.breakpoints.up("1400")]: {
        width: "35ch", // xl >
      },
      [theme.breakpoints.up("1600")]: {
        width: "38ch", // xl >
      },
      [theme.breakpoints.up("1930")]: {
        width: "45ch", // >1930
      },
    },
  },
  fontSlider: {
    height: 300,
  },
  fontSliderInput: {
    width: 42,
  },
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  fileInput: {
    width: "97%",
    margin: "10px 0",
  },
  buttonSubmit: {
    marginBottom: 10,
  },
  inputField: {
    "& textarea": {
      fontFamily: "QuickSand",
    },
    "& input": {
      fontFamily: "QuickSand",
    },
  },
  otherInputField: {
    "& input": {
      fontFamily: "QuickSand",
    },
  },
  uploadBtnHlp: {
    background: "none",
    border: "none",
    margin: "-3px 3px 5px 0px",
  },

  fileUploaderRow: { display: "flex" },
}));
