//
//
//
import React, { useEffect } from "react"; // { useState, useEffect }

import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import Masonry from "react-masonry-css";

import WisdomCard from "./Wisdom/WisdomCard";
import Swipe from "../Swipe/SwipeWisdom";
import {
  DO_LOG,
  MINTED_STRING,
  selectWisdomAnimation,
  SHOW_BETA_TWEET,
  WISDOM_SSE_ON,
  WISDOM_FIELD_NAMES_SSE,
  //  SHOW_BETA_TWEET,
} from "../../constants/constants";
import { getProfileValue, setProfileValue } from "../myLocalStorage";
//import { CallMissedSharp } from "@material-ui/icons";
//import { coinInCurrency } from "../../api";
//import { getEthInDollars } from "../../actions/wisdoms";
import useStyles from "./styles";
//import { getDisplayProfile } from "../localStorage";
import {
  getMyNodeConfigField,
  getMyDBPort,
} from "../../configs/multiConfig.mjs";
//import * as api from "../../api/index.js";
//import { wisdomlistaddress } from "../../contractManifest";

//var coinInCurrency = 0;
var eventSource = false;

const domainAddy = getMyNodeConfigField("domainAddy");
const listenPort = getMyDBPort();
const baseURL = domainAddy + ":" + listenPort;
var SSEPass1Queue = [];
var SSEPass1Tmr = false;

var SSEUpdateQueue = [];
var SSEUpdateTmr = false;

const Wisdoms = ({ setCurrentId, displayWisdomType, setEditWisdom }) => {
  const { wisdoms, isLoading, needSignin, coinInCurrency } = useSelector(
    (state) => state.wisdoms
  );
  // const [wisdomIds] = useState(
  //   wisdoms.map((wisdom) => {
  //     return wisdom.wisdomId;
  //   })
  // );

  // const [wisdomsSSEStatus, setWisdomSSEStatus] = useState("idle");
  // const [wisdomSSEData, setWisdomSSEData] = useState([]);

  if (DO_LOG)
    console.log("Wisdoms>state.wisdoms.coinInCurrency=", coinInCurrency);
  const classes = useStyles();
  //const [displayWisdomType, setDisplayWisdomType] = useState({});
  //
  // experimental Server-Sent Events
  //

  const buildParamWisdomSSE = (wisdoms) => {
    var ansStr = "";

    if (wisdoms && wisdoms?.length > 0) {
      //      ansStr = wisdoms.map((wisdom) => wisdom)
      for (let i = 0; i < wisdoms.length; i++) {
        ansStr = ansStr + wisdoms[i].wisdomId.toString();
        if (i < wisdoms.length - 1) ansStr += ",";
        // ansStr =
        //   ansStr + "w" + i.toString() + "=" + wisdoms[i].wisdomId.toString();
      }
      if (DO_LOG) console.log("buildParamWisdomSSE>wisdomIds params=", ansStr);
    } else {
      if (DO_LOG) console.log("buildParamWisdomSSE>no wisdoms");
    }
    return ansStr;
  }; // build param wisdom SSE
  //
  //
  //
  // const delay = (MS) => {
  //   const endMS = Math.round(Date.now()) + MS;
  //   //console.log("delay ", MS, "untilMS", endMS);
  //   var nowMS = Math.round(Date.now());
  //   while (nowMS < endMS) {
  //     nowMS = Math.round(Date.now());
  //     //if (Math.random() > 0.999999) console.log("time", endMS - nowMS);
  //   }
  //   //console.log("delay ", MS, "done", nowMS);
  // };
  //

  // const registerWisdomSSE = () => {
  //   setWisdomSSEStatus("idle");
  //   if (wisdoms && wisdoms?.length > 0) {
  //     // const wisdomIds = [];
  //     // wisdoms.forEach((wisdom) => {
  //     //   wisdomIds.push(wisdom.wisdomId);
  //     // });

  //     const wisdomIds = wisdoms.map((wisdom) => {
  //       return wisdom.wisdomId;
  //     });

  //     console.log(
  //       "registerWisdomSSE>register wisdomIds=",
  //       //wisdomIds.join(","),
  //       wisdomIds
  //     );

  //     //
  //     api
  //       .registerWisdomSSE({ wisdomIds: wisdomIds })
  //       .then((res) =>
  //         res.status === 200 ? res.json() : setWisdomSSEStatus("rejected")
  //       )
  //       .then((result) => setWisdomSSEData(result.data))
  //       .catch((err) => setWisdomSSEStatus("rejected"));
  //   } else {
  //     //
  //     console.log("registerWisdomSSE>skipped NO wisdoms");
  //   }
  // }; // register wisdom SSE

  //make what's on screen current!
  const updateFieldFromSSE = ({ sseData, target, fieldName, wisdomId }) => {
    // console.log(
    //   "updateFieldFromSSE",
    //   target.wisdomId,
    //   fieldName,
    //   target[fieldName],
    //   sseData[fieldName]
    // );

    if (sseData[fieldName] && sseData[fieldName] !== target[fieldName]) {
      target[fieldName] = sseData[fieldName];
      // console.log(
      //   "updateFieldFromSSE>new value fieldName=",
      //   fieldName,
      //   "=",
      //   target[fieldName]
      // );

      const dom = document.getElementById(fieldName + wisdomId.toString());
      if (dom) {
        if (DO_LOG)
          console.log(
            "updateFieldFromSSE>fieldName=",
            fieldName,
            target[fieldName]
          );
        // votes are invisible when count = 0
        dom.style.display = "block";
        dom.innerHTML = target[fieldName];
        // console.log("updateFieldFromSSE>dom=", JSON.stringify(dom, f));
      } else {
        console.log(
          "updateFieldFromSSE>DOM NOT FOUND fieldName=",
          fieldName + wisdomId.toString()
        );
      }
    } else {
      if (DO_LOG)
        if (fieldName === "currentValue")
          console.log(
            "updateFieldFromSSE>NO CHANGE for fieldName=",
            fieldName + wisdomId.toString()
          );
    }
  }; // update field from SSE
  //
  //
  //make what's on screen current!
  // const updateFieldsFromSSE = (sseData, target, fieldNames, wisdomId) => {
  //   fieldNames.forEach((fieldName) => {
  //     if (sseData[fieldName] && sseData[fieldName] !== target[fieldName]) {
  //       target[fieldName] = sseData[fieldName];
  //       console.log(
  //         "updateFieldsFromSSE",
  //         target.wisdomId,
  //         fieldName,
  //         target[fieldName]
  //       );
  //       // console.log(
  //       //   "updateFieldFromSSE>new value fieldName=",
  //       //   fieldName,
  //       //   "=",
  //       //   target[fieldName]
  //       // );

  //       const dom = document.getElementById(fieldName + wisdomId.toString());
  //       if (dom) {
  //         if (DO_LOG)
  //           console.log(
  //             "updateFieldsFromSSE>fieldName=",
  //             fieldName,
  //             target[fieldName]
  //           );
  //         // votes are invisible when count = 0
  //         dom.style.display = "block";
  //         dom.innerHTML = target[fieldName];
  //         // console.log("updateFieldFromSSE>dom=", JSON.stringify(dom, f));
  //       } else {
  //         console.log(
  //           "updateFieldsFromSSE>DOM NOT FOUND fieldName=",
  //           fieldName + wisdomId.toString()
  //         );
  //       }
  //     } else {
  //       if (DO_LOG)
  //         if (fieldName === "currentValue")
  //           console.log(
  //             "updateFieldsFromSSE>NO CHANGE for fieldName=",
  //             fieldName + wisdomId.toString()
  //           );
  //     }
  //   });
  // }; // update fields from SSE
  //
  // remove from screen what will be updated
  //
  const clearFieldFromSSE = ({ sseData, target, fieldName, wisdomId }) => {
    //console.log("clearFieldFromSSE", target.wisdomId, fieldName);

    if (sseData[fieldName] && sseData[fieldName] !== target[fieldName]) {
      //target[fieldName] = sseData[fieldName];
      // console.log(
      //   "updateFieldFromSSE>new value fieldName=",
      //   fieldName,
      //   "=",
      //   target[fieldName]
      // );

      const dom = document.getElementById(fieldName + wisdomId.toString());
      if (dom) {
        if (DO_LOG)
          console.log(
            "clearFieldFromSSE>fieldName=",
            fieldName,
            target[fieldName]
          );
        dom.style.display = "none";
        //dom.innerHTML = target[fieldName];
        // votes are invisible when count = 0
        //dom.style.display = "none";
        // console.log("updateFieldFromSSE>dom=", JSON.stringify(dom, f));
      } else {
        console.log(
          "clearFieldFromSSE>DOM NOT FOUND fieldName=",
          fieldName + wisdomId.toString()
        );
      }
    } else {
      if (DO_LOG)
        if (fieldName === "currentValue")
          console.log(
            "clearFieldFromSSE>NO CHANGE for fieldName=",
            fieldName + wisdomId.toString()
          );
    }
  }; // clear field from SSE
  //
  // const updateFieldsFromSSE = (sseData, target, fieldNames, wisdomId) => {
  //   fieldNames.forEach((fieldName) => {
  //     updateFieldFromSSE(sseData, target, fieldName, wisdomId);
  //     console.log("updateFieldsFromSSE>", target.wisdomId);
  //     //if (Math.random() > 0.9) delay(200);
  //   });
  //   // MAYBE best to just trigger redisplay of card to show new values
  //   //    (instead of each field individually)
  // }; //

  //
  //
  const queueSSEPass1 = (sseData, target, fieldNames, wisdomId) => {
    var intervalTimeoutMS = 200;

    fieldNames.forEach((fieldName) => {
      // only queue fields that are in the sseData
      if (sseData[fieldName]) {
        SSEPass1Queue.push({ sseData, target, fieldName, wisdomId });
        // console.log(
        //   "queueSSEUpdates>",
        //   target.wisdomId,
        //   "elt0=",
        //   JSON.stringify(SSEUpdateQueue[0])
        // );
        //console.log("queue length=", SSEPass1Queue.length);
      }
    });

    if (!SSEPass1Tmr) {
      SSEPass1Tmr = setInterval(() => {
        //console.log("", SSEPass1Queue.length);
        var updateJSON = SSEPass1Queue.shift();
        if (updateJSON) {
          // hide the field
          clearFieldFromSSE({ ...updateJSON });

          // queue the update to give new value
          queueSSEUpdate({ ...updateJSON });
        }
        //console.log("    ", SSEPass1Queue.length);
        if (SSEPass1Queue.length === 0) {
          clearInterval(SSEPass1Tmr);
          SSEPass1Tmr = false;
        }
      }, intervalTimeoutMS);
    }
  }; // queue SSE Pass1

  //
  //
  const queueSSEUpdate = ({ sseData, target, fieldName, wisdomId }) => {
    var intervalTimeoutMS = 200;

    // only queue fields that are in the sseData
    if (sseData.hasOwnProperty(fieldName)) {
      SSEUpdateQueue.push({ sseData, target, fieldName, wisdomId });
      // console.log(
      //   "queueSSEUpdates>",
      //   target.wisdomId,
      //   "elt0=",
      //   JSON.stringify(SSEUpdateQueue[0])
      // );
      //console.log("queue length=", SSEUpdateQueue.length);
    }

    if (!SSEUpdateTmr) {
      SSEUpdateTmr = setInterval(() => {
        //console.log("", SSEUpdateQueue.length);
        const updateJSON = SSEUpdateQueue.shift();
        if (updateJSON) {
          updateFieldFromSSE({ ...updateJSON });
        }
        //console.log("    ", SSEUpdateQueue.length);
        if (SSEUpdateQueue.length === 0) {
          clearInterval(SSEUpdateTmr);
          SSEUpdateTmr = false;
        }
      }, intervalTimeoutMS);
    }
  }; // queue SSE updates
  //
  //
  //
  //
  // const queueSSEUpdates = (sseData, target, fieldNames, wisdomId) => {
  //   var intervalTimeoutMS = 500;

  //   fieldNames.forEach((fieldName) => {
  //     // only queue fields that are in the sseData
  //     if (sseData.hasOwnProperty(fieldName)) {
  //       SSEUpdateQueue.push({ sseData, target, fieldName, wisdomId });
  //       // console.log(
  //       //   "queueSSEUpdates>",
  //       //   target.wisdomId,
  //       //   "elt0=",
  //       //   JSON.stringify(SSEUpdateQueue[0])
  //       // );
  //       //console.log("queue length=", SSEUpdateQueue.length);
  //     }

  //     if (!SSEUpdateTmr) {
  //       SSEUpdateTmr = setInterval(() => {
  //         //console.log("", SSEUpdateQueue.length);
  //         const updateJSON = SSEUpdateQueue.shift();
  //         if (updateJSON) {
  //           updateFieldFromSSE({ ...updateJSON });
  //         }
  //         //console.log("    ", SSEUpdateQueue.length);
  //         if (SSEUpdateQueue.length === 0) {
  //           clearInterval(SSEUpdateTmr);
  //           SSEUpdateTmr = false;
  //         }
  //       }, intervalTimeoutMS);
  //     }
  //   });
  // }; // queue SSE updates

  //
  // process SSE data , array of fields/values for
  //   1 or more wisdom objects
  //
  const updateWisdomData = (dataStr) => {
    //console.log("updateWisdomData>dataStr=", dataStr);
    const parsedJSONs = JSON.parse(dataStr);
    //console.log("updateWisdomData>data=", parsedData);
    wisdoms.forEach((wisdom) => {
      parsedJSONs.forEach((parsedJSON) => {
        //
        // if (wisdom.wisdomId === 191)
        //   console.log(
        //     "updateWisdomData>update wisdomId",
        //     wisdom.wisdomId,
        //     parsedData["likesCount"]
        //   );

        if (parsedJSON.wisdomId === wisdom.wisdomId.toString()) {
          // if (DO_LOG || true)
          //   console.log(
          //     "updateWisdomData>update wisdomId",
          //     wisdom.wisdomId.toString(),
          //     parsedJSON.wisdomId,
          //     "currentValue",
          //     parsedJSON.currentValue
          //   );

          queueSSEPass1(
            parsedJSON,
            wisdom,
            WISDOM_FIELD_NAMES_SSE,
            parsedJSON.wisdomId
          );

          // queueSSEUpdates(
          //   parsedJSON,
          //   wisdom,
          //   WISDOM_FIELD_NAMES_SSE,
          //   parsedJSON.wisdomId
          // );

          // updateFieldsFromSSE(
          //   parsedJSON,
          //   wisdom,
          //   WISDOM_FIELD_NAMES_SSE,
          //   parsedJSON.wisdomId
          // );
          //delay(500);
        }
      });
    });

    // setWisdomSSEData((wisdoms) =>
    //   [...wisdoms].map((serverWisdom) => {
    //     if (serverWisdom.wisdomId === parsedData.wisdomId) {
    //       console.log(
    //         "updateWisdomData>update wisdomId",
    //         serverWisdom.wisdomId
    //       );
    //       updateFieldsFromSSE(parsedData, serverWisdom, WISDOM_FIELD_NAMES_SSE);
    //       return parsedData;
    //     }
    //     return serverWisdom;
    //   })
    // );
  };

  useEffect(() => {
    if (!isLoading) {
      if (WISDOM_SSE_ON) {
        try {
          if (eventSource) eventSource.close();

          const urlParams = buildParamWisdomSSE(wisdoms);
          if (urlParams !== "") {
            if (DO_LOG)
              console.log(
                "Wisdoms.useEffect>after registerWisdomSSE. urlParams=",
                urlParams
              );
            eventSource = new EventSource(
              `${baseURL}/wisdoms/registerWisdomSSE/${urlParams}`
            );
            // console.log(
            //   "Wisdoms.eventSource>eventSource",
            //   eventSource
            // );

            eventSource.onmessage = (e) => {
              if (DO_LOG) console.log("Wisdoms.eventSource>event=", e);
              updateWisdomData(e.data);
            };

            eventSource.onclose = (e) => {
              if (DO_LOG)
                console.log(
                  "Wisdoms.eventSource>Connection closed by the server"
                );
            };
            eventSource.onerror = (err) => {
              if (DO_LOG)
                console.log(
                  "Wisdoms.eventSource>There was an error from server",
                  err
                );
            };

            eventSource.onopen = (res) => {
              if (res.ok && res.status === 200) {
                if (DO_LOG)
                  console.log("Wisdoms.eventSource>Connection made ", res);
              } else if (
                res.status >= 400 &&
                res.status < 500 &&
                res.status !== 429
              ) {
                if (DO_LOG)
                  console.log("Wisdoms.eventSource>Client side error ", res);
              }
            };

            return () => {
              eventSource.close();
            };
          }
        } catch (err) {
          console.log("Wisdoms.useEffect.eventSource>ERROR", err);
        }
      }
    }
  }, [isLoading]);

  // this handles need signin
  if (needSignin && !SHOW_BETA_TWEET) {
    return <h2>Please sign in to your MakeItWisdom.com account again.</h2>;
  }
  // this loading done, but no wisdoms selected
  if (!wisdoms.length && !isLoading)
    if (displayWisdomType === MINTED_STRING) {
      return <h2>No Wisdoms selected.</h2>;
    } else {
      return (
        <div>
          <h2>
            Propose new Wisdom for free using the form on the left side of this
            page. <br />
            You must have an active MakeItWisdom.com account and a crypto wallet
            with MATIC coins to pay to mint it to the blockchain.
            <p />
            As the form shows, the mint price varies as you add (optional)
            hashtags from Select Hashtags dropdown.
            <p />
            We'll consider the Wisdom boundaries to approve or decline your
            proposal.
            <p />
            After you receive email notification, return to this page to mint
            your approved Wisdom to the blockchain so others can find, enjoy,
            buy and sell it.
            <br />
            <p />
            If we decline your proposal, you can try again.
            {/* <p />
            All MakeItWisdom blockchain transactions must be confirmed via your crypto
            wallet, are stored on the blockchain, are final and can't be undone
            or reversed. */}
            <p />
            See also: <br />
            <div className={classes.seeAlsoDiv}>
              <a href="/getstarted">Get started</a>
              <br />
              <a href="/faqs">FAQ</a>
              <br />
              <a href="/boundaries">Boundaries</a>
              <br />
              <a href="/transfer">Transfer</a>
              <br />
              <a href="/residualnft">Residual NFT</a> <br />
              <br />
            </div>
          </h2>
        </div>
      );
    }
  //  var displayWisdomType = "some";
  // var displayProfile = getDisplayProfile();
  // //setDisplayWisdomType(getDisplayProfile().displayWisdomType);
  // //  console.log("Wisdoms>displayProfile=", displayProfile);

  // if (displayProfile) displayWisdomType = displayProfile?.displayWisdomType;

  //  console.log("Wisdoms>displayWisdomType=", displayWisdomType);

  //  const ethInDollars = await getEthInDollars();

  const selectedAnimation = getProfileValue("preventAnimation", false)
    ? ""
    : selectWisdomAnimation();
  setProfileValue("preventAnimation", false);
  //console.log("Wisdoms>animation=", selectedAnimation);
  const { innerWidth: screenWidth } = window;
  const breakpoints = {
    default: 3,
    1100: 2,
    950: 1,
  };

  //console.log("Wisdoms>wisdoms=", wisdoms);
  return isLoading ? (
    <CircularProgress />
  ) : (
    <div>
      {/* <Typography variant="h6">Hello {displayWisdomType} Wisdom </Typography> */}

      {screenWidth > 550 && wisdoms.length > 2 && (
        <Masonry
          breakpointCols={breakpoints}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {wisdoms?.map((serverWisdom) => (
            <div key={"WisdomCard" + serverWisdom._id.toString()}>
              <WisdomCard
                serverWisdom={serverWisdom}
                setCurrentId={setCurrentId}
                setEditWisdom={setEditWisdom}
                displayWisdomType={displayWisdomType}
                selectedAnimation={selectedAnimation}
                coinInCurrency={coinInCurrency}
              />
            </div>
          ))}
        </Masonry>
      )}
      {screenWidth <= 550 && (
        <>
          <Swipe
            wisdoms={wisdoms}
            setCurrentId={setCurrentId}
            style={{ maxWidth: "95%" }}
            displayWisdomType={displayWisdomType}
          ></Swipe>
          <p />
        </>
      )}

      {screenWidth > 550 && wisdoms.length < 3 && (
        <div className={classes.wisdomRow}>
          {wisdoms.map((serverWisdom) => (
            <div
              key={"WisdomCard" + serverWisdom._id.toString()}
              maxwidth={"200px"}
              style={{ paddingLeft: "120px" }}
            >
              <WisdomCard
                serverWisdom={serverWisdom}
                setCurrentId={setCurrentId}
                setEditWisdom={setEditWisdom}
                displayWisdomType={displayWisdomType}
                selectedAnimation={selectedAnimation}
                coinInCurrency={coinInCurrency}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Wisdoms;

// const App = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       await fetchEventSource(`${serverBaseURL}/sse`, {
//         method: "POST",
//         headers: {
//           Accept: "text/event-stream",
//         },
//         onopen(res) {
//           if (res.ok && res.status === 200) {
//             console.log("Connection made ", res);
//           } else if (
//             res.status >= 400 &&
//             res.status < 500 &&
//             res.status !== 429
//           ) {
//             console.log("Client side error ", res);
//           }
//         },
//         onmessage(event) {
//           console.log(event.data);
//           const parsedData = JSON.parse(event.data);
//           setData((data) => [...data, parsedData]);
//         },
//         onclose() {
//           console.log("Connection closed by the server");
//         },
//         onerror(err) {
//           console.log("There was an error from server", err);
//         },
//       });
//     };
//     fetchData();
//   }, []);
