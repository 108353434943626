//
//
//
import React from "react";
import { Container, Grow, Paper } from "@material-ui/core";
//import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import UserSettings from "./UserSettings";

import { loadWebUser } from "../myLocalStorage";
//import useStyles from "./styles";
import { isUserOwner } from "../../actions/users";
import { isLocalhost } from "../serviceWorker";
var PermissionSettings, ContractSettings, ServerSettings;

const webUser = loadWebUser();
const doLog = false;

try {
  if (isUserOwner(webUser)) {
    if (doLog) console.log("****** >> user is OWNER << ******");
    PermissionSettings = require("./PermissionSettings").PermissionSettings;
    ContractSettings = require("./ContractSettings").ContractSettings;
    ServerSettings = require("./ServerSettings").ServerSettings;
    if (doLog && isLocalhost()) {
      if (PermissionSettings != null) {
        //console.log("PermissionSettings SET");
      } else {
        console.log("PermissionSettings NOT SET");
      }
      if (ContractSettings != null) {
        //console.log("ContractSettings SET");
      } else {
        console.log("ContractSettings NOT SET");
      }
      if (ServerSettings != null) {
        //console.log("ServerSettings SET");
      } else {
        console.log("ServerSettings NOT SET");
      }
    }
  }
} catch (e) {
  console.log("Settings> ERROR", e);
}

// import PermissionSettings from "./PermissionSettings";
// import UserSettings from "./UserSettings";
// import ContractSettings from "./ContractSettings";
// import ServerSettings from "./ServerSettings";

// import {
//   PROPOSED_STRING,
//   MINTED_STRING,
//   DISPLAY_WISDOM_TYPE,
// } from "../../constants/constants";

// import {
//   getDisplayProfileValue,
//   setDisplayProfileValue,
// } from "../localStorage";

//console.log("***********> Settings>webUser=", webUser);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Settings = () => {
  //  const classes = useStyles();
  const query = useQuery();
  if (doLog) console.log("Settings>query.page=", query.get("page"));
  const page = query.get("page") || 1;
  const pageReload = query.get("pageReload") || false;

  //const [webUser, setWebUser] = useState();

  //   const dispatch = useDispatch();

  //   const history = useHistory();

  //  var displayWisdomType = MINTED_STRING; // TODO pull from storage

  // useEffect(() => {
  //   setWebUser(JSON.parse(localStorage.getItem(PROFILE)));
  //   console.log("***********> Settings.useEffect>webUser=", webUser);
  // }, [webUser]);

  return (
    <Grow in>
      <Container maxWidth="xl">
        <Paper
          elevation={8}
          style={{
            padding: 12,
            border: "1px solid black",
          }}
        >
          <UserSettings />
        </Paper>
        <p />
        {isUserOwner(webUser) && PermissionSettings != null && (
          <Paper
            elevation={8}
            style={{
              padding: 12,
              border: "1px solid black",
            }}
          >
            <PermissionSettings
              page={page}
              status={"active"}
              forceReload={true}
              pageReload={pageReload}
            />
          </Paper>
        )}
        <p />
        {isUserOwner(webUser) && ContractSettings != null && (
          <Paper
            elevation={8}
            style={{
              padding: 12,
              border: "1px solid black",
            }}
          >
            <ContractSettings />
          </Paper>
        )}
        <p />
        {isUserOwner(webUser) && ServerSettings != null && (
          <Paper
            elevation={8}
            style={{
              padding: 12,
              border: "1px solid black",
            }}
          >
            <ServerSettings />
          </Paper>
        )}
        <p />
      </Container>
    </Grow>
  );
};

export default Settings;
