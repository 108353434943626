//
//
//
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { loadWebUser } from "../myLocalStorage";
import { Typography } from "@material-ui/core";

import * as api from "../../api";
import AlertBanner from "../AlertBanner/AlertBanner.js";
import { getMyListenPort } from "../../configs/multiConfig.mjs";

//import Dotenv from "dotenv";

//import { logEvent } from "../../api";

const formName = "referralForm";

// const defaultValues = {
//   to_email: "",
//   to_name: "",
//   from_name: webUser?.name,
//   message: "",
// };

const ReferralEmailForm = () => {
  const s = {
    recaptchaCallback: "not fired",
    recaptchaValue: "[empty]",
    recaptchaLoad: true,
    recaptchaExpired: false,
    recaptchaValid: false,
  };
  const [recaptchaState, recaptchaSetState] = useState(s);
  const _reCaptchaRef = React.createRef();
  //  }

  const webUser = loadWebUser();
  const defaultValues = {
    to_email: "",
    to_name: "",
    from_name: webUser?.name,
    domain_addy: "",
    message: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCloseAlert = () => {
    // changing state should cause rerender
    setAlertBanner({ ...alertBanner, showNow: false });
  };
  const alertKey = "EmailInviteAlertKey";
  const [alertBanner, setAlertBanner] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKey,
    handleClose: handleCloseAlert,
  });

  //  const componentDidMount = () => {
  //   setTimeout(() => {
  //     recaptchaSetState({ recaptchaLoad: true });
  //   }, DELAY);
  //   console.log("didMount - reCaptcha Ref-", _reCaptchaRef);
  //};

  const handleRecaptchaChange = async (value) => {
    //    console.log("Captcha value:", value);
    // TODO google admin page says I must VERIFY user response. don't know how: https://developers.google.com/recaptcha/docs/verify
    // if value is null recaptcha expired
    if (value === null) {
      recaptchaSetState({
        recaptchaValue: value,
        recaptchaExpired: true,
        recaptchaValid: false,
      });
    } else {
      recaptchaSetState({
        recaptchaValue: value,
        recaptchaExpired: false,
        recaptchaValid: true,
      });
      // make server call to verify score
      await api.vcha({ token: value });
      //const { data } =
      // TODO: check response success
      //console.log("handleRecaptchaChange>", data);
    }
  };

  const asyncScriptOnLoad = () => {
    recaptchaSetState({ recaptchaCallback: "called!" });
    //console.log("scriptLoad - reCaptcha Ref-", _reCaptchaRef);
  };

  const {
    // recaptchaValue,
    // recaptchaCallback,
    // recaptchaLoad,
    // recaptchaExpired,
    recaptchaValid,
  } = recaptchaState || {};

  const handleSubmit = async (event) => {
    //
    event.preventDefault();

    //
    // tell server to send invite email
    const respJSON = await api.inviteByEmail(formValues);
    //console.log("handleSubmit>Invite", respJSON);
    if (respJSON?.data) {
      //alert(respJSON?.data?.message);
      // success!
      setAlertBanner({
        children: null,
        type: "success",
        message: respJSON?.data?.message,
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    } else {
      // error!
      setAlertBanner({
        children: null,
        type: "error",
        message: "Server error. Please try again later.",
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    }
  }; // on submit

  const readyToSend = () => {
    if (
      formValues.to_email === "" ||
      formValues.to_name === "" ||
      formValues.message === "" ||
      formValues.from_name === ""
    ) {
      return false;
    }
    return true;
  };
  //
  if (!webUser) {
    return <Typography>Sign in to invite people by email.</Typography>;
  } else {
    // get website addy
    if (formValues.domain_addy === "") {
      var suffix = getMyListenPort().toString();
      suffix = suffix === 80 ? "" : ":" + suffix.toString();
      const config_domain_addy = "http://MakeItWisdom.com" + suffix;
      setFormValues({
        ...formValues,
        domain_addy: config_domain_addy,
      });
    }
    return (
      <form
        id={formName}
        name={formName}
        onSubmit={handleSubmit}
        // onSubmit={async (e) => {
        //   await handleSubmit(e);
        //   //handleRecaptchaChange(null);
        // }}
      >
        <AlertBanner {...alertBanner}></AlertBanner>

        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Grid item>
            <TextField
              id="name-input"
              name="to_email"
              label="To Email"
              type="text"
              value={formValues.to_email}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <TextField
              id="name-input"
              name="to_name"
              label="To Name"
              type="text"
              value={formValues.to_name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <TextField
              id="name-input"
              name="from_name"
              label="Your Name"
              type="text"
              value={formValues.from_name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <TextField
              id="name-input"
              name="message"
              label="Message"
              type="text"
              value={formValues.message}
              onChange={handleInputChange}
            />
          </Grid>
          <p />
          <ReCAPTCHA
            style={{ display: "inline-block", marginBottom: "10px" }}
            theme="light"
            ref={_reCaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
            onChange={handleRecaptchaChange}
            asyncScriptOnLoad={asyncScriptOnLoad}
          />
          {recaptchaValid && readyToSend() && (
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          )}
          <p />
        </Grid>
      </form>
    );
  }
};
export default ReferralEmailForm;
