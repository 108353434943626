//
//
//
//
//
//
import React from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";

// import Avatar from "@material-ui/core/Avatar";
// import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
// import CardContent from "@material-ui/core/CardContent";
// import CardHeader from "@material-ui/core/CardHeader";
// import IconButton from "@material-ui/core/IconButton";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import Typography from "@material-ui/core/Typography";

// import FavoriteIcon from "@material-ui/icons/Favorite";
// import ShareIcon from "@material-ui/icons/Share";
// import MoreVertIcon from "@material-ui/icons/MoreVert";

// import item001 from "../../images/item001.jpg";
// import item002 from "../../images/item002.jpg";
// import item003 from "../../images/item003.jpg";
import WisdomCard from "../Wisdoms/Wisdom/WisdomCard";

const useStyles = makeStyles(() => {
  const baseStyle = {
    padding: "1em",
    color: "white",
    maxWidth: "95%",
  };
  const activeBaseStyle = {
    color: "white",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  };

  return {
    slide: {
      ...baseStyle,
      backgroundColor: "black",
    },
    slide0: {
      ...baseStyle,
      backgroundColor: "skyblue",
    },
    slide1: {
      ...baseStyle,
      backgroundColor: "orange",
    },
    slide2: {
      ...baseStyle,
      backgroundColor: "indianred",
    },
    image: {
      width: "100%",
      height: "auto",
    },
    active: {
      ...activeBaseStyle,
      backgroundColor: "lightseagreen",
    },
    active0: {
      ...activeBaseStyle,
      backgroundColor: "lightseagreen",
    },
    active1: {
      ...activeBaseStyle,
      backgroundColor: "yellowgreen",
    },
    active2: {
      ...activeBaseStyle,
      backgroundColor: "pink",
    },
    card: {
      margin: "0 auto",
      width: "70%",
    },
    cardContent: {
      textAlign: "left",
    },
  };
});

const Swipe = ({ wisdoms, setCurrentId, displayWisdomType }) => {
  const [swipeableActions, setSwipeableActions] = React.useState();
  const [tabIndex, setTabIndex] = React.useState(0);
  //  const [maxTabNum, setMaxTabNum] = React.useState(6);
  const classes = useStyles();
  const history = useHistory();
  const pathRoot = useLocation().pathname;
  const pathSearch = useLocation().search;

  //   const items = [item001, item002, item003];
  //   const itemInitial = ["S", "C", "M"];
  //   const itemNames = ["シュークリーム", "チョコレートケーキ", "ミルクプリン"];
  //   const itemOfficialNames = [
  //     "ｶｽﾀｰﾄﾞｼｭｰｸﾘｰﾑ いちごのｱｲｼﾝｸﾞがけ（3個入り）",
  //     "チョコレートムースケーキ（2個入り）",
  //     "りんごのミルクプリン（２個入り）",
  //   ];
  //   const itemcategories = ["ヘルシー食", "たんぱく質調整食", "たんぱく質調整食"];

  const handleChange = (index) => {
    //console.log("Swipe>now index=", index);
    if (index === wisdoms.length) {
      if (swipeableActions && pathSearch === "42")
        console.log("Swipe>LOAD MORE WISDOM");
      history.push(toOtherPage(true));
    }
    setTabIndex(index);
  };

  const toOtherPage = (nextPage) => {
    var n1 = pathSearch.indexOf("?page=");
    var n2 = pathSearch.indexOf("&page=");
    var n = n1 === -1 ? n2 : n2 === -1 ? n1 : Math.min(n1, n2);
    //    console.log("ToOtherPage>page?=", pathSearch.substring(n + 6));
    var currentPage = parseInt(pathSearch.substring(n + 6), 10);
    //    console.log("ToOtherPage>currentPage=", currentPage);
    nextPage = currentPage + 1;
    var s = pathSearch.substring(0, n !== -1 ? n : pathSearch.length);
    if (s === "") s = s + "?";
    else s = s + "&";

    var op = pathRoot + s + "page=" + nextPage.toString() + "&pageReload=true";
    return op;
  };

  const { innerWidth: screenWidth } = window;
  const wisdomWidth = (screenWidth - 90).toString() + "px";

  // const page1Timout = setTimeout(() => {
  //   handleChange(1);
  // }, 2000);
  //setMaxTabNum(wisdoms.length);
  //  console.log("Swipe>count=", wisdoms.length);
  return (
    <>
      <React.Fragment>
        <Tabs
          value={tabIndex}
          onChange={(e, value) => handleChange(value)}
          variant="fullWidth"
          indicatorColor="primary"
        >
          {wisdoms.map((wisdom, i) => (
            <Tab
              style={{ maxWidth: "20px", padding: "0px", margin: "0px" }}
              fullWidth={false}
              key={"TabKey" + i.toString()}
              className={classes.active}
              label={i + 1}
            />
          ))}
          <Tab
            style={{ maxWidth: "20px" }}
            fullWidth={false}
            key={"TabKeyNEXT"}
            className={classes.active}
            label={"Next"}
          />
        </Tabs>
        <SwipeableViews
          style={{ maxWidth: wisdomWidth }}
          enableMouseEvents
          action={(actions) => setSwipeableActions(actions)}
          resistance
          animateHeight
          index={tabIndex}
          onChangeIndex={(index) => handleChange(index)}
        >
          {wisdoms.map((serverWisdom, index) => (
            <div
              key={"WisdomCard" + serverWisdom._id.toString()}
              className={classes.slide}
            >
              <WisdomCard
                serverWisdom={serverWisdom}
                setCurrentId={setCurrentId}
                displayWisdomType={displayWisdomType}
              ></WisdomCard>
            </div>
          ))}
          <div key={"WisdomCardSwipeableViewNext"} className={classes.slide}>
            NEXT
          </div>
        </SwipeableViews>
      </React.Fragment>
    </>
  );
};
export default Swipe;
