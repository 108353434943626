//
//
//
import React from "react";
import { Tooltip } from "@material-ui/core/";

const Trigger = ({ triggerText, buttonRef, showModal, style, toolTipText }) => {
  return (
    <Tooltip title={<h2>{toolTipText}</h2>} placement="top">
      <button
        className="btn btn-lg btn-danger center modal-button"
        ref={buttonRef}
        style={style}
        onClick={showModal}
      >
        {triggerText}
      </button>
    </Tooltip>
  );
};
export default Trigger;
