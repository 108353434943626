export const miwAppsJSON = {
  miwApps: [
    {
      appName: "miwClient",
      Purposes: [
        {
          purposeName: "dev",
          version: "v0",
          DB: [{ appName: "miwServer", purposeName: "dev", version: "v0" }],
          BC: [{ appName: "miwBlockchain", purposeName: "dev", version: "v0" }],
        },
        {
          purposeName: "devTest",
          version: "v0",
          DB: [{ appName: "miwServer", purposeName: "devTest", version: "v0" }],
          BC: [
            { appName: "miwBlockchain", purposeName: "devTest", version: "v0" },
          ],
        },
        {
          purposeName: "prodTest",
          version: "v0",
          DB: [
            { appName: "miwServer", purposeName: "prodTest", version: "v0" },
          ],
          BC: [
            {
              appName: "miwBlockchain",
              purposeName: "prodTest",
              version: "v0",
            },
          ],
        },
        {
          purposeName: "prod",
          version: "v0",
          DB: [{ appName: "miwServer", purposeName: "prod", version: "v0" }],
          BC: [
            { appName: "miwBlockchain", purposeName: "prod", version: "v0" },
          ],
        },
      ],
    },
    {
      appName: "miwServer",
      Purposes: [
        {
          purposeName: "dev",
          version: "v0",
          configFilename: "serverOptions.json",
        },
        {
          purposeName: "devTest",
          version: "v0",
          configFilename: "serverOptions.json",
        },
        {
          purposeName: "prodTest",
          version: "v0",
          configFilename: "serverOptions.json",
        },
        {
          purposeName: "prod",
          version: "v0",
          configFilename: "serverOptions.json",
        },
      ],
    },
    {
      appName: "miwWebhooks",
      Purposes: [
        {
          purposeName: "dev",
          version: "v0",
          fromPhone: "",
          Messages: [
            {
              activityName: "WisdomSold",
              messagePrototype:
                "{formAddress} paid you {value} {COIN_LABEL}. See {txLink}",
            },
            {
              activityName: "WisdomMinted",
              messagePrototype:
                "You earned {value} {COIN_LABEL} for approving!. See {txLink}",
            },
          ],
        },
      ],
    },
    {
      appName: "miwAdmin",
      Purposes: [
        { purposeName: "dev", version: "" },
        { purposeName: "devTest", version: "" },
        { purposeName: "prodTest", version: "" },
        { purposeName: "prod", version: "" },
      ],
    },
    {
      appName: "miwBlockchain",
      Purposes: [
        {
          purposeName: "dev",
          version: "v0",
          configName: "development",
          rpcUrl: "http://127.0.0.1:8545",
          networkName: "HH_fwd",
          chainId: "31337",
          blockExplorerUrl: "",
          contractManifestFile: "contractManifest_dev.js",
          accounts: [
            {
              wallet: "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266",
              privateKey:
                "0xac0974bec39a17e36ba4a6b4d238ff944bacb478cbed5efcae784d7bf4f2ff80",
            },
          ],
        },
        {
          purposeName: "devTest",
          version: "v0",
          configName: "devCloud",
          rpcUrl: "http://2a9c-98-174-247-196.ngrok.io",
          networkName: "HH_fwd",
          chainId: "31337",
          blockExplorerUrl: "https://polygonscan.com",
          contractManifestFile: "contractManifest_devTest.js",
          accounts: [
            {
              wallet: "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266",
              privateKey:
                "0xac0974bec39a17e36ba4a6b4d238ff944bacb478cbed5efcae784d7bf4f2ff80",
            },
          ],
        },
        {
          purposeName: "prodTest",
          version: "v0",
          configName: "mumbai",
          rpcUrl: "https://rpc-mainnet.maticvigil.com/",
          networkName: "Polygon Testnet",
          chainId: "80001",
          blockExplorerUrl: "https://mumbai.polygonscan.com/",
          contractManifestFile: "contractManifest_prodTest.js",
          accounts: [
            {
              wallet: "0x42AEEbBCf85A06658dFC2dadfFA0C96C71Bb4d83",
              privateKey: "secret",
            },
          ],
        },
        {
          purposeName: "prod",
          version: "v0",
          configName: "polygon",
          rpcUrl: "https://rpc-mainnet.maticvigil.com/",
          networkName: "Polygon",
          chainId: "137",
          blockExplorerUrl: "https://polygonscan.com",
          contractManifestFile: "contractManifest_prod.js",
          accounts: [
            {
              wallet: "0xa864425D00a81E47F7000c5d800b390f1dbf174b",
              privateKey: "secret",
            },
          ],
        },
      ],
    },
  ],
};
