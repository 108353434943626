import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import Icon from "./icon";
import { signin, signup } from "../../actions/auth";
import { AUTH } from "../../constants/actionTypes";
import useStyles from "./styles";
import Input from "./Input";
import * as api from "../../api/index";
import ReCAPTCHA from "react-google-recaptcha";
import { isLocalhost } from "../serviceWorker";
//import { RECAPTCHA_CLIENT_KEY } from "../../constants/env";

// for auth
// https://console.cloud.google.com/apis/credentials?project=pc-api-5213798708730720894-803
//
const doLog = false;

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  newPassword: "",
};

const SignUpIn = () => {
  const [form, setForm] = useState(initialState);
  const [isSignupIn, setIsSignupIn] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [resetChecked, setResetChecked] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);

  const switchMode = () => {
    //setForm(initialState);
    setIsSignupIn((prevIsSignupIn) => !prevIsSignupIn);
    setShowPassword(false);
  };

  const switchChangePWMode = () => {
    //setForm(initialState);
    setIsChangePassword((prevIsChangePassword) => !prevIsChangePassword);
    setShowNewPassword(false);
  };

  const handleResetCheck = (event) => {
    if (doLog) console.log("handleResetcheck>", event.target.checked);
    setForm({ ...form, resetPassword: event.target.checked });
    setResetChecked(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log("handleSubmit>form1", form, document.getElementById("email"));
    if (!form.email || form.email === "")
      form.email = document.getElementById("email") || "";
    if (!form.password || form.password === "")
      form.password = document.getElementById("password") || "";
    if (!form.newPassword || form.newPassword === "")
      form.newPassword = document.getElementById("newPassword") || "";

    form.email = form.email.toLowerCase();
    //console.log("handleSubmit>form2", form);
    //console.log("handleSubmit>event=", e);

    if (isSignupIn) {
      dispatch(signup(form, history));
    } else {
      dispatch(signin(form, history));
    }
  }; // handle submit

  const googleSuccess = async (res) => {
    const result = res?.profileObj;
    const token = res?.tokenId;

    try {
      if (doLog)
        console.log("googleSuccess>after api.signInGoogle>result", result);
      result.email = result?.email.toLowerCase();
      const { data } = await api.signInGoogle(result);

      if (doLog)
        console.log("googleSuccess>after api.signInGoogle>", data.result);
      //
      dispatch({ type: AUTH, data: { result: data.result, token } });

      // navigate to home page
      // TODO: now that signed in, proposed radio button should be active
      history.push("/");
    } catch (error) {
      console.log("googleSuccess>ERROR", error);
    }
  }; // google success

  const googleError = () => {
    if (doLog) console.log("Google Sign In was unsuccessful. Try again later");
  };
  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  //
  const handleRecaptchaChange = async (value) => {
    //console.log("Captcha value:", value);
    // if value is null recaptcha expired
    if (doLog) console.log("handleRecaptchaChange>value=", value);
    if (value === null) {
      recaptchaSetState({
        recaptchaValue: value,
        recaptchaExpired: true,
        recaptchaValid: false,
      });
    } else {
      recaptchaSetState({
        recaptchaValue: value,
        recaptchaExpired: false,
        recaptchaValid: true,
      });
      // make server call to verify score
      const { data } = await api.vcha({ token: value });
      //
      // TODO: check response success
      if (doLog) console.log("handleRecaptchaChange>", data);
    }
  };

  const asyncScriptOnLoad = () => {
    recaptchaSetState({ recaptchaCallback: "called!" });
    if (doLog) console.log("scriptLoad - reCaptcha Ref-", _reCaptchaRef);
  };

  //
  const s = {
    recaptchaCallback: "not fired",
    recaptchaValue: "[empty]",
    recaptchaLoad: true,
    recaptchaExpired: false,
    recaptchaValid: false,
  };

  const [recaptchaState, recaptchaSetState] = useState(s);
  const _reCaptchaRef = React.createRef();

  //useEffect

  //
  if (doLog) console.log("Auth.SignUpIn>");
  const {
    // recaptchaValue,
    // recaptchaCallback,
    // recaptchaLoad,
    // recaptchaExpired,
    recaptchaValid,
  } = recaptchaState || {};
  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={6}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {isSignupIn ? "Sign up" : "Sign in"}
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          autoComplete={"off"}
        >
          <Grid container spacing={2}>
            {isSignupIn && (
              <>
                <Input
                  name="firstName"
                  label="First Name"
                  handleChange={handleChange}
                  autoFocus
                  half
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  handleChange={handleChange}
                  half
                />
                <Input
                  name="twitterHandle"
                  label="Twitter Handle"
                  handleChange={handleChange}
                  half
                />
              </>
            )}
            <Input
              id="email"
              name="email"
              label="Email Address"
              handleChange={handleChange}
              autoComplete="off"
              type="email"
            />
            <Input
              id="password"
              name="password"
              label="Password"
              autoComplete="off"
              handleChange={handleChange}
              type={showPassword ? "text" : "password"}
              handleShowPassword={handleShowPassword}
            />

            {!isSignupIn && isChangePassword && (
              <Input
                id="newPassword"
                name="newPassword"
                label="New Password"
                autoComplete="off"
                handleChange={handleChange}
                type={showPassword ? "text" : "password"}
                handleShowPassword={handleShowNewPassword}
              />
            )}
            {isSignupIn && (
              <Input
                name="confirmPassword"
                label="Repeat Password"
                autoComplete="off"
                handleChange={handleChange}
                type={showPassword ? "text" : "password"}
              />
            )}
          </Grid>
          <ReCAPTCHA
            style={{
              display: "inline-block",
              marginTop: "10px",
              //marginBottom: "1px",
            }}
            theme="light"
            ref={_reCaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
            onChange={handleRecaptchaChange}
            asyncScriptOnLoad={asyncScriptOnLoad}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!recaptchaValid && !isLocalhost()}
            className={classes.submit}
          >
            {isSignupIn ? "Sign Up" : "Sign In"}
          </Button>
          <GoogleLogin
            //clientId="564033717568-bu2nr1l9h31bhk9bff4pqbenvvoju3oq.apps.googleusercontent.com"
            clientId="933833753131-u10upgivle2cu29hvkr3j8ipoh0ba1fb.apps.googleusercontent.com"
            // isSignedIn={true}
            render={(renderProps) => (
              <Button
                className={classes.googleButton}
                color="primary"
                fullWidth
                onClick={renderProps.onClick}
                disabled={
                  renderProps.disabled || (!recaptchaValid && !isLocalhost())
                }
                startIcon={<Icon />}
                variant="contained"
              >
                Google Sign In
              </Button>
            )}
            onSuccess={googleSuccess}
            onFailure={googleError}
            cookiePolicy="single_host_origin"
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button onClick={switchMode}>
                {isSignupIn
                  ? "Already have an account? Sign in"
                  : "Don't have an account? Sign up"}
              </Button>
            </Grid>
            {!isSignupIn && (
              <>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={resetChecked}
                        onChange={handleResetCheck}
                      />
                    }
                    label="RESET PASSWORD"
                  />
                </Grid>
                <Grid item>
                  <Button onClick={switchChangePWMode}>
                    {isChangePassword
                      ? "Provide current and new password"
                      : "Change password"}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default SignUpIn;
