export const miwNodesJSON = {
  miwNodes: [
    {
      hostName: "DanHome",
      nodeName: "DanMacM1",
      nodeId: "0000",
      domainName: "localhost",
      domainAddy: "http://localhost",
      ip: "  ",
      Folders: [
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom/client",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/client",
          appName: "miwClient",
          purposeName: "dev",
          version: "v0",
          listenPort: "3000",
          maxAPIperIPperSecond: 3,
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "devTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_devTest/client",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/client",
          appName: "miwClient",
          purposeName: "devTest",
          version: "v0",
          listenPort: "3000",
          maxAPIperIPperSecond: 3,
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "devTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_prodTest/client",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/client",
          appName: "miwClient",
          purposeName: "prodTest",
          version: "v0",
          listenPort: "3100",
          maxAPIperIPperSecond: 3,
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "prodTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_prod/client",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/client",
          appName: "miwClient",
          purposeName: "prod",
          version: "v0",
          listenPort: "8080",
          maxAPIperIPperSecond: 3,
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "prod",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom/server",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/server",
          appName: "miwServer",
          purposeName: "dev",
          version: "v0",
          listenPort: "4900",
          configFolder: "",
          authorizeIP: ["", ""],
          dbName: "makeitwisdom_dev_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_dev_v0?retryWrites=true&w=majority",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "devTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_dev/server",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/server",
          appName: "miwServer",
          purposeName: "dev",
          version: "v0",
          listenPort: "4900",
          configFolder: "",
          authorizedIPs: ["", ""],
          dbName: "makeitwisdom_dev_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_dev_v0?retryWrites=true&w=majority",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "devTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_devTest/server",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/server",
          appName: "miwServer",
          purposeName: "devTest",
          version: "v0",
          listenPort: "4900",
          configFolder: "",
          authorizedIPs: ["", ""],
          dbName: "makeitwisdom_devTest_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_devTest_v0?retryWrites=true&w=majority",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "devTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_prodTest/MIW/server",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/server",
          appName: "miwServer",
          purposeName: "prodTest",
          version: "v0",
          listenPort: "4980",
          configFolder: "",
          authorizedIPs: ["", ""],
          dbName: "makeitwisdom_prodTest_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_prodTest_v0?retryWrites=true&w=majority",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "prodTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_prod/MIW/server",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/server",
          appName: "miwServer",
          purposeName: "prod",
          version: "v0",
          listenPort: "5000",
          configFolder: "",
          authorizedIPs: ["", ""],
          dbName: "makeitwisdom_prod_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_prod_v0?retryWrites=true&w=majority",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "prod",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom/notify",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/notify",
          appName: "miwNotify",
          purposeName: "dev",
          version: "v0",
          listenPort: "3900",
          configFolder: "",
          authorizeIP: ["", ""],
          dbName: "makeitwisdom_dev_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_dev_v0?retryWrites=true&w=majority",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "devTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_dev/notify",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/notify",
          appName: "miwNotify",
          purposeName: "dev",
          version: "v0",
          listenPort: "3900",
          configFolder: "",
          authorizedIPs: ["", ""],
          dbName: "makeitwisdom_dev_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_dev_v0?retryWrites=true&w=majority",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "devTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_devTest/notify",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/notify",
          appName: "miwNotify",
          purposeName: "devTest",
          version: "v0",
          listenPort: "3900",
          configFolder: "",
          authorizedIPs: ["", ""],
          dbName: "makeitwisdom_devTest_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_devTest_v0?retryWrites=true&w=majority",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "devTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_prodTest/MIW/notify",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/notify",
          appName: "miwNotify",
          purposeName: "prodTest",
          version: "v0",
          listenPort: "3980",
          configFolder: "",
          authorizedIPs: ["", ""],
          dbName: "makeitwisdom_prodTest_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_prodTest_v0?retryWrites=true&w=majority",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "prodTest",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom_prod/MIW/notify",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/notify",
          appName: "miwNotify",
          purposeName: "prod",
          version: "v0",
          listenPort: "4000",
          configFolder: "",
          authorizedIPs: ["", ""],
          dbName: "makeitwisdom_prod_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_prod_v0?retryWrites=true&w=majority",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "prod",
        },
        {
          path: "/Users/danfarfan/Documents/Crypto/makeitwisdom/admin",
          stage1Folder:
            "/Users/danfarfan/Documents/Crypto/staging_MIW/stage1/admin",
          appName: "miwAdmin",
          purposeName: "dev",
          version: "v0",
          listenPort: "6000",
          uploadHostName: "DigitalOcean",
          uploadPurposeName: "devTest",
        },
      ],
    },
    {
      hostName: "DigitalOcean",
      nodeName: "DO_Shared_0000",
      nodeId: "0000",
      domainName: "makeitwisdom.com",
      domainAddy: "http://makeitwisdom.com",
      ip: "",
      Folders: [
        {
          path: "/home/makeitwisdom_dev/MIW/client",
          stage1Folder: "/home/makeitwisdom_dev/stage1/client",
          appName: "miwClient",
          purposeName: "dev",
          version: "v0",
          listenPort: "3000",
        },
        {
          path: "/home/makeitwisdom_devTest/MIW/client",
          stage1Folder: "/home/makeitwisdom_devTest/stage1/client",
          appName: "miwClient",
          purposeName: "devTest",
          version: "v0",
          listenPort: "3000",
        },
        {
          path: "/home/makeitwisdom_prodTest/MIW/client",
          stage1Folder: "/home/makeitwisdom_prodTest/stage1/client",
          appName: "miwClient",
          purposeName: "prodTest",
          version: "v0",
          listenPort: "3080",
        },
        {
          path: "/home/makeitwisdom_prod/MIW/client",
          stage1Folder: "/home/makeitwisdom_prod/stage1/client",
          appName: "miwClient",
          purposeName: "prod",
          version: "v0",
          listenPort: "8080",
        },
        {
          path: "/home/makeitwisdom/MIW/server",
          stage1Folder: "/home/makeitwisdom/stage1/server",
          appName: "miwServer",
          purposeName: "",
          version: "v0",
          listenPort: "4900",
          configFolder: "",
          dbName: "makeitwisdom_dev_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_dev_v0?retryWrites=true&w=majority",
        },
        {
          path: "/home/makeitwisdom_devTest/MIW/server",
          stage1Folder: "/home/makeitwisdom_devTest/stage1/server",
          appName: "miwServer",
          purposeName: "dev",
          version: "v0",
          listenPort: "4900",
          configFolder: "",
          dbName: "makeitwisdom_dev_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_dev_v0?retryWrites=true&w=majority",
        },
        {
          path: "/home/makeitwisdom_devTest/MIW/server",
          stage1Folder: "/home/makeitwisdom_devTest/stage1/server",
          appName: "miwServer",
          purposeName: "devTest",
          version: "v0",
          listenPort: "4900",
          configFolder: "",
          dbName: "makeitwisdom_devTest_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_devTest_v0?retryWrites=true&w=majority",
        },
        {
          path: "/home/makeitwisdom_prodTest/MIW/server",
          stage1Folder: "/home/makeitwisdom_prodTest/stage1/server",
          appName: "miwServer",
          purposeName: "prodTest",
          version: "v0",
          listenPort: "4980",
          configFolder: "",
          dbName: "makeitwisdom_prodTest_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_prodTest_v0?retryWrites=true&w=majority",
        },
        {
          path: "/home/makeitwisdom_prod/MIW/server",
          stage1Folder: "/home/makeitwisdom_prod/stage1/server",
          appName: "miwServer",
          purposeName: "prod",
          version: "v0",
          listenPort: "5000",
          configFolder: "",
          dbName: "makeitwisdom_prod_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_prod_v0?retryWrites=true&w=majority",
        },
        {
          path: "/home/makeitwisdom/MIW/notify",
          stage1Folder: "/home/makeitwisdom/stage1/notify",
          appName: "miwNotify",
          purposeName: "",
          version: "v0",
          listenPort: "3900",
          configFolder: "",
          dbName: "makeitwisdom_dev_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_dev_v0?retryWrites=true&w=majority",
        },
        {
          path: "/home/makeitwisdom_devTest/MIW/notify",
          stage1Folder: "/home/makeitwisdom_devTest/stage1/notify",
          appName: "miwNotify",
          purposeName: "dev",
          version: "v0",
          listenPort: "3900",
          configFolder: "",
          dbName: "makeitwisdom_dev_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_dev_v0?retryWrites=true&w=majority",
        },
        {
          path: "/home/makeitwisdom_devTest/MIW/notify",
          stage1Folder: "/home/makeitwisdom_devTest/stage1/notify",
          appName: "miwNotify",
          purposeName: "devTest",
          version: "v0",
          listenPort: "3900",
          configFolder: "",
          dbName: "makeitwisdom_devTest_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_devTest_v0?retryWrites=true&w=majority",
        },
        {
          path: "/home/makeitwisdom_prodTest/MIW/notify",
          stage1Folder: "/home/makeitwisdom_prodTest/stage1/notify",
          appName: "miwNotify",
          purposeName: "prodTest",
          version: "v0",
          listenPort: "3980",
          configFolder: "",
          dbName: "makeitwisdom_prodTest_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_prodTest_v0?retryWrites=true&w=majority",
        },
        {
          path: "/home/makeitwisdom_prod/MIW/notify",
          stage1Folder: "/home/makeitwisdom_prod/stage1/notify",
          appName: "miwNotify",
          purposeName: "prod",
          version: "v0",
          listenPort: "4000",
          configFolder: "",
          dbName: "makeitwisdom_prod_v0",
          dbConnectURL:
            "mongodb+srv://DanFarfanMakeItWisdom:R2h6nSrAqQqvgB00@cluster0.fvs9o.mongodb.net/makeitwisdom_prod_v0?retryWrites=true&w=majority",
        },
        {
          path: "/home/makeitwisdom_devTest/MIW/admin",
          stage1Folder: "/home/makeitwisdom_devTest/stage1/admin",
          appName: "miwAdmin",
          purposeName: "devTest",
          version: "v0",
          listenPort: "6000",
        },
      ],
    },
  ],
};
