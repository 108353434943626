import React from "react";
//import Box from "@material-ui/core/Box";
import { TextField } from "@material-ui/core";
import { computeWisdomMaxLength } from "../../../actions/wisdoms";
//import { PROFILE } from "../../../constants/constants";
//import { loadWebUser } from "../../myLocalStorage";
//import { PROFILE } from "../constants/constants";

const DEFAULT_FONT_SIZE = 20;
const DEFAULT_FONT_FAMILY = "Courier";

const WisdomBox = ({ wisdom, cursor }) => {
  //console.log("WisdomBox>wisdom=", wisdom);
  //console.log("WisdomBox>cursor=", cursor);
  //const rowCount = 8;
  //  const webUser = loadWebUser();
  return (
    <div style={{ maxWidth: "600px" }}>
      <TextField
        //className={classes.inputField}
        name="wisdomText"
        variant="standard"
        multiline
        fullWidth
        //minRows={rowCount}
        //disabled={true}
        readOnly="readonly"
        //readOnly={true}
        mx="10px"
        px="5px"
        py="10px"
        InputProps={{
          disableUnderline: true,
        }}
        inputProps={{
          maxLength: computeWisdomMaxLength(),
          style: {
            color: wisdom?.fontColor,
            backgroundColor: wisdom?.backgroundColor,
            padding: "15px 15px",
            margin: "0px 10px",
            borderRadius: "8px",
            textAlign: "center",
            lineHeight: "1.0",
            fontSize: wisdom?.fontSize || DEFAULT_FONT_SIZE,
            fontFamily: wisdom?.fontFamily || DEFAULT_FONT_FAMILY,
            cursor: cursor,
          },
        }}
        value={wisdom?.wisdomText + "\n "}
      />

      {/* <Box
        variant="wisdom"
        border={1}
        borderColor={wisdom?.borderColor || "black"}
        mx="10px"
        px="5px"
        py="10px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={wisdom?.backgroundColor || "white"}
        color={wisdom?.fontColor || "black"}
        fontSize={wisdom?.fontSize || 22}
      >
        {wisdom?.wisdomText || "wisdom here"}
      </Box> */}
    </div>
  );
};

export default WisdomBox;
