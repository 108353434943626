import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 200;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  media: {
    height: 0,
    //paddingTop: "56.25%",
    paddingTop: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    //backgroundBlendMode: "darken",
  },
  mediaNoPic: {
    height: 0,
    paddingTop: "26.25%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: "darken",
  },
  border: {
    border: "solid",
  },
  brandContainer: {
    display: "flex",
    alignItems: "left",
    textDecoration: "none",
    fontSize: "1rem",
  },

  fullHeightCard: {
    height: "100%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "15px",
    maxHeight: "25vh",
    transitionDuration: "0.7s",
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: "20px",
    left: "20px",
    color: "white",
  },
  overlay2: {
    position: "absolute",
    top: "20px",
    right: "20px",
    color: "white",
  },
  grid: {
    display: "flex",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  image: {
    marginLeft: "10px",
    marginTop: "5px",
  },

  details: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
  },
  title: {
    padding: "0 16px",
  },
  cardActions: {
    padding: "0 16px 8px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardAction: {
    display: "block",
    textAlign: "initial",
  },
  responsiveFont: {
    fontSize: "1.5vw",

    [theme.breakpoints.down("sm")]: {
      fontSize: "4vw", // <600
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw", // >600
    },
    [theme.breakpoints.up("800")]: {
      fontSize: "2vw", // >800
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8vw", // >900
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4vw", // >1200
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.9vw", // >1536
    },
    [theme.breakpoints.up("1800")]: {
      fontSize: "1.1vw", // >1800
    },
  },
}));
