import {
  FETCH_AUTHORS,
  FETCH_AUTHOR,
  START_LOADING_USERS,
  STOP_LOADING_USERS,
  //  UPDATE_USER,
} from "../constants/actionTypes";
import * as api from "../api/index.js";
import { loadWebUser } from "../components/myLocalStorage";
import {
  DECLINED_STRING,
  PROPOSED_STRING,
  WS_COMMENTS_BY_WISDOM_AUTHOR,
  WS_COMMENTS_BY_WISDOM_OWNER,
} from "../constants/constants.js";
import {
  isUserWisdomAuthor,
  isUserWisdomOwner,
  isWisdomProposed,
} from "./wisdoms";
import * as perm from "../constants/permissions.js";
import { isOnList } from "../constants/permissions.js";

// import { ethers } from "ethers";
// import { useWeb3React } from "@web3-react/core";
// import { ContactSupportOutlined } from "@mui/icons-material";
//const web3React = useWeb3React();
//const hre = require("hardhat");

var ownerWallet;

export const getAuthor =
  (id = "") =>
  async (dispatch) => {
    //  console.log("client.getAuthor>id=", id);
    try {
      if (id !== "") {
        dispatch({ type: START_LOADING_USERS });

        const { data } = await api.fetchAuthor(id);
        //    console.log("client.getAuthor>data=", data);

        dispatch({
          type: FETCH_AUTHOR,
          payload: { user: data },
        });
        dispatch({ type: STOP_LOADING_USERS });
      }
    } catch (error) {
      console.log(error);
    }
  };
//
//
//
export const getAuthors = (query) => async (dispatch) => {
  //  console.log("client.getAuthors>query=", query);

  try {
    dispatch({ type: START_LOADING_USERS });
    const {
      data: { data, currentPage, numberOfPages },
    } = await api.fetchAuthors(query);

    // console.log(
    //   "client.getAuthors>page,pages,data=",
    //   currentPage,
    //   numberOfPages,
    //   data
    // );

    dispatch({
      type: FETCH_AUTHORS,
      payload: { data, currentPage, numberOfPages },
    });
    dispatch({ type: STOP_LOADING_USERS });
  } catch (error) {
    console.log(error);
  }
};
//
//
//
export const updateUser = (id, updateQuery) => async (dispatch) => {
  try {
    console.log("updateUser>updateQuery=", updateQuery);

    await api.updateUser(id, updateQuery);

    //dispatch({ type: UPDATE_USER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

//
// Helper function
//

export const setOwnerWallet = (wallet) => {
  console.log("setOwnerWallet", wallet);
  ownerWallet = wallet;
};
//
export const canUserDeclineWisdoms = (webUser, serverWisdom) => {
  //console.log("canUserDeclineWisdoms>owner=", isUserOwner(webUser));
  if (serverWisdom?.status === DECLINED_STRING) return false;
  if (isUserOwner(webUser)) return true;
  //console.log("canUserDeclineWisdoms>checking non-owner");
  return (
    canUserApproveWisdoms(webUser, serverWisdom) &&
    isWisdomProposed(serverWisdom)
  );
};
//
export const canUserApproveWisdoms = (webUser, serverWisdom) => {
  // owner can override
  //console.log("canUserApproveWisdoms>status=", serverWisdom?.status);
  if (serverWisdom?.status !== PROPOSED_STRING) return false;
  if (isUserOwner(webUser)) return true;
  //console.log("canUserApproveWisdoms>isApprover", webUser?.isWisdomApprover);
  // TODO check isOnList !
  if (
    webUser?.isWisdomApprover &&
    //webUser?.googleId !== serverWisdom?.authorId &&
    webUser?._id !== serverWisdom?.authorId
  ) {
    return true;
  }
  return false;
};
//
export const canUserApproveHashtags = (userData) => {
  // TODO check isOnList !
  return userData?.isHashtagApprover;
};
//
export const canUserComment = (webUser, serverWisdom) => {
  if (!webUser) return false;

  var userCount = {};
  userCount[webUser?.authorName] = 0;
  userCount[webUser?.ownerName] = 0;

  serverWisdom.comments.forEach((userComment) => {
    const name = userComment.split(":")[0].trim();
    try {
      userCount[name]++;
    } catch (err) {
      userCount[name] = 1;

      console.log(
        "canUserComment>ERROR!",
        userComment,
        ",",
        name,
        ",",
        userCount[name]
      );
    }
    // console.log(
    //   "canUserComment>",
    //   userComment,
    //   ",",
    //   name,
    //   ",",
    //   userCount[name]
    // );
  });
  return (
    !isUserOwner(webUser) ||
    (isUserWisdomAuthor(webUser, serverWisdom) &&
      userCount[webUser?.authorName] < WS_COMMENTS_BY_WISDOM_AUTHOR) ||
    (isUserWisdomOwner(webUser, serverWisdom) &&
      userCount[webUser?.ownerName] < WS_COMMENTS_BY_WISDOM_OWNER)
  );

  // return (
  //   !isUserOwner(webUser) ||
  //   (isUserWisdomAuthor(webUser, serverWisdom) &&
  //     WS_COMMENTS_BY_WISDOM_AUTHOR) ||
  //   (isUserWisdomOwner(webUser, serverWisdom) && WS_COMMENTS_BY_WISDOM_OWNER)
  // );
};

export const canAuthorMintWisdom = (wisdom) => {
  // isOnList()
  if (isOnList(wisdom?.authorId, perm.NOMINT_WISDOM.name)) {
  }
}; // can author mint wisdom

export const canAuthorProposeImage = (wisdom) => {
  // isOnList()
}; // can author propose image

//
export const isUserOwner = (userData) => {
  // TODO compare userData.wallet to wisdom (any?) contract owner!
  // if (userData) {
  //   //console.log("auth.isUserOwner>", userData?.isOwner);
  //   return userData?.isOwner || false;
  // } else {

  // const { library } = useWeb3React();
  // if (library) {
  //   const signer = library.getSigner();
  //   console.log("isUserOwner>signer=", signer);
  // }

  if (!userData?.name) {
    userData = loadWebUser();
  }
  // if (!userData ) {
  //   console.log("isUserOwner>user is NULL");
  // } else {
  //   console.log("isUserOwner>user.isOwner", userData.isOwner, userData.name);
  // }
  // if (ownerWallet) {
  //   console.log("isUserOwner>ownerWallet=", ownerWallet);
  //   console.log("isUserOwner>", userData?.isOwner || false);
  // }
  if (!ownerWallet) return userData?.isOwner || false;

  return userData?.wallet === ownerWallet;

  // }
}; // is user owner
//
export const canUserInvite = (userData) => {
  // TODO check isOnList, invitesRemaining
  return true;
};
//
export const canUserMintHashtags = (userData) => {
  // TODO check isOnList !
  return true;
};
//
export const canUserMintWisdoms = (userData) => {
  // TODO check isOnList !
  return true;
};
//

//

export const getWisdomsProposedCount = (userData) => {
  // if (userData) {
  //   return userData.result.wisdomsProposed.length;
  // } else {
  return userData?.wisdomsProposed?.length;
  // }
};

export const getWisdomsMintedCount = (userData) => {
  // if (userData) {
  //   return userData.result.wisdomsMinted.length;
  // } else {
  return userData?.wisdomsMinted?.length;
  // }
};

export const getWisdomsOwnedCount = (userData) => {
  // if (userData) {
  //   return userData.result.wisdomsOwned.length;
  // } else {
  return userData?.wisdomsOwned?.length;
  // }
};

export const getHashtagsMintedCount = (userData) => {
  // if (userData) {
  //   return userData.result.hashtagsMinted.length;
  // } else {
  return userData?.hashtagsMinted.length;
  // }
};

export const getHashtagsOwnedCount = (userData) => {
  // if (userData) {
  //   return userData.result.hashtagsOwned.length;
  // } else {
  return userData?.hashtagsOwned.length;
  // }
};

export const getUserCount = (userData, fieldName) => {
  // if (userData) {
  //   return userData.result[fieldName].length;
  // } else {
  return userData[fieldName].length;
  // }
};

//
// contract functions
//
