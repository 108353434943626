import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 200;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },

  FAQ__question: {
    background: "none",
    border: "none",
    width: "100%",
    textAlign: "left",
    //fontSize: "18px",
    padding: "1rem",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    fontSize: "1.5vw",

    [theme.breakpoints.down("sm")]: {
      fontSize: "4vw", // <600
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw", // >600
    },
    [theme.breakpoints.up("800")]: {
      fontSize: "2vw", // >800
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8vw", // >900
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4vw", // >1200
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.9vw", // >1536
    },
    [theme.breakpoints.up("1800")]: {
      fontSize: "1.1vw", // >1800
    },
  },

  FAQ__answer: {
    //fontSize: "20px",
    fontSize: "1.7vw",

    [theme.breakpoints.down("sm")]: {
      fontSize: "4vw", // <600
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw", // >600
    },
    [theme.breakpoints.up("800")]: {
      fontSize: "2vw", // >800
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8vw", // >900
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4vw", // >1200
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.9vw", // >1536
    },
    [theme.breakpoints.up("1800")]: {
      fontSize: "1.1vw", // >1800
    },
  },

  FAQ__answer__hidden: {
    display: "none",
  },

  FAQs: {
    fontFamily: "Courier",
    fontSize: "12px",
  },
  responsiveFont: {
    fontSize: "1.5vw",

    [theme.breakpoints.down("sm")]: {
      fontSize: "4vw", // <600
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw", // >600
    },
    [theme.breakpoints.up("800")]: {
      fontSize: "2vw", // >800
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8vw", // >900
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4vw", // >1200
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.9vw", // >1536
    },
    [theme.breakpoints.up("1800")]: {
      fontSize: "1.1vw", // >1800
    },
  },
}));
