import React, { Component } from "react";
import { SellWisdomModal } from "../SellWisdomModal";
import TriggerButton from "../../TriggerButton";
//
export class SellWisdomModalContainer extends Component {
  state = { isShown: false };
  showModal = () => {
    this.setState({ isShown: true }, () => {
      this.closeButton.focus();
    });
    this.toggleScrollLock();
  };
  closeModal = () => {
    this.setState({ isShown: false });
    this.TriggerButton.focus();
    this.toggleScrollLock();
  };
  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  };
  onClickOutside = (event) => {
    if (this.modal && this.modal.contains(event.target)) return;
    this.closeModal();
  };

  toggleScrollLock = () => {
    document.querySelector("html").classList.toggle("scroll-lock");
  };
  render() {
    return (
      <React.Fragment>
        <TriggerButton
          showModal={this.showModal}
          buttonRef={(n) => (this.TriggerButton = n)}
          triggerText={this.props.triggerText}
          toolTipText="Make this Wisdom for sale"
          style={{
            border: "none",
            backgroundColor: "transparent",
            outline: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
        />
        {this.state.isShown ? (
          <SellWisdomModal
            onSubmit={this.props.onSubmit}
            modalRef={(n) => (this.modal = n)}
            buttonRef={(n) => (this.closeButton = n)}
            closeModal={this.closeModal}
            onKeyDown={this.onKeyDown}
            onClickOutside={this.onClickOutside}
            onChange={this.props.onChange}
            data={this.props.data}
            sellCharge={this.props.sellCharge}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default SellWisdomModalContainer;
