import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  fontSlider: {
    //height: 270,
    height: 60,
    width: 300,
    //margin: 20,
    alignContent: "center",
    // [theme.breakpoints.down("sm")]: {
    //   width: 250, // <600
    // },
    [theme.breakpoints.up("sm")]: {
      width: 130, // >600
    },
    [theme.breakpoints.up("800")]: {
      width: 150, // >800
    },
    [theme.breakpoints.up("1000")]: {
      width: 130, // md>900
    },
    [theme.breakpoints.up("lg")]: {
      width: 180, // >1200
    },
    // [theme.breakpoints.up("1400")]: {
    //   width: 200, // xl >1536
    // },
    [theme.breakpoints.up("1600")]: {
      width: 250, // xl >1536
    },
    [theme.breakpoints.up("1930")]: {
      width: 300, // >1930
    },
  },
  fontSliderInput: {
    width: 42,
  },
}));
