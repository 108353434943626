import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  [theme.breakpoints.down("sm")]: {
    mainContainer: {
      flexDirection: "column-reverse",
    },
  },
  appBar: {
    borderRadius: 15,
    margin: "30px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    color: "rgba(0,183,255, 1)",
  },
  image: {
    marginLeft: "15px",
  },
  root: {
    display: "flex",
  },
  responsiveFont: {
    fontSize: "1.5vw",

    [theme.breakpoints.down("sm")]: {
      fontSize: "4vw", // <600
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw", // >600
    },
    [theme.breakpoints.up("800")]: {
      fontSize: "2vw", // >800
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8vw", // >900
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4vw", // >1200
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.9vw", // >1536
    },
    [theme.breakpoints.up("1800")]: {
      fontSize: "1.1vw", // >1800
    },
  },
}));
