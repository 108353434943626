import {
  FETCH_ALL_MINTED,
  FETCH_ALL_PROPOSED,
  FETCH_BY_SEARCH,
  FETCH_BY_HASHTAG,
  FETCH_WISDOM,
  PROPOSE_WISDOM,
  UPDATE_WISDOM,
  DELETE_WISDOM,
  LIKE_WISDOM,
  HIDE_WISDOM,
  UNHIDE_WISDOM,
  COMMENT,
  START_LOADING,
  STOP_LOADING,
  NEED_SIGNIN,
  APPROVE_WISDOM,
  DECLINE_WISDOM,
  MINT_WISDOM,
  CANCEL_WISDOM,
  VOTE_WISDOM,
  //  FETCH_AUTHORS,
} from "../constants/actionTypes";

export default (
  state = {
    isLoading: true,
    needSignin: false,
    wisdoms: [],
    reload: false,
    coinInCurrency: 0,
    currentPage: 1,
    numberOfPages: 1,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true, reload: false };
    case STOP_LOADING: {
      //console.log("reducer:wisdooms.STOP_LOADING>");
      return { ...state, isLoading: false, reload: false, needSignin: false };
    }
    case NEED_SIGNIN: {
      //console.log("reducer:wisdooms.NEED_SIGNIN>");
      return { ...state, isLoading: false, reload: false, needSignin: true };
    }
    case FETCH_BY_SEARCH:
    case FETCH_BY_HASHTAG:
    case FETCH_ALL_PROPOSED:
    case FETCH_ALL_MINTED:
      return {
        ...state,
        wisdoms: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        coinInCurrency: action.payload.coinInCurrency,
        reload: false,
      };
    // case FETCH_ALL_PROPOSED:
    //   return {
    //     ...state,
    //     wisdoms: state.wisdoms.map((post) =>
    //       post.status === action.payload.status ? action.payload : post
    //     ),
    //     currentPage: action.payload.currentPage,
    //     numberOfPages: action.payload.numberOfPages,
    //   };
    // case FETCH_BY_AUTHOR:
    //   return { ...state, wisdoms: action.payload.data };
    case FETCH_WISDOM:
      return { ...state, post: action.payload.post };
    case APPROVE_WISDOM:
      return { ...state, post: action.payload.post };
    case DECLINE_WISDOM:
      return { ...state, post: action.payload.post };
    case MINT_WISDOM:
      return { ...state, post: action.payload.post };
    case CANCEL_WISDOM:
      return { ...state, post: action.payload.post };

    case HIDE_WISDOM:
    case UNHIDE_WISDOM:
      return { ...state, post: action.payload.post };

    case LIKE_WISDOM:
      return {
        ...state,
        wisdoms: state.wisdoms.map((wisdom) =>
          wisdom._id === action.payload._id ? action.payload : wisdom
        ),
      };
    case VOTE_WISDOM:
      // refresh wisdom in state with new version returned by voteWisdom
      // maybe not needed w/ SSE in place
      return {
        ...state,
        wisdoms: state.wisdoms.map((wisdom) =>
          wisdom._id === action.payload._id ? action.payload : wisdom
        ),
      };
    case COMMENT:
      return {
        ...state,
        wisdoms: state.wisdoms.map((post) => {
          if (post._id === +action.payload._id) {
            return action.payload;
          }
          return post;
        }),
      };
    case PROPOSE_WISDOM:
      return { ...state, wisdoms: [...state.wisdoms, action.payload] };
    case UPDATE_WISDOM:
      return {
        ...state,
        wisdoms: state.wisdoms.map((wisdom) =>
          wisdom._id === action.payload._id ? action.payload : wisdom
        ),
      };
    case DELETE_WISDOM:
      return {
        ...state,
        wisdoms: state.wisdoms.filter(
          (wisdom) => wisdom._id !== action.payload
        ),
      };
    // experiment
    // case FETCH_AUTHORS: {
    //   console.log("wisdoms.FETCH_AUTHORS", action?.data);
    //   return {
    //     ...state,
    //     users: action.payload.data,
    //     currentPage: action.payload.currentPage,
    //     numberOfPages: action.payload.numberOfPages,
    //     reload: false,
    //   };
    // }

    default:
      //console.log("reducer:wisdoms>default action.type-", action.type);
      return state;
  }
};
