//
//
//
import * as actionType from "../constants/actionTypes";
//import { PROFILE } from "../constants/constants";
import {
  // getDisplayProfileValue,
  // loadWebUser,
  // setProfileValue,
  //saveProfile,
  saveProfileString,
  clearMyStorage,
} from "../components/myLocalStorage";

const authReducer = (state = { authData: null }, action) => {
  switch (action.type) {
    case actionType.AUTH:
      //console.log("authReducer>storing PROFILE user=", action?.data);
      // .result = serverUser
      // .token
      // TODO... need to read current wallet address and store it on profile
      //7/26/22 experiment await & saveProfile instead of localStorage
      //await saveProfile(PROFILE, action?.data);
      saveProfileString(JSON.stringify({ ...action?.data }));
      //localStorage.setItem(PROFILE, JSON.stringify({ ...action?.data }));

      // console.log(
      //   "authReducer>profile=",
      //   JSON.parse(localStorage.getItem(PROFILE))
      // );
      return { ...state, authData: action.data, loading: false, errors: null };
    //
    case actionType.LOGOUT:
      //console.log("authReducer>clear");
      //localStorage.clear();
      clearMyStorage();

      return { ...state, authData: null, loading: false, errors: null };

    default:
      //console.log("reducer:auth>default action.type-", action.type);
      return state;
  }
};

export default authReducer;
