import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Select,
  MenuItem,
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import HelpOutline from "@material-ui/icons/HelpOutline";

import { useDispatch, useSelector } from "react-redux";
import FileBase from "react-file-base64";
import { useHistory } from "react-router-dom";
//import ChipInput from "material-ui-chip-input";
import { Multiselect } from "multiselect-react-dropdown";
//import styled from "styled-components";

import ColorPicker from "../ColorPicker/ColorPicker";
// for web3 / contract
import { useWeb3React } from "@web3-react/core";
//import { injected } from "../../hooks/connectors";
import { ethers } from "ethers";
//import MakeItWisdomContract from "../../artifacts/contracts/MakeItWisdomContract.sol/MakeItWisdomContract.json";
//import { makeitwisdomaddress } from "../../config";
import WisdomContract from "../../artifacts/contracts/WisdomContract.sol/WisdomContract.json";
import { wisdomaddress } from "../../contractManifest.js";

//import WisdomCommonLib from "../../artifacts/contracts/WisdomCommonLib.sol/WisdomCommonLib.json";
//import { wisdomcommonlibaddress } from "../../config";
import * as api from "../../api/index.js";
import ReCAPTCHA from "react-google-recaptcha";
//import { RECAPTCHA_CLIENT_KEY } from "../../constants/env";

import {
  computeWisdomMaxLength,
  //  convertEthToUsd,
  //  getCoinInUsd,
  //  getCoinInUsdNow,
  isUserWisdomOwner,
  updateWisdom,
  // delay,
} from "../../actions/wisdoms";
//
import { findHashtagByText, getHashtags } from "../../actions/hashtags";

import fontNames from "../../constants/fontNames";
import {
  DECLINED_STRING,
  DISPLAY_FREE_HASHTAGS_ONLY,
  DISPLAY_WISDOM_TYPE,
  COIN_ABBR_LABEL,
  MINTED_STRING,
  PROPOSED_STRING,
  USD_ABBR_LABEL,
  WISDOM_MINT_PRICE_BASE,
  MAX_IMAGE_SIZE,
  COIN_LABEL,
  DO_LOG,
  STATUS_CODE_WISDOM_NOT_UNIQUE,
} from "../../constants/constants";

import useStyles from "./styles";
import FontSlider from "../Widgets/FontSlider";
import * as perm from "../../constants/permissions.js";
//import { isOnList, isOnEitherList } from "../Settings/Permissions";
import { isUserOwner } from "../../actions/users";
import {
  getDisplayProfileValue,
  loadWebUser,
  setDisplayProfileValue,
} from "../myLocalStorage";
import { isOnList } from "../../constants/permissions";
import { isLocalhost } from "../serviceWorker";
//import SellWisdomModalContainer from "../Forms/SellWisdomModalContainer";

//import { DECLINE_WISDOM } from "../../constants/actionTypes";
import AlertBanner from "../AlertBanner/AlertBanner.js";
import { getMyBCConfig } from "../../configs/multiConfig.mjs";
// 11/6/22 fileuploader submits form when image button pressed, so original FileBase used instead
//import FileUploader from "./FileUploader";
//import { randomInt } from "crypto";

const doLog = false;
const DEFAULT_FONT_SIZE = 20;
const MIN_FONT_SIZE = 14;
const MAX_FONT_SIZE = 30;
//var maxLength = MAX_WISDOM_LENGTH_DEFAULT;

//const DEFAULT_BORDER_COLOR = "#000";

//

const { networkName } = getMyBCConfig();
var wisdomContract;

const WisdomForm = ({
  currentId,
  setCurrentId,
  displayWisdomType,
  editWisdom,
  coinInCurrencyParent,
}) => {
  var mintPriceUsd = 0;
  const [messageError, setMessageError] = useState(false);
  const [wisdomContractLoadingState, setWisdomContractLoadingState] = useState(
    "wisdomContractLoading"
  );
  // const [coinInCurrency2, setCoinInCurrency2] = useState(
  //   useSelector((state) => state.wisdoms.coinInCurrency)
  // );
  //
  //  const [coinInCurrency2] = useState(coinInCurrencyParent);
  if (DO_LOG)
    console.log(
      "WisdomForm>passed in coinInCurrencyParent=",
      coinInCurrencyParent
    );
  const isMounted = useRef();
  // try to retrieve the serverWisdom used for this page from STATE

  if (doLog)
    console.log(
      "WisdomForm>currentId=",
      currentId,
      "wisdomContractLoading",
      wisdomContractLoadingState
    );
  const serverWisdom = useSelector((state) => {
    if (doLog)
      console.log(
        "WisdomForm>wisdomCount=",
        state.wisdoms.wisdoms.length,
        "",
        state.wisdoms.wisdoms[0]?._id
      );
    return currentId !== "0"
      ? state.wisdoms.wisdoms.find((wisdom) => wisdom._id === currentId)
      : editWisdom;
  });

  //  pull coin in currency from state.wisdoms,
  //    which is returned w/ every Wisdoms fetched

  //  var coinInCurrency = coinInCurrencyParent; // old way ??
  // var coinInCurrency =
  //   useSelector((state) => {
  //     if (DO_LOG )
  //       console.log(
  //         "WisdomForm.useSelector>coinInCurrency",
  //         state?.wisdoms?.coinInCurrency
  //       );
  //     return Number(state.wisdoms.coinInCurrency);
  //   }) || 0;

  //
  if (doLog)
    console.log(
      "WisdomForm>currentId=",
      currentId,
      "serverWisdom.wisdomId=",
      serverWisdom?.wisdomId,
      "serverWisdom.wisdomText=",
      serverWisdom?.wisdomText,
      "serverWisdom._id=",
      serverWisdom?._id
    );
  if (doLog)
    console.log(
      "WisdomForm>currentId=",
      currentId,
      "editWisdom.wisdomId=",
      editWisdom?.wisdomId,
      "editWisdom.wisdomText=",
      editWisdom?.wisdomText,
      "editWisdom._id=",
      editWisdom?._id
    );

  //
  // state.wisdoms.coinInCurrency is returned by server w/ all Wisdom chunks
  //console.log("WisdomForm.coinInCurrency>state.wisdoms", state.wisdoms);
  //var { coinInCurrency } = useSelector((state) => state.wisdoms);
  // if (doLog && Math.random() > 0.8 && coinInCurrencyParent) {
  //   console.log("WisdomForm.coinInCurrency[176]>", coinInCurrencyParent);
  // }
  // if (DO_LOG)
  //   console.log("WisdomForm.coinInCurrency[179]>", coinInCurrencyParent);
  const [backgroundColor, setBackgroundColor] = useState("#DBB");
  const [fontColor, setFontColor] = useState("#000");
  const [fontFamily, setFontFamily] = useState("QuickSand");
  const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
  //  const [hashtagArray, setHashtagArray] = useState([]);
  const [freeHashtagsOnly, setFreeHashtagsOnly] = useState(
    getDisplayProfileValue(DISPLAY_FREE_HASHTAGS_ONLY, false)
  );
  //  const [imageFilename, setImageFilename] = useState(false);

  const [wisdomMintPriceEth, setWisdomMintPriceEth] = useState(
    WISDOM_MINT_PRICE_BASE
  );
  const [wisdomMintPriceBaseEth, setWisdomMintPriceBaseEth] = useState(
    WISDOM_MINT_PRICE_BASE
  );
  const [wisdomMintPriceUsd, setWisdomMintPriceUsd] = useState(0);
  //const [borderColor, setBorderColor] = useState(DEFAULT_BORDER_COLOR);

  // init default wisdom in case user builds a new one to propose
  if (doLog)
    console.log(
      "WisdomForm.setWisdomData>wisdomText=",
      serverWisdom?.wisdomText
    );
  const [wisdomData, setWisdomData] = useState({
    // title: "",
    _id: serverWisdom?._id,
    wisdomText: serverWisdom?.wisdomText || "",
    hashtagTexts: serverWisdom?.hashtagTexts || [],
    imageFile: serverWisdom?.imageFile || "",
    rank: serverWisdom?.rank || 0,
    backgroundColor: serverWisdom?.backgroundColor || "#DBB",
    fontColor: serverWisdom?.fontColor || "#000",
    fontFamily: serverWisdom?.fontFamily || "QuickSand",
    fontSize: serverWisdom?.fontSize || DEFAULT_FONT_SIZE,
    ownerApproveOnly: false,
  });
  if (doLog) console.log("WisdomForm.setWisdomData>DONE");

  //
  //
  //
  const serverHashtags = useSelector((state) => state?.hashtags?.hashtags);
  // create field to display in dropdown
  serverHashtags.forEach((h) => {
    h.hashtagTextBump = h.hashtagText;
    if (h.wisdomMintPriceBumpEth > 0) {
      h.hashtagTextBump +=
        "  (+" + h.wisdomMintPriceBumpEth.toString() + " " + COIN_LABEL + ")";
    }
  });
  const freeServerHashtags = serverHashtags.filter(
    (h) => h.wisdomMintPriceBumpEth === 0
  );
  //  console.log("WisdomForm>state.hashtags=", serverHashtags);
  //  console.log("WisdomForm>fressHashtags=", freeServerHashtags);
  //  console.log("WisdomForm>free only=", freeHashtagsOnly);

  //const [hashtagError, setHashtagError] = useState(false);

  // for web3 / contract
  const { library, account, chainId, active } = useWeb3React();
  // hardhat
  //  const accounts = await web3.eth.getAccounts();
  //  const web3React = useWeb3React();
  //
  const dispatch = useDispatch();
  const classes = useStyles();
  const webUser = loadWebUser();
  const history = useHistory();
  // old way
  // coinInCurrency = useSelector((state) => state.wisdoms.coinInCurrency);
  // if (DO_LOG )
  //   console.log(
  //     "     WisdomForm>state.wisdoms.coinInCurrency=",
  //     coinInCurrency
  //   );

  const computeMintPrice = async (hashtagArray, _mintPriceEth) => {
    if (doLog)
      console.log("computeMintPrice>hashtag count=", hashtagArray.length);
    var mintPriceEth = _mintPriceEth; // || WISDOM_MINT_PRICE_BASE;
    //    var newCoinInCurrency = 0;

    for (let i = 0; i < hashtagArray.length; i++) {
      // retrieve hashtag to maybe bump mint price
      const hashtagText = hashtagArray[i].hashtagText;
      const hashtag = findHashtagByText(serverHashtags, hashtagText);
      mintPriceEth += hashtag?.wisdomMintPriceBumpEth;
      // console.log(
      //   "computeMintPrice>adding",
      //   hashtagText,
      //   "hashtag?.wisdomMintPriceBumpEth=",
      //   hashtag?.wisdomMintPriceBumpEth,
      //   "mintPrice=",
      //   mintPriceEth
      // );
    }
    if (doLog)
      console.log("computeMintPrice>after bumps mintPriceEth=", mintPriceEth);
    wisdomData.mintPrice = mintPriceEth;

    // if (coinInCurrency2 === 0) {
    //   newCoinInCurrency = useSelector((state) => state.wisdoms.coinInCurrency);
    //   setCoinInCurrency2(newCoinInCurrency);
    // } else {
    //   newCoinInCurrency = coinInCurrency2;
    // }

    mintPriceUsd = convertCoinToCurrencyNow(mintPriceEth, coinInCurrencyParent);
    if (doLog)
      console.log(
        "computeMintPrice>after convert mintPriceUsd=",
        mintPriceUsd,
        "wisdomMintPriceUsd=",
        wisdomMintPriceUsd
      );
    if (wisdomMintPriceUsd !== mintPriceUsd) {
      // setTimeout(() => {
      setWisdomMintPriceUsd(mintPriceUsd);
      // setWisdomMintPriceUsdStr(
      //   " ~ $" + mintPriceUsd.toFixed(2).toString() + USD_ABBR_LABEL
      // );
      if (DO_LOG) console.log("computeMintPrice>set", mintPriceUsd);
      // }, 500);
      setWisdomMintPriceEth(mintPriceEth);
    }
    if (DO_LOG) console.log("computeMintPrice>", mintPriceEth, mintPriceUsd);
  }; // compute mint Price

  const handleCloseAlert = () => {
    // changing state should cause rerender
    setAlertBanner({ ...alertBanner, showNow: false });
  };
  const alertKey = "WisdomFormAlertKey";
  const [alertBanner, setAlertBanner] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKey,
    handleClose: handleCloseAlert,
  });

  // BEGIN hashtag
  const styleMulti = {
    chips: {
      background: "#3949ab", // "#303f9f",
    },
    searchBox: {
      border: "none",
      borderBottom: "1px solid blue",
      borderRadius: "0px",
      margin: ".5rem",
    },
    multiselectContainer: {
      color: "black",
    },
  };

  // console.log(
  //   "WisdomForm>state.hashtags=",
  //   useSelector((state) => state.hashtags)
  // );

  // useSelector((state) => {
  //   console.log("WisdomForm>state=", state);
  // });

  // const {
  //   post: hashtag,
  //   hashtags,
  //   isLoading,
  // } = useSelector((state) => state.hashtags);

  //  const location = useLocation();
  //  const currentUrl = location?.pathname + location?.search;

  // END hashtag
  //
  //
  //
  //
  const convertCoinToCurrencyNow = (coinAmt, _coinInCurrency) => {
    //const cInC = coinInCurrency ? coinInCurrency : 1.4;

    //    var cinc = _coinInCurrency === 0 ? coinInCurrency2 : _coinInCurrency;
    var cinc = _coinInCurrency;
    //if (!cinc) cinc = 0;

    if (DO_LOG)
      console.log(
        "          convertCoinToCurrencyNow",
        coinAmt,
        _coinInCurrency,
        coinInCurrencyParent,
        coinAmt * cinc
      );
    return coinAmt * cinc;
  };
  //
  const clear = async () => {
    if (doLog) console.log("clear>");
    setCurrentId(0);
    // setWisdomData({ title: "", message: "", tags: [], imageFile: "" });
    setWisdomData({
      _id: "",
      wisdomText: "",
      hashtagTexts: [],
      imageFile: "",
      rank: 0,
      backgroundColor: "#DBB",
      fontColor: "#000",
      fontFamily: "QuickSand",
      fontSize: DEFAULT_FONT_SIZE,
      mintPrice: WISDOM_MINT_PRICE_BASE,
      ownerApproveOnly: false,
    });
    setBackgroundColor("#DBB");
    setFontColor("#000");
    setFontFamily("QuickSand");
    //    setTagInputText("");
    setMessageError(false);
    setWisdomMintPriceEth(wisdomMintPriceBaseEth);
    setWisdomMintPriceUsd(
      convertCoinToCurrencyNow(wisdomMintPriceBaseEth, coinInCurrencyParent)
    );
    //setWisdomMintPriceUsd(0);
  };
  //
  //
  const s = {
    recaptchaCallback: "not fired",
    recaptchaValue: "[empty]",
    recaptchaLoad: true,
    recaptchaExpired: false,
    recaptchaValid: false,
  };

  const [recaptchaState, recaptchaSetState] = useState(s);
  //11/6/22 experient
  //  const [wisdomMintPriceUsdStr, setWisdomMintPriceUsdStr] = useState(" ~ tbd");
  const _reCaptchaRef = React.createRef();
  //  var wisdomMintPriceUsdStr;
  //
  const buildSelectedHashtags = (serverWisdom) => {
    // build selected hashtags object
    var selectedHashtags = [];
    if (serverWisdom) {
      serverWisdom.hashtagTexts.map((hashtagText) => {
        selectedHashtags.push({ hashtagText: hashtagText });
        return true;
      });
      computeMintPrice(selectedHashtags, wisdomMintPriceBaseEth);
    }
    return selectedHashtags;
  };

  useEffect(() => {
    if (doLog) console.log("WisdomForm.useEffect>");
    //    console.log("useEffect>wisdomData=", wisdomData?.message,"=");
    // if (!wisdomData?.message) {
    //   clear();
    //   console.log("useEffect>after clear>");
    // }
    isMounted.current = true;
    if (isMounted.current) {
      if (serverWisdom) {
        if (doLog)
          console.log("WisdomForm.useEffect>setting serverWisdom pieces");
        setWisdomData(serverWisdom);
        setBackgroundColor(serverWisdom?.backgroundColor || "#DBB");
        setFontColor(serverWisdom?.fontColor || "#000");
        setFontFamily(serverWisdom?.fontFamily || "QuickSand");
        setFontSize(serverWisdom?.fontSize || DEFAULT_FONT_SIZE);
      } else {
        if (doLog) console.log("WisdomForm.useEffect>NO serverWisdom pieces");
      }

      // check state for minted hashtags
      // load minted hashtags if not already loaded
      //
      if (!serverHashtags || serverHashtags.length === 0) {
        //console.log("WisdomForm>NO hashtags, so load");
        dispatch(
          getHashtags({
            searchText: "",
            status: MINTED_STRING,
            ownerId: "",
            userName: "",
            ownerName: "",
            page: "0",
          })
        );
      }
      //
      // var newCoinInCurrency;
      // if (coinInCurrency2 === 0) {
      //   newCoinInCurrency = useSelector(
      //     (state) => state.wisdoms.coinInCurrency
      //   );
      //   setCoinInCurrency2(newCoinInCurrency);
      // } else {
      //   newCoinInCurrency = coinInCurrency2;
      // }

      const loadWisdomContractData = async () => {
        if (library) {
          if (DO_LOG)
            console.log("WisdomForm.loadWisdomContractData>YES library");
          //const provider = await library;
          const signer = library.getSigner();
          wisdomContract = new ethers.Contract(
            wisdomaddress,
            WisdomContract.abi,
            signer
          );

          if (!wisdomContract) {
            if (doLog)
              console.log("WisdomForm.loadWisdomContractData>NO contract");
            // alertBanner
            setAlertBanner({
              children: null,
              type: "error",
              message: "ERROR: The smart contract is not visible.",
              showNow: true,
              theKey: alertKey,
              handleClose: handleCloseAlert,
            });

            return;
          } else {
            if (doLog)
              console.log(
                "WisdomForm.loadWisdomContractData>YES wisdom contract"
              );
          }

          try {
            if (doLog)
              console.log(
                "WisdomForm.loadWisdomContractData>load _wisdomMintPrice"
              );
            var valBigInt, valEth;
            if (wisdomMintPriceBaseEth === WISDOM_MINT_PRICE_BASE) {
              valBigInt = await wisdomContract._wisdomMintPrice();

              if (!isMounted.current) {
                return;
              }
              valEth = ethers.utils.formatUnits(valBigInt.toString(), "ether");
              setWisdomMintPriceBaseEth(Number(valEth));
            } else {
              valBigInt = 0;
              valEth = wisdomMintPriceBaseEth;
            }
            if (doLog)
              console.log(
                "WisdomForm.loadWisdomContractData._wisdomMintPriceBaseEth=",
                valBigInt,
                valEth,
                Number(valEth)
              );
            computeMintPrice(
              buildSelectedHashtags(serverWisdom), // serverWisdom
              Number(valEth)
            );
            //if (valEth != wisdomMintPriceEth) setWisdomMintPriceEth(valEth);
            //        if (wisdomMintPriceUsd === 0) {
            var priceUsd = convertCoinToCurrencyNow(
              valEth,
              coinInCurrencyParent
            );

            if (priceUsd) {
              setWisdomMintPriceUsd(priceUsd);
              // setWisdomMintPriceUsdStr(
              //   " ~ $" + priceUsd.toFixed(2).toString() + USD_ABBR_LABEL
              // );
              // wisdomMintPriceUsdStr =
              //   " ~ $" + priceUsd.toFixed(2).toString() + USD_ABBR_LABEL;

              setWisdomContractLoadingState("WisdomForm.wisdomContractLoaded");
            } else {
              if (doLog)
                console.log(
                  "**** ***** WisdomForm.loadWisdomContractData>priceUsd=",
                  priceUsd
                );
            }
          } catch (error) {
            //
            console.log(
              "WisdomForm.loadWisdomContractData>Wisdom Mint Price unknown. Contract not visible"
            );

            if (!isMounted.current) {
              return;
            }

            // alertBanner
            setAlertBanner({
              children: null,
              type: "error",
              message:
                "ERROR: Be certain your wallet points to the " +
                networkName +
                " network. Wallet Disconnect, reconnect. Maybe reload this webpage.",
              showNow: true,
              theKey: alertKey,
              handleClose: handleCloseAlert,
            });
          }
          //console.log("WisdomForm.loadContractData>LOADED");
        } else {
          if (DO_LOG)
            console.log(
              "WisdomForm.loadWisdomContractData>NO library. account=",
              account,
              "\nchainId=",
              chainId,
              "\nactive",
              active
            );
          setAlertBanner({
            children: null,
            type: "error",
            message:
              "ERROR: Be certain your wallet points to the " +
              networkName +
              " network. Wallet Disconnect, reconnect. Maybe reload this webpage.",
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });
        }
      }; // load wisdom contract data

      setTimeout(async () => {
        if (
          wisdomMintPriceEth === WISDOM_MINT_PRICE_BASE
          // ||
          // Number(wisdomMintPriceUsd) === 0
        ) {
          if (DO_LOG)
            console.log(
              "WisdomForm.useEffect.setTimeout",
              wisdomMintPriceEth,
              Number(wisdomMintPriceUsd)
            );
          if (isMounted.current) {
            await loadWisdomContractData().catch(console.error);
          } else {
            if (DO_LOG)
              console.log("WisdomForm.useEffect.setTimeout>NOT MOUNTED");
          }
        }
      }, 1000);
    } // mounted

    return () => {
      isMounted.current = false;
    };
    // djf comented 3/13/22. this cause autoconnect, unable to DISconnect
    //    web3React.activate(injected, undefined, true);
  }, [
    library,
    account,
    chainId,
    serverWisdom,
    wisdomMintPriceEth,
    wisdomMintPriceUsd,
    active,
    //    buildSelectedHashtags,
    coinInCurrencyParent,
    //    computeMintPrice,
    //    convertCoinToCurrencyNow,
    dispatch,
    //    handleCloseAlert,
    serverHashtags,
    wisdomMintPriceBaseEth,
  ]);

  //
  //
  const proposeWisdomServer = async (wisdomData) => {
    if (doLog) console.log("proposeWisdomServer>formWisdom=", wisdomData);
    const webUser = loadWebUser();
    if (webUser) {
      // mintPrice returned by server in ETH if NOT sent by client
      //const mintPrice = 0.02;
      // server assigns an approver
      try {
        const newWisdom = {
          ...wisdomData,
          authorName: webUser?.name,
          fontSize: fontSize || DEFAULT_FONT_SIZE,
          //mintPrice: mintPrice,
          originExchange: wisdomaddress,
          authorWallet: webUser?.wallet,
        };
        const { data } = await api.proposeWisdom(newWisdom);

        //console.log("proposeWisdomServer>from server data=", data);
        // alert(
        //   "Thanks for proposing new Wisdom!\nYou will receive email confirmation shortly.\nInstructions for how to mint your wisdom to the blockchain will come in a second email if your proposal is approved.\nIf your proposal is declined, the second email will list the boundary condition(s) not met.\nFor more details, see Boundaries and FAQ on the menu (Close this, then menu icon top left)."
        // );
        setAlertBanner({
          children: null,
          type: "success",
          message:
            "Thanks for proposing new Wisdom!\nYou will receive email confirmation shortly.\nInstructions for how to mint your wisdom to the blockchain will come in a second email if your proposal is approved.\nIf your proposal is declined, the second email will list the boundary condition(s) not met.\nFor more details, see Boundaries and FAQ on the menu (Close this, then menu icon top left).",
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });

        //           history.push(`/wisdoms/${serverWisdom._id}`)

        return [data, false];
      } catch (err) {
        // alert(
        //   "Error while proposing new Wisdom.\nPlease reload the page and try again.\n" +
        //     err.toString()
        // );
        console.log("proposeWisdom:", err.toString());
        var errMsg =
          err.toString().indexOf(STATUS_CODE_WISDOM_NOT_UNIQUE.toString()) > 0
            ? "Error: Duplicate Wisdom text."
            : "Error while proposing new Wisdom.\nPlease reload the page and try again.\n" +
              err.toString();

        setAlertBanner({
          children: null,
          type: "error",
          message: errMsg,
          // "Error while proposing new Wisdom.\nPlease reload the page and try again.\n" +
          // err.toString(),
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
        // await delay(5000);
        return [null, false];
      } // try catch
    } else {
      // alert("Sign in, then try again");
      setAlertBanner({
        children: null,
        type: "error",
        message: "Sign in to your MakeItWisdom account.",
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
      return [null, false];
    }
  }; // propose wisdom server
  //
  //
  // const proposeWisdomContract = async (wisdomData) => {
  //   console.log("proposeWisdomContract>wisdomData=", wisdomData);
  //   //wisdomData.wisdomId = 111;
  //   var callbackCount = 0;
  //   //
  //   //
  //   if (library) {
  //     const signer = library.getSigner();
  //     const contract = new ethers.Contract(
  //       wisdomaddress,
  //       WisdomContract.abi,
  //       signer
  //     );

  //     const commonlib = new ethers.Contract(
  //       wisdomcommonlibaddress,
  //       WisdomCommonLib.abi,
  //       signer
  //     );

  //     try {
  //       // var event = WisdomContract.ProposedWisdomEvent;
  //       // event.watch(function (error, result) {
  //       //   // result contains non-indexed arguments and topics
  //       //   // given to the `Deposit` call.
  //       //   if (!error)
  //       //     console.log("proposeWisdom>event callback>result=", result);
  //       // });

  //       // listen for event emitted
  //       contract.on(
  //         "ProposedWisdomEvent",
  //         (wisdomId, owner, hashtagTexts, wisdomText) => {
  //           ++callbackCount;
  //           console.log(
  //             "ProposedWisdomEvent>callback wisdomId=",
  //             wisdomId,
  //             "wisdomText=",
  //             wisdomText
  //           );
  //           console.log(
  //             "ProposedWisdomEvent>callback wisdomId=",
  //             wisdomId.toString()
  //           );
  //           // store wisdom to server
  //           //   but just once!
  //           if (callbackCount === 1) {
  //             wisdomData.wisdomId = parseInt(wisdomId.toString());
  //             const serverWisdom = proposeWisdomServer(wisdomData);
  //           history.push(`/wisdoms/${serverWisdom._id}`)
  //           }
  //         }
  //       );

  //       const tx = await contract.proposeWisdom(
  //         wisdomData.wisdomText,
  //         wisdomData.hashtagTexts,
  //         true // auto sale on
  //       );

  //       await tx.wait();
  //       console.log("proposeWisdomContract>OK DONE. transaction=", tx);

  //       return tx;
  //     } catch (err) {
  //       if (err?.data?.message) {
  //         const i = err.data.message.indexOf("custom error");
  //         if (i !== -1) {
  //           console.log(
  //             "proposeWisdomContract>ERROR=",
  //             err?.data?.message.substring(i + 13)
  //           );
  //           alert(err?.data?.message.substring(i + 13));
  //         }
  //       }
  //       console.log("proposeWisdomContract>ERROR=", err);
  //     }
  //   } else {
  //     if ( DO_LOG) console.log("mintPioneerContract>NO LIBRARY");
  //   }
  //   return null;
  // }; // propose wisdom contract
  //
  //

  //
  //
  const handleFreeHashtagsOnlyChange = (event) => {
    setDisplayProfileValue(DISPLAY_FREE_HASHTAGS_ONLY, event.target.checked);
    setFreeHashtagsOnly(event.target.checked);
  };

  const handleProposeWisdom = async (e) => {
    //var tagsArray = [];
    e.preventDefault();
    var errorCount = 0;
    var errorMessages = [];
    //console.log("handleProposeWisdom>wisdomData=", wisdomData);
    // must get fresh in case expired
    const webUser = loadWebUser();
    if (doLog) console.log("handleProposeWisdom>webUser=", webUser);

    if (webUser?.wallet === "") {
      setMessageError(true);
      errorMessages.push("Sign in to your MakeItWisdom.com account.");
      ++errorCount;
    }

    if (wisdomData.wisdomText === "") {
      setMessageError(true);
      errorMessages.push("Enter wisdom text");
      ++errorCount;
    } else {
      const response = await api.checkAuth({ _id: webUser?._id });
      if (doLog) console.log("CheckAuth>response=", response);
      if (!response?.data.result) {
        setAlertBanner({
          children: null,
          type: "error",
          message: "Error. Please Sign Out and Sign In again.",
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
        return null;
      }
    }

    // check no special characters - no hashtags
    if (wisdomData.wisdomText.includes("#")) {
      if (doLog) console.log("handleProposeWisdom>wisdomText includes #.");
      setMessageError(true);
      errorMessages.push(
        "Wisdom may not contain #. Select hashtags from the select list on this form."
      );
      ++errorCount;
    }
    //console.log("handleProposeWisdom>wisdomText=", wisdomData.wisdomText);

    //
    if (errorCount === 0) {
      //console.log("handleSubmit>tags=", wisdomData.hashtagTexts, "=");
      if (currentId === 0) {
        // propose new wisdom
        // put hashtags into serverWisdom
        //        wisdomData.hashtagTexts = hashtagArray.map((h) => h.hashtagText);

        if (isOnList(webUser, perm.FREEMINT_WISDOM.name)) {
          if (doLog) console.log("handleProposeWisdom>ownerApproveOnly");
          wisdomData.ownerApproveOnly = true;
        }
        if (doLog) console.log("handleProposeWisdom>NEW Wisdom", wisdomData);
        // TODO: return [serverWisdom, showError]
        const [serverWisdom, showError] = await proposeWisdomServer(wisdomData);

        //const tx = await proposeWisdomContract(wisdomData);
        if (!serverWisdom) {
          //alert("Error. Please try again.");
          if (showError) {
            setAlertBanner({
              children: null,
              type: "error",
              message: "Error. Please try again.",
              showNow: true,
              theKey: alertKey,
              handleClose: handleCloseAlert,
            });
          }
          return;
        } else {
          clear();
        }
        //history.push(`/wisdoms/${wisdomData._id}`);
        // display PROPOSED
        if (displayWisdomType === MINTED_STRING) {
          if (doLog) console.log("WisdomForm>change to proposed!");
          setDisplayProfileValue(DISPLAY_WISDOM_TYPE, PROPOSED_STRING);
        }
        history.push(`/`);
      } else {
        // update wisdom
        //       if (wisdomData?.status === PROPOSED_STRING) {
        //        wisdomData.hashtagTexts = hashtagArray.map((h) => h.hashtagText);
        //        } else {
        if (wisdomData?.status === DECLINED_STRING) {
          //        wisdomData.hashtagTexts = hashtagArray.map((h) => h.hashtagText);
          wisdomData.status = PROPOSED_STRING;
        }
        //       }
        if (doLog) console.log("handleProposeWisdom>UPDATE Wisdom", wisdomData);
        dispatch(
          updateWisdom(
            currentId,
            {
              ...wisdomData,
              authorName: webUser?.name,
              fontSize: fontSize || DEFAULT_FONT_SIZE,
            },
            canUserEditWisdomText(webUser, serverWisdom)
          )
        );
        clear();
      }
    } else {
      console.log("handleProposeWisdom>errorCount=", errorCount);
      //alert(errorMessages.join("\n"));
      // error!
      setAlertBanner({
        children: null,
        type: "error",
        message: errorMessages.join("\n"),
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    }
  }; // handle submit wisdom

  // //
  // //
  // if (DO_LOG )
  //   console.log(
  //     "WisdomForm>before return.. coinInCurrency=",
  //     coinInCurrency2,
  //     ".usdStr2=",
  //     wisdomMintPriceUsdStr2
  //   );
  // possible early return
  if (!webUser?.name) {
    return (
      <Paper className={classes.paper} elevation={6}>
        <Typography variant="h6" align="center">
          Please sign in to propose and vote Wisdoms.
        </Typography>
      </Paper>
    );
  }

  // const handleAddChip = (tag) => {
  //   //console.log("handleAddChip>tag=", tag, "=");
  //   tag = tag.trim();
  //   if (tag.length > 0) {
  //     setWisdomData({
  //       ...wisdomData,
  //       hashtagTexts: [...wisdomData.hashtagTexts, tag.trim()],
  //     });
  //   }
  //   setTagInputText("");
  // };

  // const handleDeleteChip = (chipToDelete) => {
  //   setWisdomData({
  //     ...wisdomData,
  //     hashtagTexts: wisdomData.hashtagTexts.filter(
  //       (tag) => tag !== chipToDelete
  //     ),
  //   });
  // };

  // const handleUpdateInput = (event) => {
  //   //console.log("handleUpdateInput>=", event.target.value, "=");
  //   setTagInputText(event.target.value);
  //   setWisdomData({
  //     ...wisdomData,
  //     hashtagTexts: event.target.value,
  //   });
  // };

  const handleFontFamilyChange = (event) => {
    //console.log("handleFontFamilyChange=", event.target.value);
    setFontFamily(event.target.value);
    //console.log("handleFontFamilyChange=", event.target.value);
    setWisdomData({
      ...wisdomData,
      fontFamily: event.target.value,
    });
  };
  //

  const handleFontSizeChange = (_fontSize) => {
    setFontSize(_fontSize);
    //console.log("handleFontSizeChange=", _fontSize);
    setWisdomData({
      ...wisdomData,
      fontSize: _fontSize,
    });
  };

  function handleHashtagChange(event) {
    //NOPE event.preventDefault();
    //console.log("handleHashtagChange>", event);
    //var mintPriceEth = WISDOM_MINT_PRICE_BASE;
    const hashtags = [];
    for (let i = 0; i < event.length; i++) {
      //hashtags.push(event[i].hashtagText);
      hashtags.push({ hashtagText: event[i].hashtagText });
    }
    wisdomData.hashtagTexts = hashtags.map((h) => h.hashtagText);

    //    setHashtagArray(hashtags);
    computeMintPrice(hashtags, wisdomMintPriceBaseEth);
  } // handle Hashtag Change;

  // function handleHashtagChange(event) {
  //   console.log("changeHashtagFilter>", event);
  //   var mintPriceEth = WISDOM_MINT_PRICE_BASE;
  //   const hashtags = [];
  //   let i = 0;
  //   for (i = 0; i < event.length; i++) {
  //     hashtags.push(event[i].hashtagText);
  //     // retrieve hashtag to maybe bump mint price
  //     const hashtag = findHashtagByText(serverHashtags, event[i].hashtagText);
  //     mintPriceEth += hashtag?.wisdomMintPriceBumpEth;
  //     console.log(
  //       "handleHashtagChange>adding",
  //       event[i].hashtagText,
  //       "hashtag?.wisdomMintPriceBumpEth=",
  //       hashtag?.wisdomMintPriceBumpEth,
  //       "mintPrice=",
  //       mintPriceEth
  //     );
  //   }
  //   //if (reRender) reRender();
  //   wisdomData.mintPrice = mintPriceEth;
  //   const mintPriceUSD = convertEthToUsd(mintPriceEth);
  //   setWisdomMintPriceUsd(mintPriceUSD);
  //   setWisdomMintPriceEth(mintPriceEth);
  //   setHashtagArray(hashtags);
  //   //setForceReload(true);
  // }

  // const handleBorderColorChange = (_borderColor) => {
  //   setBorderColor(_borderColor);
  //   //console.log("handleBorderColorChange=", _borderColor);
  //   setWisdomData({
  //     ...wisdomData,
  //     borderColor: _borderColor,
  //   });
  // };
  //const isDisabledNow = !webUser;
  const canUserEditWisdom = (webUser, serverWisdom) => {
    if (!webUser) webUser = loadWebUser();
    if (!currentId) return true;
    if (!webUser || !serverWisdom) return false; // || !serverWisdom)
    if (isUserWisdomOwner(webUser, serverWisdom)) return true;
    if (isUserOwner(webUser)) return true;
    return false;
  };

  const canUserEditWisdomText = (webUser, serverWisdom) => {
    if (!webUser) webUser = loadWebUser();
    if (!currentId) return true;
    if (!webUser || !serverWisdom) return false; // || !serverWisdom
    if (isUserWisdomOwner(webUser, serverWisdom)) {
      if (serverWisdom.status === PROPOSED_STRING) return true;
    }
    if (isUserOwner(webUser)) return true;
    return false;
  };

  const canUserProposeImage = (webUser) => {
    if (!webUser) webUser = loadWebUser();
    if (isOnList(webUser, perm.DENY_PROPOSE_IMAGE.name)) return false;
    if (doLog)
      console.log(
        "canUserProposeImage>NOT isOnList",
        perm.DENY_PROPOSE_IMAGE.name
      );
    if (!webUser) return false;
    if (!currentId) return true;
    //if (isUserOwner(webUser)) return true;
    return false;
  };

  const canUserEditWisdomHashtags = (webUser, serverWisdom) => {
    if (!webUser) webUser = loadWebUser();
    if (!webUser || !serverWisdom) return false; // || !serverWisdom
    if (!currentId) return true;
    if (isUserWisdomOwner(webUser, serverWisdom)) {
      if ([PROPOSED_STRING, DECLINED_STRING].indexOf(serverWisdom.status) > -1)
        return true;
    }
    //if (isUserOwner(webUser)) return true;
    return false;
  };
  //  maxLength = computeMaxLength;
  // if (canUserEditWisdomText(webUser, serverWisdom)) {
  //   console.log("WisdomForm>user CAN edit WisdomText");
  // } else {
  //   console.log("WisdomForm>user CAN NOT edit WisdomText");
  // }
  //

  var selectedHashtags = buildSelectedHashtags(serverWisdom);
  //
  const handleRecaptchaChange = async (value) => {
    //console.log("Captcha value:", value);
    // if value is null recaptcha expired
    if (value === null) {
      recaptchaSetState({
        recaptchaValue: value,
        recaptchaExpired: true,
        recaptchaValid: false,
      });
    } else {
      recaptchaSetState({
        recaptchaValue: value,
        recaptchaExpired: false,
        recaptchaValid: true,
      });
      // make server call to verify score
      await api.vcha({ token: value });
      //const { data } =
      // TODO: confirm success
      //console.log("handleRecaptchaChange>", data);
    }
  };

  const asyncScriptOnLoad = () => {
    recaptchaSetState({ recaptchaCallback: "called!" });
    //console.log("scriptLoad - reCaptcha Ref-", _reCaptchaRef);
  };

  const {
    // recaptchaValue,
    // recaptchaCallback,
    // recaptchaLoad,
    // recaptchaExpired,
    recaptchaValid,
  } = recaptchaState || {};

  // var wisdomMintPriceUsdStr = " ~ $";
  // wisdomMintPriceUsdStr +=
  //   getCoinInUsd() != -1
  //     ? wisdomMintPriceUsd.toString()
  //     : (wisdomMintPriceEth * 0.9).toString();
  // wisdomMintPriceUsdStr += USD_ABBR_LABEL;

  var wisdomMintPriceUsdStr2;
  // should this be BEFORE create the state variables to compute initial values??
  if (coinInCurrencyParent > 0) {
    if (DO_LOG)
      console.log(
        "WisdomForm> ",
        "PARENT coinInCurrencyParent=",
        coinInCurrencyParent,
        "wisdomMintPriceUsd=",
        wisdomMintPriceUsd
      );
    //var appEqual = "\u2245";
    if (wisdomMintPriceUsd === 0) {
      var priceUsd = convertCoinToCurrencyNow(
        wisdomMintPriceEth,
        coinInCurrencyParent
      );
      // exp off
      //setWisdomMintPriceUsd(priceUsd);
      //
      // wisdomMintPriceUsdStr =
      //   " ~ $" + priceUsd.toFixed(2).toString() + USD_ABBR_LABEL;
      // setWisdomMintPriceUsdStr(
      //   " ~ $" + priceUsd.toFixed(2).toString() + USD_ABBR_LABEL
      // );
      wisdomMintPriceUsdStr2 =
        " ~ $" + priceUsd.toFixed(2).toString() + USD_ABBR_LABEL;
    } else {
      wisdomMintPriceUsdStr2 =
        " ~ $" + wisdomMintPriceUsd.toFixed(2).toString() + USD_ABBR_LABEL;
      // wisdomMintPriceUsdStr =
      //   " ~ $" + wisdomMintPriceUsd.toFixed(2).toString() + USD_ABBR_LABEL;
      // experiment off
      // setWisdomMintPriceUsd(
      //   " ~ $" +
      //     Number(wisdomMintPriceUsd).toFixed(2).toString() +
      //     USD_ABBR_LABEL
      // );
    }
  } else {
    wisdomMintPriceUsdStr2 = "  ***";
    if (DO_LOG)
      console.log("WisdomForm>PARENT coinInCurrency=", coinInCurrencyParent);
  }

  const imageExtAllowed = ["gif", "png", "jpg", "jpeg", "svg"];
  if (doLog)
    console.log("wisdomForm>webUser", webUser, "serverWisdom>", serverWisdom);
  //
  //if (serverWisdom) handleFontSizeChange(serverWisdom?.fontSize);
  if (doLog) console.log("WisdomForm>ready to return component");
  //
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const fileImageHelp =
    "OPTIONALLY upload 1 family friendly image file of types:" +
    imageExtAllowed.join(", ") +
    ". The max file size is " +
    numberWithCommas(MAX_IMAGE_SIZE).toString() +
    " bytes.";
  //

  //
  //
  if (DO_LOG)
    console.log(
      "WisdomForm>before return.. coinInCurrencyParent=",
      coinInCurrencyParent,
      "wisdomMintPriceUsdStr2=",
      wisdomMintPriceUsdStr2
    );

  //
  return (
    <Paper className={classes.paper} elevation={6}>
      <form
        id="proposeWisdom"
        autoComplete="off"
        noValidate
        className={`${classes.root} ${classes.form}`}
        // onClick={(event) => {
        //   console.log("WisdomForm>onClick", event);
        //   //document.getElementById("proposeWisdom").submit();
        // }}
        onSubmit={(event) => {
          if (DO_LOG) console.log("WisdomForm>onSubmit");
          handleProposeWisdom(event);
        }}
      >
        <AlertBanner {...alertBanner}></AlertBanner>

        <Typography variant="h6">
          {currentId ? "Editing a Wisdom" : "Propose New Wisdom"}
          <Tooltip
            placement="top"
            title={
              <h2>
                After you propose a Wisdom (for free), we'll approve/decline
                (~24 hours). After you mint your approved Wisdom (for a fee),
                others may enjoy, buy and sell it.
              </h2>
            }
          >
            <button
              className={classes.uploadBtnHlp}
              type="button"
              onClick={() =>
                setAlertBanner({
                  children: null,
                  type: "success",
                  message:
                    "After you propose a Wisdom (for free), we'll approve/decline (~24 hours). After you mint your approved Wisdom (for a fee), others may enjoy, buy and sell it.",
                  showNow: true,
                  theKey: alertKey,
                  handleClose: handleCloseAlert,
                })
              }
            >
              <HelpOutline />
            </button>
          </Tooltip>
        </Typography>

        {/* <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item> */}
        <div>
          <TextField
            key="WisdomTextField"
            id="proposeWisdomText"
            className={classes.inputField}
            name="wisdomText"
            variant="filled"
            disabled={!canUserEditWisdomText(webUser, serverWisdom)}
            //fullWidth
            multiline
            minRows={5}
            inputProps={{
              maxLength: computeWisdomMaxLength(),
              style: {
                color: fontColor,
                backgroundColor: backgroundColor,
                // padding: "5px 5px",
                // margin: "10px 10px",
                padding: "15px 15px",
                margin: "0px 10px",
                borderRadius: "8px",
                textAlign: "center",
                lineHeight: "1.0",
                fontSize: fontSize || DEFAULT_FONT_SIZE,
                fontFamily: fontFamily,
              },
            }}
            label="Wisdom"
            value={wisdomData?.wisdomText}
            error={messageError}
            onChange={(e) => {
              // console.log(
              //   "proposeWisdomForm>onChange.wisdomText=",
              //   e.target.value
              // );
              setWisdomData({ ...wisdomData, wisdomText: e.target.value });
            }}
          />
        </div>
        <div>
          {canUserEditWisdom(webUser, serverWisdom) && (
            <FontSlider
              handleFontSizeChange={handleFontSizeChange}
              initValue={serverWisdom?.fontSize || DEFAULT_FONT_SIZE}
              minValue={MIN_FONT_SIZE}
              maxValue={MAX_FONT_SIZE}
              defaultValue={DEFAULT_FONT_SIZE}
            />
          )}
        </div>
        <br />
        {canUserEditWisdom(webUser, serverWisdom) && (
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Select
                onChange={handleFontFamilyChange}
                value={fontFamily}
                variant="outlined"
              >
                {fontNames.map((element) => (
                  <MenuItem
                    value={element[Object.keys(element)] + ""}
                    key={element[Object.keys(element)] + ""}
                  >
                    {element[Object.keys(element)] + ""}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Tooltip title={<h2>Set background color</h2>} placement="top">
              <Grid item>
                <ColorPicker
                  name="backgroundColor"
                  buttonLabel="Back"
                  // defaultValue={backgroundColor}
                  value={backgroundColor}
                  disabled={!canUserEditWisdom(webUser, serverWisdom)}
                  classes={classes}
                  destination="background"
                  hintText="background color"
                  // value={this.state.color} - for controlled component
                  // setShowPicker={(trufalse) => {
                  //   console.log("setShowPicker=", trufalse);
                  // }}
                  onChange={(color) => {
                    if (color) {
                      setBackgroundColor(color.toString());
                      //console.log("backgroundColor=", color.toString());
                      setWisdomData({
                        ...wisdomData,
                        backgroundColor: color.toString(),
                      });
                    }
                  }}
                />
              </Grid>
            </Tooltip>
            <Tooltip title={<h2>Set text color</h2>} placement="top">
              <Grid item>
                <ColorPicker
                  name="fontColor"
                  buttonLabel="Font"
                  classes={classes}
                  destination="font"
                  hintText="font color"
                  value={fontColor}
                  // value={this.state.color} - for controlled component
                  // setShowPicker={(trufalse) => {
                  //   console.log("setShowPicker=", trufalse);
                  // }}
                  onChange={(color) => {
                    if (color) {
                      setWisdomData({
                        ...wisdomData,
                        fontColor: color,
                      });
                      setFontColor(color.toString());
                      //console.log("fontColor=", fontColor, color);
                      //console.log("fontColor=", color.toString());
                    }
                  }}
                />
              </Grid>
            </Tooltip>
          </Grid>
        )}

        <div style={{ padding: "5px 0", width: "94%" }}>
          <Multiselect
            options={freeHashtagsOnly ? freeServerHashtags : serverHashtags}
            selectedValues={selectedHashtags}
            selectionLimit="1"
            //selectedValues={[{ hashtagText: serverHashtags[0]?.hashtagText }]}
            style={styleMulti}
            placeholder="Select Hashtags"
            onSelect={handleHashtagChange} // Function will trigger on select event
            onRemove={handleHashtagChange} // Function will trigger on remove event
            // onKeyPressFn={this.onKeyPress} //
            displayValue="hashtagTextBump"
            disable={
              !canUserEditWisdomHashtags(webUser, wisdomData || serverWisdom)
            }
            showCheckbox={true}
          />

          <Tooltip
            placement="top"
            title={
              <h2>
                Limit hashtag list to those that don't charge extra to mint.
              </h2>
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={freeHashtagsOnly}
                  onChange={handleFreeHashtagsOnlyChange}
                />
              }
              label="List Free Hashtags Only"
            />
          </Tooltip>
          <Typography variant="h6" align="left" key="MintPriceLabel">
            Mint price: {wisdomMintPriceEth} {COIN_ABBR_LABEL}
            {wisdomMintPriceUsdStr2}
          </Typography>
        </div>
        {/* </Grid> */}
        {/* <Grid item>
            <FontSlider
              handleFontSizeChange={handleFontSizeChange}
              minValue={MIN_FONT_SIZE}
              maxValue={MAX_FONT_SIZE}
              defaultValue={DEFAULT_FONT_SIZE}
            />
          </Grid> */}
        {/* </Grid> */}
        {!canUserProposeImage(webUser) && (
          <Typography variant="h6" align="left">
            No Image
          </Typography>
        )}
        <div className={classes.fileInput}>
          {canUserEditWisdomText(webUser, serverWisdom) &&
            canUserProposeImage(webUser) && (
              <div className={classes.fileUploaderRow}>
                {/* <div>
                  <FileUploader
                    type="file"
                    multiple={false}
                    handleFile={(fileInfo) => {
                      console.log("WisdomForm>fileInfo = ", fileInfo);
                      const filename = fileInfo?.name.toLowerCase();
                      setImageFilename(filename);
                      const exts = filename.split(".");
                      if (exts.length === 0) {
                        setAlertBanner({
                          children: null,
                          type: "error",
                          message:
                            "Only image files allowed: " +
                            imageExtAllowed.join(", "),
                          showNow: true,
                          theKey: alertKey,
                          handleClose: handleCloseAlert,
                        });
                      }

                      //console.log("Wisdom attached filename", filename);
                      if (!imageExtAllowed.includes(exts.pop())) {
                        setAlertBanner({
                          children: null,
                          type: "error",
                          message:
                            "Only image files allowed: " +
                            imageExtAllowed.join(", "),
                          showNow: true,
                          theKey: alertKey,
                          handleClose: handleCloseAlert,
                        });
                      } else {
                        const fileSize = fileInfo?.base64.length * (3 / 4) - 2;
                        if (fileSize > MAX_IMAGE_SIZE) {
                          setAlertBanner({
                            children: null,
                            type: "error",
                            message:
                              "Maximum file size is " +
                              MAX_IMAGE_SIZE.toLocaleString().toString() +
                              " bytes",
                            showNow: true,
                            theKey: alertKey,
                            handleClose: handleCloseAlert,
                          });
                        } else {
                          setWisdomData({
                            ...wisdomData,
                            imageFile: fileInfo?.base64,
                          });
                        }
                      }
                    }}
                  />
                  &nbsp;
                  {!imageFilename && "No file selected"}
                  {imageFilename && imageFilename}
                </div> */}
                <Tooltip
                  placement="top"
                  title={
                    <h2>
                      Optionally upload 1 family friendly image file of types:{" "}
                      {imageExtAllowed.join(", ")} <br />
                      The max file size is {numberWithCommas(
                        MAX_IMAGE_SIZE
                      )}{" "}
                      bytes.
                    </h2>
                  }
                >
                  <button
                    className={classes.uploadBtnHlp}
                    type="button"
                    onClick={() =>
                      setAlertBanner({
                        children: null,
                        type: "success",
                        message: fileImageHelp,
                        showNow: true,
                        theKey: alertKey,
                        handleClose: handleCloseAlert,
                      })
                    }
                  >
                    <HelpOutline />
                  </button>
                </Tooltip>

                <FileBase
                  type="file"
                  multiple={false}
                  onDone={(fileInfo) => {
                    const filename = fileInfo?.name.toLowerCase();
                    const exts = filename.split(".");
                    if (exts.length === 0) {
                      setAlertBanner({
                        children: null,
                        type: "error",
                        message:
                          "Only image files allowed: " +
                          imageExtAllowed.join(", "),
                        showNow: true,
                        theKey: alertKey,
                        handleClose: handleCloseAlert,
                      });
                    }

                    //console.log("Wisdom attached filename", filename);
                    if (!imageExtAllowed.includes(exts.pop())) {
                      setAlertBanner({
                        children: null,
                        type: "error",
                        message:
                          "Only image files allowed: " +
                          imageExtAllowed.join(", "),
                        showNow: true,
                        theKey: alertKey,
                        handleClose: handleCloseAlert,
                      });
                    } else {
                      const fileSize = fileInfo?.base64.length * (3 / 4) - 2;
                      if (fileSize > MAX_IMAGE_SIZE) {
                        setAlertBanner({
                          children: null,
                          type: "error",
                          message:
                            "Maximum file size is " +
                            MAX_IMAGE_SIZE.toLocaleString().toString() +
                            " bytes",
                          showNow: true,
                          theKey: alertKey,
                          handleClose: handleCloseAlert,
                        });
                      } else {
                        setWisdomData({
                          ...wisdomData,
                          imageFile: fileInfo?.base64,
                        });
                      }
                    }
                  }}
                />
              </div>
            )}
        </div>

        <ReCAPTCHA
          style={{
            display: "inline-block",
            marginTop: "5px",
            marginBottom: "10px",
          }}
          theme="light"
          ref={_reCaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
          onChange={handleRecaptchaChange}
          asyncScriptOnLoad={asyncScriptOnLoad}
        />

        <Button
          className={classes.buttonSubmit}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
          disabled={
            (!canUserEditWisdom(webUser, serverWisdom) || !recaptchaValid) &&
            !isLocalhost()
          }
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={clear}
          fullWidth
          disabled={!canUserEditWisdom(webUser, serverWisdom)}
        >
          Clear
        </Button>
        <p />
      </form>
    </Paper>
  );
};

export default WisdomForm;

//
// example code
//   handle image / base64 w/o the module!
//
// import React, { useState } from "react";
// import "./App.css";

// function App() {
//   const [baseImage, setBaseImage] = useState("");

//   const uploadImage = async (e) => {
//     const file = e.target.files[0];
//     const base64 = await convertBase64(file);
//     setBaseImage(base64);
//   };

//   const convertBase64 = (file) => {
//     return new Promise((resolve, reject) => {
//       const fileReader = new FileReader();
//       fileReader.readAsDataURL(file);

//       fileReader.onload = () => {
//         resolve(fileReader.result);
//       };

//       fileReader.onerror = (error) => {
//         reject(error);
//       };
//     });
//   };

//   return (
//     <div className="App">
//       <input
//         type="file"
//         onChange={(e) => {
//           uploadImage(e);
//         }}
//       />
//       <br></br>
//       <img src={baseImage} height="200px" />
//     </div>
//   );
// }

// export default App;
