import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 200;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  seeAlsoDiv: {
    marginLeft: "10px",
  },
}));
