import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import React from "react";

import { ThemeProvider } from "styled-components";
//import { ThemeProvider } from "@material-ui/core/styles";

import { GlobalStyles } from "./components/GlobalStyle.js";
import { lightTheme, darkTheme } from "./components/Themes";
import { useDarkMode } from "./components/useDarkMode";

import { Container } from "@material-ui/core"; //, ThemeProvider
//import { createTheme } from "@material-ui/core/styles";

//import { SubjectOutlined } from "@material-ui/icons";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import WisdomDetails from "./components/WisdomDetails/WisdomDetails";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Auth from "./components/Auth/Auth";
//import AuthorOrTag from "./components/AuthorOrTag/AuthorOrTag";
//import Pioneer from "./components/Pioneer/Pioneer";
import GetStarted from "./components/GetStarted/GetStarteds";
import ResidualNFT from "./components/ResidualNFT/ResidualNFT";
import FAQs from "./components/FAQs/FAQs";
import Stats from "./components/Stats/Stats";
import Transfer from "./components/Transfer/Transfer";
import About from "./components/About/About";
import Settings from "./components/Settings/Settings";
import Pioneers from "./components/Pioneers/Pioneers";
import Boundaries from "./components/Boundaries/Boundaries";
import Invites from "./components/Invites/Invites";
import Swipe from "./components/Swipe/SwipeWisdom";

import { TemporaryDrawer } from "./components/TheDrawer/TheDrawerTemp";

import { SHOW_BETA_TWEET } from "./constants/constants.js";

import useStyles from "./styles";
//import { purple } from "@material-ui/core/colors";
import Authors from "./components/Authors/Authors";
import AuthorDetails from "./components/Authors/AuthorDetails";
import { loadWebUser } from "./components/myLocalStorage";
import NotFound from "./components/NotFound/notFound";
import CallIns from "./components/CallIn/CallIns.js";
import FilterToyHelps from "./components/FilterToyHelp/filterToyHelps.js";
//import Toggle from "./components/Toggle.js";

//
// ngrok http (3000)  (client server)
// ngrok http (5000) (server database)
// ngrok http (3100)  (alchemy / webhook)
// ngrok http (8545)  ( hardhat dev blockchain)
//
const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
};

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#3f51b5",
//     },
//     secondary: purple,
//     typography: {
//       // fontFamily: "QuickSand", "Roboto",
//       fontFamily: ["QuickSand", "Roboto", "Chilanka", "cursive"].join(","),
//       fontWeightLight: 400,
//       fontWeightRegular: 500,
//       fontWeightMedium: 600,
//       fontWeightBold: 700,
//       caption: {
//         fontFamily: "openSans", // Change a specific variant
//       },
//     },
//     overrides: {
//       TextField: {
//         root: {
//           fontSize: "10px",
//         },
//       },
//     },
//     box: {
//       wisdom: {
//         fontFamily: "Brush Script Mt",
//       },
//     },
//     // textfield: {
//     //   // fontFamily: "QuickSand", "Roboto",
//     //   fontFamily: ["Chilanka", "cursive"].join(","),
//     // },
//     //fontFamily: ["Chilanka", "cursive"].join(","),
//   },
// });

var toggleTheDrawerFcn;

const App = () => {
  const webUser = loadWebUser(); // JSON.parse(localStorage.getItem(PROFILE));
  //var webUser;
  //console.log("App>webUser=", webUser);
  const classes = useStyles();
  //  const history = useHistory();

  // const [theme, setTheme] = useState("light");
  // const themeToggler = () => {
  //   theme === "light" ? setTheme("dark") : setTheme("light");
  // };

  const [theme, mountedComponent] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const toggleTheDrawerCB = (action, fcn) => {
    if (action === "registerCB") {
      //console.log("toggleTheDrawerCB>registerCB typeof=", typeof fcn);
      toggleTheDrawerFcn = fcn;
    } else {
      //console.log("toggleTheDrawerCB>toggle1");
      if (toggleTheDrawerFcn) {
        //console.log(
        //  "toggleTheDrawerCB>toggle2 typeof=",
        //  typeof toggleTheDrawerFcn
        //);
        toggleTheDrawerFcn("left", true);
        //console.log("toggleTheDrawerCB>toggle3");
      }
    }
  }; // toggle The DrawerCB

  //console.log("App>webUser=", webUser);
  //console.log("App>window.location", window.location.href);
  if (!mountedComponent) return <div />;
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <ThemeProvider theme={themeMode}>
          <>
            <GlobalStyles />
            <Container maxWidth="xl">
              <div className={classes.root}>
                <TemporaryDrawer
                  user={webUser}
                  toggleTheDrawerCB={toggleTheDrawerCB}
                />
                <div>
                  {!SHOW_BETA_TWEET && (
                    <Navbar toggleTheDrawerCB={toggleTheDrawerCB} />
                  )}
                  <Switch>
                    <Route
                      path="/"
                      exact
                      component={() => (
                        <Redirect to="/wisdoms?pageReload=true" />
                      )}
                    />

                    <Route path="/wisdoms/proposed" exact component={Home} />
                    <Route
                      path="/wisdoms/search/:searchText/:hashtagTexts/:status/:author/:authorName/:page/:pageReload"
                      exact
                      component={Home}
                    />
                    <Route
                      path="/wisdoms/search/:searchText/:hashtagTexts/:status/:author/:authorName/:page"
                      exact
                      component={Home}
                    />
                    <Route path="/wisdoms/search" exact component={Home} />
                    <Route path="/wisdoms/hashtagText" exact component={Home} />
                    <Route path="/wisdoms" exact component={Home} />
                    <Route
                      path={[
                        "/authors/:name/:status/:page/:pageReload",
                        "/authors",
                      ]}
                      exact
                      component={Authors}
                    />
                    <Route
                      path={["/author/:id/:status"]}
                      exact
                      component={AuthorDetails}
                    />
                    {/* <Route
                    path={["/wisdoms/author/:author", "/tags/:name"]}
                    exact
                    component={AuthorOrTag}
                  /> */}
                    <Route
                      path="/wisdoms/:id"
                      exact
                      component={WisdomDetails}
                    />
                    <Route
                      path="/auth"
                      exact
                      component={() =>
                        !webUser ? <Auth /> : <Redirect to="/wisdoms" />
                      }
                    />
                    <Route path="/auth2" exact component={() => <Auth />} />
                    {/* <Route path="/pioneers" exact component={Pioneer} /> */}
                    <Route path="/getstarted" exact component={GetStarted} />
                    <Route path="/residualnft" exact component={ResidualNFT} />
                    <Route path="/faqs" exact component={FAQs} />
                    <Route path="/stats" exact component={Stats} />
                    <Route path="/transfer" exact component={Transfer} />
                    <Route path="/callins" exact component={CallIns} />
                    <Route
                      path="/filtertoyhelps"
                      exact
                      component={FilterToyHelps}
                    />
                    <Route path="/settings" exact component={Settings} />
                    <Route path="/pioneers" exact component={Pioneers} />
                    <Route path="/boundaries" exact component={Boundaries} />
                    <Route path="/invites" exact component={Invites} />
                    <Route path="/about" exact component={About} />
                    <Route path="/swipe" exact component={Swipe} />
                    <Route
                      path="/filtertoyhelps"
                      exact
                      component={FilterToyHelps}
                    />

                    {/* <Route
                    component={() => <Redirect to="http://gypcapp.com:3000" />}
                  /> */}
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </Container>
          </>
        </ThemeProvider>
      </BrowserRouter>
    </Web3ReactProvider>
  );
};

export default App;
