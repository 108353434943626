import React, { useState } from "react";
import { logEvent } from "../../api";
//import { Container, Grow, Typography } from "@material-ui/core";
//import { useDispatch } from "react-redux";
//import { useHistory, useLocation } from "react-router-dom";

import useStyles from "./styles";
// import {
//   PROPOSED_STRING,
//   MINTED_STRING,
//   DISPLAY_WISDOM_TYPE,
// } from "../../constants/constants";

// import {
//   getDisplayProfileValue,
//   setDisplayProfileValue,
// } from "../localStorage";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

function GetStarted({
  children,
  defaultOpen = [],
  open: openFromProps,
  onToggle: onToggleFromProps = () => {},
}) {
  const isControlled = () => (openFromProps ? true : false);
  const [open, setIsOpen] = useState(defaultOpen);
  const getOpen = () => (isControlled() ? openFromProps : open);
  const isOpen = (index) => {
    return getOpen().includes(index) ? true : false;
  };

  const onToggle = (index) => {
    if (isControlled()) {
      onToggleFromProps(index);
    } else {
      if (getOpen().includes(index)) {
        setIsOpen(getOpen().filter((item) => item !== index));
        logEvent({ eventName: "closeGS", data1: index.toString() });
      } else {
        setIsOpen([...getOpen(), index]);
        logEvent({ eventName: "openGS", data1: index.toString() });
      }

      onToggleFromProps(index);
    }
  };
  return (
    <dl>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          isOpen: isOpen(index),
          onToggle: () => onToggle(index),
        });
      })}
    </dl>
  );
}

function Question({ children, isOpen, answerId, onToggle }) {
  const classes = useStyles();

  return (
    <dt>
      <button
        className={classes.FAQ__question}
        aria-expanded={isOpen}
        aria-controls={answerId}
        onClick={onToggle}
      >
        {children(isOpen, onToggle)}
      </button>
    </dt>
  );
}

function Answer({ children, id, isOpen }) {
  // const mergedClassname = classNames("FAQ__answer", {
  //   FAQ__answer__hidden: !isOpen,
  // });
  const classes = useStyles();

  const mergedClassname = isOpen ? "FAQ__answer" : "FAQ__answer__hidden";
  return (
    <dd>
      <p
        className={classes[mergedClassname]}
        id={id}
        style={{ displayOFF: "none" }}
      >
        {children}
      </p>
    </dd>
  );
}

function QAItem({ children, isOpen, onToggle }) {
  return React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      isOpen: isOpen,
      onToggle: onToggle,
    });
  });
}

GetStarted.QAItem = QAItem;
GetStarted.Question = Question;
GetStarted.Answer = Answer;

//

export default GetStarted;
