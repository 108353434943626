import { makeStyles } from "@material-ui/core/styles";

// all copied from Home/styles.js
//
export default makeStyles((theme) => ({
  appBarSearch: {
    borderRadius: 4,
    marginBottom: "1rem",
    display: "flex",
    padding: "16px",
  },
  pagination: {
    borderRadius: 4,
    marginTop: "1rem",
    padding: "16px",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "bloxk",
  },
  tableCellBoundary: {
    fontSize: "1.5vw",

    [theme.breakpoints.down("sm")]: {
      fontSize: "4vw", // <600
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw", // >600
    },
    [theme.breakpoints.up("800")]: {
      fontSize: "2vw", // >800
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8vw", // >900
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4vw", // >1200
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.9vw", // >1536
    },
    [theme.breakpoints.up("1800")]: {
      fontSize: "1.1vw", // >1800
    },
  },
}));

// /* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
// @media screen and (min-width: 601px) {
//   div.example {
//     font-size: 80px;
//   }
// }

// /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
// @media screen and (max-width: 600px) {
//   div.example {
//     font-size: 30px;
//   }
// }
