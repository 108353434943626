/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link, useLocation } from "react-router-dom";

import {
  //  PROPOSED_STRING,
  MINTED_STRING,
  //  DISPLAY_WISDOM_TYPE,
} from "../../constants/constants";

import {
  getMintedWisdoms,
  getProposedWisdoms,
  getWisdomsBySearch,
} from "../../actions/wisdoms";
import useStyles from "../styles";
import { loadWebUser } from "../myLocalStorage";
//import { createImportSpecifier } from "typescript";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const PaginateWisdoms = ({
  page,
  displayWisdomType,
  forceReload,
  userName = "unk",
}) => {
  const { numberOfPages } = useSelector((state) => state.wisdoms);
  const dispatch = useDispatch();

  //  const query = useQuery();
  //  const searchQuery = query.get("searchQuery");
  const classes = useStyles();

  //  const { library } = useWeb3React();
  // if (library) {
  //   const signer = library.getSigner();
  //   //console.log("PaginateWisdoms>signer=", signer);
  // }

  // console.log(
  //   "Paginate>useSelector.state.wisdoms",
  //   useSelector((state) => state.wisdoms)
  // );
  const numWisdoms = useSelector((state) => state.wisdoms.wisdoms.length);
  // console.log("Paginate>numWisdoms=", numWisdoms);

  //  const isLoading = useSelector((state) => state.wisdoms.isLoading);
  // console.log("Paginate>isLoading>", isLoading);
  // console.log("Pagination.useLocation", useLocation());
  // console.log("Pagination.useLocation.search", useLocation().search);
  // console.log("Pagination.searchQuery>", searchQuery);

  const pathRoot = useLocation().pathname;
  const pathSearch = useLocation().search;

  useEffect(() => {
    // if (page) {
    //   console.log("Pagination.useEffect>dispatch = ");
    // }
    if (page) {
      // console.log(
      //   "Pagination.useEffect>reload, forceReload, page=",
      //   reload,
      //   forceReload,
      //   page
      // );

      if (numWisdoms === 0 || forceReload) {
        const webUser = loadWebUser();
        //const userName = webUser?.name || "unk";
        //console.log("Pagination.useEffect>", displayWisdomType);
        //console.log("Pagination.useEffect>reloading now");
        //console.log("Pagination.searchQuery>", searchQuery);
        // TODO: build proper dispatch, not just minted or proposed
        if (pathRoot === "/wisdoms/search") {
          // building this query from the url is SOO STUPID
          const parms = pathSearch.split("&");
          //console.log("Pagination.useEffect>parms=", parms);

          const query = {
            searchText: parms[0].split("=")[1],
            hashtagTexts: parms[1].split("=")[1],
            status: parms[2].split("=")[1],
            authorId: parms[3].split("=")[1],
            authorName: parms[4].split("=")[1],
            ownerId: parms[5]?.split("=")[1] || "*",
            ownerName: parms[6]?.split("=")[1] || "*",
            width: parms[7]?.split("=")[1],
            page: page,
            userName: webUser?.name || "unk",
            userId: webUser?._id || "unk",
          };
          dispatch(getWisdomsBySearch(query));
        } else {
          if (displayWisdomType === MINTED_STRING) {
            dispatch(getMintedWisdoms(page, userName));
          } else {
            dispatch(getProposedWisdoms(page, userName));
          }
        }
      }
    }
    // else {
    //   //console.log("Pagination.useEffect>page=", page);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  //console.log("Paginate>displayWisdomType", displayWisdomType, "page", page);
  // const pageRoot =
  //   displayWisdomType === MINTED_STRING
  //     ? "/wisdoms?page="
  //     : "/wisdoms/proposed?page=";
  // TODO: build rest of URL string to access different page of same search criteria
  const toOtherPage = (otherPage) => {
    var n1 = pathSearch.indexOf("?page=");
    var n2 = pathSearch.indexOf("&page=");
    var n = n1 === -1 ? n2 : n2 === -1 ? n1 : Math.min(n1, n2);
    var s = pathSearch.substring(0, n !== -1 ? n : pathSearch.length);
    if (s === "") s = s + "?";
    else s = s + "&";

    var op = pathRoot + s + "page=" + otherPage.toString() + "&pageReload=true";
    return op;
  };

  return (
    <Pagination
      classes={{ ul: classes.ul }}
      count={numberOfPages}
      page={Number(page) || 1}
      variant="outlined"
      color="primary"
      renderItem={(item) => (
        <PaginationItem
          {...item}
          component={Link}
          //to={`/wisdoms?page=${item.page}`}
          // TODO: make Paginate.to get other page of serverWisdoms, but same URL
          //to={`${pageRoot}${item.page}&pageReload=true`}
          to={() => toOtherPage(item.page)}
        />
      )}
    />
  );
};

export default PaginateWisdoms;
