import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 200;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  media: {
    height: 0,
    //paddingTop: "56.25%",
    paddingTop: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    //backgroundBlendMode: "darken",
  },
  mediaNoPic: {
    height: 0,
    paddingTop: "26.25%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: "darken",
  },
  border: {
    border: "solid",
  },
  brandContainer: {
    display: "flex",
    alignItems: "left",
    textDecoration: "none",
    fontSize: "1rem",
  },

  fullHeightCard: {
    height: "100%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "15px",
    maxHeight: "25vh",
    transitionDuration: "0.7s",
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: "20px",
    left: "20px",
    color: "white",
  },
  overlay2: {
    position: "absolute",
    top: "20px",
    right: "20px",
    color: "white",
  },
  grid: {
    display: "flex",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  image: {
    marginLeft: "10px",
    marginTop: "5px",
  },

  details: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
  },
  title: {
    padding: "0 16px",
  },
  cardActions: {
    padding: "0 16px 8px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardAction: {
    display: "block",
    textAlign: "initial",
  },
  alert: {
    position: "relative",
    padding: "1rem 1rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    borderRadius: "0.25rem",
  },
  error: {
    color: "#842029",
    backgroundColor: "#f8d7da",
    borderColor: "#f5c2c7",
    fontSize: "30px",
  },

  success: {
    color: "#0f5132",
    backgroundColor: "#d1e7dd",
    borderColor: "#badbcc",
    fontSize: "30px",
    position: "relative",
    padding: "1rem 1rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    borderRadius: "0.25rem",
  },

  warning: {
    color: "#664d03",
    backgroundColor: "#fff3cd",
    borderColor: "#ffecb5",
    fontSize: "30px",
    position: "relative",
    padding: "1rem 1rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    borderRadius: "0.25rem",
  },
  primary: {
    color: "#084298",
    backgroundColor: "#cfe2ff",
    borderColor: "#b6d4fe",
    fontSize: "30px",
    position: "relative",
    padding: "1rem 1rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    borderRadius: "0.25rem",
  },

  secondary: {
    color: "#41464b",
    backgroundColor: "#e2e3e5",
    bordercolor: "#d3d6d8",
    fontSize: "30px",
    position: "relative",
    padding: "1rem 1rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    borderRadius: "0.25rem",
  },

  info: {
    color: "#055160",
    backgroundColor: "#cff4fc",
    bordercolor: "#b6effb",
    fontSize: "30px",
    position: "relative",
    padding: "1rem 1rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    borderRadius: "0.25rem",
  },
  /* The close button */
  closebtn: {
    marginLeft: "15px",
    color: "black",
    fontWeight: "bold",
    float: "right",
    fontSize: "22px",
    lineHeight: "20px",
    cursor: "pointer",
    transition: "0.3s",
  },

  /* The previous button */
  prevbtn: {
    marginLeft: "15px",
    color: "black",
    fontWeight: "bold",
    float: "left",
    fontSize: "22px",
    lineHeight: "20px",
    cursor: "pointer",
    transition: "0.3s",
  },

  /* The next button */
  nextbtn: {
    marginLeft: "15px",
    color: "black",
    fontWeight: "bold",
    float: "left",
    fontSize: "22px",
    lineHeight: "20px",
    cursor: "pointer",
    transition: "0.3s",
  },

  /* When moving the mouse over the close button */
  // closebtn:hover {
  //   color: "black",
  // },

  hide: {
    display: "none",
  },
  //
  responsiveFont: {
    fontSize: "1.5vw",

    [theme.breakpoints.down("sm")]: {
      fontSize: "4vw", // <600
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw", // >600
    },
    [theme.breakpoints.up("800")]: {
      fontSize: "2vw", // >800
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8vw", // >900
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4vw", // >1200
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "0.9vw", // >1536
    },
    [theme.breakpoints.up("1800")]: {
      fontSize: "1.1vw", // >1800
    },
  },
}));
