import React, { useState, useEffect } from "react";
import {
  AppBar,
  Typography,
  Toolbar,
  Avatar,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
//import { Menu as MenuIcon } from "@material-ui/icons/Menu";
//import * as MenuIcon from "@material-ui/icons/Menu";
import decode from "jwt-decode";

import makeItWisdomLogo from "../../images/MakeItWisdom_MW1_64x64.png";
//import memoriesText from "../../images/memoriesText.png";
import * as actionType from "../../constants/actionTypes";
import useStyles from "./styles";
//import settingsIcon from "../../images/outline_settings_black_24dp.png";
//import menuIcon from "../../images/outline_menu_black_24dp.png";

//import menuIcon from "../../images/outline_settings_black_24dp.png";
import MenuIcon from "@material-ui/icons/Menu";
//import SettingsIcon from "@material-ui/icons/Settings";

import ConnectWallet from "../Wallet/ConnectWallet";
import { Menu } from "semantic-ui-react";
import { PROFILE } from "../../constants/constants";
import { loadWebUser, clearMyStorage } from "../myLocalStorage";
//import { mergeClasses } from "@material-ui/styles";

const Navbar = ({ toggleTheDrawerCB }) => {
  var toggleTheDrawerCBClick = () => {
    toggleTheDrawerCB();
  };

  const [webUser, setWebUser] = useState(
    JSON.parse(localStorage.getItem(PROFILE))
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  // const menuStyle = {
  //   border: "none",
  //   borderRadius: 0,
  //   boxShadow: "none",
  //   width: "100%",
  //   marginBottom: "1em",
  //   marginTop: "4em",
  //   transition: "box-shadow 0.5s ease, padding 0.5s ease",
  // };

  // const fixedMenuStyle = {
  //   backgroundColor: "#fff",
  //   border: "1px solid #ddd",
  //   boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
  // };

  // var state = {
  //   menuFixed: true,
  //   renderTab: "Marketplace",
  // };

  //  const { menuFixed } = state;

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });
    // 7/26/22 experiment
    //    localStorage.setItem(PROFILE, "{}");
    setWebUser(null);
    history.push("/auth");
  };

  useEffect(() => {
    // experiment
    //clearMyStorage();
    const token = JSON.parse(localStorage.getItem(PROFILE))?.token;

    if (token) {
      const decodedToken = decode(token);
      // "expiresIn" ?? nope. .exp is correct
      // google sets it for 1 hour.
      // MIW server sets it for 6 hours
      // console.log(
      //   "Navbar.useEffect>token",
      //   JSON.stringify(decodedToken, null, 3)
      // );
      // console.log("Navbar.useEffect>token.exp=", decodedToken.exp);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        //console.log("expired, logout");
        clearMyStorage();
        logout();
      } else {
        //console.log("token, loadWebUser");
        setWebUser(loadWebUser());
      }
    } else {
      // no go w/o token
      //console.log("no token, logout");
      //logout();
    }

    //setWebUser(loadWebUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]); // webUser?.token

  //  console.log("Navbar>user=", user);

  return (
    <AppBar className={classes.appBar} position="static" color="inherit">
      {/* <div className={classes.brandContainer}> */}
      <div position="left" className={classes.brandContainer}>
        <Tooltip placement="top" title={<h2>Menu</h2>}>
          <IconButton
            className={classes.menuIcon}
            color="secondary"
            aria-label="Menu"
            sizesmall="true"
            width="60px"
            onClick={() => toggleTheDrawerCBClick("left", true)}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <Tooltip placement="right" title={<h2>Home page</h2>}>
          <Link to="/" className={classes.brandContainer}>
            {/* <Typography className={classes.heading} variant="h6">
            MakeItWisdom
          </Typography> */}
            <img
              className={classes.image}
              src={makeItWisdomLogo}
              alt="icon"
              height="40px"
            />
          </Link>
        </Tooltip>
      </div>

      <Toolbar className={classes.toolbar}>
        {webUser ? (
          <div className={classes.profile}>
            <Avatar
              className={classes.purple}
              alt={webUser.name}
              src={webUser.imageUrl}
            >
              {webUser?.name?.charAt(0)}
            </Avatar>
            <Typography className={classes.userName} variant="h6">
              {webUser.name}
            </Typography>
            <Button
              variant="contained"
              className={classes.logout}
              color="primary"
              onClick={logout}
            >
              {actionType.LOGOUT}
            </Button>
            &nbsp;&nbsp;
            {/* <Menu
              borderless
              fixed={menuFixed ? "top" : undefined}
              style={menuFixed ? fixedMenuStyle : menuStyle}
            >
              <ConnectWallet />
            </Menu> */}
            <Menu.Menu position="right">
              <ConnectWallet />
            </Menu.Menu>
          </div>
        ) : (
          <Button
            component={Link}
            to="/auth2"
            variant="contained"
            color="primary"
          >
            Sign In
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
