import React from "react";
import { Container, Grow, Typography, Paper } from "@material-ui/core";
// import { useDispatch } from "react-redux";
// import { useHistory, useLocation } from "react-router-dom";

//import useStyles from "./styles";
import ReferralEmailForm from "../Forms/ReferralEmailForm";
// import emailjs from "@emailjs/browser";
//require("dotenv").config();

// import {
//   PROPOSED_STRING,
//   MINTED_STRING,
//   DISPLAY_WISDOM_TYPE,
// } from "../../constants/constants";

// import {
//   getDisplayProfileValue,
//   setDisplayProfileValue,
// } from "../localStorage";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const Invites = () => {
  return (
    <Grow in>
      <Container maxWidth="xl">
        <Paper
          elevation={8}
          style={{
            padding: 12,
            border: "1px solid black",
          }}
        >
          <Typography variant="h6">Invite</Typography>
          <br />
          <ReferralEmailForm />
        </Paper>
      </Container>
    </Grow>
  );
};

export default Invites;
