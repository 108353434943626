//
//
//
import React, { useEffect } from "react";
import {
  Avatar,
  CardMedia,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//import { getWisdom } from "../../actions/wisdoms";

import { getAuthor } from "../../actions/users";
import useStyles from "./styles";
//import WisdomBox from "../Wisdoms/Wisdom/WisdomBox";
//import userEvent from "@testing-library/user-event";
//import DeleteIcon from "@material-ui/icons/Delete";
// import ThumbUpIcon from "@material-ui/icons/ThumbUp";
// import ThumbDownIcon from "@material-ui/icons/ThumbDown";
// import CancelIcon from "@material-ui/icons/Cancel";
// import AddCircleIcon from "@material-ui/icons/AddCircle";
// import TagIcon from "@material-ui/icons/TagFaces";
// import { PROFILE } from "../../constants/constants.js";

//import { PROPOSED_STRING } from "../../constants/constants";
//import users from "../../reducers/users";
import { loadWebUser } from "../myLocalStorage";

// const ImageOrNot = ({ imageFile }) => {
//   const classes = useStyles();
//   if (imageFile === "") {
//     return <div></div>;
//   } else {
//     return <img className={classes.media} src={imageFile} alt={"none"} />;
//   }
// }; // image or not

const AuthorDetails = () => {
  //
  //console.log("AuthorDetails>state=");

  useSelector((state) => {
    console.log("AuthorDetails>state=", state);
  });
  //
  const { users: authors, isLoading } = useSelector((state) => state.users);
  console.log("AuthorDetails>authors=", authors, "isLoading=", isLoading);

  //  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  //  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  console.log("AuthorDetails>id=", id);

  const author = authors.filter(({ _id }) => {
    console.log("AuthorDetails.authors.filter _id=>", _id);
    return id === _id;
  })[0];

  console.log("AuthorDetails>author=", author);
  const webUser = loadWebUser();

  // const approveWisdom = (e) => {
  //   console.log("approveWisdom", serverWisdom);
  // };

  // const declineWisdom = (e) => {
  //   console.log("declineWisdom", serverWisdom);
  // };

  useEffect(() => {
    console.log("AuthorDetails.useEffect>");
    if (!author) {
      //      setIsLoading(true);
      console.log("AuthorDetails.useEffect>dispatching getAuthor");
      dispatch(getAuthor(id));
      //      setIsLoading(false);
    } else {
      console.log("AuthorDetails.useEffect>author is NOT NULL");
    }
  }, [id, author, dispatch]);

  //  useEffect(() => {
  // if (!isLoading) {
  //   if (!serverWisdom) {
  //   dispatch(
  //     getAuthorsBySearch({
  //       search: "none",
  //       hashtagTexts: serverWisdom?.hashtagTexts.join(","),
  //       status: serverWisdom?.status || MINTED_STRING,
  //     })
  //   );
  //   }
  // }
  //  }, [serverWisdom, dispatch]);

  if (!author) {
    console.log("AuthorDetails>author is NULL");
    //dispatch(getAuthor(id));
    return null;
  }
  // called when user clicks on a "you might also like" item
  // const openWisdom = (_id) => history.push(`/wisdoms/${_id}`);
  // const openWisdom = (event, _id) => {
  //   event.preventDefault();
  //   dispatch(getWisdom(_id, webUser?.name || "unk", history));
  //   console.log("openWisdom>", _id);

  //   history.push(`/wisdoms/${_id}`);
  // };

  if (isLoading && (!author || author._id !== id)) {
    return (
      <Paper elevation={6} className={classes.loadingPaper}>
        <CircularProgress size="7em" />
      </Paper>
    );
  }

  //  const recommendedWisdoms = authors.filter(({ _id }) => _id !== author._id);

  // const openHashtag = (e, query) => {
  //   e.preventDefault();
  //   console.log("openHashtag", query);
  //   //    dispatch(getAuthorsByHashtag(query));
  //   //history.push("/wisdoms/hashtagText?hashtagText=$" + query.hashtagText);
  //   dispatch(getAuthorsBySearch(query), history);
  //   history.push(
  //     "/wisdoms/search?search=none&hashtagTexts=" +
  //       query.hashtagTexts +
  //       "&status=" +
  //       author?.status
  //   );
  // };

  // const searchHashtag = (_hashtagText, _displayWisdomType) => {
  //   console.log(
  //     "searchHashtag>searchQuery=",
  //     _hashtagText,
  //     "=",
  //     _displayWisdomType
  //   );
  //   const status = _displayWisdomType || MINTED_STRING;
  //   if (_hashtagText !== "") {
  //     dispatch(
  //       getAuthorsBySearch({
  //         searchText: "",
  //         hashtagTexts: [_hashtagText],
  //         status: status,
  //       })
  //     );
  //     history.push(
  //       `/wisdoms/search?search=${"none"}&hashtagTexts=${[
  //         _hashtagText,
  //       ]}&status=${status}`
  //     );
  //   } else {
  //     history.push("/");
  //   }
  // };

  // const isUserApprover = () => {
  //   return (
  //     (serverWisdom?.status === PROPOSED_STRING &&
  //       webUser?.isWisdomApprover &&
  //       webUser?.googleId !== serverWisdom?.authorId &&
  //       webUser?._id !== serverWisdom?.author) ||
  //     isUserOwner(webUser)
  //   );
  // };

  //
  // maybe move to actions/auth  isUserApprover(user, serverWisdom)
  //   then userIsApprover = isUserApprover(user, serverWisdom);

  //  const isUserApprover = webUser?.isWisdomApprover;

  // const canUserApprove = () => {
  //   // owner can override
  //   if (isUserOwner(webUser)) return true;
  //   if (author?.status !== PROPOSED_STRING) return false;
  //   if (
  //     webUser?.isWisdomApprover &&
  //     webUser?.googleId !== author?.authorId &&
  //     webUser?._id !== author?.authorId
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };
  // const handleTagLink = () => {};
  console.log("AuthorDetails>author=", author);
  console.log("AuthorDetails>webUser=", webUser);

  return (
    <Paper
      style={{ padding: "20px", borderRadius: "15px" }}
      elevation={6}
      key={"AuthorDetailsPaper" + author._id}
    >
      <div className={classes.card}>
        <div className={classes.section}>
          {/* <Typography variant="h3" component="h2">
            {post.wisdomText}
          </Typography> */}
          {/* <WisdomBox wisdom={author} cursor={"pointer"} /> */}

          {/* <Typography
            gutterBottom
            variant="h6"
            color="textSecondary"
            component="h2"
          > */}
          {/* {author.hashtagTexts.map((tag) => (
              <Button
                //                className={classes.hashtagButton}
                size="small"
                color="secondary"
                key={"tagLink" + tag + author?.createdAt?.toString()}
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(
                    getAuthorsBySearch({
                      searchText: "none",
                      hashtagTexts: tag,
                      status: author?.status,
                      page: 1,
                    })
                  );
                  history.push(
                    `/wisdoms/search?search=${"none"}&hashtagTexts=${tag}&status=${
                      author?.status
                    }&authorId=${"*"}&authorName=${"*"}&page=1`
                  );

                }}
              >
                <TagIcon fontSize="small" /> &nbsp; {tag}
              </Button>
              ////              </div>
            ))} 
          </Typography>*/}
          {/* <Typography gutterBottom variant="body1" component="p">
            {post.wisdomText}
          </Typography> */}
          <Avatar
            className={classes.purple}
            alt={author?.name}
            src={author?.imageUrl}
          ></Avatar>

          <Typography variant="h6">
            {/* <Link
              to={`/authors/${serverWisdom?.author}/${serverWisdom?.status}`}
              style={{ textDecoration: "none", color: "#3f51b5" }}
            >
              {` ${serverWisdom.authorName}`}
            </Link> */}
          </Typography>
          <Divider style={{ margin: "20px 0" }} />

          <Typography variant="h6">{author?.name}</Typography>
          <Typography variant="h6">{author?.twitterHandle}</Typography>
          <Typography variant="h6">
            Minted: {author?.wisdomOwned.length}
          </Typography>
          <Typography variant="h6">
            Owned: {author?.wisdomMintedCount}
          </Typography>

          <Divider style={{ margin: "20px 0" }} />
        </div>
        <CardMedia
          className={classes.mediaNoPic}
          key={"Card-CardMedia" + author?._id}
          image={require("../../images/no_pic.png")}
          //title={"the title"}
        />
      </div>
      )
    </Paper>
  );
};

export default AuthorDetails;
