import React, { useState } from "react";
import {
  COIN_ABBR_LABEL,
  WISDOM_SALE_DURATION_HOURS,
} from "../../../constants/constants";
import AlertBanner from "../../AlertBanner/AlertBanner";

export const SellWisdomForm = ({
  onSubmit,
  onChange,
  data,
  coinInCurrency,
  sellCharge,
}) => {
  // Alert setup
  const handleCloseAlert = () => {
    // changing state should cause rerender
    setAlertBanner({ ...alertBanner, showNow: false });
  };

  const alertKey = +"sellWisdom_AlertKey";
  const [alertBanner, setAlertBanner] = useState({
    children: null,
    type: "info",
    message: "",
    showNow: false,
    theKey: alertKey,
    handleClose: handleCloseAlert,
  });

  // called from afar to change alert banner this dialog box
  const updateAlertBanner = (message) => {
    const alertype = message.toUpperCase().includes("ERROR") ? "error" : "info";

    setAlertBanner({
      children: null,
      type: alertype,
      message: message,
      showNow: true,
      theKey: alertKey,
      handleClose: handleCloseAlert,
    });
  };

  return (
    <form onSubmit={(event) => onSubmit(event, updateAlertBanner)}>
      <div className="form-group">
        <AlertBanner {...alertBanner}></AlertBanner>
        <label>
          Wisdoms go on sale periodically automatically, for free, for an
          escalating price.
        </label>
        &nbsp;
        <label>
          Making this Wisdom for sale right now costs {sellCharge}{" "}
          {COIN_ABBR_LABEL} plus a gas fee. After you click the Submit button,
          your crypto wallet will give you details so you can authorize the
          transaction.
        </label>
        <p />
        <label>
          See Residual NFT on the main menu to understand revenue sharing and
          why you will receive 70% of the price you enter below when the Wisdom
          is purchased.
        </label>
        <p />
        <label>
          The sale will expire after {WISDOM_SALE_DURATION_HOURS} hours.
        </label>
        <p />
        <label>Sell for what price (in {COIN_ABBR_LABEL})? </label>
        <input
          className="form-control"
          id="forSalePrice"
          value={data?.forSalePrice}
          onChange={(event) => onChange(event, updateAlertBanner)}
        />
      </div>
      <br />
      <div className="form-group">
        <button className="form-control btn btn-primary" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};
export default SellWisdomForm;
