export const FETCH_AUTHOR = "FETCH_AUTHOR";
export const FETCH_AUTHORS = "FETCH_AUTHORS";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const AUTH = "AUTH";
export const LOGOUT = "SIGNOUT";
export const START_LOADING_USERS = "START_LOADING_USERS";
export const STOP_LOADING_USERS = "STOP_LOADING_USERS";

export const ACTIVATE_WALLET = "ACTIVATE_WALLET";
//
//

export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const NEED_SIGNIN = "NEED_SIGNIN";
export const FETCH_ALL_MINTED = "FETCH_ALL_MINTED";
export const FETCH_ALL_PROPOSED = "FETCH_ALL_PROPOSED";
export const FETCH_BY_SEARCH = "FETCH_BY_SEARCH";
export const FETCH_BY_AUTHORS = "FETCH_BY_AUTHORS";
export const FETCH_BY_HASHTAG = "FETCH_BY_HASHTAG";
export const FETCH_WISDOM = "FETCH_WISDOM";
export const LIKE_WISDOM = "LIKE_WISDOM";
export const VOTE_WISDOM = "VOTE_WISDOM";
export const HIDE_WISDOM = "HIDE_WISDOM";
export const UNHIDE_WISDOM = "UNHIDE_WISDOM";
export const COMMENT = "COMMENT";
export const PROPOSE_WISDOM = "PROPOSE_WISDOM";
export const UPDATE_WISDOM = "UPDATE_WISDOM";
export const DELETE_WISDOM = "DELETE_WISDOM";
export const APPROVE_WISDOM = "APPROVE_WISDOM";
export const DECLINE_WISDOM = "DECLINE_WISDOM";
export const MINT_WISDOM = "MINT_WISDOM";
export const CANCEL_WISDOM = "CANCEL_WISDOM";

export const FETCH_PIONEERS = "FETCH_PIONEERS";
export const MINT_PIONEER = "MINT_PIONEER";
export const START_LOADING_PIONEERS = "START_LOADING_PIONEERS";
export const STOP_LOADING_PIONEERS = "STOP_LOADING_PIONEERS";
//
//
export const APPROVE_HASHTAG = "APPROVE_HASHTAG";
export const CANCEL_HASHTAG = "CANCEL_HASHTAG";
export const DELETE_HASHTAG = "DELETE_HASHTAG";
export const DECLINE_HASHTAG = "DECLINE_HASHTAG";
export const FETCH_HASHTAGS = "FETCH_HASHTAGS";
//export const FETCH_OWNER_HASHTAGS = "FETCH_OWNER_HASHTAGS";
export const FETCH_HASHTAG = "FETCH_HASHTAG";
export const HIDE_HASHTAG = "HIDE_HASHTAG";
export const MINT_HASHTAG = "MINT_HASHTAG";
export const PROPOSE_HASHTAG = "CREATE_HASHTAG";
export const START_LOADING_HASHTAGS = "START_LOADING_HASHTAGS";
export const STOP_LOADING_HASHTAGS = "STOP_LOADING_HASHTAGS";
export const UPDATE_HASHTAG = "UPDATE_HASHTAG";
export const UNHIDE_HASHTAG = "UNHIDE_HASHTAG";
