import React, { useState, useEffect } from "react";
import { Container, Grow, Typography, Paper, Tooltip } from "@material-ui/core";
//import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import useStyles from "./styles";
import {
  //  COIN_ABBR_LABEL,
  COIN_ABBR_LABEL_PAREN,
  COIN_LABEL,
  DO_LOG,
} from "../../constants/constants";

// for web3 / contract
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
//import WisdomContract from "../../artifacts/contracts/WisdomContract.sol/WisdomContract.json";
//import MakeItWisdomContract from "../../artifacts/contracts/MakeItWisdomContract.sol/MakeItWisdomContract.json";
import MakeItWisdomLockboxContract from "../../artifacts/contracts/MakeItWisdomLockboxContract.sol/MakeItWisdomLockboxContract.json";
import {
  makeitwisdomlockboxaddress,
  //  makeitwisdomaddress,
  //  wisdomaddress,
} from "../../contractManifest.js";
import HelpOutline from "@material-ui/icons/HelpOutline";

import * as api from "../../api/index.js";
import { loadWebUser } from "../myLocalStorage";
//import SellWisdomModalContainer from "../Forms/SellWisdomModalContainer";
//import { getNewCoinInUsd } from "../../actions/wisdoms";
import TransferLockboxModalContainer from "../Forms/TransferLockboxModalContainer";
import AlertBanner from "../AlertBanner/AlertBanner.js";
import { useSelector } from "react-redux";
import { getMyBCConfig } from "../../configs/multiConfig.mjs";

//
const { networkName } = getMyBCConfig();

const buttonTextTransfer = "Transfer Now";
const doLog = false;
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const Transfer = () => {
  const classes = useStyles();
  const { library, account, chainId } = useWeb3React();

  //const [ethInDollars, setEthInDollars] = useState(0);
  // balance in the lockbox for this user
  const [makeItWisdomLockboxBalanceEth, setMakeItWisdomLockboxBalanceEth] =
    useState(0);
  // eth to be transfered (used for makeItWisdomLockboxContract, WisdomContract and MakeItWisdomContract)
  const [transferBalanceEth, setTransferBalanceEth] = useState(0);
  // loading webUser balance from lockbox contract
  const [makeItWisdomLockboxLoadingState, setMakeItWisdomLockboxLoadingState] =
    useState("makeItWisdomLockboxBalanceLoading");
  //
  const [walletBalanceEth, setWalletBalanceEth] = useState(0);
  var walletBalanceWei = 0;

  var { coinInCurrency } = useSelector((state) => state.wisdoms);
  //   const dispatch = useDispatch();
  const history = useHistory();

  const webUser = loadWebUser();
  //
  const handleCloseAlert = () => {
    // changing state should cause rerender
    setAlertBanner({ ...alertBanner, showNow: false });
  };
  const alertKey = "TransferAlertKey";
  const [alertBanner, setAlertBanner] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKey,
    handleClose: handleCloseAlert,
  });

  //
  const transferBalanceContract = async (wallet, transferBalanceEth, nowMS) => {
    if (doLog)
      console.log(
        "transferBalanceContract>transferBalanceEth=",
        transferBalanceEth
      );
    //var callbackCount = 0;
    // convert transferBalance from eth to wei
    const transferBalanceWei = ethers.utils
      .parseEther(transferBalanceEth.toString(), "wei")
      .toString();

    //
    //
    if (library) {
      const signer = library.getSigner();
      if (doLog) {
        console.log("transferBalanceContract>");
        console.log("transferBalanceContract>signer=", signer);
      }
      const makeItWisdomLockboxContract = new ethers.Contract(
        makeitwisdomlockboxaddress,
        MakeItWisdomLockboxContract.abi,
        signer
      );

      // const signer = library.getSigner();
      // const contract = new ethers.Contract(
      //   wisdomaddress,
      //   WisdomContract.abi,
      //   signer
      // );

      //try {
      // listen for event emitted
      // makeItWisdomLockboxContract.on(
      //   "WithdawalEvent",
      //   (_wallet, _transferBalanceWei, _nowMS) => {
      //     const _nowMSInt = parseInt(_nowMS.toString());

      //     console.log(
      //       "transferBalanceContract>callback wallet=",
      //       _wallet,
      //       "transferBalanceWei=",
      //       _transferBalanceWei.toString(),
      //       "nowMSInt=",
      //       _nowMSInt
      //     );
      //     // confirm event matches
      //     //   but just once!

      //     if (
      //       _wallet === wallet &&
      //       _transferBalanceWei === transferBalanceWei &&
      //       _nowMSInt === nowMS
      //     ) {
      //       if (callbackCount === 0) {
      //         ++callbackCount;

      //         // NOTE: NO update the server
      //         // confiirm success to user
      //         alert("Transfer " + transferBalanceEth.toString() + " Complete!");
      //       } else {
      //         console.log(
      //           "transferBalanceContract>callbackCount=",
      //           callbackCount
      //         );
      //       }
      //     } else {
      //       console.log("transferBalanceContract>event no match");
      //     }
      //   }
      // ); // event listener

      // console.log(
      //   "transfer>>converting",
      //   transferBalanceEth,
      //   " eth to wei",
      //   transferBalanceWei,
      //   "nowMS",
      //   nowMS
      // );
      //      const nowMS = Math.round(Date.now() / 1000);
      //10000000000000000 = 0.01 MATIC
      const tx = await makeItWisdomLockboxContract.withdrawal(
        transferBalanceWei,
        nowMS
      );

      const receipt = await tx.wait();
      // console.log("transfer>>OK DONE. transaction=", tx);
      // console.log("transfer>>OK DONE. receipt=", receipt);
      // determine from receipt that transaction completed
      //    and process returned values in the event
      if (receipt?.events.length > 0) {
        // check each event?
        for (let i = 0; i < receipt.events.length; ++i) {
          // pull data from event
          // uint256 come back as BigNumber
          // _wallet, _transferBalanceWei, _nowMS
          const event = receipt.events[i];
          //console.log("transfer>>   event=", event);
          // event is for this wisdom
          if (event?.event === "WithdawalEvent") {
            if (
              transferBalanceWei.toString() === event?.args?.amount.toString()
            ) {
              if (nowMS.toString() === event?.args?.nowMS.toString()) {
                // event is for us!
                // alert(
                //   "Transfer " +
                //     transferBalanceEth.toString() +
                //     COIN_ABBR_LABEL +
                //     " Complete!"
                // );
                //history.push("/transfer");
              } else {
                if (doLog)
                  console.log("transfer>>wrongNow=", event?.args?.nowMS);
              }
            } else {
              if (doLog)
                console.log(
                  "transfer>>wrongAmount",
                  transferBalanceWei,
                  event?.args?.amount.toString()
                );
            }
          } else {
            console.log("transfer>>wrongEvent=", event?.event);
          }
        } // each event in receipt
      } else {
        console.log("transfer>>No Events In Receipt");
      }

      return receipt;
      // }
      // catch (err) {
      //   if (err?.data?.message) {
      //     const i = err.data.message.indexOf("custom error");
      //     if (i !== -1) {
      //       console.log(
      //         "transfer>>ERROR=",
      //         err?.data?.message.substring(i + 13)
      //       );
      //       alert(err?.data?.message.substring(i + 13));
      //     }
      //   }
      //   console.log("transfer>>ERROR=", err);
      // }
    } else {
      if (DO_LOG) console.log("transfer>>NO LIBRARY");
    }
    return null;
  }; // sell Wisdom contract
  //
  //
  //
  const handleTransferAll = (event) => {
    api.logEvent({
      eventName: "xferAll",
      data1: makeItWisdomLockboxBalanceEth.toString(),
    });
    setTransferBalanceEth(makeItWisdomLockboxBalanceEth);
  };
  //
  const handleTransferHalf = (event) => {
    api.logEvent({
      eventName: "xferHalf",
      data1: makeItWisdomLockboxBalanceEth.toString(),
    });
    setTransferBalanceEth(makeItWisdomLockboxBalanceEth / 2);
  };
  //
  const handleTransferBalanceEthChange = async (event) => {
    event.preventDefault(event);
    setTransferBalanceEth(event.target.value);
  };
  //
  const handleTransferBalance = async (event, closeModal) => {
    event.preventDefault(event);
    // const transferBalanceWei = ethers.utils
    //   .parseEther(transferBalanceEth.toString(), "wei")
    //   .toString();

    if (doLog)
      console.log("handleTransferBalance>balance=", transferBalanceEth);

    const webUser = loadWebUser();
    if (webUser) {
      if (transferBalanceEth <= 0) {
        //alert("Transfer amount must be > 0");
        //
        setAlertBanner({
          children: null,
          type: "error",
          message: "Transfer amount must be greater than 0",
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });

        return;
      }
      const nowMS = Math.round(Date.now() / 1000);

      //const approverWallet = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";
      // mint contract function calls server funcion if successful

      const receipt = await transferBalanceContract(
        webUser?.wallet,
        transferBalanceEth,
        nowMS
      );
      try {
        if (closeModal) closeModal();
        if (!receipt) {
          api.logEvent({
            eventName: "xferBal-error",
            data1: transferBalanceEth.toString(),
            data2: webUser?.wallet,
          });
          //alert("Error transfering balance. Please try again.");
          //
          setAlertBanner({
            children: null,
            type: "error",
            message: "Error transfering balance. Please try again.",
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });
        } else {
          api.logEvent({
            eventName: "xferBal-ok",
            data1: transferBalanceEth.toString(),
            data2: webUser?.wallet,
          });
          // success!
          setAlertBanner({
            children: null,
            type: "success",
            message: "Coins transfered!",
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });

          //history.go(0);
          // doesn't happen. ignored?
          setTimeout(() => {
            //console.log("Transfer>success!");
            //history.push("/transfer");
            history.go(0);
          }, 4000);
        }
      } catch (err) {
        console.log("Transfer ERROR", err);
        //
        setAlertBanner({
          children: null,
          type: "error",
          message: "Transfer ERROR",
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
      }
    } else {
      if (doLog)
        api.logEvent({
          eventName: "xferBal-signin",
          data1: transferBalanceEth.toString(),
          data2: webUser?.wallet,
        });
      try {
        if (closeModal) closeModal();
      } catch (err2) {}
      //alert("Sign in and try again.");
      //
      setAlertBanner({
        children: null,
        type: "error",
        message: "Sign in to your MakeItWisdom account.",
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    }
  }; // handle transfer balance
  //
  //  example code!
  // const ethers = require("ethers");
  // const provider = new ethers.providers.JsonRpcProvider(HTTP_PROVIDER);
  // const wallet = new ethers.Wallet(PRIVATE_KEY, provider);
  // const swap1 = await wallet.sendTransaction({//});
  // const receipt = provider.waitForTransaction(swap1.hash, 1, 150000).then(() => {//});
  //
  //
  //
  //
  useEffect(() => {
    // const loadEthInDollars = async () => {
    //   setEthInDollars(await getNewCoinInUsd());
    // };

    const loadMakeItWisdomLockboxBalance = async (wallet) => {
      if (wallet) {
        if (library) {
          //console.log("Transfer.loadMakeItWisdomLockboxBalance>wallet=", wallet);
          const provider = await library;
          const contract = new ethers.Contract(
            makeitwisdomlockboxaddress,
            MakeItWisdomLockboxContract.abi,
            provider
          );

          if (!contract) {
            if (doLog)
              console.log(
                "Transfer.loadMakeItWisdomLockboxBalance>NO lockbox contract"
              );
            return;
          }
          try {
            //console.log("loadMakeItWisdomLockboxBalance>lockbox contract address", makeitwisdomlockboxaddress);
            const makeItWisdomLockboxWei = await contract.getBalance(wallet);
            setMakeItWisdomLockboxBalanceEth(
              ethers.utils.formatUnits(
                makeItWisdomLockboxWei.toString(),
                "ether"
              )
            );

            // console.log(
            //   "Transfer.loadMakeItWisdomLockboxBalance>makeItWisdomLockboxBalanceEth=",
            //   ethers.utils.formatUnits(makeItWisdomLockboxWei.toString(), "ether")
            // );

            setMakeItWisdomLockboxLoadingState(
              "makeItWisdomLockboxBalanceLoaded"
            );
          } catch (balanceErr) {
            //wallet points to wrong network to get .getBalance

            //
            //console.log("loadPioneerDataContract>Contract not visible");
            // alertBannerLoaded
            setAlertBanner({
              children: null,
              type: "error",
              message:
                "ERROR: Be certain your wallet points to the " +
                networkName +
                " network. Wallet Disconnect, reconnect. Maybe reload this webpage.",
              showNow: true,
              theKey: alertKey,
              handleClose: handleCloseAlert,
            });
          } // catch
          //console.log("Transfer.loadMakeItWisdomLockboxBalance>LOADED");
          //
          // Wallet balance
          //
          //const provider = new ethers.providers.Web3Provider(window.ethereum);
          if (webUser?.wallet !== "") {
            walletBalanceWei = await provider.getBalance(webUser?.wallet);
            walletBalanceWei = walletBalanceWei
              ? walletBalanceWei.toString()
              : "0";
            setWalletBalanceEth(ethers.utils.formatEther(walletBalanceWei));
          }
          console.log(
            "Transfer>walletBalance=",
            walletBalanceWei,
            ethers.utils.formatEther(walletBalanceWei),
            webUser?.wallet
          );
        } else {
          if (DO_LOG)
            console.log("Transfer.loadMakeItWisdomLockboxBalance>NO library");
        } // library
      } else {
        console.log("Transfer.loadMakeItWisdomLockboxBalance>NO wallet=");
      }
    }; // load pioneer data contract
    // djf commented 3/14/22
    //web3React.activate(injected, undefined, true);
    loadMakeItWisdomLockboxBalance(webUser?.wallet).catch(console.error);
    //loadEthInDollars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [library, account, chainId, makeItWisdomLockboxLoadingState]);

  if (!webUser) {
    return (
      <Grow in>
        <Container maxWidth="xl">
          <Paper
            elevation={8}
            style={{
              padding: 12,
              border: "1px solid black",
            }}
          >
            <Typography variant="h5">
              Sign in to transfer crypto funds to your wallet.
            </Typography>
          </Paper>
        </Container>
      </Grow>
    );
  } else {
    return (
      <Grow in>
        <Container maxWidth="xl">
          <AlertBanner {...alertBanner}></AlertBanner>
          <Paper
            elevation={8}
            style={{
              padding: 12,
              border: "1px solid black",
            }}
          >
            <Typography variant="h5">
              When you earn MATIC, it is stored safely here in the Lockbox smart
              contract. Only people who can access your crypto wallet can access
              your Lockbox. You must always protect your crypto wallet. Never
              share your wallet passwords, private keys or security words. We
              will never ask you for that information. If anyone does, they are
              trying to steal your crypto.
              <p />
              For only the cost of a small gas fee, you can use the Transfer Now
              button below to move your MATIC from your Lockbox to your wallet.
              Once in your wallet, you can sell for cash or trade your MATIC on
              any exchange that handles MATIC.
            </Typography>
          </Paper>
          <p />
          <Paper
            elevation={8}
            style={{
              padding: 12,
              border: "1px solid black",
            }}
          >
            <Typography variant="h5">
              Transfer From
              <Tooltip
                placement="top"
                title={
                  <h2>
                    Crypto coins earned from Wisdom sales and residuls
                    accumulate on the smart contract.
                  </h2>
                }
              >
                <button
                  className={classes.btnhelp}
                  onClick={() =>
                    // alert(
                    //   "Crypto coins earned from Wisdom sales and residuls accumulate on the smart contract."
                    // )
                    setAlertBanner({
                      children: null,
                      type: "info",
                      message:
                        "Crypto coins earned from Wisdom sales and residuls accumulate on the smart contract.",
                      showNow: true,
                      theKey: alertKey,
                      handleClose: handleCloseAlert,
                    })
                  }
                >
                  <HelpOutline />
                </button>
              </Tooltip>
            </Typography>
            <br />
            <Typography variant="h6">
              MakeItWisdom Lockbox Contract Balance.....{" "}
              {makeItWisdomLockboxBalanceEth} {COIN_LABEL}
            </Typography>
          </Paper>
          <p />
          <Paper
            elevation={8}
            style={{
              padding: 12,
              border: "1px solid black",
            }}
          >
            <Typography variant="h5">
              Transfer To
              <Tooltip
                placement="top"
                title={
                  <h2>
                    Transfer your crypto coins from the smart contract to your
                    crypto wallet (e.g. MetaMask) so you can then sell or trade
                    them on a crypto exchange or use them on other web3
                    websites.
                  </h2>
                }
              >
                <button
                  className={classes.btnhelp}
                  onClick={() =>
                    setAlertBanner({
                      children: null,
                      type: "info",
                      message:
                        "Transfer your crypto coins from the smart contract to your crypto wallet (e.g. MetaMask) so you can then sell or trade them on a crypto exchange or use them on other web3 websites.",
                      showNow: true,
                      theKey: alertKey,
                      handleClose: handleCloseAlert,
                    })
                  }
                >
                  <HelpOutline />
                </button>
              </Tooltip>
            </Typography>
            <br />
            <Typography variant="h6">
              Crypto wallet..... {webUser?.wallet}
            </Typography>
            <Typography variant="h6">
              Crypto wallet balance..... {walletBalanceEth}
            </Typography>
          </Paper>
          <p />
          <Paper
            elevation={8}
            style={{
              padding: 12,
              border: "1px solid black",
            }}
          >
            <Typography variant="h5">
              Transfer Amount {COIN_ABBR_LABEL_PAREN}
              <Tooltip
                placement="top"
                title={
                  <h2>
                    MakeItWisdom uses {COIN_LABEL} crypto coin for minting,
                    buying and selling Wisdom rNFT.
                  </h2>
                }
              >
                <button
                  className={classes.btnhelp}
                  onClick={() =>
                    setAlertBanner({
                      children: null,
                      type: "info",
                      message:
                        "MakeItWisdom uses " +
                        COIN_LABEL +
                        " crypto coin for minting, buying and selling Wisdom rNFT.",
                      showNow: true,
                      theKey: alertKey,
                      handleClose: handleCloseAlert,
                    })
                  }
                >
                  <HelpOutline />
                </button>
              </Tooltip>
            </Typography>
            <br />
            <button
              className="form-control btn btn-primary"
              onClick={handleTransferAll}
              style={{ fontSize: "1.5em", marginRight: "20px" }}
            >
              All
            </button>
            <button
              className="form-control btn btn-primary"
              onClick={handleTransferHalf}
              style={{ fontSize: "1.5em", marginRight: "20px" }}
            >
              Half
            </button>
            <input
              className="form-control"
              id="transferBalanceEth"
              style={{ fontSize: "1.5em" }}
              value={transferBalanceEth}
              onChange={handleTransferBalanceEthChange}
            />
            <p />
            <TransferLockboxModalContainer
              triggerText={buttonTextTransfer}
              onSubmit={handleTransferBalance}
              onChange={handleTransferBalance}
              data={{
                makeItWisdomLockboxBalanceEth: makeItWisdomLockboxBalanceEth,
                transferBalanceEth: transferBalanceEth,
                coinInCurrency: coinInCurrency,
              }}
            />
            <Tooltip
              placement="top"
              title={
                <h2>
                  Transfering your crypto is free besides the minimal blockchain
                  gas fee which will be revealed by your crypto wallet.
                  Transfers can't be reversed.
                </h2>
              }
            >
              <button
                className={classes.btnhelp}
                onClick={() =>
                  setAlertBanner({
                    children: null,
                    type: "info",
                    message:
                      "Transfering your crypto is free besides the minimal blockchain gas fee which will be revealed by your crypto wallet. Transfers can't be reversed.",
                    showNow: true,
                    theKey: alertKey,
                    handleClose: handleCloseAlert,
                  })
                }
              >
                <HelpOutline />
              </button>
            </Tooltip>
            <p />
            <h3>
              See also: <br />
              <div className={classes.seeAlsoDiv}>
                <a href="/residualnft">Residual NFT.</a> <br />
                <a href="/faqs">FAQ.</a>
              </div>
              <p />
              <strong>Crypto Wallets</strong>
              <div className={classes.seeAlsoDiv}>
                <a
                  href="https://metamask.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MetaMask
                </a>
                <br />
                <a
                  href="https://www.coinbase.com/wallet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (future) Coinbase WalletLink
                </a>
                <br />
                <a
                  href="https://walletconnect.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (future) Any WalletConnect wallet
                </a>
              </div>
              <br />
            </h3>
          </Paper>
        </Container>
      </Grow>
    );
  }
};

export default Transfer;
