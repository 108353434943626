import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonBase,
  Card,
  CardActions,
  //  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Tooltip,
  Typography,
} from "@material-ui/core/";
import { SellWisdomModalContainer } from "../../Forms/SellWisdomModalContainer";
// import { ThemeProvider } from "@material-ui/core/styles";
// import { createTheme } from "@material-ui/core/styles";
// import {
//   //  lightGreen,
//   blue,
//   green,
//   blueGrey,
//   //  pink,
//   red,
//   deepPurple,
//   purple,
// } from "@material-ui/core/colors";

import "./modal.css";
import "../../../index.css";
//
//import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
//import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
//import LaunchIcon from "@material-ui/icons/Launch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
//import AddCircleIcon from "@material-ui/icons/AddCircle";
//import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
//import {
//  MonetizationOn,
//  MoneyOff,
// Favorite,
// FavoriteBorderOutlined,
//} from "@material-ui/icons";

import thinksNoMeIcon from "../../../images/outline_psychology_black_24dp.png";
import thinksMeIcon from "../../../images/baseline_psychology_black_24dp.png";
import cheersNoMeIcon from "../../../images/outline_celebration_black_24dp.png";
import cheersMeIcon from "../../../images/baseline_celebration_black_24dp.png";
import feelsNoMeIcon from "../../../images/outline_favorite_border_black_24dp.png";
import feelsMeIcon from "../../../images/baseline_favorite_black_24dp.png";
import likesNoMeIcon from "../../../images/outline_thumb_up_black_24dp.png";
import likesMeIcon from "../../../images/baseline_thumb_up_black_24dp.png";
//import dotProgressIcon from "../../../images/baseline_circle_black_24dp.png";

import { useDispatch } from "react-redux";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

// for web3 / contract
import { useWeb3React } from "@web3-react/core";
//import { injected } from "../../../hooks/connectors";
import { ethers } from "ethers";
import WisdomContract from "../../../artifacts/contracts/WisdomContract.sol/WisdomContract.json";
import MakeItWisdomContract from "../../../artifacts/contracts/MakeItWisdomContract.sol/MakeItWisdomContract.json";
import {
  makeitwisdomaddress,
  wisdomaddress,
} from "../../../contractManifest.js";

import * as api from "../../../api/index.js";
import * as perm from "../../../constants/permissions.js";
import {
  getListExpireString,
  isOnList,
  updateListCount,
} from "../../../constants/permissions.js";

import {
  //  likeWisdom,
  //  deleteWisdom,
  getWisdom,
  isWisdomHidden,
  isWisdomMinted,
  //  isWisdomProposed,
  isWisdomDeclined,
  isUserWisdomOwner,
  //  getNewCoinInUsd,
  //  getCoinInUsd,
  isWisdomForSale,
  isWisdomApproved,
  //  voteWisdom,
  cancelWisdom,
  hideWisdom,
  isUserWisdomAuthor,
} from "../../../actions/wisdoms";
import useStyles from "./styles";
import WisdomBox from "./WisdomBox";
import {
  MINTED_STRING,
  //  PROPOSED_STRING,
  //  PROFILE,
  APPROVED_STRING,
  MAKE_WISDOM_FOR_SALE_CHARGE,
  COIN_ABBR_LABEL,
  SIGN_IN_TO_ACCOUNT,
  SIGN_IN_TO_ACCOUNT_AGAIN,
  COIN_LABEL,
  DATE_MS_12_31_2099,
  DO_LOG,
  SECONDS_PER_DAY,
  SHOW_BETA_TWEET,
  COMING_SOON,
  STATUS_CODE_USER_IS_UNAUTH,
} from "../../../constants/constants";
//import { isUserOwner } from "../../../actions/users";
import { loadWebUser, setProfileValue } from "../../myLocalStorage";
import BoundarySection from "../../WisdomDetails/BoundarySection";
//
import AlertBanner from "../../AlertBanner/AlertBanner.js";
//import CancelIcon from "@material-ui/icons/Cancel";
//import { MonetizationOn } from "@material-ui/icons";

//const PsychologyOutlined = require("../../../images/outline_psychology_black_24dp.png");
const doLog = false;

const WisdomCard = ({
  serverWisdom,
  setCurrentId,
  setEditWisdom,
  displayWisdomType,
  sellCharge = MAKE_WISDOM_FOR_SALE_CHARGE,
  selectedAnimation,
  coinInCurrency,
}) => {
  const webUser = loadWebUser();
  var walletBalanceWei = 0;
  var walletBalanceEth = 0;

  var momentStr;
  //  console.log("WisdomCard>webUser=", webUser);
  // used to force refresh of WisdomCard. but whole Wisdoms page??
  const [theVoteCount, setTheVoteCount] = useState(0);
  const [wisdomRefresh, setWisdomRefresh] = useState(0);

  //
  const [hidden, setHidden] = useState(serverWisdom?.hidden);
  const [forSalePriceEth, setForSalePriceEth] = useState(
    serverWisdom?.forSalePrice
  );
  const [wisdomBusy, setWisdomBusy] = useState(false);

  // Alert setup
  const handleCloseAlert = () => {
    // changing state should cause rerender
    setAlertBanner({ ...alertBanner, showNow: false });
  };

  const alertKey = serverWisdom?.wisdomId.toString() + "AlertKey";
  const [alertBanner, setAlertBanner] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKey,
    handleClose: handleCloseAlert,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  //  const userId = user?.googleId || user?._id;
  //  const userId = webUser?._id;
  // const hasLikedWisdom =
  //   serverWisdom?.likes?.find((like) => like === userId) || false;

  const { library } = useWeb3React();

  //const coinInCurrency = getCoinInUsd();

  // console.log(
  //   "WisdomCard>",
  //   userId,
  //   serverWisdom?.hidden,
  //   "=",
  //   serverWisdom?.wisdomText,
  //   "=",
  //   serverWisdom?.likes.length,
  //   "=",
  //   hasLikedWisdom
  //   // "=",
  //   // serverWisdom?.likes
  // );
  const location = useLocation();
  const currentUrl = location?.pathname + location?.search;
  //  alert("wisdomCard>currentUrl=" + currentUrl);

  const buttonTextSell = "Sell";
  //
  const handleSellWisdomPrice = (event, updateAlertBanner) => {
    event.preventDefault(event);
    if (event.target.value >= 0) {
      //console.log("handleSellWisdomPrice>forSalePrice=", event.target.value);
      setForSalePriceEth(event.target.value);
    } else {
      updateAlertBanner("error: Price must be greater than 0");
    }
  }; // handle make wisdom for sale price

  //
  //
  //
  const sellWisdomServer = async (serverWisdom) => {
    //console.log("sellWisdomServer>wisdom=", serverWisdom);
    const webUser = loadWebUser();
    if (webUser) {
      serverWisdom.forSaleNow = true;
      const fields = {
        //wisdomId: serverWisdom?.wisdomId,
        forSalePrice: serverWisdom?.forSalePrice,
        saleStartDateMS: serverWisdom?.saleStartDateMS,
        saleEndDateMS: serverWisdom?.saleEndDateMS,
        forSaleNow: true,
      };
      //const { data } = await
      api.sellWisdom(serverWisdom._id, fields);
      //console.log("sellWisdomServer>after api.sellWisdom");
      setWisdomBusy(false);
      //console.log("sellWisdomServer>from server data=", data);
      //      console.log("sellWisdomServer>push now");
      // experiment
      //history.push(currentUrl);
      setWisdomRefresh(wisdomRefresh + 1);
      //window.location.reload();
    } else {
      setWisdomBusy(false);
      //alert("Sign in, then try again");
      setAlertBanner({
        children: null,
        type: "error",
        message: SIGN_IN_TO_ACCOUNT,
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    }
    return null;
  }; // sell Wisdom server
  //
  //
  const sellWisdomContract = async (serverWisdom, webUser) => {
    //console.log("sellWisdomContract>wisdomData=", serverWisdom);
    //var callbackCount = 0;
    //
    //
    if (library) {
      const signer = library.getSigner();
      if (doLog) {
        console.log("sellWisdomContract>signer=", signer);
        console.log("sellWisdomContract>wisdomaddress=", wisdomaddress);
        //      console.log("sellWisdomContract>signer=", signer);
      }
      const wisdomContract = new ethers.Contract(
        wisdomaddress,
        WisdomContract.abi,
        signer
      );

      if (!wisdomContract) {
        //alert("Error accessing smart contract. Please reload the webpage.");
        setAlertBanner({
          children: null,
          type: "error",
          message: "Error accessing smart contract. Please reload the webpage.",
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });

        return null;
      }

      const response = await api.checkAuth({ _id: webUser?._id });
      if (doLog)
        console.log("sellWisdomContract.CheckAuth>response=", response);
      if (!response?.data.result) {
        setAlertBanner({
          children: null,
          type: "error",
          message: SIGN_IN_TO_ACCOUNT_AGAIN,
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
        return false;
      }
      // convert forSalePrice from eth to wei
      serverWisdom.forSalePrice = forSalePriceEth;
      const forSalePriceWei = ethers.utils
        .parseEther(serverWisdom.forSalePrice.toString(), "wei")
        .toString();

      const forSaleChargeWei = ethers.utils
        .parseEther(sellCharge.toString(), "wei") //MAKE_WISDOM_FOR_SALE_CHARGE
        .toString();

      // console.log(
      //   "sellWisdomContract>converting",
      //   serverWisdom.forSalePrice,
      //   " eth to wei",
      //   forSalePriceWei
      // );
      const nowMS = Math.round(Date.now() / 1000);
      serverWisdom.saleStartDateMS = nowMS;
      //10000000000000000 = 0.01 MATIC
      const tx = await wisdomContract.sellWisdom(
        serverWisdom.wisdomId,
        true,
        forSalePriceWei,
        nowMS,
        0,
        {
          value: forSaleChargeWei,
        }
      );
      if (doLog) {
        console.log(
          "sellWisdomContract>serverWisdomId",
          serverWisdom.wisdomId.toString()
        );
        console.log("sellWisdomContract>transaction=", tx);
      }
      const receipt = await tx.wait(1);
      if (doLog) {
        console.log("sellWisdomContract>receipt=", receipt);

        // pull from the smart contract receipt: wisdomId, startSale, forSalePrice, saleStartDateMS, saleEndDateMS
        // determine from receipt that transaction completed
        //    and process returned values in the event
        console.log(
          "sellWisdomContract>   event count=",
          receipt?.events.length
        );
      }
      if (receipt?.events.length > 0) {
        // check each event?
        for (let i = 0; i < receipt.events.length; ++i) {
          // pull data from event
          // uint256 come back as BigNumber
          // wisdomId, forSalePrice, saleStartDateMS, saleEndDateMS
          const event = receipt.events[i];
          if (doLog) console.log("sellWisdomContract>   event=", event);
          if (event?.event === "SellWisdomEvent") {
            if (doLog)
              console.log(
                "sellWisdomContract>  event.wisdomId=",
                event?.args?.wisdomId.toString()
              );
            // event is for us!
            if (
              serverWisdom.wisdomId.toString() ===
              event?.args?.wisdomId.toString()
            ) {
              // event is for this wisdom

              // serverWisdom.wisdomId = parseInt(
              //   event?.args?.wisdomId.toString()
              // );
              serverWisdom.saleStartDateMS = parseInt(
                event?.args?.saleStartDateMS.toString()
              );
              // contract MAY override price or supply default
              //   price if user specified 0
              // contract sends price in wei, store in eth
              serverWisdom.forSalePrice = ethers.utils.formatUnits(
                event?.args?.forSalePrice.toString(),
                "ether"
              );
              serverWisdom.saleEndDateMS = parseInt(
                event?.args?.saleEndDateMS.toString()
              );
              //
              // **** update the server
              //
              if (doLog)
                console.log(
                  "sellWisdomContract>tell server now to start sale for ",
                  serverWisdom?.wisdomId
                );

              sellWisdomServer(serverWisdom);

              return true;
            } else {
              console.log(
                "sellWisdomContract>wrongWisdom=",
                event?.args?.wisdomId?.toString(),
                serverWisdom.wisdomId
              );
            }
          } else {
            console.log("sellWisdomContract>wrongEvent=", event?.event);
          }
        } // each event in receipt
      } else {
        console.log("sellWisdomContract>No Events In Receipt");
      }

      return false;
      // }
      // catch (err) {
      //   if (err?.data?.message) {
      //     const i = err.data.message.indexOf("custom error");
      //     if (i !== -1) {
      //       console.log(
      //         "sellWisdomContract>ERROR=",
      //         err?.data?.message.substring(i + 13)
      //       );
      //       alert(err?.data?.message.substring(i + 13));
      //     }
      //   }
      //   console.log("sellWisdomContract>ERROR=", err);
      // }
    } else {
      if (DO_LOG) console.log("sellWisdomContract>NO LIBRARY");
    }
    return false;
  }; // sell Wisdom contract
  //
  //
  // called when user clicks SUBMIT button on SellWisdomForm
  //
  const handleSellWisdom = async (event, closeModal, updateAlertBanner) => {
    event.preventDefault(event);
    //    const forSalePriceEth = event.target.forSalePrice.value || 0.2;
    //    console.log("handleSellWisdom>forSalePrice=", forSalePriceEth);
    if (SHOW_BETA_TWEET) {
      setAlertBanner({
        children: null,
        type: "info",
        message: COMING_SOON,
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    } else {
      const webUser = loadWebUser();
      if (webUser) {
        //const approverWallet = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";
        // mint contract function calls server funcion if successful
        if (updateAlertBanner) {
          updateAlertBanner(
            "Confirm transaction in your crpyto wallet then wait on this page for the result."
          );
        }
        try {
          // sell wisdom contract calls sell wisdom server
          const receipt = await sellWisdomContract(serverWisdom, webUser);
          if (!receipt) {
            try {
              if (closeModal) closeModal();
            } catch (err) {
              console.log("handleSellWisdom>ERROR closing dialog box", err);
            }
            serverWisdom.forSaleNow = false;

            //alert("Error making Wisdom for sale to contract. Please try again.");
            setAlertBanner({
              children: null,
              type: "error",
              message:
                "Error making Wisdom for sale to contract. Please try again.",
              showNow: true,
              theKey: alertKey,
              handleClose: handleCloseAlert,
            });
          } else {
            // success!
            setAlertBanner({
              children: null,
              type: "success",
              message: "It's now for sale. Good luck!",
              showNow: true,
              theKey: alertKey,
              handleClose: handleCloseAlert,
            });
            //setWisdomRefresh(wisdomRefresh+1);
          }
        } catch (err) {
          serverWisdom.forSaleNow = false;
          if (closeModal) closeModal();
          setAlertBanner({
            children: null,
            type: "error",
            message:
              "Error making Wisdom for sale to contract. Please try again.",
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });
        }
      } else {
        if (closeModal) closeModal();
        serverWisdom.forSaleNow = false;

        setAlertBanner({
          children: null,
          type: "error",
          message: SIGN_IN_TO_ACCOUNT,
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
      }
    }
  }; // handle make wisdom for sale
  //
  //
  //
  const handleHideWisdom = async () => {
    if (SHOW_BETA_TWEET) {
      setAlertBanner({
        children: null,
        type: "info",
        message: COMING_SOON,
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    } else {
      const nowMS = Math.round(Date.now() / 1000);
      dispatch(hideWisdom(serverWisdom?._id));
      serverWisdom.hidden = !serverWisdom.hidden;
      serverWisdom.saleStartDateMS = serverWisdom?.hidden
        ? DATE_MS_12_31_2099
        : nowMS + 3 * SECONDS_PER_DAY;
      setHidden(serverWisdom?.hidden);
      setProfileValue("preventAnimation", true);
      // alertBanner
      if (serverWisdom.hidden) {
        setAlertBanner({
          children: null,
          type: "info",
          message:
            "View your hidden Wisdoms by searching for 'myhidden' on the Minted page.",
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
      } else {
        setAlertBanner({
          children: null,
          type: "info",
          message: "This Wisdom is now visible to the public." + hidden,
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
      }
    }
  }; // handle hide wisdom
  //
  //
  //
  const buyWisdomServer = async (serverWisdom, freeBuy, shareString) => {
    //console.log("buyWisdomServer>formWisdom=", serverWisdom);
    const webUser = loadWebUser();
    if (webUser && serverWisdom) {
      // process string of sharePaid values (convert from WEI to MATIC)
      const sharesArray = shareString.split(",");

      const authorSharePaid = Number(
        ethers.utils.formatUnits(sharesArray[0], "ether")
      );
      const sellerSharePaid = Number(
        ethers.utils.formatUnits(sharesArray[1], "ether")
      );
      const previousOwnerSharePaid = Number(
        ethers.utils.formatUnits(sharesArray[2], "ether")
      );
      const pioneerSharePaid = Number(
        ethers.utils.formatUnits(sharesArray[3], "ether")
      );

      serverWisdom.status = MINTED_STRING;
      const fields = {
        id: serverWisdom._id,
        ownerId: webUser._id,
        ownerName: webUser.name,
        ownerWallet: webUser.wallet,
        saleStartDateMS: serverWisdom.saleStartDateMS,
        saleEndDateMS: serverWisdom.saleEndDateMS,
        forSalePrice: serverWisdom.forSalePrice,
        lastSoldPrice: serverWisdom.lastSoldPrice,
        forSaleNow: false,
        currentValue: serverWisdom.currentValue,
        freeBuy: freeBuy,
        authorSharePaid: authorSharePaid,
        sellerSharePaid: sellerSharePaid,
        previousOwnerSharePaid: previousOwnerSharePaid,
        pioneerSharePaid: pioneerSharePaid,
      };

      if (freeBuy) {
        // TODO check server updated, no error
        if (isOnList(webUser, perm.FREEBUY_WISDOM.name)) {
          // reduce count by 1
          updateListCount(webUser, perm.FREEBUY_WISDOM.name, -1);
        }
      }
      await api.buyWisdom(serverWisdom._id, fields);
      //const { data } =
      //TODO: check result success
      //console.log("buyWisdomServer>after api.buyWisdom");
      setWisdomBusy(false);
      //      console.log("buyWisdomServer>from server data=", data);
      //      console.log("buyWisdomServer>push now");

      //history.push(currentUrl);
    } else {
      //alert("Sign in, then try again");
      setAlertBanner({
        children: null,
        type: "error",
        message: SIGN_IN_TO_ACCOUNT,
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    }
    return null;
  }; // buy wisdom server
  //
  // propose no longer in contract, so mint builds the wisdom on chain
  //  similar to how propose originally did
  //
  const buyWisdomContract = async (serverWisdom, webUser, freeBuy) => {
    //console.log("buyWisdomContract>wisdom=", serverWisdom);
    //wisdomData.wisdomId = 111;
    //var callbackCount = 0;
    //
    //
    if (library) {
      const provider = await library;

      const signer = library.getSigner();
      const makeItWisdomContract = new ethers.Contract(
        makeitwisdomaddress,
        MakeItWisdomContract.abi,
        signer
      );
      if (!makeItWisdomContract) {
        //alert("Error accessing smart contract. Please reload the webpage.");
        setAlertBanner({
          children: null,
          type: "error",
          message: "Error accessing smart contract. Please reload the webpage.",
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });

        return false;
      }

      const response = await api.checkAuth({ _id: webUser?._id });
      if (doLog) console.log("buyWisdomContract.CheckAuth>response=", response);
      if (!response?.data.result) {
        setAlertBanner({
          children: null,
          type: "error",
          message: SIGN_IN_TO_ACCOUNT_AGAIN,
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
        return false;
      }

      const newCurrentValue = serverWisdom?.forSalePrice;

      try {
        // convert forSalePrice from eth to wei
        const forSalePriceWei =
          isOnList(webUser, perm.FREEBUY_WISDOM.name) && freeBuy
            ? 0
            : ethers.utils
                .parseEther(newCurrentValue.toString(), "wei")
                .toString();

        // console.log(
        //   "buyWisdomContract>converting",
        //   serverWisdom?.forSalePrice,
        //   " eth to wei",
        //   forSalePriceWei
        // );

        if (forSalePriceWei > 0) {
          //
          // wallet balance
          //
          if (webUser?.wallet !== "") {
            walletBalanceWei = await provider.getBalance(webUser?.wallet);
            walletBalanceWei = walletBalanceWei.toString();
            walletBalanceEth = ethers.utils.formatEther(walletBalanceWei);
            //setWalletBalanceEth(walletBalance);
          }
        }

        if (walletBalanceEth < newCurrentValue) {
          // prevent transaction
          setAlertBanner({
            children: null,
            type: "error",
            message: "Insufficient balance in your crypto wallet",
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });

          return false;
        } else {
          if (doLog)
            console.log(
              "WisdomCard.buyWisdomContract>balance=",
              walletBalanceEth,
              ">=",
              newCurrentValue
            );
        }

        const nowMS = Math.round(Date.now() / 1000);

        //20000000000000000 = 0.02 MATIC
        const tx = await makeItWisdomContract.safeBuyWisdom(
          serverWisdom.wisdomId,
          nowMS,
          {
            value: forSalePriceWei,
          }
        );

        const receipt = await tx.wait(1);
        // console.log("buyWisdomContract>OK DONE. transaction=", tx);
        // console.log("buyWisdomContract>OK DONE. receipt=", receipt);
        // determine from receipt that transaction completed
        //    and process returned values in the event
        if (receipt?.events.length > 0) {
          // check each event?
          for (let i = 0; i < receipt.events.length; ++i) {
            // pull data from event
            // uint256 come back as BigNumber
            // wisdomId, forSalePrice, saleStartDateMS, saleEndDateMS
            const event = receipt.events[i];
            //console.log("buyWisdomContract>   event=", event);
            if (event?.event === "SafeBuyWisdomEvent") {
              // event is for us!
              // uint256 come to us as BigNumber, so .toString() gets just number
              //    and Number() turns to number
              if (
                serverWisdom.wisdomId.toString() ===
                event?.args?.wisdomId.toString()
              ) {
                // event is for this wisdom, so update fields
                serverWisdom.ownerId = webUser?._id;
                serverWisdom.ownerName = webUser?.name;
                serverWisdom.ownerWallet = webUser?.wallet;
                serverWisdom.saleStartDateMS = parseInt(
                  event?.args?.saleStartDateMS.toString()
                );
                serverWisdom.saleEndDateMS = parseInt(
                  event?.args?.saleEndDateMS.toString()
                );
                serverWisdom.currentValue = newCurrentValue;
                // contract sends price in wei, store in eth
                // console.log(
                //   "buyWisdomContract>",
                //   event?.args?.forSalePrice,
                //   event?.args?.forSalePrice.toString()
                // );
                serverWisdom.lastSoldPrice = serverWisdom.forSalePrice;
                // contract stores in WEI, database in full coin, so convert
                serverWisdom.forSalePrice = Number(
                  ethers.utils.formatUnits(
                    event?.args?.forSalePrice.toString(),
                    "ether"
                  )
                );
                serverWisdom.forSaleNow = false;
                setForSalePriceEth(serverWisdom.forSalePrice);
                //serverWisdom.forSalePrice = parseInt(forSalePrice.toString());

                //
                // **** update the server
                //
                buyWisdomServer(
                  serverWisdom,
                  freeBuy,
                  event?.args?.shareString
                );
              } else {
                console.log(
                  "buyWisdomContract>wrongWisdom=",
                  event?.args?.wisdomId?.toString(),
                  serverWisdom.wisdomId
                );
              }
            } else {
              if (event?.event) {
                console.log("buyWisdomContract>wrongEvent=", event.event);
              }
            }
          } // each event in receipt
        } else {
          console.log("buyWisdomContract>No Events In Receipt");
        }
        setWisdomBusy(false);

        return true; // receipt;
      } catch (err) {
        // err.code, err.message used when user reject transaction (error from Wallet)
        // err.data.code and err.data.message used when error fron smart contract!
        // TODO: handle err AND err.data !!
        // TODO: code -32603 (-32000) means nonce out of sync
        //
        //console.log("buyWisdomContract>ERROR", err);
        var resultCode;
        var resultMessage;

        if (err?.data) {
          resultCode = err?.data?.code;
          resultMessage = err?.data?.message;
        } else {
          resultCode = err?.code;
          resultMessage = err?.message;
        }
        if (resultCode) {
          const i = resultMessage.indexOf("custom error");
          if (i !== -1) {
            console.log(
              "buyWisdomContract>ERROR1=",
              resultMessage.substring(i + 13)
            );
            console.log("buyWisdomContract>ERROR2 code=", resultCode);
            if (resultCode !== 4001) {
              //alert(resultMessage.substring(i + 13));
              setAlertBanner({
                children: null,
                type: "error",
                message: SIGN_IN_TO_ACCOUNT,
                showNow: true,
                theKey: alertKey,
                handleClose: handleCloseAlert,
              });
            } else {
              // user rejected xaction in their crypto wallet
              setWisdomBusy(false);
              setAlertBanner({
                children: null,
                type: "error",
                message: "Purchase cancelled",
                showNow: true,
                theKey: alertKey,
                handleClose: handleCloseAlert,
              });
              // tell server to stop the sale
              //const response =
              await api.stopBuyWisdom(serverWisdom?._id);
              return false;
            }
          } else {
            //console.log("buyWisdomContract>ERROR3 code=", resultCode);
            if (resultCode === 4001) {
              //user rejected xacton from their crypto wallet
              setWisdomBusy(false);
              setAlertBanner({
                children: null,
                type: "error",
                message: "Purchase cancelled",
                showNow: true,
                theKey: alertKey,
                handleClose: handleCloseAlert,
              });
              // tell server to stop the sale
              //const response =
              await api.stopBuyWisdom(serverWisdom?._id);
              return false;
            }
          }
        }
        //        console.log("buyWisdomContract>ERROR4=", err);
        //        console.log("buyWisdomContract>ERROR5 code=", err?.code);
        if (resultCode === 4001) {
          setWisdomBusy(false);
          return false;
        }
      } // catch error
    } else {
      if (DO_LOG) console.log("buyWisdomContract>NO LIBRARY");
    }
    setWisdomBusy(false);
    return false;
  }; // buy wisdom contract
  //
  //
  //
  const handleBuyWisdom = async (freeBuy) => {
    //console.log("handleBuyWisdom>wisdom", serverWisdom, "freeBuy=", freeBuy);

    if (SHOW_BETA_TWEET) {
      setAlertBanner({
        children: null,
        type: "info",
        message: COMING_SOON,
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    } else {
      const webUser = loadWebUser();
      //const approverWallet = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";
      // mint wisdom contract calls mint wisdom server if successful
      // TODO: MAYBE the buy from contract logic should be moved to SERVER SIDE
      //    so process is more robust?
      //
      //console.log("handleBuyWisdom.startBuyWisdom>");
      // server must confirm that this wisdom is NOT already being bought
      //  by someone else.
      // response.result = true/false
      // response.resultCode = 0, STATUS_CODE_USER_IS_UNAUTH, STATUS_CODE_BUY_IN_PROGRESS,
      // response.message = text
      const response = await api.startBuyWisdom(serverWisdom?._id);
      if (doLog)
        console.log("handleBuyWisdom.startBuyWisdom>response=", response);
      if (!response?.data.result) {
        setAlertBanner({
          children: null,
          type: "error",
          message:
            response?.data.resultCode === STATUS_CODE_USER_IS_UNAUTH
              ? SIGN_IN_TO_ACCOUNT_AGAIN
              : "Wisdom purchase already in progress.",
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
        return null;
      } else {
        if (doLog) console.log("handleBuyWisdom> purchase started now!!");
      }

      setWisdomBusy(true);
      try {
        // buyWisdomContract launches buyWisdomServer if contract ok
        //  also handles error messages
        const result = await buyWisdomContract(serverWisdom, webUser, freeBuy);
        // if (!result) {
        //   //alert("Error buying Wisdom through contract. Please try again.");
        //   setAlertBanner({
        //     children: null,
        //     type: "error",
        //     message:
        //       "Error buying Wisdom through smart contract. Please try again.",
        //     showNow: true,
        //     theKey: alertKey,
        //     handleClose: handleCloseAlert,
        //   });
        // } else {
        if (result) {
          // success!
          setWisdomBusy(false);
          setAlertBanner({
            children: null,
            type: "success",
            message: "Now you own it!",
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });
          // TODO: reload WisdomCard so new owner name displayed!
          setTimeout(() => {
            //history.push(currentUrl);
            if (doLog) console.log("WisdomCard>handleBuyWisdom success!");
            setWisdomRefresh(wisdomRefresh + 1);
            //history.go(0);
          }, 3000);
        }
      } catch (err) {
        //
        setWisdomBusy(false);
        setAlertBanner({
          children: null,
          type: "error",
          message:
            "Error buying Wisdom through smart contract. Please try again.",
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
      }
    }
  }; // handle buy wisdom
  //
  //
  //
  const stopSellWisdomServer = async (serverWisdom) => {
    if (doLog) console.log("stopSellWisdomServer>wisdom=", serverWisdom);

    if (SHOW_BETA_TWEET) {
      setAlertBanner({
        children: null,
        type: "info",
        message: COMING_SOON,
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    } else {
      const webUser = loadWebUser();
      if (webUser) {
        try {
          serverWisdom.forSaleNow = false;
          const fields = {
            //wisdomId: serverWisdom?.wisdomId,
            forSalePrice: serverWisdom?.forSalePrice,
            saleStartDateMS: serverWisdom?.saleStartDateMS,
            saleEndDateMS: serverWisdom?.saleEndDateMS,
            forSaleNow: false,
          };
          //const { data } = await
          api.sellWisdom(serverWisdom._id, fields);

          setWisdomBusy(false);
          //console.log("stopSellWisdomServer>from server data=", data);
          //history.push("/wisdoms?pageReload=true");
          //      console.log("stopSellWisdomServer>push now");
          //      history.push("/wisdoms/?pageReload=true");
          history.push(currentUrl);
          //      window.location.reload();
        } catch {
          setAlertBanner({
            children: null,
            type: "error",
            message: "Error stopping Wisdom sale. Please try again.",
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });
        }
      } else {
        //alert("Sign in, then try again");
        setAlertBanner({
          children: null,
          type: "error",
          message: SIGN_IN_TO_ACCOUNT,
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
      }
    }
    return null;
  }; // stop sell Wisdom server
  //
  //
  const stopSellWisdomContract = async (serverWisdom, webUser) => {
    if (doLog) console.log("stopSellWisdomContract>wisdomData=", serverWisdom);
    //var callbackCount = 0;
    //
    //
    if (!webUser) return null;

    if (library) {
      const signer = library.getSigner();
      const wisdomContract = new ethers.Contract(
        wisdomaddress,
        WisdomContract.abi,
        signer
      );

      if (!wisdomaddress) {
        //alert("Error accessing smart contract. Please reload the webpage.");
        return null;
      }

      const response = await api.checkAuth({ _id: webUser?._id });
      if (doLog) console.log("CheckAuth>response=", response);
      if (!response?.data.result) {
        setAlertBanner({
          children: null,
          type: "error",
          message: SIGN_IN_TO_ACCOUNT_AGAIN,
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
        return null;
      }

      try {
        // convert mintPrice from eth to wei
        // serverWisdom.forSalePrice = forSalePriceEth;
        // const forSalePriceWei = ethers.utils
        //   .parseEther(serverWisdom.forSalePrice.toString(), "wei")
        //   .toString();

        // const forSaleChargeWei = ethers.utils
        //   .parseEther(MAKE_WISDOM_FOR_SALE_CHARGE.toString(), "wei")
        //   .toString();

        serverWisdom.forSalePrice = forSalePriceEth;
        const forSalePriceWei = ethers.utils
          .parseEther(serverWisdom.forSalePrice.toString(), "wei")
          .toString();

        // const forSaleChargeWei = ethers.utils
        //   .parseEther(MAKE_WISDOM_FOR_SALE_CHARGE.toString(), "wei")
        //   .toString();

        // console.log(
        //   "stopSellWisdomContract>converting",
        //   serverWisdom.forSalePrice,
        //   " eth to wei",
        //   forSalePriceWei
        // );
        const nowMS = Math.round(Date.now() / 1000);
        serverWisdom.saleStartDateMS = nowMS;
        //20000000000000000 = 0.02 MATIC

        const tx = await wisdomContract.sellWisdom(
          serverWisdom.wisdomId,
          false,
          forSalePriceWei,
          nowMS,
          0
          // {
          //   value: 0, // forSaleChargeWei,
          // }
        );

        const receipt = await tx.wait(1);

        if (doLog) {
          console.log("stopSellWisdomContract>OK DONE. transaction=", tx);
          console.log("stopSellWisdomContract>OK DONE. receipt=", receipt);
          //
        }
        // determine from receipt that transaction completed
        //    and process returned values in the event
        if (receipt?.events.length > 0) {
          // check each event?
          for (let i = 0; i < receipt.events.length; ++i) {
            // pull data from event
            // uint256 come back as BigNumber
            // wisdomId, forSalePrice, saleStartDateMS, saleEndDateMS
            const event = receipt.events[i];
            //console.log("stopSellWisdomContract>   event=", event);
            if (event?.event === "SellWisdomEvent") {
              // event is for us!
              if (
                serverWisdom.wisdomId.toString() ===
                event?.args?.wisdomId.toString()
              ) {
                // event is for this wisdom
                serverWisdom.forSaleNow = false;
                serverWisdom.saleStartDateMS = parseInt(
                  event?.args?.saleStartDateMS.toString()
                );
                serverWisdom.saleEndDateMS = parseInt(
                  event?.args?.saleEndDateMS.toString()
                );
                // contract MAY override price or supply default
                //   price if user specified 0
                // contract sends price in wei, store in eth
                serverWisdom.forSalePrice = ethers.utils.formatUnits(
                  event?.args?.forSalePrice.toString(),
                  "ether"
                );
                // update the server
                stopSellWisdomServer(serverWisdom);
              } else {
                console.log(
                  "stopSellWisdomContract>wrongWisdom=",
                  event?.args?.wisdomId?.toString(),
                  serverWisdom.wisdomId
                );
              }
            } else {
              if (event?.event) {
                console.log("stopSellWisdomContract>wrongEvent=", event?.event);
              }
            }
          } // each event in receipt
        } else {
          console.log("stopSellWisdomContract>No Events In Receipt");
        }
        setWisdomBusy(false);
        return receipt;
      } catch (err) {
        setWisdomBusy(false);
        if (err?.data?.message) {
          const i = err.data.message.indexOf("custom error");
          if (i !== -1) {
            console.log(
              "stopSellWisdomContract>ERROR=",
              err?.data?.message.substring(i + 13)
            );
            //alert(err?.data?.message.substring(i + 13));
            setAlertBanner({
              children: null,
              type: "error",
              message: err?.data?.message.substring(i + 13),
              showNow: true,
              theKey: alertKey,
              handleClose: handleCloseAlert,
            });
          }
        }
        if (err?.code === 4001) {
          setWisdomBusy(false);
          return true;
        }
        console.log("stopSellWisdomContract>ERROR=", err);
      }
    } else {
      if (DO_LOG) console.log("stopSellWisdomContract>NO LIBRARY");
    }
    setWisdomBusy(false);
    return null;
  }; // stop sell Wisdom contract
  //
  //
  //
  // const DotProgressImage = () => {
  //   return (
  //     <img src={dotProgressIcon} alt="dot" width="25px" height="25px"></img>
  //   );
  // };

  const handleStopSellWisdom = async (event) => {
    event.preventDefault(event);
    //    const forSalePriceEth = event.target.forSalePrice.value || 0.2;
    //console.log("handleStopSellWisdom>forSalePrice=", forSalePriceEth);

    if (SHOW_BETA_TWEET) {
      setAlertBanner({
        children: null,
        type: "info",
        message: COMING_SOON,
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    } else {
      const webUser = loadWebUser();
      if (webUser) {
        //const approverWallet = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";
        // mint contract function calls server funcion if successful
        setWisdomBusy(true);
        const receipt = await stopSellWisdomContract(serverWisdom, webUser);
        if (!receipt) {
          //alert("Error stopping Wisdom sale in contract. Please try again.");
          setAlertBanner({
            children: null,
            type: "error",
            message:
              "Error stopping Wisdom sale in contract. Please try again.",
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });
        } else {
          // success!
          setAlertBanner({
            children: null,
            type: "success",
            message: "Sale stopped.",
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });
        }
      } else {
        //alert("Sign in and try again.");
        setAlertBanner({
          children: null,
          type: "error",
          message: SIGN_IN_TO_ACCOUNT,
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
      }
    }
  }; // handle stop sell
  //
  //
  // experiment DotProgress
  //
  // const DotProgress = () => {
  //   return (
  //     <div className={classes.animateDotProgressLeftToRight}>
  //       <DotProgressImage />
  //     </div>
  //   );
  // }; // Dot Progress

  //
  // all voting could be new component WisdomVotes
  //
  //
  //
  const handleVote = async (event, voteType, voteTypeCount) => {
    event.preventDefault();

    if (SHOW_BETA_TWEET) {
      setAlertBanner({
        children: null,
        type: "info",
        message: COMING_SOON + ": " + voteType,
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    } else {
      const webUser = loadWebUser();
      if (!webUser) {
        //alert("Sign in to vote: " + voteType);
        setAlertBanner({
          children: null,
          type: "error",
          message: SIGN_IN_TO_ACCOUNT,
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });
        setProfileValue("preventAnimation", true);
        //history.go(0);
        //return;
      } else {
        const hasVotedWisdom =
          webUser[voteType].find((vote) => vote === serverWisdom?._id) || false;

        if (hasVotedWisdom) {
          //console.log("handleVote>", voteType, "removing ", serverWisdom?._id);
          webUser[voteType] = webUser[voteType].filter(
            (id) => id !== serverWisdom?._id
          );
          // save to local storage
          setProfileValue(voteType, webUser[voteType]);
          setProfileValue(voteTypeCount, --webUser[voteTypeCount]);
          //change wisdom
          serverWisdom[voteTypeCount]--;
          serverWisdom[voteType] = [];
        } else {
          //console.log("handleVote>", voteType, "adding ", serverWisdom?._id);
          //setLikes([...webUser[voteType], serverWisdom?._id]);
          // save to local storage
          setProfileValue(voteTypeCount, ++webUser[voteTypeCount]);
          webUser[voteType].push(serverWisdom?._id);
          setProfileValue(voteType, webUser[voteType]);
          // change wisdom
          serverWisdom[voteTypeCount]++;
          serverWisdom[voteType] = [webUser._id];
        }
        // prevent animation on Wisdoms page refresh
        setProfileValue("preventAnimation", true);
        // TODO: add server confirmation & error handling
        //  (might not actually be logged in)
        //
        //    const webUserAfter = loadWebUser();
        //console.log("handleVote>webUserAfter", webUserAfter);
        // original way updates state w/ wisdom voteWisdom returns
        //dispatch(voteWisdom(serverWisdom?._id, voteType, voteTypeCount));
        // skip dispatch, hit server directly
        try {
          const { data } = await api.voteWisdom(
            serverWisdom._id,
            { voteType: voteType, voteTypeCount: voteTypeCount },
            webUser?.token
          );
          // copy new over old?
          //serverWisdom = data;
          //maybe just the vote counts
          serverWisdom.likesCount = data.likesCount;
          serverWisdom.feelsCount = data.feelsCount;
          serverWisdom.thinksCount = data.thinksCount;
          serverWisdom.cheersCount = data.cheersCount;
        } catch (err) {
          //
          if (!webUser?.token) {
            setAlertBanner({
              children: null,
              type: "error",
              message: SIGN_IN_TO_ACCOUNT,
              showNow: true,
              theKey: alertKey,
              handleClose: handleCloseAlert,
            });
          } else {
            setAlertBanner({
              children: null,
              type: "error",
              message: "Server error. Please try again later.",
              showNow: true,
              theKey: alertKey,
              handleClose: handleCloseAlert,
            });
          }
        }

        setTheVoteCount(theVoteCount + 1);
        //history.go(0);
      }
    }
  }; // handle vote

  //
  const VoteIcons = {
    cheersMe: () => {
      return (
        <img
          src={cheersMeIcon}
          alt="cheers"
          width="35px"
          height="35px"
          //style={{ backgroundColor: "#D4AF37" }}
        ></img>
      );
    },
    feelsMe: () => {
      return (
        <img
          src={feelsMeIcon}
          alt="feels"
          width="35px"
          height="35px"
          //style={{ backgroundColor: "#D4AF37" }}
        ></img>
      );
    },
    likesMe: () => {
      return (
        <img
          src={likesMeIcon}
          alt="likes"
          width="35px"
          height="35px"
          //style={{ backgroundColor: "#D4AF37" }}
        ></img>
      );
    },
    thinksMe: () => {
      return (
        <img
          src={thinksMeIcon}
          alt="thinks"
          width="35px"
          height="35px"
          //style={{ backgroundColor: "#D4AF37" }}
        ></img>
      );
    },
    cheersNoMe: () => {
      return (
        <img
          src={cheersNoMeIcon}
          alt="cheers"
          width="35px"
          height="35px"
          //style={{ backgroundColor: "#D4AF37" }}
        ></img>
      );
    },
    feelsNoMe: () => {
      return (
        <img
          src={feelsNoMeIcon}
          alt="feels"
          width="35px"
          height="35px"
          //style={{ backgroundColor: "#D4AF37" }}
        ></img>
      );
    },
    likesNoMe: () => {
      return (
        <img
          src={likesNoMeIcon}
          alt="likes"
          width="35px"
          height="35px"
          //style={{ backgroundColor: "#D4AF37" }}
        ></img>
      );
    },
    thinksNoMe: () => {
      return (
        <img
          src={thinksNoMeIcon}
          alt="thinks"
          width="35px"
          height="35px"
          //style={{ backgroundColor: "#D4AF37" }}
        ></img>
      );
    },
  };

  //
  const Votes = ({ voteType, voteTypeCount, wisdomId }) => {
    const webUser = loadWebUser();
    //    const voteTypeSingle = voteType.substring(0, voteType.length - 1);
    // if (serverWisdom?.wisdomText == "test1") {
    //   console.log("Votes>=", voteType, voteTypeCount, "webUser=", webUser);
    // }
    if (voteTypeCount === "likesCount") {
      //console.log("Votes>dom id", voteTypeCount + wisdomId.toString());
    }
    const ThisVoteIconNoMe = VoteIcons[voteType + "NoMe"];
    const ThisVoteIconMe = VoteIcons[voteType + "Me"];
    if (webUser) {
      if (serverWisdom[voteTypeCount] > 0) {
        return webUser[voteType]?.find((vote) => vote === serverWisdom?._id) ? (
          <div className={classes.voteFlex}>
            <ThisVoteIconMe />
            <div
              id={voteTypeCount + wisdomId.toString()}
              style={{ display: "block" }}
            >
              {serverWisdom[voteTypeCount]}{" "}
            </div>
          </div>
        ) : (
          <div className={classes.voteFlex}>
            <ThisVoteIconNoMe />
            <div
              id={voteTypeCount + wisdomId.toString()}
              style={{ display: "block" }}
            >
              {serverWisdom[voteTypeCount]}{" "}
            </div>
          </div>
        );
      }
    }
    if (serverWisdom[voteTypeCount] === 0) {
      return (
        <div className={classes.voteFlex}>
          <ThisVoteIconNoMe />
          <div
            id={voteTypeCount + wisdomId.toString()}
            style={{ display: "none" }}
          >
            {serverWisdom[voteTypeCount]}{" "}
          </div>
        </div>
      );
    } else {
      //
      return (
        <div className={classes.voteFlex}>
          <ThisVoteIconNoMe />
          <div
            id={voteTypeCount + wisdomId.toString()}
            style={{ display: "block" }}
          >
            {serverWisdom[voteTypeCount]}{" "}
          </div>
        </div>
      );
    }
  }; // Votes

  const openWisdom = (e) => {
    e.stopPropagation();
    dispatch(
      getWisdom(
        serverWisdom?._id,
        serverWisdom?.wisdomId,
        webUser?.name || "unk",
        history
      )
    );
    //console.log("openWisdom>", serverWisdom?._id);
    history.push(`/wisdoms/${serverWisdom?._id}`);
  };

  const canUserEditWisdom = (webUser, serverWisdom) => {
    // if (isUserOwner(webUser)) {
    //   //console.log("canUserEditWisdom>user is owner!");
    //   return true;
    // }
    // else {
    //   //console.log("canUserEditWisdom>user is NOT owner!");
    // }
    if (isUserWisdomOwner(webUser, serverWisdom)) {
      // console.log(
      //   "canUserEditWisdom>user is wisdom owner!",
      //   serverWisdom?.wisdomText
      // );
      return isWisdomMinted(serverWisdom);
      //||
      //isWisdomProposed(serverWisdom) ||
      //isWisdomDeclined(serverWisdom)
    }
    //else {
    // console.log(
    //   "canUserEditWisdom>user is NOT wisdom owner!",
    //   serverWisdom?.wisdomText
    // );
    //}
    return false;
  }; // can user edit wisdom
  //
  const canUserHideWisdom = (webUser, serverWisdom) => {
    return (
      isWisdomMinted(serverWisdom) &&
      isUserWisdomOwner(webUser, serverWisdom) &&
      isUserWisdomAuthor(webUser, serverWisdom) &&
      !isWisdomHidden(serverWisdom) &&
      !isWisdomForSale(serverWisdom)
    );
  };
  //
  const canUserUnHideWisdom = (webUser, serverWisdom) => {
    return (
      isWisdomMinted(serverWisdom) &&
      isUserWisdomOwner(webUser, serverWisdom) &&
      isUserWisdomAuthor(webUser, serverWisdom) &&
      isWisdomHidden(serverWisdom) &&
      !isWisdomForSale(serverWisdom)
    );
  };
  //
  const canUserStopSellWisdom = (webUser, serverWisdom) => {
    return (
      isWisdomForSale(serverWisdom) && isUserWisdomOwner(webUser, serverWisdom)
    );
  };
  //
  const canUserSellWisdom = (webUser, serverWisdom) => {
    return (
      isWisdomMinted(serverWisdom) &&
      isUserWisdomOwner(webUser, serverWisdom) &&
      !isWisdomHidden(serverWisdom) &&
      !isWisdomForSale(serverWisdom)
      //!serverWisdom?.forSaleNow
    );
  };
  //
  const canUserBuyWisdom = (webUser, serverWisdom) => {
    if (serverWisdom?._id === "6281e23788b50b0df0555b62") {
      //console.log("canUserBuyWisdom>webUser=", webUser);
      //console.log("canUserBuyWisdom>serverWisdom=", serverWisdom);
    }
    return (
      webUser &&
      isWisdomForSale(serverWisdom) &&
      !isWisdomHidden(serverWisdom) &&
      !isOnList(webUser, perm.NOBUY_WISDOM.name) &&
      !isUserWisdomOwner(webUser, serverWisdom)
    );
  };
  //
  // const isAuthorOwner = (serverWisdom) => {
  //   return serverWisdom.authorName === serverWisdom.ownerName;
  // };
  // const isUserSellingWisdom = (webUser, serverWisdom) => {
  //   return (
  //     isWisdomForSale(serverWisdom) &&
  //     !isWisdomHidden(serverWisdom) &&
  //     isUserWisdomOwner(webUser, serverWisdom)
  //   );
  // };
  //
  //
  const mintWisdomServer = async (serverWisdom, freeMint) => {
    //console.log("mintWisdomServer>formWisdom=", serverWisdom);
    const webUser = loadWebUser();
    if (webUser) {
      serverWisdom.status = MINTED_STRING;
      const fields = {
        status: MINTED_STRING,
        wisdomId: serverWisdom.wisdomId,
        approverId: serverWisdom.approverId,
        approverSharePaid: serverWisdom.approverSharePaid,
        exchangeSharePaid: serverWisdom.exchangeSharePaid,
        authorWallet: webUser?.wallet,
        ownerWallet: webUser?.wallet,
        authorName: webUser?.name,
        ownerName: webUser?.name,
        mintPrice: serverWisdom.mintPrice,
        mintedDateMS: serverWisdom.mintedDateMS,
        saleStartDateMS: serverWisdom.saleStartDateMS,
        saleEndDateMS: serverWisdom.saleEndDateMS,
        forSalePrice: serverWisdom.forSalePrice,
        forSaleNow: false,
      };
      const resp = await api.mintWisdom(serverWisdom._id, fields);
      //
      try {
        if (resp?.status === 201) {
          //console.log("mintWisdomServer>resp=", resp);
          serverWisdom.mintedDateMS = resp.data.mintedDateMS;
          // TODO: check response success
          if (freeMint) {
            // TODO check server updated, no error
            if (isOnList(webUser, perm.FREEMINT_WISDOM)) {
              // reduce count by 1
              updateListCount(webUser, perm.FREEMINT_WISDOM.name, -1);
            }
          }
          setWisdomBusy(false);
          if (doLog) {
            console.log("mintWisdomServer>from server resp=", resp);
            console.log("mintWisdomServer>push now");
          }
          setAlertBanner({
            children: null,
            type: "success",
            message: "Wisdom minted! Congrats!",
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });
          setTimeout(() => {
            history.push(currentUrl);
          }, 5000);
        } else {
          setWisdomBusy(false);
          //alert("Server error. Try again.");
          setAlertBanner({
            children: null,
            type: "error",
            message: SIGN_IN_TO_ACCOUNT,
            showNow: true,
            theKey: alertKey,
            handleClose: handleCloseAlert,
          });
        }
      } catch (err) {
        //
        setWisdomBusy(false);
        console.log("mintWisdom.ERROR:", err);
      }
    } else {
      //alert("Sign in, then try again");
      setAlertBanner({
        children: null,
        type: "error",
        message: SIGN_IN_TO_ACCOUNT,
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    }

    return null;
  }; // mint wisdom server
  //
  // propose no longer in contract, so mint builds the wisdom on chain
  //  similar to how propose originally did
  //
  // return false if caller error generic message needed
  //    return true means all good OR fail, but error message given
  //
  const mintWisdomContract = async (serverWisdom, webUser, freeMint) => {
    if (doLog)
      console.log(
        "mintWisdomContract>wisdomData=",
        serverWisdom,
        "webUser=",
        webUser,
        "freeMint=",
        freeMint
      );
    //
    //
    if (library) {
      const signer = library.getSigner();
      // console.log("mintWisdomContract>");
      // console.log("mintWisdomContract>signer=", signer);

      const wisdomContract = new ethers.Contract(
        wisdomaddress,
        WisdomContract.abi,
        signer
      );
      if (!wisdomContract) {
        //alert("Error accessing smart contract. Please reload the webpage.");
        setAlertBanner({
          children: null,
          type: "error",
          message: "Error accessing smart contract. Please signin again.",
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });

        return true;
      }

      const response = await api.checkAuth({ _id: webUser?._id });
      if (doLog)
        console.log("mintWisdomContract.CheckAuth>response=", response);
      if (!response?.data.result) {
        setAlertBanner({
          children: null,
          type: "error",
          message: SIGN_IN_TO_ACCOUNT_AGAIN,
          showNow: true,
          theKey: alertKey,
          handleClose: handleCloseAlert,
        });

        return true;
      }

      try {
        // convert mintPrice from eth to wei
        const mintPriceWei =
          isOnList(webUser, perm.FREEMINT_WISDOM.name) && freeMint
            ? 0
            : ethers.utils
                .parseEther(serverWisdom.mintPrice.toString(), "wei")
                .toString();
        // console.log(
        //   "mintWisdomContract>converting",
        //   serverWisdom.mintPrice,
        //   " eth to wei",
        //   mintPriceWei
        // );
        // const nowDate = new Date();
        // const offsetMinutes = nowDate.getTimezoneOffset() + 180;
        // const offsetSeconds = offsetMinutes * 60;
        // const nowMS0 = Math.round(nowDate.getTime() / 1000) + offsetSeconds;
        const nowMS = Math.round(Date.now() / 1000);
        // console.log(
        //   "mintWisdomContract>timezoneoffset",
        //   nowDate.getTimezoneOffset(),
        //   offsetMinutes,
        //   nowMS
        // );

        //20000000000000000 = 0.02 MATIC
        const tx = await wisdomContract.safeMintWisdom(
          serverWisdom.wisdomText,
          serverWisdom.hashtagTexts,
          nowMS,
          serverWisdom.approverWallet,
          0, // remint mintPrice
          0, // remint currentValue
          0, // remint forSalePrice
          {
            value: mintPriceWei,
          }
        );
        if (!tx) {
          console.log("mintWisdomContract>ERROR: Contract returned no tx");
          return true;
        }

        const receipt = await tx.wait(1);
        if (DO_LOG || doLog) {
          console.log("mintWisdomContract>OK DONE. transaction=", tx);
          console.log("mintWisdomContract>OK DONE. receipt=", receipt);
        }
        //
        //
        // determine from receipt that transaction completed
        //    and process returned values in the event
        if (receipt?.events.length > 0) {
          // check each event?
          for (let i = 0; i < receipt.events.length; ++i) {
            // pull data from event
            // uint256 come back as BigNumber
            // wisdomId, forSalePrice, saleStartDateMS, saleEndDateMS
            const event = receipt.events[i];
            //console.log("mintWisdomContract>   event=", event);
            // event is for this wisdom
            if (event?.event === "SafeMintWisdomEvent") {
              // event is for us!
              if (nowMS.toString() === event?.args?.nowMS?.toString()) {
                if (DO_LOG || doLog) {
                  console.log("mintWisdomContract>SafeMintWisdomEvent found");
                }

                serverWisdom.wisdomId = parseInt(
                  event?.args?.wisdomId.toString()
                );
                serverWisdom.mintedDateMS = nowMS;
                serverWisdom.saleStartDateMS = parseInt(
                  event?.args?.saleStartDateMS.toString()
                );
                serverWisdom.saleEndDateMS = parseInt(
                  event?.args?.saleEndDateMS.toString()
                );
                // contract sends price in wei, store in eth
                serverWisdom.forSalePrice = Number(
                  ethers.utils.formatUnits(
                    event?.args?.forSalePrice.toString(),
                    "ether"
                  )
                );
                // contract sends shares in wei, store in eth
                serverWisdom.approverSharePaid = Number(
                  ethers.utils.formatUnits(
                    event?.args?.approverSharePaid.toString(),
                    "ether"
                  )
                );
                // contract sends shares in wei, store in eth
                serverWisdom.exchangeSharePaid = Number(
                  ethers.utils.formatUnits(
                    event?.args?.exchangeSharePaid.toString(),
                    "ether"
                  )
                );

                setForSalePriceEth(serverWisdom.forSalePrice);
                //serverWisdom.forSalePrice = parseInt(forSalePrice.toString());

                if (DO_LOG || doLog) {
                  console.log(
                    "mintWisdomContract>SafeMintWisdomEvent applied",
                    serverWisdom
                  );
                }
                //
                // ***** update the server
                //
                // ( sends approverShare and exchangeShare to server)
                mintWisdomServer(serverWisdom, freeMint);
              } else {
                console.log(
                  "mintWisdomContract>nowMS=",
                  event?.args?.nowMS?.toString()
                );
              }
            } else {
              console.log("mintWisdomContract>wrongEvent=", event?.event);
            }
          } // each event in receipt
        } else {
          console.log("mintWisdomContract>No Events In Receipt");
        }

        return false;
      } catch (err) {
        // TODO: mimic buyWisdomContract error handling. Make New Function to unify handling
        console.log("mintWisdomContract>ERROR REASON=", err?.reason);

        if (err?.reason) {
          // console.log(
          //   "mintWisdomContract>ERROR DATA MESSAGE=",
          //   err?.data?.message
          // );

          //const i = err.data.message.indexOf("custom error");
          const i = err?.reason.indexOf("custom error");
          if (i !== -1) {
            const errMessage = err?.reason
              .substring(i + 13)
              .replaceAll("'", "");
            //console.log("mintWisdomContract>ERROR MESSAGE=", errMessage);
            //alert(err?.data?.message.substring(i + 13));
            setAlertBanner({
              children: null,
              type: "error",
              message: errMessage,
              showNow: true,
              theKey: alertKey,
              handleClose: handleCloseAlert,
            });
            // tell the server
            await api.logEvent({
              eventName: "MintWisdom-FAIL",
              data1: serverWisdom?.wisdomText,
              data2: errMessage,
            });
            return true;
          }
        }
        console.log("mintWisdomContract>ERROR=", JSON.stringify(err, null, 3));
        if (err?.code === 4001) {
          setWisdomBusy(false);
          return false;
        }
      }
    } else {
      if (DO_LOG || true) console.log("mintWisdomContract>NO LIBRARY");
    }
    setWisdomBusy(false);
    return false;
  }; // mint wisdom contract
  //
  //
  //
  const handleMintWisdom = async (freeMint) => {
    if (DO_LOG || true) console.log("handleMintWisdom>freeMint=", freeMint);
    const webUser = loadWebUser();
    setWisdomBusy(true);
    //const approverWallet = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";
    // mint wisdom contract calls mint wisdom server if successful
    const result = await mintWisdomContract(serverWisdom, webUser, freeMint);
    if (!result) {
      setAlertBanner({
        children: null,
        type: "error",
        message: "Error minting to contract. Please try again.",
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
    }
    setWisdomBusy(false);
  }; // handle mint wisdom
  //
  //
  //
  const handleCurrentValueClick = (value) => {
    setAlertBanner({
      children: null,
      type: "info",
      message: "Current value is " + value.toString() + " " + COIN_LABEL,
      showNow: true,
      theKey: alertKey,
      handleClose: handleCloseAlert,
    });
  }; // handle current value click
  //
  const handleForSalePriceClick = (value) => {
    setAlertBanner({
      children: null,
      type: "info",
      message: "For sale price is " + value.toString() + " " + COIN_LABEL,
      showNow: true,
      theKey: alertKey,
      handleClose: handleCloseAlert,
    });
  }; // handle current value click
  // useEffect(() => {
  //   if (!serverWisdom) {
  //     dispatch(getWisdom(serverWisdom?._id));
  //   }
  // }, [serverWisdom, hidden]);
  useEffect(() => {
    if (doLog) console.log("WisdomCard.useEffect");
  }, [wisdomRefresh]);

  //console.log("WisdomCard>forSalePrice=", serverWisdom?.forSalePrice);
  if (isWisdomHidden(serverWisdom) && !isUserWisdomOwner(webUser, serverWisdom))
    // blank card
    return (
      <Card
        className={classes.card}
        raised
        elevation={6}
        key={"WisdomCard" + serverWisdom?.createdAt?.toString()}
      ></Card>
    );
  else {
    const freeMintExpireString = getListExpireString(
      perm.FREEMINT_WISDOM.name,
      webUser,
      serverWisdom,
      "(",
      ")"
    );
    //

    const freeBuyExpireString = getListExpireString(
      perm.FREEBUY_WISDOM.name,
      webUser,
      serverWisdom,
      "(",
      ")"
    );
    // const displayWisdomType = getDisplayProfileValue(
    //   DISPLAY_WISDOM_TYPE,
    //   MINTED_STRING
    // );
    momentStr =
      displayWisdomType === MINTED_STRING
        ? moment(new Date(serverWisdom?.mintedDateMS * 1000)).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : serverWisdom?.createdAt;

    // if (serverWisdom.wisdomText == "TW7") {
    //   console.log(
    //     "WisdomCard>mintedDateMS.TW7",
    //     serverWisdom?.mintedDateMS,
    //     momentStr
    //   );
    // }
    // if (serverWisdom.wisdomText == "TW6") {
    //   console.log(
    //     "WisdomCard>mintedDateMS.TW6",
    //     serverWisdom?.mintedDateMS,
    //     momentStr
    //   );
    // }

    //serverWisdom?.createdAt;
    var cheerTooltip = webUser
      ? "Cheer or not"
      : SHOW_BETA_TWEET
      ? "Beta soon"
      : "Sign in to cheer";
    var feelTooltip = webUser
      ? "Feel or not"
      : SHOW_BETA_TWEET
      ? "Beta soon"
      : "Sign in to feel";
    var likeTooltip = webUser
      ? "Like or not"
      : SHOW_BETA_TWEET
      ? "Beta soon"
      : "Sign in to like";
    var thinkTooltip = webUser
      ? "Think or not"
      : SHOW_BETA_TWEET
      ? "Beta soon"
      : "Sign in to think";

    const signInToBuyTooltip = SHOW_BETA_TWEET ? "Beta soon" : "Sign in to buy";
    //console.log("WisdomCard>webuser=", webUser);

    const showCancelButton =
      isWisdomApproved(serverWisdom) ||
      (isWisdomDeclined(serverWisdom) &&
        isUserWisdomOwner(webUser, serverWisdom));

    if (wisdomBusy) {
      return (
        <center>
          <CircularProgress size="7em" />
          <Typography variant="h4">Please wait</Typography>
          <Typography variant="h4">Do not change pages</Typography>
        </center>
      );
      //return <DotProgress />;
    }
    // if (serverWisdom?.wisdomText.indexOf("tw5") > -1) {
    //   console.log("WisdomCard>freeBuyExpireString>", freeBuyExpireString, "<");
    // }
    // default primary blue color #1976d2
    // const customTheme = createTheme({
    //   palette: {
    //     primary: { main: purple[500] },
    //     secondary: { main: red[500] },
    //   },
    // });
    //
    // hashtags were color="textSecondary"
    return (
      <div style={{ minWidth: "360px", paddingLeft: "10px" }}>
        <Card
          className={(classes.card, classes[selectedAnimation])}
          raised
          elevation={6}
          key={"WisdomCard" + serverWisdom?.createdAt?.toString()}
        >
          <AlertBanner {...alertBanner}></AlertBanner>

          <ButtonBase
            component="span"
            name="openWisdom"
            className={classes.cardAction}
            key={"openWisdom" + serverWisdom?.createdAt?.toString()}
            onClick={openWisdom}
          >
            <CardMedia
              className={
                serverWisdom?.imageFile ? classes.media : classes.mediaNoPic
              }
              key={"Card-CardMedia" + serverWisdom?.createdAt?.toString()}
              image={
                serverWisdom?.imageFile || require("../../../images/no_pic.png")
              }
            />
            <div
              className={classes.overlay}
              key={"momentDiv" + serverWisdom?.createdAt?.toString()}
            >
              <Tooltip title={<h2>Author</h2>} placement="right">
                <Typography variant="h6">{serverWisdom?.authorName}</Typography>
              </Tooltip>
              <Typography variant="body2" style={{ paddingTop: "0rem" }}>
                {moment(momentStr).fromNow()}
              </Typography>
              {/* {!isAuthorOwner(serverWisdom) && ( */}
              <Tooltip title={<h2>Owner</h2>} placement="right">
                <Typography variant="h6" style={{ paddingTop: "0.1rem" }}>
                  <div id={"ownerName" + serverWisdom.wisdomId.toString()}>
                    {serverWisdom?.ownerName}
                  </div>
                </Typography>
              </Tooltip>
              {/* )} */}
            </div>

            {canUserEditWisdom(webUser, serverWisdom) && !SHOW_BETA_TWEET && (
              <div className={classes.overlay2} name="edit">
                <Tooltip title={<h2>Edit</h2>} placement="left">
                  <Button
                    key={"ButtonHoriz" + serverWisdom?.createdAt?.toString()}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (serverWisdom?.wisdomId === -1) {
                        // alert(
                        //   "WisdomCard.editWisdom>setting EditWisdom>" +
                        //     serverWisdom?.wisdomId.toString() +
                        //     "*" +
                        //     serverWisdom?.wisdomText +
                        //     "*"
                        // );
                        // proposed Wisdoms don't have wisdomId yet,
                        //    so pass wisdom up/down to get to WisdomForm
                        setCurrentId(serverWisdom?._id);
                        setEditWisdom(serverWisdom);
                      } else {
                        setCurrentId(serverWisdom?._id);
                      }
                    }}
                    style={{ color: "white" }}
                    size="small"
                  >
                    <MoreHorizIcon fontSize="medium" />
                  </Button>
                </Tooltip>
              </div>
            )}
            {/* <DotProgress /> */}
            <div
              className={classes.details}
              key={"tagDiv" + serverWisdom?.createdAt?.toString()}
            >
              <Typography variant="body2" component="h2">
                {serverWisdom?.hashtagTexts?.map((tag) => `#${tag} `)}
              </Typography>
            </div>
            <WisdomBox wisdom={serverWisdom} cursor={"pointer"} />
            {!isWisdomMinted(serverWisdom) && (
              <Typography
                variant="body1"
                className={classes.details}
                style={{ marginLeft: "20px" }}
              >
                <strong> Status: {serverWisdom?.status}</strong>
                <strong> Mint Price: {serverWisdom?.mintPrice}</strong>
              </Typography>
            )}

            {isWisdomDeclined(serverWisdom) && (
              <>
                <Divider style={{ margin: "20px 0" }} />

                <div style={{ marginLeft: "20px" }}>
                  <Typography variant="h6">
                    Wisdom boundary violations
                  </Typography>
                  <BoundarySection serverWisdom={serverWisdom} />
                </div>
                <Divider style={{ margin: "20px 0" }} />
              </>
            )}
          </ButtonBase>

          <CardActions className={classes.cardActions}>
            {isWisdomMinted(serverWisdom) && (
              <Tooltip title={<h2>{likeTooltip}</h2>} placement="top">
                <span>
                  <Button
                    size="small"
                    color="primary"
                    // disabled={!webUser}
                    onClick={(event) =>
                      handleVote(event, "likes", "likesCount")
                    }
                  >
                    <Votes
                      voteType="likes"
                      voteTypeCount="likesCount"
                      VoteIcon={VoteIcons["likes"]}
                      wisdomId={serverWisdom?.wisdomId}
                    />
                  </Button>
                </span>
              </Tooltip>
            )}
            {isWisdomMinted(serverWisdom) && (
              <Tooltip title={<h2>{feelTooltip}</h2>} placement="top">
                <span>
                  <Button
                    size="small"
                    color="primary"
                    // disabled={!webUser}
                    onClick={(event) =>
                      handleVote(event, "feels", "feelsCount")
                    }
                  >
                    <Votes
                      voteType="feels"
                      voteTypeCount="feelsCount"
                      wisdomId={serverWisdom?.wisdomId}
                    />
                  </Button>
                </span>
              </Tooltip>
            )}
            {isWisdomMinted(serverWisdom) && (
              <Tooltip title={<h2>{thinkTooltip}</h2>} placement="top">
                <span>
                  <Button
                    size="small"
                    color="primary"
                    // disabled={!webUser}
                    onClick={(event) =>
                      handleVote(event, "thinks", "thinksCount")
                    }
                  >
                    <Votes
                      voteType="thinks"
                      voteTypeCount="thinksCount"
                      wisdomId={serverWisdom?.wisdomId}
                    />
                  </Button>
                </span>
              </Tooltip>
            )}
            {isWisdomMinted(serverWisdom) && (
              <Tooltip title={<h2>{cheerTooltip}</h2>} placement="top">
                <span>
                  <Button
                    size="small"
                    color="primary"
                    //disabled={!webUser || SHOW_BETA_TWEET}
                    onClick={(event) =>
                      handleVote(event, "cheers", "cheersCount")
                    }
                  >
                    <Votes
                      voteType="cheers"
                      voteTypeCount="cheersCount"
                      wisdomId={serverWisdom?.wisdomId}
                    />
                  </Button>
                </span>
              </Tooltip>
            )}
          </CardActions>

          <div className={classes.cardActions} style={{ margin: "0px 10px" }}>
            {!webUser && isWisdomMinted(serverWisdom) && (
              // <Tooltip title={<h2>Sign in to buy</h2>} placement="top">
              //   <Typography>
              //     {serverWisdom?.forSalePrice} {COIN_ABBR_LABEL}
              //   </Typography>
              // </Tooltip>
              <Tooltip title={<h2>{signInToBuyTooltip}</h2>} placement="top">
                <button
                  className="btn btn-lg btn-danger center modal-button"
                  //ref={buttonRef}
                  //style={style}
                  //onClick={showModal}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  <div className={classes.rowFlex}>
                    <span
                      id={"forSalePrice" + serverWisdom.wisdomId.toString()}
                    >
                      {Math.round(serverWisdom?.forSalePrice * 100) / 100}{" "}
                    </span>
                    <span style={{ paddingLeft: "10px" }}>
                      {" "}
                      {COIN_ABBR_LABEL}
                    </span>
                  </div>
                </button>
              </Tooltip>
            )}

            {/* {webUser &&
            isWisdomMinted(serverWisdom) &&
            !isWisdomForSale(serverWisdom) && (
              <Tooltip
                title={<h2>Current value: {serverWisdom?.currentValue}</h2>}
                placement="top"
              >
                <Typography
                  onClick={() =>
                    handleCurrentValueClick(serverWisdom?.currentValue)
                  }
                >
                  {Math.round(serverWisdom?.currentValue * 100) / 100}{" "}
                  {COIN_ABBR_LABEL}
                </Typography>
              </Tooltip>
            )} */}
            {webUser &&
              isWisdomMinted(serverWisdom) &&
              !isWisdomForSale(serverWisdom) && (
                <Tooltip
                  title={<h2>Current value: {serverWisdom?.currentValue}</h2>}
                  placement="top"
                >
                  <button
                    className="btn btn-lg btn-danger center modal-button"
                    //ref={buttonRef}
                    //style={style}
                    onClick={() =>
                      handleCurrentValueClick(serverWisdom?.currentValue)
                    }
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      outline: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <div className={classes.rowFlex}>
                      <span
                        id={"currentValue" + serverWisdom.wisdomId.toString()}
                      >
                        {Math.round(serverWisdom?.currentValue * 100) / 100}{" "}
                      </span>
                      <span style={{ paddingLeft: "10px" }}>
                        {" "}
                        {COIN_ABBR_LABEL}
                      </span>
                    </div>
                  </button>
                </Tooltip>
              )}
            {webUser &&
              // !isUserWisdomOwner(webUser, serverWisdom) &&
              isWisdomMinted(serverWisdom) &&
              isWisdomForSale(serverWisdom) && (
                // <Tooltip
                //   title={<h2>For sale price: {forSalePriceEth}</h2>}
                //   placement="top"
                // >
                //   <Typography
                //     onClick={() =>
                //       handleForSalePriceClick(serverWisdom?.forSalePrice)
                //     }
                //   >
                //     {Math.round(forSalePriceEth * 100) / 100} {COIN_ABBR_LABEL}
                //   </Typography>
                // </Tooltip>
                <Tooltip
                  title={<h2>For sale price: {forSalePriceEth}</h2>}
                  placement="top"
                >
                  <button
                    className="btn btn-lg btn-danger center modal-button"
                    //ref={buttonRef}
                    //style={style}
                    onClick={() =>
                      handleForSalePriceClick(serverWisdom?.forSalePrice)
                    }
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      outline: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  >
                    {/* <div id={"forSalePrice" + serverWisdom.wisdomId.toString()}>
                      {Math.round(forSalePriceEth * 100) / 100}{" "}
                      {COIN_ABBR_LABEL}
                    </div> */}
                    <div className={classes.rowFlex}>
                      <span
                        id={"forSalePrice" + serverWisdom.wisdomId.toString()}
                      >
                        {Math.round(serverWisdom?.forSalePrice * 100) / 100}{" "}
                      </span>
                      <span style={{ paddingLeft: "10px" }}>
                        {" "}
                        {COIN_ABBR_LABEL}
                      </span>
                    </div>
                  </button>
                </Tooltip>
              )}

            {freeBuyExpireString !== "" &&
              canUserBuyWisdom(webUser, serverWisdom) && (
                <Button
                  size="large"
                  color="secondary"
                  key={"freeBuyButton" + serverWisdom?._id}
                  onClick={() => handleBuyWisdom(true)}
                >
                  {/* <MonetizationOn fontSize="small" /> &nbsp;{" "} */}
                  {"FREE Buy " + freeBuyExpireString}
                </Button>
              )}

            {showCancelButton && (
              <Button
                size="large"
                color="secondary"
                key={"cancelButton" + serverWisdom?.proposedDateMS?.toString()}
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(cancelWisdom(serverWisdom, webUser));
                  //history.push(`/`);
                  setAlertBanner({
                    children: null,
                    type: "success",
                    message: "Wisdom cancelled. Please propose a new Wisdom.",
                    showNow: true,
                    theKey: alertKey,
                    handleClose: handleCloseAlert,
                  });
                  setTimeout(() => {
                    history.push(`/`);
                  }, 5000);
                }}
              >
                {/* <CancelIcon fontSize="small" /> &nbsp;  */}
                Cancel
              </Button>
            )}

            {serverWisdom?.status === APPROVED_STRING &&
              isUserWisdomOwner(webUser, serverWisdom) && (
                <Button
                  size="large"
                  color="primary"
                  key={"mintButton" + serverWisdom?.createdAt?.toString()}
                  onClick={() => handleMintWisdom(false)}
                >
                  {/* <MonetizationOn fontSize="small" /> &nbsp;{" "} */}
                  {"Mint for " +
                    serverWisdom?.mintPrice.toString() +
                    COIN_LABEL}
                </Button>
              )}

            {isWisdomApproved(serverWisdom) &&
              freeMintExpireString !== "" &&
              isUserWisdomOwner(webUser, serverWisdom) && (
                <Button
                  size="large"
                  color="secondary"
                  key={"freeMintButton" + serverWisdom?.createdAt?.toString()}
                  onClick={() => handleMintWisdom(true)}
                >
                  {/* <MonetizationOn fontSize="small" /> &nbsp;{" "} */}
                  {"FREE Mint " + freeMintExpireString}
                </Button>
              )}
            <p />
            {canUserBuyWisdom(webUser, serverWisdom) && (
              <Tooltip
                title={
                  <h2>
                    Buy this Wisdom for{" "}
                    {serverWisdom?.forSalePrice.toString() + COIN_ABBR_LABEL}
                  </h2>
                }
                placement="top"
              >
                <Button
                  size="large"
                  color="primary"
                  key={"theBuyButton" + serverWisdom?.createdAt?.toString()}
                  onClick={() => handleBuyWisdom(false)}
                >
                  {"Buy " +
                    (
                      Math.round(serverWisdom?.forSalePrice * 100) / 100
                    ).toString()}
                </Button>
              </Tooltip>
            )}
            {canUserSellWisdom(webUser, serverWisdom) && !SHOW_BETA_TWEET && (
              <Tooltip title={<h2>Sell this Wisdom</h2>} placement="top">
                <SellWisdomModalContainer
                  triggerText={buttonTextSell}
                  onSubmit={handleSellWisdom}
                  onChange={handleSellWisdomPrice}
                  data={{
                    forSalePrice: forSalePriceEth,
                  }}
                  coinInCurrency={coinInCurrency}
                  sellCharge={sellCharge}
                />
              </Tooltip>
            )}
            {canUserStopSellWisdom(webUser, serverWisdom) && (
              <Tooltip
                title={<h2>Stop the sale for just gas fee</h2>}
                placement="top"
              >
                <Button
                  size="large"
                  color="secondary"
                  key={"stopSellButton" + serverWisdom?.createdAt?.toString()}
                  onClick={handleStopSellWisdom}
                >
                  {"End sale"}
                </Button>
              </Tooltip>
            )}
            {canUserHideWisdom(webUser, serverWisdom) && (
              <Button
                size="small"
                color="secondary"
                key={"hideButton" + serverWisdom?.createdAt?.toString()}
                onClick={handleHideWisdom}
              >
                <VisibilityOffIcon fontSize="small" /> &nbsp; Hide
              </Button>
            )}
            {canUserUnHideWisdom(webUser, serverWisdom) &&
              isWisdomHidden(serverWisdom) && (
                <Button
                  size="small"
                  color="secondary"
                  key={"unHideButton" + serverWisdom?.createdAt?.toString()}
                  onClick={handleHideWisdom}
                >
                  <VisibilityIcon fontSize="small" /> &nbsp; unHide
                </Button>
              )}
          </div>
        </Card>
      </div>
    );
  }
};

export default WisdomCard;
