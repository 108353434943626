//
//

// const WISDOM_FIRST_SALE_PRICE = require("./constants");
// const WISDOM_MINT_PRICE_BASE = require("./constants");

// const api = require("../api/index");
// const setProfileValue = require("../components/localStorage");

import {
  WISDOM_FIRST_SALE_PRICE,
  WISDOM_MINT_PRICE_BASE,
} from "./constants.js";
import * as api from "../api/index.js";
import { setProfileValue } from "../components/myLocalStorage.js";

const doLog = false;

//
export const FREEMINT_WISDOM = { name: "FREE_MINT_WISDOM", enum: 0 };
export const MANAGE_FREEMINT_WISDOM = {
  name: "MANAGE_FREE_MINT_WISDOM",
  enum: 1,
};

export const FREEBUY_WISDOM = { name: "FREE_BUY_WISDOM", enum: 2 };
export const MANAGE_FREEBUY_WISDOM = {
  name: "MANAGE_FREE_BUY_WISDOM",
  enum: 3,
};

export const INVITE_ALL = { name: "INVITE_ALL", enum: 4 };
export const MANAGE_INVITE_ALL = { name: "MANAGE_INVITE_ALL", enum: 5 };

export const NOINVITE = { name: "NOINVITE", enum: 6 };
export const MANAGE_NOINVITE = { name: "MANAGE_NOINVITE", enum: 7 };

export const NOMINT_WISDOM = { name: "NOMINT_WISDOM", enum: 8 };
export const MANAGE_NOMINT_WISDOM = { name: "MANAGE_NOMINT_WISDOM", enum: 9 };

export const NOMINT_HASHTAG = { name: "NOMINT_HASHTAG", enum: 10 };
export const MANAGE_NOMINT_HASHTAG = {
  name: "MANAGE_NOMINT_HASHTAG",
  enum: 11,
};

export const NOBUY_WISDOM = { name: "NOBUY_WISDOM", enum: 12 };
export const MANAGE_NOBUY_WISDOM = { name: "MANAGE_NOBUY_WISDOM", enum: 13 };

export const NOBUY_HASHTAG = { name: "NOBUY_HASHTAG", enum: 14 };
export const MANAGE_NOBUY_HASHTAG = { name: "MANAGE_NOBUY_HASHTAG", enum: 15 };

export const HIDE_WISDOM = { name: "HIDE_WISDOM", enum: 16 };
export const MANAGE_HIDE_WISDOM = { name: "MANAGE_HIDE_WISDOM", enum: 17 };

export const HIDE_HASHTAG = { name: "HIDE_HASHTAG", enum: 18 };
export const MANAGE_HIDE_HASHTAG = { name: "MANAGE_HIDE_HASHTAG", enum: 19 };

export const AGENCY_MINT = { name: "AGENCY_MINT", enum: 20 };
export const MANAGE_AGENCY_MINT = { name: "MANAGE_AGENCY_MINT", enum: 21 };

export const APPROVE_WISDOM = { name: "APPROVE_WISDOM", enum: 22 };
export const MANAGE_APPROVE_WISDOM = {
  name: "MANAGE_APPROVE_WISDOM",
  enum: 23,
};

export const APPROVE_HASHTAG = { name: "APPROVE_HASHTAG", enum: 24 };
export const MANAGE_APPROVE_HASHTAG = {
  name: "MANAGE_APPROVE_HASHTAG",
  enum: 25,
};

export const TESTER = { name: "TESTER", enum: 26 };
export const MANAGE_TESTER = { name: "MANAGE_TESTER", enum: 27 };

export const MANAGE_EVERY_LIST = { name: "MANAGE_EVERY_LIST", enum: 28 };

export const FREEMINT_HASHTAG = { name: "FREE_MINT_HASHTAG", enum: 29 };
export const MANAGE_FREEMINT_HASHTAG = {
  name: "MANAGE_FREE_MINT_HASHTAG",
  enum: 30,
};

export const FREEBUY_HASHTAG = { name: "FREE_BUY_HASHTAG", enum: 31 };
export const MANAGE_FREEBUY_HASHTAG = {
  name: "MANAGE_FREE_BUY_HASHTAG",
  enum: 32,
};

// not in contract
export const MINT_WISDOM = { name: "MINT_WISDOM", enum: 33 };
export const MANAGE_MINT_WISDOM = { name: "MANAGE_MINT_WISDOM", enum: 34 };
export const MINT_HASHTAG = { name: "MINT_HASHTAG", enum: 35 };
export const MANAGE_MINT_HASHTAG = { name: "MANAGE_MINT_HASHTAG", enum: 36 };
export const ROLE_OWNER = { name: "ROLE_OWNER", enum: 37 };
export const ROLE_COOWNER = { name: "ROLE_COOWNER", enum: 38 };
export const ROLE_ADMIN = { name: "ROLE_ADMIN", enum: 39 };
export const ROLE_OPERATOR1 = { name: "ROLE_OPERATOR1", enum: 40 };
export const ROLE_OPERATOR2 = { name: "ROLE_OPERATOR2", enum: 41 };
export const ROLE_OPERATOR3 = { name: "ROLE_OPERATOR3", enum: 42 };
export const DENY_PROPOSE_IMAGE = { name: "DENY_PROPOSE_IMAGE", enum: 43 };

export const permissions = [
  FREEMINT_WISDOM,
  MANAGE_FREEMINT_WISDOM,
  FREEBUY_WISDOM,
  MANAGE_FREEBUY_WISDOM,
  FREEMINT_HASHTAG,
  MANAGE_FREEMINT_HASHTAG,
  FREEBUY_HASHTAG,
  MANAGE_FREEBUY_HASHTAG,
  INVITE_ALL,
  MANAGE_INVITE_ALL,
  NOINVITE,
  MANAGE_NOINVITE,
  NOMINT_WISDOM,
  MANAGE_NOMINT_WISDOM,
  NOMINT_HASHTAG,
  MANAGE_NOMINT_HASHTAG,
  NOBUY_WISDOM,
  MANAGE_NOBUY_WISDOM,
  NOBUY_HASHTAG,
  MANAGE_NOBUY_HASHTAG,
  HIDE_WISDOM,
  MANAGE_HIDE_WISDOM,
  HIDE_HASHTAG,
  MANAGE_HIDE_HASHTAG,
  AGENCY_MINT,
  MANAGE_AGENCY_MINT,
  APPROVE_WISDOM,
  MANAGE_APPROVE_WISDOM,
  APPROVE_HASHTAG,
  MANAGE_APPROVE_HASHTAG,
  TESTER,
  MANAGE_TESTER,
  MANAGE_EVERY_LIST,
  // not in contract
  MINT_WISDOM,
  MANAGE_MINT_WISDOM,
  MINT_HASHTAG,
  MANAGE_MINT_HASHTAG,
  ROLE_OWNER,
  ROLE_COOWNER,
  ROLE_ADMIN,
  ROLE_OPERATOR1,
  ROLE_OPERATOR2,
  ROLE_OPERATOR3,
  DENY_PROPOSE_IMAGE,
];

//
//Utility functions others can use
//

export function getPermissionByListName(serverUser, listName) {
  //console.log("getPermissionByListName>seeking", listName, "for", serverUser);
  if (!serverUser) return {};
  if (serverUser?.permissions?.length === 0) return {};
  var permission;
  serverUser.permissions.forEach((p) => {
    //console.log("getPermissionByListName>checking", p.listName);
    if (p.listName === listName) {
      //console.log("getPermissionByListName>FOUND", listName);
      permission = p;
    }
  });
  //console.log("getPermissionByListName>returning", permission);

  return permission;
} // get permission by list name

export function getListExpireCount(serverUser, listName) {
  //  console.log("getListExpireCount>checking user=", serverUser);

  if (!serverUser) return 0;
  if (!serverUser?.permissions) return 0;
  if (serverUser?.permissions?.length === 0) return 0;

  // expired permission returns 0
  const nowMS = Math.round(Date.now() / 1000);

  var expireCount = 0;
  serverUser.permissions.forEach((permission) => {
    // console.log(
    //   "getListExpireCount>checking",
    //   permission.listName,
    //   "for",
    //   listName
    // );
    if (permission.listName === listName) {
      // console.log("getListExpireCount>checking listName match");
      // expireCount == -1 means no count
      expireCount = permission.expireCount;

      if (permission.expireDateMS > 0) {
        // console.log("getListExpireCount>permission HAS expireDateMS");

        if (permission.expireDateMS < nowMS) {
          // console.log(
          //   "\n\ngetListExpireCount>EXPIRED  expireDateMS",
          //   permission.expireDateMS,
          //   "nowMS=",
          //   nowMS
          // );
          // update server?
          expireCount = 0;
        } else {
          // console.log(
          //   "\n\ngetListExpireCount>NOT EXPIRED  expireDateMS",
          //   permission.expireDateMS,
          //   "nowMS=",
          //   nowMS
          // );
        }
      }
    }
  });
  return expireCount;
} // get list expire count
//
export function getListExpireString(
  listName,
  serverUser,
  serverWisdom,
  prefixString,
  suffixString
) {
  // returns "" if not allowed
  // returns prefix + count + suffix  if allowed
  // returns " " if unlimited allowed
  //

  if (!serverUser || !serverWisdom) return "";
  if (serverUser?.permissions?.length === 0) return "";

  // expired permission returns 0
  //  const nowMS = Math.round(Date.now() / 1000);
  //  console.log("***getListExpireString>text=", serverWisdom.wisdomText);

  var count = getListExpireCount(serverUser, listName);
  //  console.log("getListExpireString>count=", count);

  if (count === -1 || count > 0) {
    if (listName === FREEMINT_WISDOM.name) {
      // disallow free mint if server has non-free hashtags
      if (serverWisdom.mintPrice !== WISDOM_MINT_PRICE_BASE) count = 0;
    } else {
      if (listName === FREEBUY_WISDOM.name) {
        // freebuy applies only to wisdom authored by giver of the permission
        // AND only at the first sale price
        const permission = getPermissionByListName(serverUser, listName);
        if (serverWisdom.authorId === permission?.fromId) {
          if (serverWisdom.forSalePrice !== WISDOM_FIRST_SALE_PRICE) {
            // console.log(
            //   "getListExpireString>forSalePrice=",
            //   serverWisdom.forSalePrice,
            //   "no match",
            //   WISDOM_FIRST_SALE_PRICE
            // );
            count = 0;
          }
        } else {
          // disallow free buy
          // console.log(
          //   "getListExpireString>authorId=",
          //   serverWisdom.authorId,
          //   "no match",
          //   permission?.fromId
          // );
          count = 0;
        }
      }
    }
  }

  var expireString = "";
  if (count === -1) expireString = " ";
  else if (count === 0) expireString = "";
  else expireString = prefixString + count.toString() + suffixString;

  return expireString;
} // get list expire String

//
export function isOnList(serverUser, listName) {
  // console.log(
  //   "isOnList>",
  //   serverUser?.name || "unk",
  //   listName,
  //   "permissions.length=",
  //   serverUser?.permissions?.length
  // );
  if (!serverUser) return false;
  if (!serverUser?.permissions) return false;
  if (serverUser?.permissions?.length === 0) return false;
  // for ( let i = 0; i < serverUser?.permissions.length; i++ ) {

  // }
  const nowMS = Math.round(Date.now() / 1000);
  var onList = false;
  serverUser.permissions.forEach((permission) => {
    //    console.log("isOnList>checking", permission.listName);

    //    var listCount = -1;
    if (permission.listName === listName) {
      if (!onList) {
        //        console.log("isOnList>listName MATCH");
        if (permission.expireDateMS === -1) {
          //          console.log("isOnList>NO EXPIRE DATE>");
          onList = true;
        } else {
          if (
            permission.sinceDateMS <= nowMS &&
            permission.expireDateMS > nowMS
          ) {
            // console.log(
            //   "isOnList>NOT EXPIRED DATE>",
            //   nowMS,
            //   permission.expireDateMS
            // );
            onList = true;
          } else {
            // update server?
            // console.log(
            //   "isOnList>EXPIRED DATE>",
            //   nowMS,
            //   permission.expireDateMS
            // );
          }
        }
        // check count if
        if (onList) {
          // expireCount == -1 means no count limit
          // 0 means no more
          if (permission.expireCount === 0) {
            onList = false;
          }
        }
      }
    }
  });
  return onList;
} // is on list

export const updateListCount = async (webUser, listName, deltaCount) => {
  if (webUser) {
    for (let i = webUser.permissions.length - 1; i > 0; --i) {
      const p = webUser.permissions[i];
      if (p.listName === listName) {
        // if .expireCount == -1, count is unlimited
        if (p.expireCount > -1) {
          p.expireCount += deltaCount;
          if (p.expireCount < 1) {
            // none left, so remove
            webUser.permissions.splice(i, 1);
          }
          await api.updateUser(webUser._id, {
            permissions: webUser.permissions,
          });
          // save locally
          setProfileValue("permissions", webUser?.permissions);
        }
      }
    }
  }
}; // update list count

export function isOnEitherList(serverUser, listName1, listName2) {
  return isOnList(serverUser, listName1) || isOnList(serverUser, listName2);
}

export function canUserManageList(serverUser, listName) {
  return isOnList(serverUser, "MANAGE_" + listName);
}

export const getListEnumFromListName = (listName) => {
  var listEnum = -1;
  permissions.forEach((p) => {
    if (doLog) console.log("getListEnumFromListName>checking", p.name, p.enum);
    if (p.name === listName) listEnum = p.enum;
  });
  if (doLog) console.log("getListEnumFromListName>FOUND", listEnum);
  return listEnum;
};
