export const nodeIdJSON = {
  myHostName: "DigitalOcean",
  myNodeName: "DO_Shared_0000",
  myAppNodeId: "miwClient_0000",
  myAppName: "miwClient",
  myPurposeName: "prod",
  myVersion: "v0",
  myNodeId: "0000",
};

