//
//
export const lightTheme = {
  body: "#FFF",
  text: "#000",
  toggleBorder: "#FFF",
  background: "#FFF", // "#007AFF", // "#363537",
};
export const darkTheme = {
  body: "#363537",
  text: "#FAFAFA",
  toggleBorder: "#6B8096",
  background: "#999",
};
