//
//
//
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CircularProgress,
  Container,
  Divider,
  Grow,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Masonry from "react-masonry-css";

//import WisdomCard from "../Wisdoms/Wisdom/WisdomCard";
import { getAuthors } from "../../actions/users";
import Pagination from "../Paginations/PaginateAuthors";
import useStyles from "./styles";

//import { MINTED_STRING } from "../../constants/constants.js";
import AuthorCard from "./AuthorCard";
//import { getWisdomsBySearch } from "../../actions/wisdoms";
import { loadWebUser } from "../myLocalStorage";
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const Authors = ({ reload = false }) => {
  const classes = useStyles();

  const {
    name = "",
    status = "",
    page = "1",
    pageReload = false,
  } = useParams();
  //  const query = useQuery();
  //  const pageReload = query.get("pageReload") || false;
  const dispatch = useDispatch();

  useSelector((state) => {
    console.log("Authors>state=", state);
  });

  const { users, isLoading } = useSelector((state) => state.users);
  const [forceReload, setForceReload] = useState(true);
  const [currentId, setCurrentId] = useState(0);

  //  const location = useLocation();
  //const history = useHistory();
  // console.log("Authors>useParams=", useParams());
  // console.log("Authors=", name, ",", status, ",", page, ",", location.pathname);
  // console.log(
  //   "Authors>reload=",
  //   reload,
  //   ", forceReload=",
  //   forceReload,
  //   ", pageReload=",
  //   pageReload
  // );
  // console.log("Authors>isLoading=", isLoading);

  // useEffect(() => {
  //   console.log("Authors.useEffect>dispatch getAuthors NOW");
  //   dispatch(
  //     getAuthors({
  //       name: name,
  //       status: status,
  //       page: page,
  //     })
  //   );
  // }, [dispatch, name]);
  useEffect(() => {
    // console.log(
    //   "Authors.useEffect>reload=",
    //   reload,
    //   ", forceReload=",
    //   forceReload,
    //   ", pageReload=",
    //   pageReload
    // );
    // console.log("Authors.useEffect>isLoading=", isLoading);

    if (reload || forceReload || pageReload) {
      setForceReload(false);
      // 11/6/22 commmented to remove warning about reload variable
      //reload = false;
    }
    // 11/6/22 dep reload added to remove warning
  }, [isLoading, forceReload, pageReload, reload]);
  //
  //
  //
  if (!isLoading && currentId > -2) {
    if (users.length === 0) {
      console.log("Authors>users=0");
      if (reload || forceReload || pageReload || isLoading) {
        console.log("Authors>users=0, so load");
        dispatch(
          getAuthors({
            name: name,
            status: status,
            page: page,
          })
        );
        setForceReload(false);
        reload = false;
      }
    }
  }
  //
  //
  if (!users.length && !isLoading) return "No authors";
  const breakpoints = {
    default: 3,
    1100: 2,
    950: 1,
  };
  const webUser = loadWebUser();
  return (
    <div>
      <Typography variant="h2">{name}</Typography>
      <Divider style={{ margin: "20px 0 50px 0" }} />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grow in>
          <Container maxWidth="xl">
            <Grid
              container
              justifyContent="space-between"
              alignItems="stretch"
              spacing={3}
              className={classes.gridContainer}
            >
              <Grid item xs={12} sm={6} md={9}>
                {/* <Grid container alignItems="stretch" spacing={3}> */}
                <Masonry
                  breakpointCols={breakpoints}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {users?.map((author) => (
                    <Grid key={author._id} item xs={12} sm={12} md={6} lg={3}>
                      {/* <WisdomCard post={post} /> */}
                      {/* {author?.name} */}
                      <AuthorCard author={author} setCurrentId={setCurrentId} />
                    </Grid>
                  ))}
                </Masonry>
              </Grid>

              <Paper className={classes.pagination} elevation={6}>
                <Pagination
                  name={name}
                  status={status}
                  page={page}
                  forceReload={forceReload || pageReload || reload}
                  userName={webUser?.name}
                />
              </Paper>
            </Grid>
          </Container>
        </Grow>
      )}
    </div>
  );
};

export default Authors;
