export const fontNames = [
  { name: "Abril Fatface" },
  { name: "Arial" },
  //  { name: "Black Jack" },
  { name: "Bodoni Moda" },
  { name: "Brush Script Mt" },
  { name: "Chela One" },
  { name: "Chilanka" },
  { name: "Comic Neue" },
  { name: "Courier" },
  { name: "Garamond" },
  { name: "Helvetica" },
  { name: "Dancing Script" },
  { name: "Familjen Grotesk" },
  { name: "Lato" },
  //  { name: "League Gothic" },
  //  { name: "League Script" },
  { name: "Lora" },
  { name: "Montserrat" },
  { name: "Merriweather" },
  //  { name: "Milkshake" },
  { name: "QuickSand" },
  { name: "Raleway" },
  { name: "Roboto Slab" },
  { name: "Spectral" },
  { name: "Tangerine" },
  { name: "The Girl Next Door" },
  { name: "Times New Roman" },
  //   { name: "" },
  //   { name: "" },
  //   { name: "" },
  //   { name: "" },
  //   { name: "Helvetica" },
  //   { name: "Monaco" },
];

export default fontNames;
