import { makeStyles } from "@material-ui/core/styles";
//import { normalizeUnits } from "moment";

export default makeStyles({
  media: {
    height: 0,
    paddingTop: "56.25%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    backgroundBlendMode: "darken",
  },
  mediaNoPic: {
    height: "100px",
    paddingTop: "26.25%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: "darken",
  },
  border: {
    border: "solid",
  },
  fullHeightCard: {
    height: "100%",
  },
  voteFlex: {
    display: "flex",
    flexDirection: "column",
  },
  rowFlex: {
    display: "flex",
    flexDirection: "row",
  },
  searchBtnHlp: {
    background: "none",
    border: "none",
    //margin: "5px 3px 5px 0px",
    padding: "10px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "15px",
    height: "100%",
    position: "relative",
  },
  animateSmallFinal: {
    animationName: "slideSmallFinal",
    animationDuration: "0.9s",
    animationTimingFunction: "easeInOut",
    animationDelay: "0s",
    animationIterationCount: 1,
    animationDirection: "normalizeUnits",
    animationFillMode: "forwards",
  },
  animateSmallBigFinal: {
    animationName: "slideSmallBigFinal",
    animationDuration: "0.9s",
    animationTimingFunction: "easeInOut",
    animationDelay: "0s",
    animationIterationCount: 1,
    animationDirection: "normalizeUnits",
    animationFillMode: "forwards",
  },
  animateBigFinal: {
    animationName: "slideBigFinal",
    animationDuration: "0.9s",
    animationTimingFunction: "easeInOut",
    animationDelay: "0s",
    animationIterationCount: 1,
    animationDirection: "normalizeUnits",
    animationFillMode: "forwards",
  },
  animateBigSmallFinal: {
    animationName: "slideBigSmallFinal",
    animationDuration: "0.9s",
    animationTimingFunction: "easeInOut",
    animationDelay: "0s",
    animationIterationCount: 1,
    animationDirection: "normalizeUnits",
    animationFillMode: "forwards",
  },
  animateJiggleLeft: {
    animationName: "jiggleLeft",
    animationDuration: "0.4s",
    animationTimingFunction: "easeInOut",
    animationDelay: "0s",
    animationIterationCount: 1,
    animationDirection: "normalizeUnits",
    animationFillMode: "forwards",
  },
  animateJiggleRight: {
    animationName: "jiggleRight",
    animationDuration: "0.4s",
    animationTimingFunction: "easeInOut",
    animationDelay: "0s",
    animationIterationCount: 1,
    animationDirection: "normalizeUnits",
    animationFillMode: "forwards",
  },

  animateDotProgressLeftToRight: {
    paddingLeft: "100px",
    position: "absolute",
    animationName: "dotProgressLeftToRight",
    animationDuration: "3.0s",
    animationTimingFunction: "linear",
    animationDelay: "3s",
    animationIterationCount: 99,
    animationDirection: "normal", // "normalizeUnits",
    animationFillMode: "forwards",
  },

  overlay: {
    position: "absolute",
    top: "10px",
    left: "20px",
    color: "white",
  },
  overlay2: {
    position: "absolute",
    top: "20px",
    right: "20px",
    color: "white",
  },
  grid: {
    display: "flex",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
  },
  title: {
    padding: "0 16px",
  },
  cardActions: {
    padding: "0 16px 8px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardAction: {
    display: "block",
    textAlign: "initial",
  },
  appBarSearch: {
    borderRadius: 4,
    marginBottom: "1rem",
    display: "flex",
    padding: "16px",
  },

  searchButtonSearch: {
    width: "45%",
  },
  searchButtonShuffle: {
    width: "30%",
  },
});
