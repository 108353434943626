import {
  APPROVE_WISDOM,
  DECLINE_WISDOM,
  MINT_WISDOM,
  CANCEL_WISDOM,
  START_LOADING,
  STOP_LOADING,
  FETCH_ALL_MINTED,
  FETCH_ALL_PROPOSED,
  FETCH_WISDOM,
  FETCH_BY_SEARCH,
  FETCH_BY_AUTHORS,
  FETCH_BY_HASHTAG,
  PROPOSE_WISDOM,
  UPDATE_WISDOM,
  DELETE_WISDOM,
  //  LIKE_WISDOM,
  COMMENT,
  HIDE_WISDOM,
  VOTE_WISDOM,
  NEED_SIGNIN,
} from "../constants/actionTypes";

import * as api from "../api/index.js";

import {
  PROPOSED_STRING,
  APPROVED_STRING,
  CANCELLED_STRING,
  DECLINED_STRING,
  MINTED_STRING,
  //  PROFILE,
  MAX_WISDOM_LENGTH_DEFAULT,
  // WISDOM_ABSOLUTE_MAX_LENGTH,
  // WISDOM_LENGTH_OWNER_BUMP,
  // WISDOM_LENGTH_MINTED_BUMP,
  //  HIDDEN_STRING,
  SECONDS_BETWEEN_ETH_PRICE_FETCH,
  WS_COMMENTS_ON,
  //  COINGECKO_COIN_NAME,
} from "../constants/constants";

// import * as perm from "../constants/permissions.js";
// import { isOnEitherList } from "../constants/permissions.js";
// import { getWisdomsOwnedCount, isUserOwner } from "../actions/users";
import { loadWebUser } from "../components/myLocalStorage";

var coinInUsd = -1;
var lastEthPriceGetMS = 0;
//
//
//
export const getStatusesToLoad = (status) => {
  var statuses = status || MINTED_STRING;
  if (statuses === PROPOSED_STRING) {
    statuses = PROPOSED_STRING + "," + APPROVED_STRING + "," + DECLINED_STRING;
  }
  return statuses;
};
//
//
//
export const getWisdom =
  (id, wisdomId = -1, userName = "unk") =>
  async (dispatch) => {
    //console.log("client.getWisdom>id=", id, "userName=", userName);
    try {
      dispatch({ type: START_LOADING });

      const { data } = await api.fetchWisdom(id, wisdomId, userName);
      //console.log("client.getWisdom>data=", data);
      dispatch({ type: FETCH_WISDOM, payload: { post: data } });
    } catch (error) {
      console.log(error);
    }
  };

export const getMintedWisdoms = (page, userName) => async (dispatch) => {
  //console.log("client.getMintedWisdoms>");
  try {
    const { innerWidth: screenWidth } = window;
    const coinName = "matic-network";
    const currencyName = "usd";

    try {
      dispatch({ type: START_LOADING });
      const {
        data: { data, currentPage, numberOfPages, coinInCurrency },
      } = await api.fetchMintedWisdoms(
        page,
        screenWidth,
        userName,
        coinName,
        currencyName
      );

      await dispatch({
        type: FETCH_ALL_MINTED,
        payload: {
          data,
          currentPage,
          numberOfPages,
          coinInCurrency,
        },
      });
      dispatch({ type: STOP_LOADING });
    } catch (error) {
      // server error
      // other errors?
      dispatch({ type: NEED_SIGNIN });
      //dispatch({ type: STOP_LOADING });
    }
  } catch (error) {
    console.log("getMintedWisdoms>ERROR", error);
  }
};

export const getProposedWisdoms = (page, userName) => async (dispatch) => {
  //console.log("client.getProposedWisdoms>userName=", userName);
  try {
    const { innerWidth: screenWidth } = window;
    const isLoading = false;
    dispatch({ type: START_LOADING });
    const {
      data: { data, currentPage, numberOfPages, coinInCurrency },
    } = await api.fetchProposedWisdoms(page, screenWidth, userName);

    dispatch({
      type: FETCH_ALL_PROPOSED,
      payload: { data, currentPage, numberOfPages, coinInCurrency, isLoading },
    });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    //console.log(error);
    // server error
    // other errors? besides need to signin.
    // TODO auto click now of the signout button woould be helpful.
    console.log(
      "actions.wisdoms.getProposedWisdoms>ERROR",
      JSON.stringify(error, null, 3)
    );
    dispatch({ type: NEED_SIGNIN });
    //dispatch({ type: STOP_LOADING });
  }
}; // get proposed wisdoms

// see getWisdomsBySearch
export const getWisdomsByAuthor = (query) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data, coinInCurrency },
    } = await api.fetchWisdomsByAuthor(query);

    dispatch({ type: FETCH_BY_AUTHORS, payload: { data, coinInCurrency } });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getWisdomsByHashtag = (query) => async (dispatch) => {
  console.log("client.getWisdomsByHashtag>hashtagText=", query);
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data, currentPage, numberOfPages, coinInCurrency },
    } = await api.fetchWisdomsByHashtag(query);

    dispatch({
      type: FETCH_BY_HASHTAG,
      payload: { data, currentPage, numberOfPages, coinInCurrency },
    });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getWisdomsBySearch = (query) => async (dispatch) => {
  //  console.log("client.getWisdomsBySearch>query=", query);

  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data, currentPage, numberOfPages, coinInCurrency },
    } = await api.fetchWisdomsBySearch(query);

    dispatch({
      type: FETCH_BY_SEARCH,
      payload: { data, currentPage, numberOfPages, coinInCurrency },
    });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    console.log(error);
    dispatch({ type: STOP_LOADING });
  }
};

export const proposeWisdom = (post, history) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.proposeWisdom(post);

    dispatch({ type: PROPOSE_WISDOM, payload: data });

    history.push(`/wisdoms/${data._id}`);
  } catch (error) {
    console.log(error);
  }
};

export const updateWisdom = (id, post, userCanEditText) => async (dispatch) => {
  try {
    //console.log("actions.updateWisdom>", post);
    // mintPrice can change when hashtags change
    const {
      fontFamily,
      fontSize,
      fontColor,
      backgroundColor,
      status,
      mintPrice,
    } = post;

    var fields = {
      fontFamily,
      fontSize,
      fontColor,
      backgroundColor,
      mintPrice,
    };
    if (userCanEditText) {
      fields.wisdomText = post.wisdomText;
      fields.hashtagTexts = post.hashtagTexts;
      fields.status = status;
    }
    const { data } = await api.updateWisdom(id, fields);

    //    dispatch({ type: UPDATE, payload: data });
    dispatch({ type: UPDATE_WISDOM, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const voteWisdom = (id, voteType, voteTypeCount) => async (dispatch) => {
  const webUser = loadWebUser();
  try {
    const { data } = await api.voteWisdom(
      id,
      { voteType: voteType, voteTypeCount: voteTypeCount },
      webUser?.token
    );

    dispatch({ type: VOTE_WISDOM, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// export const likeWisdom = (id) => async (dispatch) => {
//   //const webUser = loadWebUser();
//   const webUser = loadWebUser();
//   try {
//     const { data } = await api.likeWisdom(id, webUser?.token);

//     dispatch({ type: LIKE_WISDOM, payload: data });
//   } catch (error) {
//     console.log(error);
//   }
// };

export const commentWisdom = (id, value) => async (dispatch) => {
  try {
    const webUser = loadWebUser();
    const userId = webUser?._id;
    const { data } = await api.comment(id, value, webUser?.token, userId);

    dispatch({ type: COMMENT, payload: data });

    return data.comments;
  } catch (error) {
    console.log(error);
  }
};

export const deleteWisdom = (id) => async (dispatch) => {
  try {
    const webUser = loadWebUser();
    await api.deleteWisdom(id, webUser?.token);

    dispatch({ type: DELETE_WISDOM, payload: id });
  } catch (error) {
    console.log(error);
  }
};

export const approveWisdom = (serverWisdom, data) => async (dispatch) => {
  try {
    //console.log("approveWisdom>before approval", data);
    // anything important in response?
    //const { data } = await api.likeWisdom(id, user?.token);
    const webUser = loadWebUser();

    //    await api.approveWisdom(serverWisdom._id, webUser?.token, webUser?.wallet);
    await api.approveWisdom(serverWisdom?._id, data, webUser?.token);
    // TODO need success check on approveWisdom server action
    serverWisdom.status = APPROVED_STRING;
    //console.log("approveWisdom>after approval", serverWisdom);
    // echo back the serverWisdom?
    dispatch({ type: APPROVE_WISDOM, payload: serverWisdom });
  } catch (error) {
    console.log(error);
  }
};
//
export const declineWisdom = (serverWisdom) => async (dispatch) => {
  try {
    //console.log("declineWisdom>before decline", serverWisdom);
    // anything important in response?
    //const { data } = await api.likeWisdom(id, user?.token);
    const webUser = loadWebUser();

    await api.declineWisdom(
      serverWisdom._id,
      serverWisdom.declinedBoundaryList,
      webUser?.token
    );
    // TODO need success check on declineWisdom server action
    serverWisdom.status = DECLINED_STRING;
    //console.log("declineWisdom>after decline", serverWisdom);
    // echo back the serverWisdom?
    dispatch({ type: DECLINE_WISDOM, payload: serverWisdom });
  } catch (error) {
    console.log(error);
  }
};
//
export const mintWisdom = (serverWisdom) => async (dispatch) => {
  try {
    //console.log("mintWisdom>before approval", serverWisdom);
    const webUser = loadWebUser();

    // anything important in response?
    await api.mintWisdom(serverWisdom._id, webUser?.token);
    // TODO need success check on mintWisdom server action
    serverWisdom.status = MINTED_STRING;
    //console.log("mintWisdom>after minted", serverWisdom);
    // echo back the serverWisdom?
    dispatch({ type: MINT_WISDOM, payload: serverWisdom });
  } catch (error) {
    console.log(error);
  }
};
//
export const cancelWisdom = (serverWisdom) => async (dispatch) => {
  try {
    console.log("cancelWisdom>before cancel", serverWisdom);
    // anything important in response?
    const webUser = loadWebUser();

    await api.cancelWisdom(serverWisdom._id, webUser?.token);
    // TODO need success check on mintWisdom server action
    serverWisdom.status = CANCELLED_STRING;
    //console.log("cancelWisdom>after cancel", serverWisdom);
    // echo back the serverWisdom?
    dispatch({ type: CANCEL_WISDOM, payload: serverWisdom });
  } catch (error) {
    console.log(error);
  }
}; // cancel wisdom

// export const sellWisdom = (serverWisdom) => async (dispatch) => {
//   try {
//     console.log("sellWisdom>before", serverWisdom);
//     // anything important in response?
//     const webUser = loadWebUser();

//     await api.sellWisdom(serverWisdom._id, webUser?.token);
//     // TODO need success check on mintWisdom server action
//     serverWisdom.status = MINTED_STRING;
//     console.log("sellWisdom>after minted", serverWisdom);
//     // echo back the serverWisdom?
//     dispatch({ type: MINT_WISDOM, payload: serverWisdom });
//   } catch (error) {
//     console.log(error);
//   }
// }; // sell wisdom

export const hideWisdom = (id) => async (dispatch) => {
  const webUser = loadWebUser();

  try {
    const { data } = await api.hideWisdom(id, webUser?.token);

    dispatch({ type: HIDE_WISDOM, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// export const unHideWisdom = (id) => async (dispatch) => {
//   const webUser = loadWebUser();

//   try {
//     const { data } = await api.unHideWisdom(id, webUser?.token);

//     dispatch({ type: UNHIDE_WISDOM, payload: data });
//   } catch (error) {
//     console.log(error);
//   }
// };

//
//Helper functions
//
export const areCommentsOn = () => {
  return WS_COMMENTS_ON;
};

export const computeWisdomMaxLength = () => {
  //console.log("computeWisdomMaxLength>webUser=", webUser);
  var wisdomLength = MAX_WISDOM_LENGTH_DEFAULT;
  // adapative length turned off (in contract too)
  // if (webUser) {
  //   if (
  //     isUserOwner(webUser) ||
  //     webUser?.hashtagsOwned?.length > 0 ||
  //     isOnEitherList(webUser, perm.APPROVE_WISDOM, perm.APPROVE_HASHTAG)
  //   ) {
  //     // contract owner and hashtag owners and approvers get max length
  //     wisdomLength = WISDOM_ABSOLUTE_MAX_LENGTH;
  //   } else {
  //     // use # owned, # minted
  //     wisdomLength += getWisdomsOwnedCount(webUser) * WISDOM_LENGTH_OWNER_BUMP;
  //     wisdomLength += getWisdomsOwnedCount(webUser) * WISDOM_LENGTH_MINTED_BUMP;
  //   }
  // } else {
  //   console.log("computeWisdomMaxLength>webUser NOT SET");
  //   wisdomLength = MAX_WISDOM_LENGTH_DEFAULT;
  // }

  // if (wisdomLength > WISDOM_ABSOLUTE_MAX_LENGTH)
  //   wisdomLength = WISDOM_ABSOLUTE_MAX_LENGTH;

  //console.log("computeWisdomMaxLength=", wisdomLength);
  return wisdomLength;
};

export const isWisdomHidden = (serverWisdom) => {
  return serverWisdom?.hidden;
};

export const isWisdomProposed = (serverWisdom) => {
  return serverWisdom?.status === PROPOSED_STRING;
};

export const isWisdomApproved = (serverWisdom) => {
  return serverWisdom?.status === APPROVED_STRING;
};

export const isWisdomDeclined = (serverWisdom) => {
  return serverWisdom?.status === DECLINED_STRING;
};

export const isWisdomMinted = (serverWisdom) => {
  return serverWisdom?.status === MINTED_STRING;
};

export const isUserWisdomOwner = (user, wisdom) => {
  // console.log("isUserWisdomOwner>user", user);
  // console.log("isUserWisdomOwner>user._id", user?._id);
  // console.log("isUserWisdomOwner>user.googleId", user?.googleId);
  // console.log("isUserWisdomOwner>wisdom.authorId", wisdom?.authorId);
  if (!user || !wisdom) return false;
  return user?._id === wisdom?.ownerId; // user?.googleId === wisdom?.ownerId ||
};

export const isUserWisdomAuthor = (user, wisdom) => {
  if (!user || !wisdom) return false;
  return user?._id === wisdom?.authorId; // || user?.googleId === wisdom?.authorId ;
};
//
export const isWisdomForSale = (serverWisdom) => {
  const nowMS = Math.round(Date.now() / 1000);

  //  return serverWisdom?.status === MINTED_STRING && serverWisdom?.forSaleNow;
  return (
    serverWisdom?.status === MINTED_STRING &&
    serverWisdom?.saleStartDateMS <= nowMS &&
    serverWisdom?.saleEndDateMS >= nowMS &&
    serverWisdom?.forSaleNow
  );
};
//

//
export const getNewCoinInUsd = async () => {
  const nowMS = Math.round(Date.now() / 1000);

  if (
    lastEthPriceGetMS === 0 ||
    lastEthPriceGetMS < nowMS - SECONDS_BETWEEN_ETH_PRICE_FETCH
  ) {
    //console.log("getNewCoinInUsd>fetching");
    lastEthPriceGetMS = nowMS;
    //
    // old way, ask coingecko.
    //
    // const response = await fetch(
    //   "https://api.coingecko.com/api/v3/simple/price?ids=" +
    //     COINGECKO_COIN_NAME +
    //     "&vs_currencies=usd"
    // );
    //
    //
    // new new way:
    //  coinInCurrency comes as payload of all fetchWisdoms
    // hardcode for testing
    var respJson = {};
    respJson["data"] = { usd: 0.91 };

    // new way. ask MakeItWisdom server - which always has a fresh answer!
    // TODO: let user select currency on Settings page!
    // const respJson = await api.coinInCurrency({
    //   coinName: COINGECKO_COIN_NAME,
    //   currencyName: "usd",
    // });
    //    const respJson = await response.json(); //extract JSON from the http response
    //
    //
    // do something with myJson
    console.log("getNewCoinInUsd>respJson", respJson);
    coinInUsd = respJson?.data["usd"];

    console.log("coin in usd = ", coinInUsd, nowMS);
  }
  // else {
  //   console.log(
  //     "getEthInDollars>no fetch.",
  //     nowMS - lastEthPriceGetMS,
  //     " returning",
  //     coinInUsd
  //   );
  // }
  return coinInUsd;
}; // get coin in currency

export const getCoinInUsdNow = () => {
  // return the cached value.
  return Math.abs(coinInUsd);
};

export const getCoinInUsd = async () => {
  console.log("getCoinInUsd>", coinInUsd);
  // get value if not known
  if (coinInUsd === -1) await getNewCoinInUsd();
  return coinInUsd;
};

export const convertEthToUsd = async (eth) => {
  var val;

  if (coinInUsd === -1) await getNewCoinInUsd();

  if (coinInUsd > -1) {
    val = coinInUsd * eth;
  } else {
    val = 0.0;
  }
  console.log("convertEthToUsd>", eth, val.toFixed(2));
  return val.toFixed(2);
};

export const delay = async (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};
