//
// SOME of these constants should be local storage variables LOADED from smart contract
//    so website is (almost never) out of sync w/ contract.
//
//
// false means website live. All widgets all features
// true means
//    show tweet to be notified of beta
//    no navbar, no menu, no wallet, no login,
//    no wisdomForm
//    yes minted Wisdom page, pagination
//    yes searchToy
//    ?? Wisdom per page 3. new parm (sbt) true/false ??
//
// MUST align w/ SHOW_BETA_TWEET in serverOptions.json
export const SHOW_BETA_TWEET = false;
export const WISDOM_SSE_ON = true;
export const COMING_SOON = "Coming soon";
export const DO_LOG = false;
export const SIGN_IN_TO_ACCOUNT = "Sign in to your MakeItWisdom.com account.";
export const SIGN_IN_TO_ACCOUNT_AGAIN =
  "Please sign in to your MakeItWisdom.com account again.";
//
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
export const DAYS_PER_WEEK = 7;
export const SECONDS_PER_WEEK = SECONDS_PER_DAY * DAYS_PER_WEEK;
export const DAYS_PER_YEAR = 365;
export const SECONDS_PER_YEAR = SECONDS_PER_DAY * DAYS_PER_YEAR;
// wisdom and hashtag status
export const MAX_WISDOM_LENGTH_DEFAULT = 300;
export const WISDOM_ABSOLUTE_MAX_LENGTH = 300;
export const WISDOM_LENGTH_OWNER_BUMP = 40;
export const WISDOM_LENGTH_MINTED_BUMP = 40;
export const WISDOM_LENGTH_XP_BUMP = 1;
// Wisdom status strings
export const APPROVED_STRING = "Approved";
export const CANCELLED_STRING = "Cancelled";
export const DECLINED_STRING = "Declined";
export const HIDDEN_STRING = "Hidden";
export const MINTED_STRING = "Minted";
export const PROPOSED_STRING = "Proposed";

export const SETTINGS_STRING = "Settings";
// Wisdom status enum
export const PROPOSED_ENUM = 0;
export const APPROVED_ENUM = 1;
export const DECLINED_ENUM = 2;
export const MINTED_ENUM = 3;
export const HIDDEN_ENUM = 4;
export const CANCELLED_ENUM = 5;
// user stats strings
export const STATUS_ACTIVE_STRING = "active";
export const STATUS_INACTIVE_STRING = "inactive";

// for localstorage
export const PROFILE = "profile";
export const DISPLAY_PROFILE = "displayProfile";
export const DISPLAY_WISDOM_TYPE = "displayWisdomType";
export const DISPLAY_FREE_HASHTAGS_ONLY = "displayFreeHashtagsOnly";

// for menu
export const DIVIDER = "divider";
export const LIST_ITEM = "list_item";

export const COINGECKO_COIN_NAME = "matic-network";
export const COIN_LABEL = " MATIC";
export const COIN_LABEL_PAREN = "(MATIC)";
export const COIN_ABBR_LABEL = " MATIC";
export const USD_ABBR_LABEL = " USD";
export const COIN_ABBR_LABEL_PAREN = "(MATIC)";
export const SECONDS_BETWEEN_ETH_PRICE_FETCH = 180;

// charge to make a wisdom for sale
export const MAKE_WISDOM_FOR_SALE_CHARGE = 1.0; // MATIC polygon pricing (was 0.01)
// see WisdomContract._wisdomMintPrice
export const WISDOM_MINT_PRICE_BASE = 41.0; // MATIC polygon pricing (was 0.03)
// see MakeItWisdomContract.gpLevels[0]
export const WISDOM_FIRST_SALE_PRICE = 80.0; // MATIC polygon pricing (was 2.0)
export const WISDOM_SALE_DURATION_HOURS = 48; // hours

// WS = Web Site
export const WS_COMMENTS_BY_WISDOM_AUTHOR = 1;
export const WS_COMMENTS_BY_WISDOM_OWNER = 1;
export const WS_COMMENTS_ON = false;

// SC = Smart Contract
export const SC_MINT_POLICY = "EVERYONE";

// Permissions
export const PERMISSION_HOURS_DEFAULT = 48;
export const PERMISSION_COUNT_DEFAULT = 2;

export const MAX_IMAGE_SIZE = 500000;

// server config
export const WISDOM_PAGE_LIMIT_DESKTOP = "WISDOM_PAGE_LIMIT_DESKTOP";
export const WISDOM_PAGE_LIMIT_MOBILE = "WISDOM_PAGE_LIMIT_MOBILE";
export const WISDOM_SEARCH_LOG = "WISDOM_SEARCH_LOG";
export const WISDOM_EXPIRE_CHECK_ME_MS = "WISDOM_EXPIRE_CHECK_ME_MS";
export const WISDOM_EXPIRE_OTHER_MS = "WISDOM_EXPIRE_CHECK_OTHER_MS";
export const WISDOM_EXPIRE_LOG = "WISDOM_EXPIRE_LOG";
export const WISDOM_EXPIRE_ON = "WISDOM_EXPIRE_ON";

export const HASHTAG_PAGE_LIMIT_DESKTOP = "HASHTAG_PAGE_LIMIT_DESKTOP";
export const HASHTAG_PAGE_LIMIT_MOBILE = "HASHTAG_PAGE_LIMIT_MOBILE";
export const HASHTAG_SEARCH_LOG = "HASHTAG_SEARCH_LOG";
export const HASHTAG_EXPIRE_CHECK_ME_MS = "HASHTAG_EXPIRE_CHECK_ME_MS";
export const HASHTAG_EXPIRE_CHECK_OTHER_MS = "HASHTAG_EXPIRE_CHECK_OTHER_MS";
export const HASHTAG_EXPIRE_LOG = "HASHTAG_EXPIRE_LOG";
export const HASHTAG_EXPIRE_ON = "HASHTAG_EXPIRE_ON";

// dateMS that takes hidden wisdom out of the way of autoSale
export const DATE_MS_12_31_2099 = 32503679999;

const wisdomAnimationArray = [
  //"",
  "animateJiggleLeft",
  "animateJiggleRight",
  "animateSmallFinal",
  "animateSmallBigFinal",
  "animateBigFinal",
  "animateBigSmallFinal",
  "animateSmallFinal",
  "animateSmallBigFinal",
  "animateBigFinal",
  "animateBigSmallFinal",
];

export const selectWisdomAnimation = () => {
  //  return wisdomAnimationArray[1];
  return wisdomAnimationArray[
    Math.floor(Math.random() * wisdomAnimationArray.length)
  ];
};

//
//
// api status
export const STATUS_CODE_USER_IS_UNAUTH = 405;
export const STATUS_CODE_WISDOM_NOT_UNIQUE = 406;

// Server-Sent Events
//  must match what is in server/configs/serverOptions.json
//
export const WISDOM_FIELD_NAMES_SSE = [
  "likesCount",
  "feelsCount",
  "thinksCount",
  "cheersCount",
  "approvedDateMS",
  "buyStartedMS",
  "cancelledDateMS",
  "currentValue",
  "declinedDateMS",
  "fontFamily",
  "fontSize",
  "fontColor",
  "forSaleNow",
  "forSalePrice",
  "hidden",
  "mintedDateMS",
  "ownedSinceDateMS",
  "ownerId",
  "ownerName",
  "ownerTwitterHandle",
  "ownerWallet",
  "proposedDateMS",
  "saleEndDateMS",
  "saleStartDateMS",
  "status",
  "viewsCount",
];
