//
//
import {
  FETCH_HASHTAGS,
  FETCH_HASHTAG,
  PROPOSE_HASHTAG,
  UPDATE_HASHTAG,
  DELETE_HASHTAG,
  HIDE_HASHTAG,
  UNHIDE_HASHTAG,
  START_LOADING_HASHTAGS,
  STOP_LOADING_HASHTAGS,
  APPROVE_HASHTAG,
  DECLINE_HASHTAG,
  MINT_HASHTAG,
  CANCEL_HASHTAG,
} from "../constants/actionTypes";

export default (
  state = { isLoading: true, needSignin: false, hashtags: [], reload: false },
  action
) => {
  switch (action.type) {
    case START_LOADING_HASHTAGS:
      return { ...state, isLoading: true, reload: false };
    case STOP_LOADING_HASHTAGS: {
      //console.log("reducer:wisdooms.STOP_LOADING>");

      return { ...state, isLoading: false, reload: false };
    }
    case FETCH_HASHTAGS:
      return {
        ...state,
        hashtags: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        reload: false,
      };
    case FETCH_HASHTAG:
      return { ...state, post: action.payload.post };
    case APPROVE_HASHTAG:
      return { ...state, post: action.payload.post };
    case DECLINE_HASHTAG:
      return { ...state, post: action.payload.post };
    case MINT_HASHTAG:
      return { ...state, post: action.payload.post };
    case CANCEL_HASHTAG:
      return { ...state, post: action.payload.post };

    case HIDE_HASHTAG:
    case UNHIDE_HASHTAG:
      return { ...state, post: action.payload.post };

    case PROPOSE_HASHTAG:
      return { ...state, hashtags: [...state.hashtags, action.payload] };
    case UPDATE_HASHTAG:
      return {
        ...state,
        hashtags: state.hashtags.map((hashtag) =>
          hashtag._id === action.payload._id ? action.payload : hashtag
        ),
      };
    case DELETE_HASHTAG:
      return {
        ...state,
        hashtags: state.hashtags.filter(
          (hashtag) => hashtag._id !== action.payload
        ),
      };

    default:
      //console.log("reducer:hashtags>default action.type-", action.type);
      return state;
  }
};
