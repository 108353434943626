import { Container, Grow, Paper } from "@material-ui/core";
import React from "react";
import {
  //  COIN_ABBR_LABEL,
  //  COIN_ABBR_LABEL_PAREN,
  COIN_LABEL_PAREN,
} from "../../constants/constants";
//import React, { Children } from "react";
//import ReactDOM from "react-dom";
import FAQ from "./GetStarted";
import useStyles from "./styles";
import { getMyBCConfig } from "../../configs/multiConfig.mjs";
// try using QA json and .map

//const { bcNetworkName, bcChainId, bcRpcUrl, bcExplorerUrl } = getMyBCConfig();
// const bcPurposeJSON = getMyBCConfig();
// const networkName = bcPurposeJSON.netwokName;
// const chainId = bcPurposeJSON.chainId;
// const rpcUrl = bcPurposeJSON.rpcUrl;
const { networkName, chainId, rpcUrl } = getMyBCConfig();

export default function GetStarteds() {
  const classes = useStyles();

  function buildOne(afaq) {
    // var link = afaq.link
    //   ? "<a href='" + afaq.link + "'>" + afaq.linkText + "</a>"
    //   : "";

    return (
      <FAQ.QAItem key={afaq.id}>
        <FAQ.Question answerId={afaq.id}>
          {(isOpen, onToggle) => {
            return (
              <>
                {/* {isOpen ? 'Open ' : 'Close '} */}
                <span>{afaq.ques}</span>
              </>
            );
          }}
        </FAQ.Question>
        <FAQ.Answer id={afaq.id} className={classes.FAQ__question}>
          {afaq.ans}
          <a href={afaq.link}> {afaq.linkText} </a>
        </FAQ.Answer>
      </FAQ.QAItem>
    );
  }

  return (
    <Grow in>
      <Container maxWidth="xl" id="FAQs" className={classes.FAQs}>
        <Paper
          elevation={8}
          style={{
            padding: 12,
            // backgroundColor: "blue",
            border: "1px solid black",
          }}
        >
          <FAQ>
            {/* {buildFAQ()} */}
            {GSArray.map(buildOne)}
          </FAQ>
          <h2>
            <strong>Crypto Wallets</strong>

            <div className={classes.seeAlsoDiv}>
              <a
                href="https://metamask.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                MetaMask
              </a>
              <br />
              <a
                href="https://www.coinbase.com/wallet"
                target="_blank"
                rel="noopener noreferrer"
              >
                (future) Coinbase WalletLink
              </a>
              <br />
              <a
                href="https://walletconnect.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                (future) Any WalletConnect wallet
              </a>
              <br />
            </div>
          </h2>
        </Paper>
      </Container>
    </Grow>
  );
} // GetStarteds

export const GSArray = [
  {
    id: "q1",
    ques: "Step 1. Enjoy the Wisdom.",
    ans: "Scroll, keyword search and filter by hashtags to enjoy Wisdom! (bonus: Tell all your friends to visit. :-)",
  },
  {
    id: "q2",
    ques: "Step 2. Signup.",
    ans: "Signup to get a free MakeItWisdom.com account. Real names are required. Email address must be complete the simple one step verification process.",
  },
  {
    id: "q3",
    ques: "Step 3. Connect to your crypto wallet.",
    ans:
      "Your crypto wallet will be used to transfer crypto currency " +
      COIN_LABEL_PAREN +
      " when you mint, buy and sell Wisdom and earn residuals. If you haven't already, download/install a crypto wallet into your web browser (Chrome, Firefox or Edge). Follow the directions at ONE of the (Crypto Wallets) links below. During setup, select the (or add new) network called '" +
      networkName +
      "' (Chain ID= " +
      chainId +
      "  and RPC URL= " +
      rpcUrl +
      ").",
  },
  {
    id: "q4",
    ques: "Step 4. Propose Wisdom.",
    ans: "Fill out the form. Optionally, attach a picture. Optionally, attach 1 or more hashtags (may add to the mint price at the hashtag owner's discretion).",
  },
  {
    id: "q5",
    ques: "Step 5. Mint Wisdom.",
    ans: "After your proposed Wisdom is approved, confirm the hashtags and mint price, click the Mint button then confirm the transaction in your crypto wallet. *** WAIT for notification of success or failure before leaving the webpage ***",
  },
  {
    id: "q6",
    ques: "Step 6. Invite people to propose Wisdom.",
    ans: "As a Wisdom user, you may invite people to propose Wisdom! See ",
    link: "/invites",
    linkText: "Invite",
  },
  {
    id: "q7",
    ques: "Step 7. Transfer to your wallet... enjoy!",
    ans: "Proceeds from sales and residuals accumulate in the smart contract until you press the Transfer button to move them to your crypto wallet. Once in your wallet you can buy more Wisdom, transfer to an exchange, sell for (old-fashioned) currency, etc. Enjoy! ",
  },
  {
    id: "q8",
    ques: "Step 8. Tell everyone you know, again.",
    ans: "Let's make Wisdom more important than everything else on the web!",
  },
  // {
  //   id: "q7",
  //   ques: "Step 7",
  //   ans: "",
  // },
];
