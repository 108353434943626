import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";

import { injected } from "../lib/connectors";
import { useHistory } from "react-router-dom";
import { setProfileValue } from "../../src/components/myLocalStorage";

const doLog = false;
export function useEagerConnect() {
  //console.log("useEagerConnect");
  const { activate, active } = useWeb3React();

  const [tried, setTried] = useState(false);

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, [activate]);

  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress = false) {
  if (doLog) console.log("useInactiveListener>");
  const { active, error, activate } = useWeb3React();

  const history = useHistory();
  useEffect(() => {
    const { ethereum } = window;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = (chainId) => {
        if (doLog) console.log("chainChanged", chainId);
        activate(injected);
      };

      const handleAccountsChanged = (accounts) => {
        if (doLog) console.log("index.ts>accountsChanged", accounts);
        if (accounts.length > 0) {
          setProfileValue("wallet", accounts[0]);
          activate(injected);
          // try
          //deactivate();
          history.push(`/wallet/activateWallet?account=${accounts}`);
          history.push("/");
        }
      };

      const handleNetworkChanged = (networkId) => {
        if (doLog) console.log("index.ts>networkChanged", networkId);
        activate(injected);
      };

      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          // deprecated
          //ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, error, suppress, activate]);
}
