import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import withState from "recompose/withState";

// import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { DEFAULT_CONVERTER, converters } from "./transformers";
import PickerDialog from "./PickerDialog";
//import { rgbToHex } from "@material-ui/core";

const ColorPicker = ({
  // ColorPicker
  onChange,
  //   onChangeFont,
  convert,

  classes,
  // Text field
  //   name,
  //   id,
  //   hintText,
  //   placeholder,
  //   floatingLabelText,
  //   label,
  //   TextFieldProps,
  destination,
  value,
  buttonLabel,

  // State
  showPicker,
  setShowPicker,
  internalValue,
  setValue,

  //   showPickerFont,
  //   setShowPickerFont,
  //   internalValueFont,
  //   setValueFont,

  ...custom
}) => (
  <>
    {/* <TextField
      name={name}
      id={id}
      label={floatingLabelText || label}
      placeholder={hintText || placeholder}
      onClick={() => setShowPicker(true)}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
        // hintText = e.target.value;
      }}
      InputProps={{
        style: { color: value === undefined ? internalValue : value },
      }}
      {...TextFieldProps}
      {...custom}
    /> */}
    <div>
      <Button
        className={classes.buttonSubmit}
        style={{
          borderRadius: 5,
          // color: destination == "font" ? internalValue : "black",
          // backgroundColor:
          //   destination == "background" ? internalValue : "lightgray", // "#21b6ae",
          padding: "16px 10px",
          //   fontSize: "18px",
        }}
        variant="contained"
        // color="#AA00BB"
        size="small"
        onClick={() => setShowPicker(true)}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          // hintText = e.target.value;
        }}
      >
        {buttonLabel}
      </Button>
      &nbsp;&nbsp;
    </div>

    {showPicker && (
      <div>
        <PickerDialog
          value={value === undefined ? internalValue : value}
          onClick={() => {
            setShowPicker(false);
            onChange(value);
          }}
          onChange={(c) => {
            const newValue = converters[convert](c);
            setValue(newValue);
            onChange(newValue);
            //console.log("ShowPicker>onChange=", converters.rgb(c));
          }}
        />
      </div>
    )}
    {/* {showPickerFont && (
      <div>
        <PickerDialog
          value={value === undefined ? internalValueFont : value}
          onClick={() => {
            setShowPickerFont(false);
            onChangeFont(value);
          }}
          onChange={(c) => {
            const newValue = converters[convert](c);
            setValueFont(newValue);
            onChangeFont(newValue);
            console.log("ShowPickerFont>onChange=", converters.rgb(c));
          }}
        />
      </div>
    )} */}
  </>
);

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onChangeFont: PropTypes.func,
  convert: PropTypes.oneOf(Object.keys(converters)),
  name: PropTypes.string,
  id: PropTypes.string,
  hintText: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  floatingLabelText: PropTypes.string,
  //   TextFieldProps: PropTypes.shape(TextField.propTypes),
  showPicker: PropTypes.bool,
  setShowPicker: PropTypes.func,
  internalValue: PropTypes.string,
  setValue: PropTypes.func,
  //   showPickerFont: PropTypes.bool,
  //   setShowPickerFont: PropTypes.func,
  //   internalValueFont: PropTypes.string,
  //   setValueFont: PropTypes.func,
};

ColorPicker.defaultProps = {
  convert: DEFAULT_CONVERTER,
};

const makeColorPicker = compose(
  withState("showPicker", "setShowPicker", false),
  withState("internalValue", "setValue", ({ defaultValue }) => defaultValue)
  //   withState("showPickerFont", "setShowPickerFont", false),
  //   withState(
  //     "internalValueFont",
  //     "setValueFont",
  //     ({ defaultValue }) => defaultValue
  //   )
);

const MakedColorPicker = makeColorPicker(ColorPicker);

const ColorPickerField = ({
  input: { value, onChange, ...restInput },
  meta: { touched, error },
  ...restProps
}) => (
  <MakedColorPicker
    value={value}
    onChange={onChange}
    errorText={touched && error}
    {...restInput}
    {...restProps}
  />
);

ColorPickerField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default MakedColorPicker;

export { ColorPickerField };
