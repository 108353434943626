import React from "react";
import Grid from "@material-ui/core/Grid";
//import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
//import VolumeUp from "@material-ui/icons/VolumeUp";
import useStyles from "./styles";

export default function FontSlider({
  handleFontSizeChange,
  initValue,
  minValue,
  maxValue,
  defaultValue,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(initValue);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    handleFontSizeChange(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
    handleFontSizeChange(Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < minValue) {
      setValue(minValue);
    } else if (value > maxValue) {
      setValue(maxValue);
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        width="90%"
      >
        <Grid item>
          <Input
            className={classes.fontSliderInput}
            value={value}
            margin="dense"
            variant="outlined"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: { minValue },
              max: { maxValue },
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>

        <Grid item xs>
          <div className={classes.fontSlider}>
            <Slider
              //orientation="horizontal"
              getAriaValueText={() => {
                return value;
              }}
              min={minValue}
              max={maxValue}
              onChange={handleSliderChange}
              defaultValue={defaultValue}
              aria-labelledby="vertical-slider"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
} // font slider
