import React from "react";
import {
  Container,
  Grow,
  Typography,
  Card,
  CardMedia,
  Grid,
  Button,
} from "@material-ui/core";
//import { useDispatch } from "react-redux";
//import { useHistory } from "react-router-dom";

import useStyles from "./styles";
import Masonry from "react-masonry-css";
import { logEvent } from "../../api";

import {
  getMyBCConfig,
  //  getMyDBName,
  getMyDBConfig,
} from "../../configs/multiConfig.mjs";

//import Alert from "../Alert/Alert.js";
//import { Link } from "@material-ui/icons";

//import reactLogo from "../../images/hardhat_logo.jpg";

// import {
//   PROPOSED_STRING,
//   MINTED_STRING,
//   DISPLAY_WISDOM_TYPE,
// } from "../../constants/constants";

// import {
//   getDisplayProfileValue,
//   setDisplayProfileValue,
// } from "../localStorage";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }
//const dbName = "memories_v0";
// const bcName = "devTest";
// const bcURL = "http://";
// const bcChainId = "13337"; //chainId

const { networkName, chainId, rpcUrl } = getMyBCConfig();
const { dbName } = getMyDBConfig();
//
//
const About = () => {
  const classes = useStyles();
  //  const query = useQuery();
  //   const dispatch = useDispatch();

  //  const history = useHistory();

  //  var displayWisdomType = MINTED_STRING; // TODO pull from storage
  const breakpoints = {
    default: 5,
    1100: 4,
    750: 3,
  };

  const cards = [
    {
      title: "Patrick Collins",
      imageFile: require("../../images/Patrick_Collins_logo.jpg"),
      link: "https://www.youtube.com/c/PatrickCollins",
    },
    {
      title: "Javascript Mastery",
      imageFile: require("../../images/javascript_mastery_logo.jpg"),
      link: "https://www.youtube.com/c/JavaScriptMastery/videos",
    },
    {
      title: "React",
      imageFile: require("../../images/react-1-logo-png-transparent.png"),
      link: "http://react.com",
    },
    {
      title: "Hardhat",
      imageFile: require("../../images/hardhat_logo.jpg"),
      link: "http://hardhat.org",
    },
    {
      title: "MongoDB",
      imageFile: require("../../images/MongoDB_logo.jpg"),
      link: "https://cloud.mongodb.com",
    },
    {
      title: "Polygon",
      imageFile: require("../../images/polygon_logo.jpg"),
      link: "https://polygon.technology/",
    },
    {
      title: "NodeJS",
      imageFile: require("../../images/nodejs_logo.jpg"),
      link: "https://nodejs.org/en/",
    },
    {
      title: "ngrok",
      imageFile: require("../../images/ngrok_logo.jpg"),
      link: "https://ngrok.com/",
    },
    {
      title: "Digital Ocean",
      imageFile: require("../../images/DigitalOcean_logo.jpg"),
      link: "https://www.digitalocean.com",
    },
  ];

  //  const alertChildren = "<div> CHILDREN </div>";
  return (
    <div>
      <Typography variant="h4" className={classes.responsiveFont}>
        <p></p>
        <center>
          Add a little Wisdom to your daily life.
          <p />
          "Wisdom makes every lesser use of human language bearable."
          <p /> Let's make internet bearable.{" "}
        </center>
        <br />
      </Typography>
      <hr />
      <Typography variant="h6">
        <p></p>
        Thanks to...
        <p />
      </Typography>
      <Grow in>
        <Container maxWidth="xl">
          {/* <Paper
          elevation={8}
          style={{
            padding: 12,
            border: "1px solid black",
            //minHeight: "30vh",
            maxHeight: "70vh",
          }}
        > */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
            className={classes.gridContainer}
          >
            <Grid item xs={12} sm={6} md={12}>
              <Masonry
                breakpointCols={breakpoints}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {cards.map((card, index) => (
                  <Card
                    className={classes.card}
                    raised
                    elevation={6}
                    key={"AboutCard" + card.title}
                  >
                    <CardMedia
                      className={classes.media}
                      key={"Card-CardMedia" + card.title}
                      image={card.imageFile}
                      style={{ cursor: "pointer" }}
                      onClick={(event) => {
                        event.preventDefault();
                        logEvent({
                          eventName: "aboutClick",
                          data1: card.title,
                        });
                        window.open(card.link, "_blank");
                      }}
                    />
                    <center>
                      <Button
                        size="large"
                        color="primary"
                        key={"about_" + card.title}
                        //
                        target="_blank"
                        href={card.link}
                        onClick={(event) => {
                          event.preventDefault();
                          logEvent({
                            eventName: "aboutClick",
                            data1: card.title,
                          });
                          window.open(card.link, "_blank");
                        }}
                      >
                        {card.title}
                      </Button>
                    </center>
                    <br />
                    {/* <Link to="/" className={classes.brandContainer}>
                      <img
                        className={classes.image}
                        src={require("../../images/react-1-logo-png-transparent.png")}
                        alt="icon"
                      />
                    </Link> */}
                  </Card>
                ))}
              </Masonry>
            </Grid>
          </Grid>
          {/* </Paper> */}
        </Container>
      </Grow>
      <hr />
      <br />
      <Typography variant="h6">
        <p></p>
        MakeItWisdom.com is not investment advice.
        <br />
        Wallet blockchain network setup is: Name: <strong>{networkName}</strong>
        , Chain id: <strong>{chainId}</strong>, RPC URL:{" "}
        <strong>{rpcUrl}</strong>
        <br />
        Current database is: <strong>{dbName}</strong>
        <br />
        Copyright 2023 @DanFarfan. v0.1.0 beta (5-Jan-23 5:00am)
        <p />
      </Typography>
      <p /> <p />
    </div>
  );
};

export default About;
