import { makeStyles } from "@material-ui/core/styles";

// all copied from Home/styles.js
//
export default makeStyles((theme) => ({
  appBarSearch: {
    borderRadius: 4,
    marginBottom: "1rem",
    display: "flex",
    padding: "16px",
  },
  pagination: {
    borderRadius: 4,
    marginTop: "1rem",
    padding: "16px",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "bloxk",
  },
  mintButton: {
    margin: "10px",
    display: "inline-block",
    color: "#FFFFFF",
  },
  seeAlsoDiv: {
    marginLeft: "10px",
  },
}));
