import React from "react";
import { Container, Grow, Typography, Paper } from "@material-ui/core";
//import { CallMissedSharp } from "@material-ui/icons";
// import { useDispatch } from "react-redux";
// import { useHistory, useLocation } from "react-router-dom";

import useStyles from "./styles";
// import {
//   PROPOSED_STRING,
//   MINTED_STRING,
//   DISPLAY_WISDOM_TYPE,
// } from "../../constants/constants";

// import {
//   getDisplayProfileValue,
//   setDisplayProfileValue,
// } from "../localStorage";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const ResidualNFT = () => {
  const classes = useStyles();
  // const query = useQuery();
  //   const dispatch = useDispatch();

  //   const history = useHistory();

  //  var displayWisdomType = MINTED_STRING; // TODO pull from storage

  return (
    <Grow in>
      <Container maxWidth="xl">
        <Paper
          elevation={8}
          style={{
            padding: 12,
            // backgroundColor: "blue",
            border: "1px solid black",
          }}
        >
          <Typography variant="h6">
            Each minted Wisdom is a residual NFT (rNFT) that rewards everyone
            who had a hand in its success (when applicable). A Wisdom brand rNFT
            suprasses a traditional NFT in features and protections.
            <div>
              <ul>
                The Wisdom author receives 10% of each sale of their work.
              </ul>
              <ul>The current Wisdom owner receives 70% of the sale.</ul>
              <ul>
                The marketplace receives 7-10% each sale and 75-85% each mint.
              </ul>
              <ul>The previous 5 owners each receive 2% each sale.</ul>
              <ul>The Wisdom approver receives 15-25% each mint.</ul>
              <ul>
                A hashtag owner receives optional mint price bump (Wisdoms with
                their hashtag).
              </ul>
            </div>
            <p></p>
            The marketplace, wisdom approver and hashtag owner share in the mint
            price.
            <br />
            The marketplace, author and owners share in the sale price.
            <br />
            All residuals are paid immediately and automatically by smart
            contract. That's advanced web3 crypto blockchain protection at its
            finest!
            <br />
            <i>A traditional NFT doesn't even protect its price. Really.</i>
            <p />
            <h3>
              See also:
              <div className={classes.seeAlsoDiv}>
                <a href="/transfer">Transfer</a>
                <br />
                <a href="/pioneers">Pioneers</a>
                <br />
                <a href="/faqs">FAQ</a>
              </div>
            </h3>
          </Typography>
        </Paper>
      </Container>
    </Grow>
  );
};

export default ResidualNFT;
