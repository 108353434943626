import React, { useState } from "react";
import { COIN_ABBR_LABEL, COIN_LABEL } from "../../constants/constants";
import AlertBanner from "../AlertBanner/AlertBanner.js";

export const TransferLockboxForm = ({ onSubmit, onChange, data }) => {
  //
  const handleCloseAlert = () => {
    // changing state should cause rerender
    setAlertBanner({ ...alertBanner, showNow: false });
  };
  const alertKey = "TransferFormAlertKey";
  const [alertBanner, setAlertBanner] = useState({
    children: null,
    type: "error",
    message: "",
    showNow: false,
    theKey: alertKey,
    handleClose: handleCloseAlert,
  });

  const checkAmount = (event) => {
    if (data?.transferBalanceEth > 0) {
      onSubmit(event);
    } else {
      // error!
      setAlertBanner({
        children: null,
        type: "error",
        message: "Balance must be > 0",
        showNow: true,
        theKey: alertKey,
        handleClose: handleCloseAlert,
      });
      onSubmit(event);
    }
  }; // check amount
  //
  return (
    <form onSubmit={checkAmount}>
      <AlertBanner {...alertBanner}></AlertBanner>
      <div className="form-group">
        <label style={{ fontSize: "1.5em" }} htmlFor="transferBalanceEth">
          1 {COIN_LABEL} = ${data?.coinInCurrency} (approximately)
          <p />
          Are you sure you want to transfer {COIN_ABBR_LABEL} from MakeItWisdom
          to your crypto wallet? There is no undo.
          <p /> {COIN_LABEL} to transfer
          <br />
        </label>
        <input
          className="form-control"
          id="transferBalanceEth"
          style={{ fontSize: "1.5em" }}
          value={data?.transferBalanceEth}
          onChange={onChange}
        />
      </div>
      {/* <div className="form-group">
        <label htmlfor="email">Email address</label>
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="name@example.com"
        />
      </div> */}
      <div className="form-group">
        <br />
        <button
          className="form-control btn btn-primary"
          type="submit"
          style={{ fontSize: "1.5em" }}
        >
          Submit
        </button>
      </div>
    </form>
  );
};
export default TransferLockboxForm;
