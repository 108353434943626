import React from "react";
import {
  Typography,
  //  TextField,
  //  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core/";
//import { useDispatch } from "react-redux";
import useStyles from "./styles";
import HelpOutline from "@material-ui/icons/HelpOutline";

import {
  // commentWisdom,
  // isUserWisdomAuthor,
  // isUserWisdomOwner,
  isWisdomDeclined,
  isWisdomProposed,
} from "../../actions/wisdoms";

import { boundaryArray } from "../Boundaries/Boundaries.js";

//import useStyles from "./styles";
//import { PROFILE } from "../../constants/constants";
import { loadWebUser } from "../myLocalStorage";
import {
  canUserApproveWisdoms,
  canUserDeclineWisdoms,
} from "../../actions/users";

const BoundarySection = ({
  serverWisdom,
  setAlertBannerHelper,
  setBoundaryCount,
}) => {
  // constructor(props) {
  //       super(props);
  //       this.state = {value: props.value};
  //       this.handleChange = this.handleChangeBoundary.bind(this);
  //       this.state = { value: props.value || "" };
  //   };
  const webUser = loadWebUser();
  const classes = useStyles();
  //const commentsRef = useRef();

  const handleChangeBoundary = (boundary) => (event, boundaryValue) => {
    event.persist();
    //console.log("handleChangeBoundary>event.value", event.target.value);
    if (event.target.checked) {
      serverWisdom.declinedBoundaryList.push(parseInt(event.target.value));
    } else {
      // remove boundary number from array
      const pos = serverWisdom.declinedBoundaryList.indexOf(
        parseInt(event.target.value)
      );
      if (pos > -1) serverWisdom.declinedBoundaryList.splice(pos, 1);
      // for (var i = 0; i < serverWisdom.declinedBoundaryList.length; i++) {
      //   if (serverWisdom.declinedBoundaryList[i] == event.target.value) {
      //     serverWisdom.declinedBoundaryList.splice(i, 1);
      //   }
      // }
    }
    setBoundaryCount(serverWisdom.declinedBoundaryList.length);
    //console.log("handleChangeBoundary>list", serverWisdom.declinedBoundaryList);
    //window.location.reload();
  };

  // const handleAddBoundary = (event) => {
  //   console.log("");
  //   serverWisdom.declinedBoundaryList.push(event.value);
  // };

  // const handleRemoveBoundary = (event) => {
  //   //serverWisdom.declinedBoundaryList.push(event.value);
  // };
  // console.log(
  //   "BoundarySection>wisdom.declinedBoundaryList=",
  //   serverWisdom.declinedBoundaryList
  // );
  return (
    <div>
      {/* <Typography>
        {boundaryArray.map((boundary) => (
          <div key={boundary.number}>{boundary.text}</div>
        ))}
      </Typography> */}
      {(canUserApproveWisdoms(webUser) && isWisdomProposed(serverWisdom)) ||
        (canUserDeclineWisdoms(webUser, serverWisdom) && (
          <FormGroup key="TheFormGroupKey">
            {boundaryArray.map((boundary) => (
              <div
                className={classes.boundaryRow}
                key={"BoundarySectionDivKey" + boundary.number.toString()}
              >
                <Tooltip
                  placement="top"
                  key={"ToolTipKey" + boundary.number.toString()}
                  title={<h2>{boundary.info}</h2>}
                >
                  <button
                    className={classes.btnhelp}
                    key={"InfoButtonKey" + boundary.number.toString()}
                    onClick={() => setAlertBannerHelper(boundary.info)}
                  >
                    <HelpOutline />
                  </button>
                </Tooltip>

                <FormControlLabel
                  className={classes.boundaryText}
                  key={"BoundaryNumber" + boundary.number.toString()}
                  onChange={handleChangeBoundary(boundary)}
                  control={
                    <Checkbox
                      key={"CheckboxKey" + boundary.number.toString()}
                      checked={
                        serverWisdom?.declinedBoundaryList.indexOf(
                          boundary.number
                        ) !== -1 || false
                      }
                    />
                  }
                  label={boundary.text}
                  value={boundary.number}
                />
              </div>
            ))}
          </FormGroup>
        ))}

      {isWisdomDeclined(serverWisdom) && (
        <div>
          {serverWisdom.declinedBoundaryList.map((boundary) => (
            <Typography key={"declined" + boundary.toString()}>
              {boundaryArray[boundary]?.text}
            </Typography>
          ))}
        </div>
      )}
      {/* <div className={classes.commentsOuterContainer}>
        <div className={classes.commentsInnerContainer}>
          <Typography gutterBottom variant="h6">
            Comments
          </Typography>
          {comments?.map((c, i) => (
            <div key={"comment" + serverWisdom._id + i.toString()}>
              <Typography
                key={"commentText" + serverWisdom._id + i.toString()}
                gutterBottom
                variant="subtitle1"
                onClick={hideComment}
              >
                <strong>{c.split(": ")[0]}</strong>
                {c.split(":")[1]}
              </Typography>
            </div>
          ))}
          <div ref={commentsRef} />
        </div>
        <div style={{ width: "70%" }}>
          <Typography gutterBottom variant="h6">
            Write a comment
          </Typography>
          <TextField
            fullWidth
            minRows={4}
            disabled={!canUserComment(webUser, serverWisdom)}
            variant="outlined"
            label="Comment"
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <br />
          {canUserComment(webUser, serverWisdom) && (
            <Button
              style={{ marginTop: "10px" }}
              fullWidth
              disabled={!comment.length}
              color="primary"
              variant="contained"
              onClick={handleComment}
            >
              Comment
            </Button>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default BoundarySection;
