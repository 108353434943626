import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";

import { useEagerConnect, useInactiveListener } from "../../hooks";
import connectorList, {
  resetWalletConnectConnector,
} from "../../lib/connectors";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { activateWallet } from "../../actions/wallet";

import { Dropdown } from "semantic-ui-react";
//import { PROFILE } from "../../constants/constants";
import { loadWebUser, saveProfile } from "../myLocalStorage";

const doLog = false;

const ConnectWallet = () => {
  //  console.log("ConnectWallet>");
  const [isConnecting, setIsConnecting] = useState(false);
  const { activate, deactivate, active, error, account } = useWeb3React();
  const triedEager = useEagerConnect();
  const dispatch = useDispatch();
  const history = useHistory();

  useInactiveListener(!triedEager);

  const handleActivateWallet = () => {
    if (account) {
      if (doLog) console.log("cw>handleActivateWallet>account=", account);
      // tell server all about it
      const webUser = loadWebUser();
      if (webUser) {
        // remember account as user's current wallet
        if (doLog) console.log("cw>handleActivateWallet>adopting", account);
        webUser.wallet = account;

        // save user profile to local storage
        saveProfile(webUser);
        //      console.log("handleActivateWallet>email=", user.result.email);
      } else {
        console.log("cw>handleActivateWallet>NO WEBUSER");
      }
      dispatch(activateWallet({ email: webUser.email, account }));
      // these 2 lines be in a reducer that handles activate wallet
      history.push(`/wallet/activateWallet?account=${account}`);
      history.push("/");
    } else {
      console.log("cw>activateWallet>NO account");
    }
  };

  const handleClick = (connectorName) => async () => {
    if (doLog) console.log("cw>handleClick>connectorName=", connectorName);
    setIsConnecting(true);
    activate(connectorList[connectorName]);
    if (doLog) console.log("cw>handleClick>after activate");
  };

  const handleDisconnect = () => {
    if (doLog) console.log("cw>handleDisconnect>", account);
    const webUser = loadWebUser();
    if (webUser) {
      if (doLog) console.log("cw>handleDisconnect>adopting", account);
      webUser.wallet = account;

      // save user profile to local storage
      saveProfile(webUser);
    } else {
      console.log("cw>handleDisconnect>NO WEBUSER");
    }
    deactivate();
    setIsConnecting(false);
  };

  const handleRetry = () => {
    if (doLog) console.log("cw>handleRetry");
    setIsConnecting(false);
    resetWalletConnectConnector(connectorList["WalletConnect"]);
    deactivate();
  };

  useEffect(() => {
    //    console.log("ConnectWallet.useEffect>");
    if (active) {
      if (doLog) console.log("cw>useEffect>is active");
      if (isConnecting) {
        if (doLog) console.log("cw>useEffect>IS connecting");

        handleActivateWallet();
      } else {
        if (doLog) console.log("cw>useEffect>NOT connecting");
        // djf 3/14/22 experiment. tell server. causes infinite loop
        //history.push(`/wallet/activateWallet?account=${account}`);
        //history.push("/");
      }
      setIsConnecting(false);
    } else {
      if (doLog) console.log("cw>useEffect>NOT active");
    }
    //handleActivateWallet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, isConnecting]);

  return (
    <Dropdown text="Wallet" pointing className="link item">
      {(() => {
        if (active) {
          return (
            <Dropdown.Menu>
              <Dropdown.Item
                className="button-disconnect"
                onClick={handleDisconnect}
                style={{ cursor: "pointer" }}
              >
                Disconnect
              </Dropdown.Item>
            </Dropdown.Menu>
          );
        } else {
          if (!error) {
            return (
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={handleClick("MetaMask")}
                  style={{ margin: "5px", cursor: "pointer" }}
                >
                  MetaMask
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={handleClick("WalletConnect")}
                  style={{ margin: "5px", cursor: "pointer" }}
                >
                  WalletConnect
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleClick("WalletLink")}
                  style={{ margin: "5px", cursor: "pointer" }}
                >
                  WalletLink
                </Dropdown.Item> */}
              </Dropdown.Menu>
            );
          } else {
            return (
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleRetry}>Retry</Dropdown.Item>
              </Dropdown.Menu>
            );
          }
        }
      })()}
    </Dropdown>
  );
};

export default ConnectWallet;
