//
//
//
import React from "react";
import { func, string } from "prop-types";
import { Button } from "@material-ui/core";

// const Button = styled.button`
//   background: ${({ theme }) => theme.background};
//   border: 2px solid ${({ theme }) => theme.toggleBorder};
//   color: ${({ theme }) => theme.text};
//   border-radius: 30px;
//   cursor: pointer;
//   font-size:1rem;
//   padding: 0.6rem;
//   }
// `;
const Toggle = ({ toggleTheme }) => {
  return (
    <Button variant="contained" color="primary" onClick={toggleTheme}>
      Switch Theme
    </Button>
  );
};
Toggle.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
};

//
export default Toggle;
