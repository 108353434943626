import { Container, Grow, Paper } from "@material-ui/core";
import React from "react";
//import ReactDOM from "react-dom";
import FAQ from "./FAQ";
import useStyles from "./styles";

// try using QA json and .map
export default function FAQs() {
  const classes = useStyles();

  function buildOne(afaq) {
    // var link = afaq.link
    //   ? "<a href='" + afaq.link + "'>" + afaq.linkText + "</a>"
    //   : "";
    return (
      <FAQ.QAItem key={afaq.id}>
        <FAQ.Question answerId={afaq.id}>
          {(isOpen, onToggle) => {
            return (
              <>
                {/* {isOpen ? 'Open ' : 'Close '} */}
                <span>{afaq.ques}</span>
              </>
            );
          }}
        </FAQ.Question>
        <FAQ.Answer id={afaq.id} className={classes.FAQ__question}>
          {afaq.ans}
          <a href={afaq.link}> {afaq.linkText} </a>
        </FAQ.Answer>
      </FAQ.QAItem>
    );
  }

  return (
    <Grow in>
      <Container maxWidth="xl" id="FAQs" className={classes.FAQs}>
        <Paper
          elevation={8}
          style={{
            padding: 12,
            // backgroundColor: "blue",
            border: "1px solid black",
          }}
        >
          <FAQ>
            {/* {buildFAQ()} */}
            {faqArray.map(buildOne)}
          </FAQ>
        </Paper>
      </Container>
    </Grow>
  );
} // FAQs

const faqArray = [
  {
    id: "q1",
    ques: "What is this crypto project?",
    ans: "MakeItWisdom is the blockchain-secure marketplace for curated RNFTs (Residual Non-Fungible Tokens) called Wisdom. See also ",
    link: "/residualnft",
    linkText: "Residual NFTs",
  },
  {
    id: "q1a",
    ques: "What qualifies as Wisdom?",
    ans: "Good question! I know what doesn't. See ",
    link: "/boundaries",
    linkText: "Boundaries",
  },
  {
    id: "q2",
    ques: "How can I mint new Wisdom?",
    ans: "After you sign up/sign in and connect your crypto wallet, you can pay to mint Wisdom, optionally under 1 or more hashtags. The Wisdom mint process has these steps: you propose, we approve/decline, you mint/cancel. All MakeItWisdom blockchain transactions must be confirmed via your crypto wallet, are stored on the blockchain, are final and can't be undone or reversed.",
  },
  {
    id: "q3",
    ques: "How can I buy Wisdom?",
    ans: "Each Wisdom goes on sale every few days for a price above its current price. After you install, connect and put some crypto (MATIC) in your crypto wallet, use it to authorize purchases of the Wisdom you want to own. All purchases are final and can't be reversed.",
  },
  {
    id: "q4",
    ques: "Where can I buy/sell/trade the crypto coin MATIC?",
    ans: "MATIC is the crypto coin of the Level 2 Ethereum blockchain by Polygon. It trades on many crypto exchanges such as PayPal.com, RobinHood.com, Binance.us and Coinbase.com. Make CERTAIN the exchange you select allows you to use and/or send crypto to your own crypto wallet! ",
    link: "https://binance.us",
    linkText: "Affiliate link to Binance.us",
  },
  {
    id: "q5",
    ques: "How can I sell my Wisdom?",
    ans: "Any time a Wisdom is not for sale, the Wisdom owner can make it for sale at a price they specify by clicking on the SELL button on the Wisdom. There is a small charge (plus gas fee) to make Wisdom for sale on demand.",
  },
  {
    id: "q6",
    ques: "Why use MATIC instead of ether or bitcoin?",
    ans: "Bitcoin doesn't support smart contracts. It's just a token. Ethereum has smart contracts, but high transaction costs and long wait times make it less desirable than the solution Polygon provides. In the future, who knows? ",
    link: "https://polygon.technology",
    linkText: "Polygon's website",
  },
  {
    id: "q7",
    ques: "What happens when Wisdom is bought?",
    ans: "When a Wisdom is bought, residuals are paid automatically, immediately. See ",
    link: "/residualnft",
    linkText: "Residual NFT",
  },
  {
    id: "q8",
    ques: "Why are hashtags selected from a list?",
    ans: "Hashtags are the only allowed way to group Wisdom into categories. By managing hashtags, users can elect to curate a set of Wisdom in a category they are passionate about. Hashtags expand the depth and breadth of the Wisdom marketplace.",
  },
  {
    id: "q9",
    ques: "How can I mint a new hashtag?",
    ans: "This feature may be turned on after the beta period.",
    //    ans: "The hashtag mint process has these steps: you propose, we approve/decline, you mint/cancel. This feature will be available after the beta test period. All MakeItWisdom blockchain transactions must be confirmed via your crypto wallet, are stored on the blockchain, are final and can't be undone or reversed.",
  },
  // {
  //   id: "q10",
  //   ques: "What perks come with owning a hashtag?",
  //   ans: "Hashtag owners curate which Wisdoms appear with their hashtag by using the first 24 hours after a Wisdom is proposed to accept or decline it. Hashtag owners can optionally add to the Wisdom mint price to earn crypto as their hashtag grows successful.",
  // },
  {
    id: "q11",
    ques: "What is a Pioneer?",
    ans: "A Pioneer is someone who has paid for a slice of the Wisdom revenue stream secured by the smart contract. See the page for ",
    link: "/pioneers",
    linkText: "Pioneers",
  },
  {
    id: "q12",
    ques: "What about taxes?",
    ans: "Pay all taxes according to the laws in your area. MakeItWisdom.com does not provide a summary of your activity.",
  },
  // {"id":"q9",
  // "ques":"",
  // "ans":""},
];
